import React from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { StyledDialog } from './Dialog.style';
import IconButton from "../IconButton";
import cx from 'classnames';

const DialogComponent = ({ open, children, header, footer, onClose, className, modal=true, showCloseIcon = false, maxContentHeight, ...rest }) => {
  const contentStyle = {};
  if (maxContentHeight) {
    contentStyle.maxHeight = maxContentHeight;
    contentStyle.overflow = 'auto';
  }

  const handleClose = (event, reason) => {
    event.stopPropagation();
    event.preventDefault();
    if (modal && reason === "backdropClick") {
      return false;
    }

    // if (modal && reason === "escapeKeyDown") {
    //   return false;
    // }

    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth={1000}
      className={cx('dlg', className)}
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      {...rest}
    >
      <StyledDialog>
        <div>
          {header || showCloseIcon ? <div className="dialog-title">
            <div>{header}</div>
            {showCloseIcon && <div className="icon-close" onClick={handleClose}> <IconButton iconName="Close" tooltip="Close" /></div>}
          </div> : null}
          <div className="dialog-content" style={contentStyle}>
            {children}
          </div>
          {footer && <DialogActions className="dialog-footer">
            {footer}
          </DialogActions>}
        </div>
      </StyledDialog>
    </Dialog>
  );
};

export default DialogComponent;

