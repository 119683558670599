import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useRedirectAuthenticatedUser from "../../../hooks/useRedirectAuthenticatedUser";
import { userLoginAsync } from "../../../asyncActions/authAsyncActions";
import queryString from "../../../utils/queryString";
import PageTitle from "../../../components/PageTitle";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { StyledPublicPages } from "../PublicPages.style";
import Error from "../../../components/Error";
import { userLogout } from "../../../actions/authActions";
import Logo from "../../../components/Logo/Logo";
import routes from '../../routes';

// custom hook to manage the form input
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    setValue,
    onChange: handleChange,
  };
};

function Login() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { user, userLoginLoading, loginError } = authObj;

  useRedirectAuthenticatedUser(user);

  const initialUsername = queryString.getParameter("email") || "";
  const username = useFormInput(initialUsername);
  const password = useFormInput("");
  const mfaCode = useFormInput(null);
  const isMfaRequired = loginError === 'MFA_CODE_REQUIRED';
  const isInMFAStage = isMfaRequired || !!mfaCode.value;
  const [clientError, setClientError] = useState("");

  // handle button click of login form
  const handleLogin = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (username.value && password.value) {
      setClientError("");
      dispatch(userLoginAsync(username.value, password.value, mfaCode.value));
    } else setClientError("Please fill in your credentials to log in");
  };

  const handleKeypress = (e) => {
    // it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleLogin(e);
    }
  };

  return (
    <StyledPublicPages>
      <div className="marketing-note">
        <a href="https://www.novvo.ai"><Logo /></a>
        <div className="marketing-content">
          <Typography variant="h3">
            Analyze startups. Smarter. Faster.
          </Typography>
          <p>
            Get the most of your minute on Novvo. Novvo is the simplest and fastest way to get from raw data to deep insights in just few minutes.
          </p>
        </div>
      </div>
      <div className="form log-in">
        <div className="upper-links">
          Don't have an account? <NavLink to={routes.Public.GetStarted}>Sign up for free</NavLink>
        </div>
        <div className="form-content">
          <PageTitle>Log in</PageTitle>
          {isInMFAStage ?
            <>
          <Typography variant="p">
            You were sent an email with a verification code.<br />Please enter it to continue.
          </Typography>
          <div data-testid="mfa_code">
            <TextInput
              key={"mfa"}
              maxLength={6}
              focus
              label="Verification code"
              type="text"
              autocomplete="off"
              onKeyPress={handleKeypress}
              {...mfaCode}
            />
          </div>
          </>
            : <>
          <div data-testid="email_contatiner">
            <TextInput
              key="email"
              focus
              label="Email"
              autoComplete="current-password"
              onKeyPress={handleKeypress}
              {...username}
            /><br />
            <TextInput
              key="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onKeyPress={handleKeypress}
              {...password}
            />
          </div>
          </>}
          {clientError || (loginError && !isMfaRequired) ? (
            <Error error={clientError || loginError} className="form__error" />
          ) : null}

          <Button className="button" color="identity-green" size="large"
            disabled={isInMFAStage ? !mfaCode.value : userLoginLoading}
            onClick={handleLogin}
            isLoading={userLoginLoading}
          >
            {isInMFAStage ? 'Continue' : 'Log in'}
          </Button>
          <div className="links">
            {isInMFAStage ? <span onClick={() => {
              dispatch(userLogout());
            }}>
              Back to login
            </span> : <Link to={{ pathname: `${routes.Public.ForgotPassword}?email=${username.value || ''}` }} onClick={() => setClientError('')}>
              Forgot your password?
            </Link>}
            {/*<NavLink to="/getstarted">Create an account</NavLink>*/}
          </div>
          {/*<div className="links">*/}
          {/*    <NavLink to="/signup">Create an account</NavLink>*/}
          {/*</div>*/}
          </div>
        </div>
    </StyledPublicPages>
  );
}

export default Login;
