import styled from 'styled-components';

export const StyledChartLegend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.label};
  font-size: 1.2rem;
  line-height: 12px;
  > div {
    margin: 0 1.2rem;
    span {
      vertical-align: middle;
    }
    i {
      vertical-align: middle;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: black;
      margin-right: 4px;
      border-radius: 3px;
      &.line {
        height: 4px;
        width: 13px;
      }
    }
  }

  .growth-item {
    background-color: ${props => props.theme.colors.lighterGray};
    padding: 3px 10px;
    border-radius: 4px;
  }
`;
