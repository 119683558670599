import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FormLabel from "../FormLabel";
import { StyledRadioButton } from "./RadioButton.style";

function RadioButton({ label, options, error, defaultValue, selectedValue, layout='horizontal', ...rest }) {
  const inputName = `input_` + Date.now();

  return (
      <StyledRadioButton error={error} layout={layout}>
        <FormLabel label={label} error={error} />
        <div className="options">
          {options.map((option, index) => {
            const optionalProps = {};
            optionalProps.disabled = option.disabled;
            if (selectedValue || option.checked) {
              optionalProps.checked = option.checked || selectedValue === option.value;
            } else {
              optionalProps.defaultChecked = defaultValue === option.value;
            }
            return <label className="radio" key={`${inputName}_${index}`}>
                        <span className="radio__inp">
                          <input type="radio" name={inputName} value={option.value} {...rest} {...optionalProps} />
                          <svg xmlns="http://www.w3.org/2000/svg" style={{visibility: option.disabled ? 'hidden' : 'visible'}}>
                          <path d="M8,1A7,7,0,1,1,1,8,7.00793,7.00793,0,0,1,8,1M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Z"/>
                          <path d="M12,8A4,4,0,1,1,8,4,4,4,0,0,1,12,8Z"/>
                          </svg>
                        </span>
                        <div className="radio__lbl">{option.label}</div>
                    </label>;
          })}
        </div>
      </StyledRadioButton>
  );
}

RadioButton.propTypes = {
  label: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.any,
    selected: PropTypes.bool
  })),
  error: PropTypes.bool,
  defaultValue: PropTypes.string
};

export default RadioButton;
