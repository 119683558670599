import styled from 'styled-components';

export const StyledInitialPageLoadProgressBar = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  text-align: center;
  background-color: ${props => props.theme.colors.white};
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  .progress-bar_root {
    margin-top: 5px;
  }
  > svg {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0.7;
  }
  &.fade-out {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  @-webkit-keyframes fadeOut {
      0% {opacity: 1;}
      100% {opacity: 0;}
   }

   @keyframes fadeOut {
      0% {opacity: 1;}
      100% {opacity: 0;}
   }
`
