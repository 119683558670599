import BaseApi from './base-api';

class MetadataAPI extends BaseApi{
  constructor() {
    super('/metadata');
  }

  getIndustries(isAuthenticated) {
    return isAuthenticated ? this.get('industries') : this.get('industries/public');
  }
}

export default new MetadataAPI();


