import styled from 'styled-components';

export const StyledNetwork = styled.div`
 .h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .page-content {
    display: flex;
    margin: 15px 0;

    .groups {
      width: 240px;
      margin-right: 30px;
    }

    .headers {
      display: flex;
      color: ${props => props.theme.colors.legend};
      font-size: 12px;
      width: 100%;
      justify-content: space-between;
      > div:first-child {
        width: 300px;
        padding-left: 40px;
      }
      .additional {
        display: flex;
        text-align: right;
        width: calc(100% - 300px);
        justify-content: space-between;

        .left-side {
          width: 310px;
          display: flex;
          .status {
            text-align: left;
            width: 120px;
            margin-right: 25px;
          }
          .action {
            width: 140px;
            text-align: left;
            margin-right: 25px;
          }
        }

        .right-side {
          display: flex;
          align-items: center;
          width: ${props => props.isStartup ? '222px' : '127px'};
          .permissions {
            text-align: left;
            width: 80px;
            margin-right: 15px;
          }
          .date {
            text-align: left;
            width: 80px;
            margin-right: 15px;
          }
          > div:last-child {
            width: 32px;
          }
        }
      }
    }

    .main-content {
      flex: 1;
      margin: 0;
      .main:not(:last-child) {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      .main {
        padding: 15px 20px 45px 20px;
        background-color: white;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
        border-radius: 4px;
        .quick-actions {
          .actions {
            display: flex;
            svg {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            a {
              display: flex;
              align-items: center;
              font-size: 13px;
              margin-right: 50px;
            }
          }
        }
        > div > div:last-child .members-list {
          margin-bottom: 0;
        }
        .header-main {
          position: relative;
          h2 {
            margin-right: auto;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .invite-button {
            &.button-container {
              font-weight: bold;
              font-size: 14px;
              padding: 4px 10px;
              line-height: 14px;
              letter-spacing: normal;
            }
          }
        }

        .table-header {
          display: flex;
          align-items: center;
          color: #bbb;
          border-bottom: 1px solid #eee;
          font-size: 12px;

          > div:first-child {
              flex: 1;
          }

          .status {
            width: 170px;
          }
          .action {
            width: 170px;
          }
          .date {
            width: 150px;
          }
          .actions {
            width: 50px;
          }
        }
      }
    }

    .suggestions {
      margin-left: 30px;
      width: 250px;
      h3 {
        font-size: 14px;
      }
    }
  }
`;

