import styled from 'styled-components';

export const StyledComposedChart = styled.div`
  width: 100%;

  .chart {
    .recharts-cartesian-grid-horizontal > line:last-child,
    .recharts-cartesian-grid-vertical > line:last-child {
      display: none;
    }
    //.recharts-text.recharts-cartesian-axis-tick-value tspan  {
    //  text-transform: capitalize;
    //}
    &.selectable {
      .recharts-bar {
        .recharts-bar-rectangles {
          .recharts-rectangle {
            cursor: pointer;
            &.allows-selection:hover {
              stroke: ${props => props.mainColor};
              stroke-width: 2;
              &.partial {
                stroke: ${props => props.theme.colors.black};
              }
            }
            &.partial {
              stroke-width: 2;
            }
          }
        }
        &.stacked-bar {
          .recharts-rectangle.selected.w-border-selection {
            stroke: ${props => props.mainColor};
            stroke-width: 2;
          }
        }
        &:not(.stacked-bar) {
          .recharts-rectangle:hover {
            stroke: ${props => props.mainColor};
            stroke-width: 2;
          }
        }
      }



      .recharts-line-dots svg {
        cursor: pointer;

        &:hover {
          stroke: ${props => props.mainColor};
          stroke-width: 2;
        }
      }
    }

    .recharts-rectangle {
      &.partial {
        opacity: 0.7;
        stroke: ${props => props.mainColor};
        stroke-dasharray: 4;
        stroke-linecap: square;
        stroke-width: 1;
      }
    }

    &.hasSelection {
      .recharts-rectangle {
        opacity: .4 !important;

        &.selected {
          opacity: 1 !important;
        }
      }

      .recharts-line-dots svg:not(.insights) {
        opacity: .4 !important;

        &.selected {
          opacity: 1 !important;
        }
      }
    }

  }

`;

