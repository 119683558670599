import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import { userForgotPasswordService } from '../../../api/auth';
import useRedirectAuthenticatedUser from '../../../hooks/useRedirectAuthenticatedUser';
import { StyledPublicPages } from "../PublicPages.style";
import PageTitle from "../../../components/PageTitle";
import Error from "../../../components/Error";
import queryString from "../../../utils/queryString";
import Logo from "../../../components/Logo/Logo";

// custom hook to manage the form input
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange,
    };
};

function ForgotPassword() {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const email = queryString.getParameter("email") || "";
    const isReset = queryString.getParameter("reset") || "";
    const [completedSuccessfully, setCompletedSuccessfully] = useState(false);
    const { user } = useSelector((state) => state.auth);
    useRedirectAuthenticatedUser(user);

    const username = useFormInput(email);

    // handle button click of login form
    const handleForgot = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        setBusy(true);
        setError(null);
        // eslint-disable-next-line no-unused-vars,no-shadow
        userForgotPasswordService(username.value).then(({ success, data, error }) => {
            setBusy(false);
            if (!success) setError(error);
            else setCompletedSuccessfully(true);
        });
    };

    const handleKeypress = (e) => {
        // it triggers by pressing the enter key
        if (e.charCode === 13) {
            handleForgot(e);
        }
    };

    return (
        <StyledPublicPages className="forgot-password-form">
          <div className="marketing-note">
            <a href="https://www.novvo.ai"><Logo /></a>
            <div className="marketing-content">
              <Typography variant="h3">
                Analyze startups. Smarter. Faster.
              </Typography>
              <p>
                Get the most of your minute on Novvo. Novvo is the simplest and fastest way to get from raw data to deep insights in just few minutes.
              </p>
            </div>
          </div>
          <div className="form log-in">
            <div className="upper-links">
              <NavLink to={`/login?email=${username.value || ''}`}>Log into your account</NavLink>
            </div>
            <div className="form-content">
                <PageTitle>{isReset ? 'Reset' : 'Forgot'} password</PageTitle>
                {completedSuccessfully ? (
                    <div className="links">
                        Email sent successfully
                      <br /><br />
                    </div>
                ) :
                <div>
                    <TextInput
                        focus
                        label="Email"
                        autoComplete="new-password"
                        onKeyPress={handleKeypress}
                        {...username}
                    />
                </div>}

              {!completedSuccessfully ? <><Button className="button" color="identity-green" size="large" disabled={busy || !username.value} onClick={handleForgot} isLoading={busy}>
                    Reset my password
                </Button>{(error) ? <Error error={error} className="form__error" /> : null}</> : <div className="links">
                    <NavLink to={`/login?email=${username.value || ''}`}>Log into your account</NavLink>
                </div>}
            </div>
          </div>
        </StyledPublicPages>
    );
}

export default ForgotPassword;
