import styled from 'styled-components';

export const StyledAnalytics = styled.div`
  h1.h1 {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
      }
    }
  }

  .nav-panel {
    position: absolute;
    left: 3.3rem;
  }

    .date-notice {
      display: flex;
      justify-content: flex-end;
      font-size: 11px;
      font-weight: normal;
      color: ${props => props.theme.colors.label};
      align-items: center;
      text-align: right;
      height: 20px;
      strong {
        font-size: 13px;
        &.status-bad {
          color: ${props => props.theme.colors.bad};
        }
        &.status-warning {
          color: ${props => props.theme.colors.warning};
        }
        &.status-good {
          color: ${props => props.theme.colors.good};
        }
      }

    }
    .insights {
      margin-left: 12px;
      width: 220px;
    }
  }
.template-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .header {
    width: 100%;
    > div {
      padding: 16px 20px;
    }
    .upper-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 2px 24px 2px 23px;
    }
  }

  .template-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
    &.w-insights {
      width: calc(100% - 230px);
      margin-right: 10px;
    }
    .settings {
      width: 240px;
      border-right: 10px solid #f6f6f6;
      .toggle-button {
        display: flex;
        margin-bottom: 2.4rem;
        button {
          width: 100%;
        }
      }
      .btn-opener {
        width: 100%;
      }
    }
    .main {
      flex-grow: 1;
      width: calc(100% - 264px);
      .empty-message {
        margin-bottom: 2rem;
        font-size: 14px;
        color: ${props => props.theme.colors.label};
        max-width: 60%;
      }
    }
  }

  @media only screen and (min-width: ${(props) =>
  props.theme.general.xlBreakpoint}) {
      flex-direction: row;
      .settings {
        .btn-opener {
          width: auto;
        }
      }
      .main {width: 0;}
  }

`;

export const StyledAnalyticsContent = styled.div`
  border-radius: 4px;

  .dashboard-values {
    padding: 1rem 24px 0 24px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 80px;
      > div {
        background: ${props => props.theme.colors['identity-cyan']};
        color: white;
        padding: 1rem 2rem 0.5rem 2rem;
        border-radius: 4px;
        border: 1px solid transparent;
        width: 25%;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 24px;
        }
        &:hover {
          box-shadow: 2px 2px 5px #ccc;
        }
        .spinner {
          border: 3px solid #ccc;
          border-top: 3px solid white;
        }
      }
    }
  }

  .dashboard-charts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 24px 0 24px;
    .kpi-chart {
      padding: 14px 5px;
      width: 33.33%;
      min-height: 270px;
      cursor: pointer;
      margin-bottom: 16px;
      &:not(:nth-child(3n)) {
        margin-right: 16px;
        width: calc(33.33% - 16px)
      }
      .h3 {
        font-size: 18px;
      }

      .recharts-responsive-container.chart {
        .recharts-wrapper {
          cursor: pointer !important;
        }
      }
    }
  }

  .drilldown {
    .multi-charts {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 50%;
        .column-header {
          font-size: 9px;
          &:first-child {
            width: 80px;
          }
        }
        .row {
          > .row-label {
            width: 80px;
          }
          > div {
            font-size: 9px;
          }
        }
      }
    }
    .top-section {
      margin-bottom: 2rem;
      padding: 1rem 24px 0 24px;
      .main-chart {
        .multi-charts {
          > div {
            margin-bottom: 20px;
          }
        }
      }
    }

    .bottom-section {
      border-top: 10px solid #f6f6f6;
      padding: 1rem 24px 0 24px;
      .header {
        > div {
          padding: 10px 0;
          margin-right: 20px;
          font-size: 1.3rem;
        }
      }
      .tab-content {
        padding: 0;
        min-height: 300px;
        max-height: unset;
      }
      .charts {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        .h3 {
          font-size: 16px;
        }

        > div {
          width: 48%;
          margin: 2rem 0;
        }
      }

      .concentration {
        margin-top: 10px;
      }

      .recharts-responsive-container.chart {
        .recharts-wrapper {
          cursor: pointer !important;
        }
      }
    }
  }
`;

export const StyledInfoPopupDialog = styled.div`
  font-size: 13px;
  min-height: 85px;
  > pre {
    span {
      display: block;
      &.divider {
        line-height: 5px;
        display: block;
      }
    }
  }
`;




export const StyledRegenerateDialog = styled.div`
  font-size: 12px;
`;

export const StyledPreferencesDialog = styled.div`
  height: calc(100vh - 13rem);
  width: calc(100vw - 60px);
`;
