import React, { useEffect, useState } from "react";
import { validateSignature } from '../../api/auth';
import PageSpinner from '../../components/PageSpinner/PageSpinner';
import { userReset } from '../../api/auth';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { StyledPublicPages } from "./PublicPages.style";
import PageTitle from "../../components/PageTitle";
import Error from "../../components/Error";
import Logo from "../../components/Logo/Logo";
import Typography from "../../components/Typography";

function Reset() {
  const { sig: verification } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [resetPasswordBusy, setResetPasswordBusy] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const password = useFormInput('');

  useEffect(() => {
    validateSignature(verification).then(({success, data}) => {
      if (success)
        setUserData(data);
      else
        navigate('/login');
    });
  }, [verification, navigate]);

  // checking authentication
  if (!userData) {
    return <PageSpinner caption="Loading..." />
  }

  const handleReset = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setResetPasswordBusy(true);
    userReset(userData.email, password.value, verification).then(({success, error}) => {
      if (success) {
        setResetPasswordSuccess(true);
      }
      else {
        setResetPasswordBusy(false);
        setResetPasswordError(error);
      }
    });
  };

  return (
    <StyledPublicPages className="login-form">
      <div className="marketing-note">
        <a href="https://www.novvo.ai"><Logo /></a>
        <div className="marketing-content">
          <Typography variant="h3">
            Analyze startups. Smarter. Faster.
          </Typography>
          <p>
            Get the most of your minute on Novvo. Novvo is the simplest and fastest way to get from raw data to deep insights in just few minutes.
          </p>
        </div>
      </div>
      <div className="form">
        <div className="form-content">
          <PageTitle>Reset password</PageTitle>
          <form>
            <div className="form">
              {resetPasswordSuccess ?
                <div className="reset-success">Password changed successfully<br /><br />
                  <NavLink exact to={"/login?email=" + userData.email}>Log into your account</NavLink></div> :
                <>
                  <div className="reset-email">
                    <TextInput
                      label="Email"
                      type="text"
                      value={userData.email}
                      disabled
                    />
                    <br />
                  </div>
                  <div>
                    <TextInput
                      label="Choose your new password"
                      type="password"
                      autoComplete="password"
                      focus
                      {...password}
                    />
                  </div>
                  <Button disabled={resetPasswordBusy} onClick={handleReset} isLoading={resetPasswordBusy} className="button" color="identity-green" size="large">
                    {resetPasswordBusy ? 'Changing...' : 'Change password'}
                  </Button>
                  {resetPasswordError ? <Error error={resetPasswordError} className="form__error" /> : null}
                </>}
            </div>
          </form>
        </div>
      </div>
    </StyledPublicPages>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Reset;
