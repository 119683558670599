import React from 'react';
import PropTypes from "prop-types";
import { StyledPageTitle } from './StyledPageTitle';
import Icon from "../Icon";
import Typography from "../Typography";
import { useNavigate } from 'react-router-dom';

const PageTitle = ({ children, icon, variant, path, onIconClick, onClick, className }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (path)
      return navigate(path);
    if (onIconClick)
      return onIconClick();
    navigate(-1);
  };
  return (
    <StyledPageTitle onClick={onClick}>
      {icon ? <Icon type={icon} size="md" onClick={handleClick} /> : null}
      <Typography variant={variant} className={className}>
        {children}
      </Typography>
    </StyledPageTitle>
  );
};

PageTitle.propTypes = {
  icon: PropTypes.oneOf(['', 'back', 'close']),
  variant: Typography.propTypes.variant,
  path: PropTypes.string,
  onIconClick: PropTypes.func
};

PageTitle.defaultProps = {
  icon: '',
  variant: 'h1',
  path: null
};

export default PageTitle;
