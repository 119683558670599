import BaseApi from './base-api';

class SupportAPI extends BaseApi {
  constructor() {
    super('/support');
  }

  getRequestsList() {
    return this.get('requests');
  }

  inviteNovvoSupport(id) {
    return this.post(`invite`, { id });
  }

  revokeNovvoSupport(id) {
    return this.post('revoke', { id });
  }

  updateRequestsStatus(id, status) {
    return this.put(`request/${id}/status/mark`, { status });
  }

}

export default new SupportAPI();
