import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import useRedirectAuthenticatedUser from '../../../hooks/useRedirectAuthenticatedUser';
import PageTitle from '../../../components/PageTitle';
import {StyledSignup} from './Signup.style';
import PublicAPI from "../../../api/public";
import { useFormInput } from '../../../hooks';
import {userRegisterService} from "../../../api/auth";
import {verifyUserSuccess} from "../../../actions/authActions";
import enums from '../../../utils/enums';
import {validateEmail, validatePassword} from "../../../utils/validators";
import cx from 'classnames';
import RadioButton from "../../../components/RadioButton";
import FormLabel from "../../../components/FormLabel";
import IndustriesSelector from "../../../components/IndustriesSelector";
import { TranslationHelper } from "../../../utils/i18n";
import Error from "../../../components/Error";
const MandatoryFieldsError = 'Please fill in mandatory fields';

export default function Signup() {
  const navigate = useNavigate();
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useRedirectAuthenticatedUser(authObj.user);

  const [busy, setBusy] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [signatureData, setSignatureData] = useState(null);
  const [formError, setFormError] = useState('');
  const [errorField, setErrorField] = useState('');
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    PublicAPI.validateSignedUrl(location.href).then(({success, data, error}) => {
      if (success) {
        setSignatureData(data);
        if (data.type)
          setType(data.type);
        setBusy(false);
      } else {
        navigate('/');
      }
    });
  }, []);

  const email = useFormInput(signatureData?.email);
  const name = useFormInput(signatureData?.recipientName);
  const password = useFormInput('');
  const organizationName = useFormInput(signatureData?.companyName);
  const websiteUrl = useFormInput('');
  const [type, setType] = useState(signatureData?.type);
  // const agree = useFormInput(false);

  if (busy)
    return <div>Loading...</div>;

  if (signatureData.linkedOrganizationId) {
    return <div>Account was created already. you should contact the administrator of {signatureData?.companyName} and ask to be invited.</div>;
  }

  const validateForm = () => {
    setFormError('');
    if (validatePersonalFields()) {
      if (!organizationName.value || !type || !websiteUrl.value || !industries.length) {
        setFormError(MandatoryFieldsError);
        return false;
      }
    }
    return true;
  };

  const validatePersonalFields = () => {
    setErrorField('');
    if (!password.value || !email.value || !name.value) {
      setFormError(MandatoryFieldsError);
      return false;
    }

    let validation = validateEmail(email.value);
    if (validation.valid) {
      validation = validatePassword(password.value);
    }

    if (!validation.valid) {
      setFormError(validation.error || MandatoryFieldsError);
      return false;
    }

    // if (!agree.value) {
    //   setFormError('You must agree to the Privacy Policy and the Terms and Conditions');
    //   return false;
    // }

    setFormError('');
    return true;
  };

  // handle button click of login form
  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (activeTab === 0) {
      return next(evt);
    }
    if (validateForm()) {
      setBusy(true);
      userRegisterService(
        signatureData.signedUrlGuid,
        email.value,
        name.value,
        password.value,
        organizationName.value,
        websiteUrl.value,
        industries,
        type,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ).then(({ success, data, error, errorParams}) => {
        if (success) {
          dispatch(verifyUserSuccess(data));
          navigate('/');
        }
        else {
          if (errorParams?.field === 'websiteUrl')
            error = 'Company with the same website URL already exists.';
          setFormError(error);
          setErrorField(errorParams?.field);
        }

        setBusy(false);
      });
    }
  };

  const handleKeypress = (e) => {
    // it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleSubmit(e);
    }
  };

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  const onIndustriesChange = (values) => {
    setIndustries(values.join(','));
  };

  const next = (e) => {
    if (validatePersonalFields()) {
      setActiveTab(1);
    }
  };

  const renderPersonalFields = () => {
    return <>
      <div className="input-container">
        <TextInput
          error={!name.value && formError === MandatoryFieldsError && 'Name is mandatory'}
          label="Your name"
          focus
          onKeyPress={handleKeypress}
          {...name}
        />
      </div>
      <div className="input-container">
        <TextInput
          error={formError?.includes('Password')}
          label="Password"
          type="password"
          autoComplete="new-password"
          onKeyPress={handleKeypress}
          {...password}
        />
      </div>
      {/*<div className="checkbox">*/}
      {/*  <Checkbox {...agree}>*/}
      {/*    I agree to the Novvo {' '}*/}
      {/*    <NavLink to="/terms" rel="noopener noreferrer" target="_blank">*/}
      {/*      Terms and Conditions*/}
      {/*    </NavLink>{' '}*/}
      {/*    and {' '}*/}
      {/*    <NavLink to="/privacy" rel="noopener noreferrer" target="_blank">*/}
      {/*      Privacy Policy*/}
      {/*    </NavLink>*/}
      {/*  </Checkbox>*/}
      {/*</div>*/}
    </>;
  };


  const companyTypeOptions = [{
    label: 'Investor',
    value: enums.OrganizationType.Investor
  },
  {
    label: 'Startup',
    value: enums.OrganizationType.Startup
  }];

  const renderCompanyFields = () => {
    return <>
      {!signatureData?.type && <div className="input-container">
        <FormLabel label="Company Type" />
        <RadioButton onChange={onTypeChange} options={companyTypeOptions} selectedValue={type} />
      </div>
      }
      <div className="input-container">
        <TextInput
          error={formError === TranslationHelper.getError('ORGANIZATION_ALREADY_EXISTS') && errorField === 'organizationName' || formError && !organizationName.value}
          key={'company_name'}
          label="Company name"
          focus
          onKeyPress={handleKeypress}
          {...organizationName}
        />
      </div>
      <div className="input-container">
        <TextInput
          error={formError === TranslationHelper.getError('ORGANIZATION_ALREADY_EXISTS') && errorField === 'websiteUrl' || formError && !websiteUrl.value}
          key={'web_site_url'}
          label="Web site URL"
          onKeyPress={handleKeypress}
          {...websiteUrl}
        />
      </div>
      <div className="input-container">
        <IndustriesSelector
          error={formError && !industries.length}
          label={'Industry'}
          selectedIndustries={[]}
          onChange={onIndustriesChange}
        />
      </div>
    </>;
  };

  return (
    <StyledSignup className="signup-form">
      <PageTitle icon={activeTab === 1 ? "back" : null} onIconClick={() => setActiveTab(0)}>Create account</PageTitle>
      <div className="tabs">
        <div className={cx({active: activeTab === 0})}>PERSONAL</div>
        <span>></span>
        <div className={cx({active: activeTab === 1})}>COMPANY</div>
      </div>

      {activeTab === 0 ? renderPersonalFields() : renderCompanyFields() }

      {formError && <Error error={formError} className="form__error" />}

      {activeTab === 0 ?
        <Button
          disabled={busy}
          onClick={next}
          isLoading={busy}
        >
          Next
        </Button>
        :
        <Button
          disabled={busy}
          onClick={handleSubmit}
          isLoading={busy}
        >
          Create account
        </Button>
      }
    </StyledSignup>
  );
};
