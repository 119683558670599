import styled from "styled-components";


export const StyledCompanyLogo = styled.div`
  line-height: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  img {
    border-radius: 4px;
    overflow: hidden;
  }

  &.default {
    background: #bbb;

    &.color_1 {
      background: #f0df9c;
    }

    &.color_2 {
      background: #85a4cc;
    }

    &.color_3 {
      background: #b9dca4;
    }

    &.color_4 {
      background: #f7c2b4;
    }

    &.color_5 {
      background: #d79bc3;
    }
  }
`;
