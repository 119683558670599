import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Confirm, ErrorMsgBox} from "../";
import "react-image-crop/dist/ReactCrop.css";
import FileReplaceConfirmationDialog from "./FileReplaceConfirmationDialog";
import {InvestorsAPI, StartupsAPI, UsersAPI} from "../../../api";
import {updateCurrentUser} from "../../../actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import Converters from "../../../utils/converters";
import FilesUploadDropZone from "../../FilesUploadDropZone";
import {StyledFilesUpload} from "./FilesUpload.style";
import MoreMenu from "../../MoreMenu";
import Loading from "../../Loading/Loading";
import routes from "../../../pages/routes";

FilesUpload.propTypes = {
  watchlistId: PropTypes.number,
  error: PropTypes.string,
  onFilesCollectionChanged: PropTypes.func
};

export default function FilesUpload({watchlistId, error, cleanMode, onFilesCollectionChanged}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user.isAdmin;
  const isStartup = user?.isStartupMode;
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [showConflictingFilesConfirmation, setShowConflictingFilesConfirmation] = useState(false);
  const [conflictingFiles, setConflictingFiles] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const [availableFiles, setAvailableFiles] = useState([]);
  const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useState([]);
  const [isLoadingFilesList, setIsLoadingFilesList] = useState(false);
  const [showCannotDownloadMessage, setShowCannotDownloadMessage] = useState(false);
  const [showErrorUploadFile, setShowErrorUploadFile] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const helpRoute = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;


  useEffect(() => {
    fetchDataFilesList();
  }, []);

  const fetchDataFilesList = (isAfterUpload = false) => {
    setIsLoadingFilesList(true);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    API.getUploadedDataFiles(watchlistId).then(({success, data}) => {
      if (success) {
        const newFiles = (data.files || []);
        newFiles.reverse();
        setAvailableFiles(newFiles);
        if (onFilesCollectionChanged)
          onFilesCollectionChanged(newFiles);
        if (isAfterUpload) {
          const hasError = newFiles.find(f => f.size === 0);
          if (hasError)
            setShowErrorUploadFile(true);
        }
      }
      setIsLoadingFilesList(false);
    });
  };

  const onFilesSelected = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setRejectedFiles(rejectedFiles);
    } else {
      setCurrentlyUploadingFiles([...acceptedFiles]);
      setUploadError("");
      setRejectedFiles([]);

      const conflicts = acceptedFiles.filter((f) => availableFiles.some(selectedFile => selectedFile.name == f.name)).map((f) => f.name);
      if (conflicts.length) {
        setConflictingFiles(conflicts);
        setShowConflictingFilesConfirmation(true);
      } else {
        await save(acceptedFiles);
      }
    }
  };

  const addConflictingFiles = async () => {
    await save(currentlyUploadingFiles, "add");
  };

  const replaceConflictingFiles = async () => {
    setShowConflictingFilesConfirmation(false);
    await save(currentlyUploadingFiles, "replace");
  };

  const save = async (files, actionOnConflicts = "replace") => {
    setShowConflictingFilesConfirmation(false);
    setIsBusy(true);

    const updateData = {
      dataFiles: files,
      filesToDelete: [],
      actionOnConflicts,
    };

    if (await uploadFiles(updateData)) {
      fetchDataFilesList(true);
    }

    setCurrentlyUploadingFiles([]);
    setIsBusy(false);
  };

  const uploadFiles = async (updateData) => {
    if (!isStartup && watchlistId) {
      const { error } = await InvestorsAPI.updateWatchlistCompany(watchlistId, updateData);
      if (error)
        setUploadError(error);
      else
        return true;
    } else {
      const { success, data, error } = await UsersAPI.updateMyOrganizationFiles(updateData);
      if (success) {
        updateOrganization(data);
        return true;
      } else
        setUploadError(error);
    }
  };

  const deleteFile = async () => {
    const updateData = {filesToDelete: [fileToDelete.name]};
    if (!isStartup && watchlistId) {
      await InvestorsAPI.updateWatchlistCompany(watchlistId, updateData);
    } else {
      const {success, data, error} = await UsersAPI.updateMyOrganizationFiles(updateData);
      if (success)
        updateOrganization(data);
    }
    const remainingFiles = availableFiles.filter(f => f.name !== fileToDelete.name);
    setAvailableFiles(remainingFiles);
    if (onFilesCollectionChanged)
      onFilesCollectionChanged(remainingFiles);
    setFileToDelete();
  };

  const updateOrganization = (newData) => {
    const updatedUser = user;
    updatedUser.Organizations = updatedUser.Organizations.filter(
      (o) => o.id !== user.organizationId
    );
    updatedUser.Organizations.push(newData);
    dispatch(updateCurrentUser(updatedUser));
  };

  const renderAvailableFiles = () => {
    const getFileMenuOptions = (file) => {
      const options = [];
      const {name, size} = file;
      const canDownload = size > 0;
      if (!name.endsWith('.large')) {
        options.push({
          label: "Download",
          onClick: () => {
            if (canDownload) {
              const API = isStartup ? StartupsAPI : InvestorsAPI;
              window.location = API.getDownloadUrl(file.name, watchlistId);
            } else {
              setShowCannotDownloadMessage(true);
            }
          }
        });
      }

      options.push({label: "Delete", onClick: () => setFileToDelete(file)});

      return options;
    };

    return <div className="available-files">
      {currentlyUploadingFiles?.length || availableFiles?.length ? <div className="title">Available files</div> : null}
      <div className="files-list">
        {isLoadingFilesList ?
          <Loading size="small">Loading files list...</Loading>
          :
          <>
            {currentlyUploadingFiles.map((f, index) =>
              <div className="uploaded-file" key={f.name}>
                <div>{f.name.replace(/.large$/gm,'')}</div>
                <div className="uploading">Uploading ...</div>
              </div>
            )}
            {availableFiles.map((f, index) => {
              const isLargeFile = f.name.endsWith('.large');
              const isZeroFile = f.size <= 0;
              const fileName = f.name.replace(/.large$/gm,'');
              return <div className="uploaded-file" key={f.name}>
                <div>
                  <div title={fileName}>{fileName}</div>
                  {isLargeFile && <div className="comment">(Large file - not yet uploaded)</div>}
                  {isZeroFile && <div className="comment">(Empty file)</div>}
                </div>
                <div>{Converters.formatNumber(f.size, true).formattedNumberString}</div>
                <div>{Converters.toShortDateTime(new Date(f.createdAt))}</div>
                <div>{isAdmin ? <MoreMenu options={getFileMenuOptions(f)} closeOnExternalScroll /> : null}</div>
              </div>
            })}
          </>
        }
      </div>
    </div>
  };

  const hasLargeFiles = availableFiles && availableFiles.length && availableFiles.some(f => f.name.endsWith('.large'));

  return (<>
      <StyledFilesUpload>
        {!cleanMode ? <div className="header">
          <div>MRR or Booking file is required. P&L file is optional.</div>
        </div>: null}

        <div className="files-area">
          <FilesUploadDropZone multiple onFilesSelected={onFilesSelected} clearFilesAfterSelection uploadEmptyFileForLargeFiles />
          <div className="link">{!cleanMode ? <>Not sure about this? View our <a href={helpRoute} target="_blank">Templates and upload tips</a></>:null}</div>
          {renderAvailableFiles()}
        </div>

        {hasLargeFiles ? <div className="hint">
          The size of one of the uploaded files exceeds 25MB and requires Novvo's assistance. Click the 'Submit support request' button to enable our team to assist you.
        </div> : null}

        {error || uploadError ? <div className="error">{error || uploadError}</div> : null}
      </StyledFilesUpload>

      <FileReplaceConfirmationDialog
        open={showConflictingFilesConfirmation}
        conflictingFiles={conflictingFiles}
        onCancel={() => {
          setCurrentlyUploadingFiles([]);
          setShowConflictingFilesConfirmation(false);
        }}
        onAdd={addConflictingFiles}
        onReplace={replaceConflictingFiles}
      />
      {rejectedFiles?.length ? (
        <ErrorMsgBox
          open
          title={"Not allowed"}
          error={rejectedFiles.reduce((acc, f) => {
              const messages = f.errors.map(e => e.message);
              messages.forEach(message => {
                if (!acc.includes(message))
                  acc.push(message);
              });
              return acc;
            }, []).join(", ")
          }
          onClose={() => {
            setRejectedFiles([]);
          }}
        />
      ) : null}
      {fileToDelete ? <Confirm title={<strong>Delete file</strong>}
                               yesText="Yes"
                               open
                               onYes={deleteFile}
                               onClose={() => setFileToDelete(null)}>
        Are you sure you want to delete {fileToDelete.name}?
      </Confirm> : null}
    {showCannotDownloadMessage ?
      <ErrorMsgBox
        title="Cannot download file"
        open
        onClose={() => setShowCannotDownloadMessage(false)}
        error="Cannot download file as since it has 0 bytes. Please upload the file again."/> : null}
    {showErrorUploadFile ?
      <ErrorMsgBox
        title="There was an issue uploading the file"
        open
        onClose={() => setShowErrorUploadFile(false)}
        error="There was an issue uploading a file to the system. Please remove the file and upload it again."/> : null}
  </>);
}

