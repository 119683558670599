import styled from "styled-components";

export const StyledCropDialog = styled.div`
  margin: 50px 0;
  text-align: center;
  min-width: 50vw;
  min-height: 30vh;
  .ReactCrop__image {
    max-width: none;
    min-width: 100px;
    max-height: 80%;
    min-height: 100px;
  }
  .crop-content {
    width: 100%;
    height: 100%;
  }
  .footer-buttons {
    position: absolute;
    text-align: left;
    left: 0;
    right: 0;
    bottom: 15px;
  }
  @media (max-width: 700px) {
    min-width: 400px;
    min-height: 300px;
  }
`;
