import styled from 'styled-components';

export const StyledTypography = styled.div`
     &.h1 {
      font-size: ${props => props.size || '3.2rem'}; // = 32px
      color: ${props => props.theme.colors['text-base']};
      line-height: ${props => props.lineHeight || '1.2'};
      margin-bottom: 1rem;
      @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
        font-size: 3.6rem;
        line-height: normal;
      }
    }
    &.h2 {
      font-size: ${props => props.size || '1.8rem'}; // = 18px
      color: ${props => props.theme.colors['text-lighter']};
      line-height: ${props => props.lineHeight};
      margin-bottom: 1.5rem;
      @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
        font-size: 2.4rem;
        line-height: normal;
      }
    }
    &.h3 {
      font-size: ${props => props.size || '1.4rem'}; // = 14px
      color: ${props => props.theme.colors['text-base']};
      line-height: ${props => props.lineHeight};
      margin: 1.2em 0 0.6rem;
      @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
        font-size: 1.8rem;
        line-height: normal;
      }
    }
    &.p {
      font-size: ${props => props.size || '1.4rem'}; // = 14px
      line-height: ${props => props.lineHeight};
      margin-bottom: 0.5rem;
    }
    &.strong {
      font-size: ${props => props.size || '1.4rem'}; // = 14px
    }
    &.label {
     color: ${props => props.theme.colors.label};
     font-size: ${props => props.size || '1.2rem'};
    }
    &.legend {
      color: ${props => props.theme.colors.legend};
      font-size: ${props => props.size || '1.2rem'};
      line-height: ${props => props.lineHeight};
    }
    &.tag {
     color: ${props => props.theme.colors.tag};
     font-size: ${props => props.size || '1.2rem'};
     line-height: ${props => props.lineHeight};
    }
    &.div {
      font-weight: ${props => props.weight};
      font-size: ${props => props.size || '14px'}; // = 14px
      line-height: ${props => props.lineHeight};
    }

    ${props => props.color ? `color:${props.theme.colors[props.color]};` : ''}

`
