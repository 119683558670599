import styled from "styled-components";

export const StyledHeader = styled.div`
  &.site-header {
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 10;
      ${(props) => props.mode === "dark" && `background-color: #2d2d2dfa;`}
      ${(props) => props.mode === "light" && `background-color: #fff;`}
      box-shadow: 0 0 1px rgba(0,0,0,0.2);
    }
    .site-header__inner {
      max-width: ${props => props.theme.general.xxlBreakpoint};
      min-height: 8rem;
      padding: 0 2.4rem 0 8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: ${(props) => props.theme.general.lgBreakpoint}) {
        padding: 0 2.4rem;
      }
    }
    .icon.menu {
      display: none;
      @media only screen and (max-width : ${props => props.theme.general.lgBreakpoint}) {
        display: inline-block;
        margin-right: 2.4rem;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
      > div {
        h3, p {
          margin: 0;
          padding: 0 0 0 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 20px;
        }
        p {
          font-size: 12px;
          color: ${props => props.theme.colors.legend}
        }
      }
    }

    .auth-menu {
      display: flex;
      height: 32px;
      > div:not(:last-child) {
        margin-right: 15px;
      }
    }

    .auth {
      a:link,
      a:visited {
        font-size: 1.4rem;
        color: ${props => props.theme.colors.label};
        display: inline-block;
        text-decoration: none;
        padding: 0.6rem 2.4rem;
        &.active {
          color: #222;
        }
      }
      a.get-started {
        color: white;
        background-color: #00a1de;
        border-radius: 4px;
        font-weight: bold;
        white-space: nowrap;
        &.active {
          color: white;
          background-color: #00a1de;
        }
      }
      @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
        display: none;
      }
    }
    .site-header__nav {
      display: flex;
      align-items: center;
    }
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    ${(props) => props.authenticated && `padding-left: 19rem;`}
    ${(props) => !props.authenticated && `padding-left: 4rem;`}
    &__logo {
      display: none;
    }
    a {
      ${(props) => !props.authenticated && `color:${props.theme.colors.label};`}
      ${(props) => props.authenticated && `color:${props.theme.colors['text-base']};`}
      font-size: 1.4rem;
      outline: none;
      padding: 0.6rem 2.4rem;
      font-weight: bold;
    }
    a:link,
    a:visited {
      ${(props) => !props.authenticated && `color:${props.theme.colors.label};`}
      ${(props) => props.authenticated && props.mode === "dark" ? `color: white;` : `color:${props.theme.colors['text-base']};`}
      &.active {
        ${(props) => props.mode === "dark" && `color: white;`}
        ${(props) => props.mode === "light" && !props.authenticated && `color: #333;`}
        ${(props) => props.mode === "light" && props.authenticated && `color: ${props.theme.colors.navigation};`}
      }
    }
    a:hover {
      ${(props) => props.mode === "dark" && `color: ${props.theme.colors.navigation};`}
      ${(props) => props.mode === "light" && !props.authenticated && `color: #333;`}
      ${(props) => props.mode === "light" && props.authenticated && `color: ${props.theme.colors.navigation};`}
      text-decoration: none;
    }
    /* Small device */
    @media only screen and (max-width : ${props => props.theme.general.lgBreakpoint}) {
      display: none;
    }
    .right {
      margin-right: 20px;
    }
  }
`;

export default {
    StyledHeader,
};
