import React, {useEffect, useState} from "react";
import { StyledNetwork } from './network.style';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import Typography from "../../../components/Typography";
import enums from "../../../utils/enums";
import { UsersAPI } from '../../../api';
import {useSelector} from "react-redux";
import NavigationPanel from '../../../components/NavigationPanel';
import routes from '../../../pages/routes';
import CompanyCard from '../../../components/CompanyCard';
import EmptyList from '../../../components/EmptyList';
import Recommendations from '../../../components/Recommendations';
import CompanyLogo from "../../../components/CompanyLogo";
import OnboardingAlertsPanel from "../../../components/OnboardingAlertsPanel";
import Invitations from "../../../components/Invitations";
import { PersonAddAlt, CloudUploadOutlined, FileUploadOutlined } from "../../../assets/mui-icons";

const STARTUP_NAVIGATION_OPTIONS = {
  PROSPECT: {
    label: 'Prospect investors',
    caption: 'Add prospect investor',
    empty: 'No prospect investors'
  },
  CURRENT: {
    label: 'Current investors',
    caption: 'Add current investor',
    empty: 'No current investors'
  }
};

const INVESTOR_NAVIGATION_OPTIONS = {
  PROSPECT: {
    label: 'Prospect companies',
    caption: 'Analyze new prospect company',
    empty: 'No prospect companies'
  },
  CURRENT: {
    label: 'Portfolio companies',
    caption: 'Analyze new portfolio company',
    empty: 'No portfolio companies'
  }
};

export default function Network() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { isAdmin, isSupportUser, organizationName} = user || {};
  const isStartup = user?.isStartupMode;
  const navigationOptions = isStartup ? STARTUP_NAVIGATION_OPTIONS : INVESTOR_NAVIGATION_OPTIONS;
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(navigationOptions)[0]);
  const [connections, setConnections] = useState([]);
  const companyRoutes = routes[isStartup ? 'Startups' : 'Investors'];
  const uploadFilesWatchlistId = location.state?.open_file_dialog;
  const currentLocation = window.location.pathname + window.location.hash;

  const fetchConnections = () => {
    UsersAPI.getMyConnections().then(({ success, data }) => {
      if (success) {
        setConnections(data);
      }
    });
  };

  useEffect(() => {
    fetchConnections();
  }, []);

  const renderConnections = (connectionType) => {
    const filteredCompanies = connections.filter(conn => conn.connectionType === connectionType);
    if (!filteredCompanies.length)
      return <EmptyList text={navigationOptions[selectedGroup].empty} />;

    return <div>
      <div className="headers">
        <div>Name</div>
        <div className="additional">
          <div className="left-side">
            <div className="status">Status</div>
            <div className="action">Quick Action</div>
          </div>
          <div className="right-side">
            {isStartup ? <div className="permissions">Permissions</div> : null}
            <div className="date">Updated</div>
            <div />
          </div>
        </div>
      </div>
      {(filteredCompanies.map((company) => (
      <CompanyCard
        key={`${connectionType}_${company.aclId}_${company.invitationId}_${company.id}_${company.isInternal}`}
        company={company}
        connectionType={connectionType}
        shouldOpenUploadDialog={uploadFilesWatchlistId && company.id === uploadFilesWatchlistId}
        onRevoke={() => {
          setConnections([...connections.filter(c => c.id !== company.id || c.invitationId !== company.invitationId)]);
        }}
        onConnectionTypeChange={(newType) => {
          const conn = connections.find(c => c.id === company.id && c.invitationId === company.invitationId)
          conn.connectionType = newType;
          setConnections([...connections]);
        }}
        onPermissionsChange={(newPermissions) => {
          const conn = connections.find(c => c.id === company.id && c.invitationId === company.invitationId);
          conn.permissions = newPermissions;
          setConnections([...connections]);
        }}
      />
    )))}</div>;
  };

  const renderMainPanelHeader = () => {
    let action;
    const option = navigationOptions[selectedGroup];
    let {label, caption} = option;

    if (isAdmin) {
      switch (selectedGroup) {
        case 'PROSPECT':
          action = () => navigate(companyRoutes.Invite, {
            state: {connectionType: enums.ConnectionType.Prospect, from_path: currentLocation}
          });
          break;
        default:
          action = () => navigate(companyRoutes.Invite, {
            state: {connectionType: enums.ConnectionType.Current, from_path: currentLocation}
          });
          break;
      }
    }

    if (isSupportUser)
      label = 'Support requests';

    return (
      <div className="header-main">
        <Typography variant="h2"><span>{label}</span></Typography>
      </div>
    );
  };

  const renderMainContent = () => {
    let content;
    switch(selectedGroup) {
      case 'PROSPECT':
        content = renderConnections(enums.ConnectionType.Prospect);
        break;
      default:
        content = renderConnections(enums.ConnectionType.Current);
        break;
    }

    return (
      <div>
        <div>
          {renderMainPanelHeader()}
        </div>
        {content}
      </div>
    );
  };

  const renderQuickActions = () => {
    const connectionType = selectedGroup === 'PROSPECT' ? enums.ConnectionType.Prospect : enums.ConnectionType.Current;
    return <div className="quick-actions">
      <Typography variant="h2">Quick actions</Typography>
      <div className="actions">
        {!isStartup ? <NavLink to={routes.Investors.Invite} className="first-link" state={{from_path: currentLocation, connectionType}}><FileUploadOutlined /> Upload new company data</NavLink> : null}
        <NavLink to={isStartup ? routes.Startups.Invite : routes.Investors.Invite} state={{invitationMode: true, from_path: currentLocation, connectionType}}><CloudUploadOutlined />{isStartup ? 'Invite investor to view data' : 'Invite company to upload data'}</NavLink>
        {isAdmin ? <NavLink to={isStartup ? routes.Startups.UsersManagement : routes.Investors.UsersManagement} state={{from_path: currentLocation}}><PersonAddAlt />Invite teammate</NavLink> : null}
      </div>
    </div>;
  };

  const getPageTitle = () => {
    if (isSupportUser) {
      const currentOrganization = user && user.Organizations.find(o => o.id === user.organizationId);
      const logo = currentOrganization?.logo;
      return <><CompanyLogo company={{ logo }} size={44}/>{organizationName}</>;
    }
    return isStartup ? 'Network' : 'Watchlist';
  };

  return (
    <StyledNetwork isStartup={isStartup}>
      <PageTitle variant="h1" lineheight="1">{getPageTitle()}</PageTitle>
      <div className="page-content">
        {!isSupportUser &&
          <div className="groups">
            <NavigationPanel options={Object.keys(navigationOptions).map(key => {
              return {value: key, ...navigationOptions[key]};
            })} selectedValue={selectedGroup} onSelect={(value) => setSelectedGroup(value)}/>
          </div>
        }
        <div className="main-content">
          <OnboardingAlertsPanel />
          <div className="main">
            {renderQuickActions()}
          </div>
          <div className="main">
            {renderMainContent()}
          </div>
        </div>
        {!isSupportUser &&
          <div className="suggestions">
            {isAdmin ? <Invitations onAccept={fetchConnections} /> : null}
            <Recommendations type="investors"/>
          </div>
        }
      </div>
    </StyledNetwork>
  );
}
