import styled from 'styled-components';

export const StyledSingleRadio = styled.label`
    --color: ${props => (props.error) ? props.theme.colors.error : props.theme.colors.lightGray};
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.4em;
    font-size: 1.4rem;
    padding: 0.6rem 0;
    .input {
      position: relative;
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      input {
        position: absolute;
        z-index: 1;
        opacity: 0;
        width: inherit;
        height: inherit;
        margin: 0;
        padding: 0;
      }
      svg {
        fill: var(--color);
        width: 1.6rem;
        height: 1.6rem;
        path:nth-child(2) {
          transform: scale(0);
        }
      }
      input:focus + svg {
        border-radius: 50%;
        box-shadow: 0 0 0 0.4rem #00a1de33, 0 0 0 2px #00a1decc inset;
      }
      input:checked + svg {
        fill: ${props => props.theme.colors.primary};
      }
      input:checked + svg path:nth-child(2) {
        transform: scale(1);
      }

    }
    &__lbl {
      line-height: 1.2;
      cursor: pointer;
      // ${props => props.error ?  `color: var(--color);` : ''}
    }
`

