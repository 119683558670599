import styled from 'styled-components';

export const StyledSignup = styled.div`
  &.signup-form {
    max-width: 65rem;
    .typography_root.p {
      margin-bottom: 32px;
    }
    .input-container {
      margin-bottom: 8px;
    }
    .checkbox {
      margin-top: 15px;
      font-size: 14px;
      color: #9b9b9b;
      input {
        margin-right: 0.5rem;
      }
      a, a:visited, a:link {
        color: ${props => props.theme.colors.label};
      }
    }
    .form__error {
      margin: 1.2rem 0;
      padding: 0.6rem 1.2rem;
      font-size: 1.4rem;
      color: ${props => props.theme.colors.error};
      background-color: rgba(213, 60, 33, 0.05);
      border-radius: 4px;
    }
    .form__note {
      margin: 1.2rem 0;
      padding: 0.6rem 1.2rem;
      font-size: 1.4rem;
      background-color: rgba(213, 60, 33, 0.05);
      border-radius: 4px;
    }

    button {
      margin-top: 32px;
      margin-bottom: 32px;
    }
    @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
      .button-container {
        width: 100%;
      }
    }

    .social {
      margin: 20px 0;

      button {
        margin: 0 10px;
      }
    }

    .tabs {
      margin: 50px 0 30px 0;
      font-size: 14px;
      color: #9b9b9b;
      display: flex;
      font-weight: bold;
      align-items: center;

      span {
        font-size: 12px;
        font-weight: normal;
      }

      > div {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
        &.active {
          color: #9ece62;
        }
      }

    }
  }
`;

