import React, { useEffect, useState } from "react";
import {NavLink, useLocation} from 'react-router-dom';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
import PageTitle from '../../../../components/PageTitle';
import { StyledPublicPages } from "../../PublicPages.style";
import PublicAPI from '../../../../api/public';
import { useFormInput } from "../../../../hooks";
import {validateEmail} from "../../../../utils/validators";
import Error from "../../../../components/Error";
import Logo from "../../../../components/Logo/Logo";
import routes from '../../../routes';
import enums from '../../../../utils/enums';

export default function GetStarted() {
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const email = useFormInput(location.state?.email);
  const query = new URLSearchParams(location.search);
  const [type, setType] = useState(query.get('t') === 'startup' ? enums.OrganizationType.Startup : enums.OrganizationType.Investor);
  const isInvestor = type === enums.OrganizationType.Investor;
  const isProduction = window.location.href.startsWith('https://www.novvo.ai/') || window.location.href.startsWith('https://app.novvo.ai/');
  const homepageUrl = isProduction ? `https://www.novvo.ai/${(isInvestor ? 'investors' : 'startups')}` : '/';

  const validateForm = () => {
    if (!email.value)
      return setFormError('Please fill in all mandatory fields');
    const validation = validateEmail(email.value);
    if (!validation.valid)
      return setFormError(validation.error);
    setFormError('');
    return true;
  };

  // handle button click of login form
  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (validateForm()) {
      setSubmitting(true);
      PublicAPI.preSignup(email.value, type).then(({success, data, error}) => {
        if (success) {
          setIsSuccess(true);
        }
        else {
          if (error === 'EMAIL_ALREADY_EXISTS')
            error = 'User already registered';

          setFormError(error);
        }
        setSubmitting(false);
      });
    }
  };

  const handleKeypress = (e) => {
    // it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <StyledPublicPages>
      <div className="marketing-note">
        <a href={homepageUrl}><Logo /></a>
        <div className="marketing-content">
          {isInvestor ? <Typography variant="h3">
            Analyze startups. Smarter. Faster.
          </Typography> : null}
          {isInvestor ? <p>
            Get the most of your minute on Novvo. With the free trial you can analyze three startups for free. Novvo is the simplest and fastest way to get from raw data to deep insights in just few minutes.
          </p> : <p className="start-up">
              Get a powerful SaaS dashboard in under 5 minutes - no IT or coding skills required. It's as simple as plug-and-play!
          </p>}
        </div>
      </div>
      <div className="form">
        <div className="upper-links">
          Already have an account? <NavLink to={routes.Public.Login}>Log in</NavLink>
        </div>
        <div className="form-content">
          {isSuccess ?
          <>
            <PageTitle>An email address verification is required</PageTitle>
            <Typography className="success-message" variant="p">
              A verification email has been sent. To continue with account creation, please check your inbox and verify your email address.
            </Typography>
            <Typography variant="p">
              Problems receiving the verification email? Be sure to check your spam filter. You can also <span className="link" onClick={() => setIsSuccess(false)}>resend</span> verification, <span className="link" onClick={() => setIsSuccess(false)}>review</span> your email address, or contact us at <a href="mailto:support@novvo.ai">support@novvo.ai</a>
            </Typography>
            <Button className="button homepage-link" color="identity-green" size="large" width={150}>
              <a href={homepageUrl}>Got it</a>
            </Button>
          </>
          :
          <>
            <PageTitle>Sign up {isInvestor ? 'as an investor' : 'as a startup'} for free</PageTitle>
            <Typography variant="p">
              Not {isInvestor ? 'an investor' : 'a startup'}? Sign up as {isInvestor ? 'an' : 'a'} <span className="link" onClick={() => setType(isInvestor ? enums.OrganizationType.Startup : enums.OrganizationType.Investor)}>{isInvestor ? 'startup' : 'investor'}</span> for free
            </Typography>
            <div className="input-container">
              <TextInput
                key={`${isInvestor ? 'investor' : 'startup'}_email`}
                label="Your work email address"
                autoComplete="new-password"
                onKeyPress={handleKeypress}
                focus
                {...email}
              />
            </div>
            {formError && <Error error={formError} className="form__error" />}
            <Typography variant="p">
              By clicking 'Sign up', you acknowledge that you have read and understood our <a href="https://www.novvo.ai/terms" target="_blank">Terms of Use</a> and <a href="https://www.novvo.ai/privacy" target="_blank">Privacy Policy</a>, and agree to be bound by the Terms of Use.
            </Typography>
            <Button className="button" color="identity-green" size="large"
              disabled={submitting || !email.value}
              onClick={handleSubmit}
              isLoading={submitting}
            >
              Sign up
            </Button>
          </>}
        </div>
      </div>
    </StyledPublicPages>
  );
};
