import styled from 'styled-components';

export const StyledFileUploadHelp = styled.div`

  .h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .page-content {
    display: flex;
    margin: 15px 0;

    .templates-download {
      h3 {
        margin: 0;
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-left: -5px;
        a {
          margin-top: 20px;
          display: flex;
          align-items: center;
          font-size: 12px;
          svg {
            font-size: 2.5rem;
            margin-right: 5px;
          }
        }
      }
      width: 240px;
    }

    .main {
      flex: 1;
      margin: 0 30px;
      padding: 15px 20px 45px 20px;
      background-color: white;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
      border-radius: 4px;

      .new-section {
        margin-top: 5rem;
      }

      p {
        font-size: 14px;
        &.no-margin {
          margin-top: 0;
        }
        span {
          text-decoration: underline;
        }
        img {
          max-width: 90%;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

