import {
  VERIFY_TOKEN_STARTED,
  VERIFY_TOKEN_END,
  REFRESH_TOKEN_STARTED,
  REFRESH_TOKEN_END,
  USER_LOGIN_STARTED,
  USER_LOGIN_FAILURE,
  REGISTRATION_STARTED,
  VERIFY_REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  VERIFY_USER_SUCCESS,
  USER_LOGOUT,
  UPDATE_CURRENT_USER
} from "../actions/actionTypes";

// define initial state of auth reducer
const initialState = {
    token: null, // manage the access token
    silentAuth: false, // to indicate that we are already trying to verify the token
    user: null, // manage the user details
    authLoading: true, // to indicate that the auth API is in progress
    isAuthenticated: false, // consider as a authentication flag
    userLoginLoading: false, // to indicate that the user log in API is in progress
    loginError: null, // manage the error of the user log in API
    registrationLoading: false, // to indicate that the registration API is in progress
    registrationError: null, // manage the error of registration in API
};

// update store based on type and payload and return the state
const auth = (state = initialState, action) => {
    switch (action.type) {
        // verify token - started
        case VERIFY_TOKEN_STARTED: {
            const { silentAuth } = action.payload;
            return silentAuth
                ? {
                      ...state
                  }
                : initialState;
        }
        // verify token - ended/failed
        case VERIFY_TOKEN_END: {
            if (state.authLoading !== false) {
              const newState = {...state};
              if (newState.authLoading)
                newState.authLoading = false;
              return newState;
            }
            return state;
        }
         // verify token - started
        case REFRESH_TOKEN_STARTED: {
          state.silentAuth = true;
          return state;
        }
        // verify token - ended/failed
        case REFRESH_TOKEN_END: {
          const { token } = action.payload;
          state.silentAuth = false;
          if (token && token !== state.token) {
            return {
              ...state,
              silentAuth: false,
              token
            };
          }
          return state;
        }
        // user login - started
        case USER_LOGIN_STARTED: {
            return {
                ...state,
                loginError: null,
                userLoginLoading: true,
            };
        }
        // user login - ended/failed
        case USER_LOGIN_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                loginError: error,
                userLoginLoading: false,
            };
        }
        // verify token - success
        case VERIFY_USER_SUCCESS: {
            const { token, user } = action.payload;
            if (!state.user || JSON.stringify(state.user) !== JSON.stringify(user)) {
              return {
                  ...state,
                  token,
                  user,
                  isAuthenticated: true,
                  authLoading: false,
                  userLoginLoading: false,
                  silentAuth: false
              };
            }
            return {
                ...state,
                token,
                isAuthenticated: true,
                authLoading: false,
                userLoginLoading: false,
                silentAuth: false
            };
        }
        case UPDATE_CURRENT_USER: {
          const { user } = action.payload;
          return {
            ...state,
            user
          };
        }
        // user login - started
        case REGISTRATION_STARTED: {
            return {
                ...state,
                registrationError: null,
                registrationLoading: true,
            };
        }

        // verify registration - success
        case VERIFY_REGISTRATION_SUCCESS: {
          const { user } = action.payload;
          return {
            ...state,
            user,
            isAuthenticated: true,
            registrationError: null,
            registrationLoading: false,
            silentAuth: false
          };
        }

        // user login - ended/failed
        case REGISTRATION_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                registrationError: error,
                registrationLoading: false,
            };
        }
        // handle user logout
        case USER_LOGOUT: {
            return {
                ...initialState,
                authLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default auth;
