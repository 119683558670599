import React, { useState } from "react";
import { StyledCompanyProfileDetailsPanel } from './CompanyProfileDetailsPanel.style';
import {useDispatch, useSelector} from "react-redux";
import Typography from "../../Typography";
import Button from "../../Button";
import TextInput from "../../TextInput";
import {useFormInput} from "../../../hooks";
import FormLabel from "../../FormLabel";
import cx from "classnames";
import {UsersAPI} from "../../../api";
import moment from "moment";
import {updateCurrentUser} from "../../../actions/authActions";
import IndustriesSelector from "../../IndustriesSelector";
import ImageEditor from "../../ImageEditor/ImageEditor";
import Icon from "../../Icon";
import Error from "../../Error";
import { OpenInNewIcon } from '../../../assets/mui-icons';

export default function CompanyProfileDetailsPanel() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isBusy, setIsBusy] = useState(false);
  const [cardIndexInEditMode, setCardIndexInEditMode] = useState(-1);
  const [company, setCompany] = useState(user.Organizations.find(org => org.id === user.organizationId));
  const [industries, setIndustries] = useState(company.industry || '');
  const [uploadedLogoFile, setUploadedLogoFile] = useState();
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState(company.logo);
  const [errors, setErrors] = useState({});
  const isAdmin = user?.isAdmin;

  const { name, websiteUrl, city, location, updatedAt, description, longDescription, logo, presentationUrl } = company;

  const companyName = useFormInput(name);
  const companyWebSiteUrl = useFormInput(websiteUrl);
  const companyCity = useFormInput(city);
  const companyState = useFormInput(location);
  const companyDescription = useFormInput(description);
  const companyLongDescription = useFormInput(longDescription);
  const companyPresentationUrl = useFormInput(presentationUrl);

  const getEditedValue = (cardIndex) => {
    const editedValues = {};
    if (cardIndex === 0) {
      if (companyName.value !== name)
        editedValues.name = companyName.value;

      if (companyWebSiteUrl.value !== websiteUrl)
        editedValues.websiteUrl = companyWebSiteUrl.value;

      if (companyPresentationUrl.value !== presentationUrl)
        editedValues.presentationUrl = companyPresentationUrl.value;

      if (companyCity.value !== city)
        editedValues.city = companyCity.value;

      if (companyState.value !== location)
        editedValues.location = companyState.value;
    } else if (cardIndex === 1) {
      if (industries !== company.industry)
        editedValues.industry = (industries || null);

      if (companyDescription.value !== description)
        editedValues.description = companyDescription.value;

      if (companyLongDescription.value !== longDescription)
        editedValues.longDescription = companyLongDescription.value;
    }
    return editedValues;
  };

  const validate = (cardIndex) => {
    if (cardIndex === 0) {
      if (!companyName.value) {
        setErrors({
          ...errors,
          companyName: 'Name cannot be empty'
        });
        return false;
      }
    }

    return true;
  };

  const updateUserData = (data) => {
    setCompany(data);
    setCardIndexInEditMode(-1);
    setUploadedLogoFile(null);
    const updatedUser = user;
    updatedUser.organizationName = data.name;
    updatedUser.Organizations = updatedUser.Organizations.filter(org => org.id !== company.id);
    updatedUser.Organizations.push(data);
    dispatch(updateCurrentUser(updatedUser));
  };

  const onCancel = () => {
    setCardIndexInEditMode(-1);
    setUploadedLogoUrl(logo);
    setUploadedLogoFile(null);
    setErrors({});
    companyName.reset();
    companyWebSiteUrl.reset();
    companyCity.reset();
    companyState.reset();
    companyDescription.reset();
    companyLongDescription.reset();
  };

  const save = async (cardIndex) => {
    if (!validate(cardIndex))
      return;

    const promisesToSave = [];
    if (cardIndex === 0 && uploadedLogoFile)
      promisesToSave.push(UsersAPI.updateMyOrganizationImages({logo: uploadedLogoFile}));

    const editedValues = getEditedValue(cardIndex);
    if (Object.keys(editedValues).length)
      promisesToSave.push(UsersAPI.updateMyOrganization(editedValues));

    if (promisesToSave.length) {
      setIsBusy(true);
      Promise.all(promisesToSave).then(results => {
        setIsBusy(false);
        const success = !results.some(r => !r.success);
        const validResponses = results.filter(r => r.success && r.data);
        if (validResponses.length) {
          let updatedAt;
          validResponses.forEach(({data}) => {
            if (!updatedAt || updatedAt < data.updatedAt) {
              updatedAt = data.updatedAt;
              updateUserData(data);
            }
          });
        }
        if (!success) {
          const error = results.filter(r => !r.success).map(r => r.error);
          setErrors({general: error});
        }
      });
    } else {
      setCardIndexInEditMode(-1);
    }
  };

  const renderFormInput = (obj, error, attrs = {}) => {
    return <div className="input-container">
      <TextInput error={error} {...obj} {...attrs} />
    </div>;
  };

  const renderItem = (label, obj, value, isInEditMode, error, attrs) => {
    return <div className="item">
      <FormLabel label={label} />
      {isInEditMode ? renderFormInput(obj, error, attrs) : <div>{value}</div>}
    </div>;
  };

  const cleanFilename = (value) => {
    const valueParts = value.split('/');
    value = valueParts[valueParts.length-1];
    return value;
  }

  const renderLink = (label, obj, value, isInEditMode, shouldCleanFilename, error, attrs) => {
    return <div className="item">
      <FormLabel label={label} />
      {isInEditMode ? renderFormInput(obj, error, attrs) : value ? <div className="link"><a href={value.startsWith('http') ? value : `https://${value}`} target="_blank" rel="noopener noreferrer"><span>{shouldCleanFilename ? cleanFilename(value) : value}</span><OpenInNewIcon /></a></div> : null}
    </div>;
  };

  const renderDetailsFields = () => {
    const isInEditMode = cardIndexInEditMode === 0;
    let imageElement, avatarClass;
    if (isInEditMode) {
      if (logo) {
        avatarClass = 'edit';
        imageElement = <>
          <img src={logo} alt="" />
        </>;
      } else {
        avatarClass = 'edit no-image';
        imageElement = <div>
          <div>Add image</div>
        </div>;
      }
    } else {
      if (logo) {
        imageElement = <img src={logo} alt="" />;
      } else {
        avatarClass = 'no-image';
        imageElement = <Icon size="md" type="building-g" />;
      }
    }

    return <>
      <div className="left">
        {renderItem('Company name', companyName, name, isInEditMode, errors.companyName)}
        {renderLink('Web site URL', companyWebSiteUrl, websiteUrl, isInEditMode)}
        {renderLink('Presentation URL', companyPresentationUrl, presentationUrl, isInEditMode, true)}
        {/*{renderItem('City', companyCity, city, isInEditMode)}*/}
        {/*{renderItem('State or country', companyState, location, isInEditMode)}*/}
      </div>
      <div className="right">
        <FormLabel label="Company logo/avatar" />
        <div className={cx("avatar", avatarClass)}>
          {isInEditMode ?
            <ImageEditor
              defaultPhotoUrl={uploadedLogoUrl}
              maxHeight={128}
              maxWidth={128}
              cropAspect={1}
              cropWidth={128}
              cropHeight={128}
              onChange={({file, url}) => {
                setUploadedLogoUrl(url);
                setUploadedLogoFile(file);
              }}
            />
            :
            imageElement
          }
        </div>
      </div>
      </>;
  };

  const renderDescriptionFields = () => {
    const isInEditMode = cardIndexInEditMode === 1;
    return (
      <div className="full-size">
        <div className="item">
        <FormLabel label='Industry' />
        {isInEditMode ?
          <IndustriesSelector
            selectedIndustries={(industries && industries.split(',')) || []}
            onChange={(values) => {
              setIndustries(values.join(','));
            }}
          />
          :
          <div>{industries.split(',').join(', ')}</div>
        }
        </div>
        {renderItem('Short company description (6-12 words to display in lists)', companyDescription, description, isInEditMode, errors.description, {maxLength: 200})}
        {renderItem('Full company description', companyLongDescription, longDescription, isInEditMode, errors.longDescription, {type: 'textarea', maxLength: 1000})}
        {/*{renderItem('Competitors (for categorization only; not visible to connections)', companyCity, city, isInEditMode)}*/}
      </div>
    );
  };

  const renderMarketingFields = () => {
    const isInEditMode = cardIndexInEditMode === 2;
    return (
      <div className="left">
        <FormLabel label='Company overview decks, short videos, or other files that profile your unique business' />
      </div>
    );
  };

  const renderCardContent = (cardIndex) => {
    let fields;
    switch (cardIndex) {
      case 0:
        fields = renderDetailsFields();
        break;
      case 1:
        fields = renderDescriptionFields();
        break;
      case 2:
        fields = renderMarketingFields();
        break;
      default: return null;
    }

    return <>
      <div className="card-content" >
        <div className="fields">
          {fields}
        </div>
        {cardIndex === cardIndexInEditMode &&
          <div className="footer">
            <Button color="gray" onClick={onCancel}>Cancel</Button>
            <Button onClick={() => save(cardIndex)} isLoading={isBusy} disabled={isBusy}>Save</Button>
            <span className="date">Last updated {moment(updatedAt).format('h:mm A MMM DD, yyyy')}</span>
            {errors?.general ? <Error className="error-message" error={errors.general} /> : null}
          </div>
        }
      </div>
    </>;
  };

  const renderCardHeader = (cardIndex) => {
    let title;
    switch (cardIndex) {
      case 0:
        title = 'Company details';
        break;
      case 1:
        title = 'Description';
        break;
      case 2:
        title = 'Marketing material';
        break;
      default: return null;
    }
    return <div className="header" >
      <Typography variant="h2">{title}</Typography>
      {isAdmin && cardIndexInEditMode !== cardIndex ? <div className="edit"><Icon type="edit" size="md" tooltip="Edit" onClick={() => {onCancel(); setCardIndexInEditMode(cardIndex);}} /></div> : null}
    </div>;
  };

  return (
    <StyledCompanyProfileDetailsPanel>
      <div className="card">
        {renderCardHeader(0)}
        {renderCardContent(0)}
      </div>
      <div className="card">
        {renderCardHeader(1)}
        {renderCardContent(1)}
      </div>
      {/*<div className="card">*/}
      {/*  {renderCardHeader(2)}*/}
      {/*  {renderCardContent(2)}*/}
      {/*</div>*/}
    </StyledCompanyProfileDetailsPanel>
  );
}
