import styled from 'styled-components';

export const StyledNavigationPanel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  > a,
  > a:link,
  > a:visited {
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors['text-base']};
    cursor: pointer;
    svg {
      width: 16px;
      margin-right: 8px;
      path {
        fill: ${props => props.theme.colors['text-base']};
      }
    }
    &.selected {
      cursor: default;
      text-decoration: none;
      color: ${props => props.theme.colors.navigation};
      svg path {
          fill: ${props => props.theme.colors.navigation};
      }
    }
    &:not(.selected):hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: none;
      svg path {
        fill: ${props => props.theme.colors.linkhover};
      }
      span {
        text-decoration: underline;
      }
      strong {
        cursor: default;
      }
    }
  }
  > div.disabled {
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.legend};
    svg {
      width: 16px;
      margin-right: 8px;
      path {
        fill: ${props => props.theme.colors.legend};
      }
    }
  }
  .section {
    margin: 20px 0 10px 0;
    font-size: 11px;
    color: ${props => props.theme.colors.label};

    &:first-child {
      margin-top: 0;
    }
  }
`;



