import React, {useEffect, useState} from 'react';
import MultiSelect from '../MultiSelect';
import {StyledIndustriesSelector} from './IndustriesSelector.style';
import { MetadataAPI } from '../../api';
import {useSelector} from "react-redux";

export default function IndustriesSelector ({ selectedIndustries = [], label, ...rest }) {
  const {isAuthenticated} = useSelector((state) => state.auth);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    MetadataAPI.getIndustries(isAuthenticated).then(({success, data}) => {
      if (success)
        setOptions(data.map(industry => ({text: industry.name, value: industry.name})));
    });
    // // fetch industries
    // const industries = ['a1', 'a2'];
    // setOptions(industries.map(industry => ({text: industry, value: industry})));
  }, []);

  return <StyledIndustriesSelector>
    {label && <div className="label">{label}</div>}
    <MultiSelect
      options={options}
      defaultValue={selectedIndustries.map(industry => ({text: industry, value: industry}))}
      placeholder={'Search'}
      {...rest}
    >
    </MultiSelect>
  </StyledIndustriesSelector>;
};
