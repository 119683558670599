import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledProgressBar } from './ProgressBar.style'

function ProgressBar({ value, maximum, color }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      setProgress(Math.min(value / maximum * 100, 100));
    }, [value])

    return (
        <StyledProgressBar className={"progress-bar_root"} progress={progress} color={color}>
          <div className={"progress-bar_filler"}/>
        </StyledProgressBar>
    );
}

ProgressBar.propTypes = {
    maximum: PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.string
};

ProgressBar.defaultProps = {
    maximum: 100,
};

export default ProgressBar;
