import styled from 'styled-components';

export const StyledErrorMsgBox = styled.div`
  .error-msg {
    margin: 0;
    > * {
      line-height: 2.4rem;
      color: ${props => props.theme.colors.label};
    }
  }
`;

