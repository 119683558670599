import React from 'react';
import Button from '../Button';
import { StyledRecommendations } from './Recommendations.style';
import General from "../../utils/general";


const investors = [
  {
    name: 'Sequoia Capital',
    image: 'https://www.secrettelaviv.com/wp-content/uploads/2019/04/74877_sequoua.jpg',
    contactName: 'Shmil Levy',
    contactRole: 'Partner'
  },
  {
    name: 'Benchmark Capital',
    image: 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1489821126/upvtztqzzg6shfbt7bl5.png',
    contactName: 'Peter H Fenton',
    contactRole: 'General Partner'
  },
  {
    name: 'Greylock Capital',
    image: 'https://www.greylockcapital.com/wp-content/themes/glc/images/greylock-logo.svg',
    contactName: 'Liam Localio',
    contactRole: 'Managing Director'
  },
  {
    name: 'Andreessen Horowitz',
    image: 'https://finder.startupnationcentral.org/image_cloud/andreessen-horowitz_dd8aebcc-138e-11eb-b323-8316343e2d22?w=240&h=240',
    contactName: 'Angela Strange',
    contactRole: 'General Partner'
  }
];

const startups = [];

function Recommendations({type}) {

  const isProduction = window.location.href.startsWith('https://www.novvo.ai/') || window.location.href.startsWith('https://demo.novvo.ai/') || window.location.href.startsWith('https://app.novvo.ai/');;

  if (isProduction)
    return null;

  const items = type === 'investors' ? General.getRandomNumberOfItemsFromArray(investors, 3) : General.getRandomNumberOfItemsFromArray(startups, 3);

  const renderItem = (company, index) => {
    return <div key={`recommendation_${index}`}>
      <div className="company">
        <div className="company-info">
          <img src={company.image} height={32} width={32} alt={company.name} />
          <div className="company-details">
            <strong>{company.name}</strong>
            <span>{company.contactName}, {company.contactRole}</span>
          </div>
        </div>
        <div className="company-actions">
          <Button color="primary" size="small">Invite</Button>
        </div>
      </div>
    </div>;
  };

  return <StyledRecommendations>
    <h3>Recommended {type}</h3>
    {items.map((item, index) => renderItem(item, index))}
  </StyledRecommendations>;
}

Recommendations.propTypes = {
};

export default Recommendations;
