import styled from 'styled-components';

export const StyledInsights = styled.div`
  height: 100%;
  position: relative;
  h3.h3 {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    &.open {
      justify-content: space-between;
    }
    span:first-child {
      text-align: right;
      svg {
        font-size: 16px;
        color: ${props => props.theme.colors.label};
        margin-right: 5px;
      }
      em {
        font-style: normal;
        color: ${props => props.theme.colors.label};
        font-size: 12px;
        font-weight: normal;
      }
    }
    span:last-child {
      cursor: pointer;
      svg {
        font-size: 22px;
      }
    }
  }
  .insights-container {
    height: calc(100vh - 118px);
    position: absolute;
    padding-top: 1.2rem;
    font-size: 12px;
    left: 0;
    right: 0;
    .single-insight {
      font-weight: normal;
      margin-bottom: 2rem;
      cursor: pointer;
      padding: 10px;
      .h4 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          height: 15px;
          width: 15px;
          background: ${props => props.theme.colors.label};
          margin-right: 5px;
          border-radius: 4px;
          &.good {
            background: ${props => props.theme.colors.good};
          }
          &.warning {
            background: ${props => props.theme.colors.warning};
          }
          &.bad {
            background: ${props => props.theme.colors.bad};
          }
        }
      }
      &:hover, &.selected {
        border-radius: 4px;
        background: #f2f2f2;
      }
    }
  }
`;
