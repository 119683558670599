import BaseApi from './base-api';

class NotesAPI extends BaseApi {
  constructor() {
    super('/notes');
  }

  getReportNote(startupId, watchlistId) {
    if (watchlistId)
      return this.get(`watchlist/note/${watchlistId}`);
    return this.get(`company/note/${startupId}`);
  }

  createReportNote(note, startupId, watchlistId) {
    return this.post('reportNote', {note, startupId, watchlistId});
  }

}

export default new NotesAPI();
