import styled from 'styled-components';

export const StyledUserDetailsPanel = styled.div`
  .card {
    flex: 1;
    margin-bottom: 20px;
    padding: 13px 20px 40px 20px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
    border-radius: 4px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .edit {
      cursor: pointer;
    }

    h2 {
      margin-bottom: unset;
      margin-right: auto;
    }

    .MuiSvgIcon-root {
      font-size: 2.0rem;
      color: #00c4c3;
    }
  }

  .card-content {

    .fields {
      display: flex;

      .full-size {
        width: 100%;
        .item {
          margin: 10px 0;
        }
        .input-container, .MuiAutocomplete-root {
          width: 70%;
        }
        textarea {
          height: 120px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-left: 20px;
        .avatar {
          border-radius: 50%;
          width: 128px;
          height: 128px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }
        &.edit {
          margin-top: 31px;
        }
        &.empty {

          .avatar {
            border: 1px solid #bbb;
          }
        }
        &.edit.empty {
          .avatar {

            border: 1px solid ${props => props.theme.colors.primary};
          }
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        width: 300px;
        margin-left: 50px;
        &.edit {
          margin-top: 31px;
        }
        .item {
          font-size: 14px;
          margin-bottom: 20px;
          .input-container {
            width: 100%;
          }
          .form__label {
            font-size: 11px;
          }
          &.name {
            .item-value {
              font-weight: bold;
              font-size: 16px;
            }
          }
          &.email {
            .item-value {
              color: ${props => props.theme.colors.legend};
            }
          }
          .link a {
            display: flex;
            flex-direction: row;
            align-items: center;
            svg {
              margin-left: 5px;
            }
            span {
              max-width: 250px;
              white-space: nowrap;
              word-break: keep-all;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        width: 200px;
        margin-left: 50px;
        &.edit {
          margin-top: 31px;
        }
        .item {
          font-size: 14px;
          margin-bottom: 20px;
          .input-container {
            width: 100%;
          }
          .form__label {
            font-size: 11px;
          }
          &.name {
            .item-value {
              font-weight: bold;
              font-size: 16px;
            }
          }
          &.email {
            .item-value {
              color: ${props => props.theme.colors.legend};
            }
          }
          .link a {
            display: flex;
            flex-direction: row;
            align-items: center;
            svg {
              margin-left: 5px;
            }
            span {
              max-width: 250px;
              white-space: nowrap;
              word-break: keep-all;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

    }

    .footer {
      margin: 20px 0 10px 0;

      button {
        margin-right: 10px;
      }

      .date {
        margin-left: 20px;
        color: #aaa;
        font-size: 11px;
      }
      .error-message {
        margin: 5px 20px;
      }
      > div {
        margin: 10px 0 0 0;
        .error-txt {
          font-size: 12px;
        }
      }
    }

    .dropzone {
      margin-top: 10px;
      background-color: #f6f6f6;
      padding: 10px;
      color: #aaa;
      text-align: center;
      cursor: pointer;
      border: 2px dashed ${props => props.theme.colors.gray};
      p {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
`;

