import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { StyledHeatMap } from './HeatMap.style';
import cx from 'classnames';
import enums from "../../utils/enums";
import moment from "moment";

const NON_SELECTED_BAR_OPACITY = 0.4;


function hslToRgb(h, s, l){
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t){
      if(t < 0) t += 1;
      if(t > 1) t -= 1;
      if(t < 1/6) return p + (q - p) * 6 * t;
      if(t < 1/2) return q;
      if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }
  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}

function convertToRGB(color){
  if (color) {
    color = color.replace('#', '');
    if (color.length === 6) {
      const aRgbHex = color.match(/.{1,2}/g);
      return [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
      ];
    }
  }
}

function determinePeriodKind(text){
  if (text.includes('Q'))
    return enums.Period.Quarterly;
  else if (text.includes(' '))
    return enums.Period.Monthly;
  else
    return enums.Period.Yearly;
}

const convertCellIndexToPeriod = (cohort, cellIndex, periodKind) => {
  switch (periodKind) {
    case enums.Period.Monthly: return moment(cohort, 'YYYY MM').add(cellIndex + 1, 'month').format('YYYY-MM');
    case enums.Period.Quarterly: return moment(cohort, 'YYYY [Q]Q').add(cellIndex + 1, 'quarter').format('YYYY-[Q]Q');
    default: return moment(cohort, 'YYYY').add(cellIndex + 1, 'year').format('YYYY');
  }
};

const convertPeriodToCellIndex = (cohort, period, periodKind) => {
  switch (periodKind) {
    case enums.Period.Monthly: return moment(period, 'YYYY MM').diff(moment(cohort, 'YYYY MM'), 'months', true) - 1;
    case enums.Period.Quarterly: return moment(period, 'YYYY-[Q]Q').diff(moment(cohort, 'YYYY-[Q]Q'), 'quarter', true) - 1;
    default: return moment(period, 'YYYY').diff(moment(cohort, 'YYYY'), 'year', true) - 1;
  }
};

function HeatMap({ data, xLabels, yLabels, yTitle, color, colorScheme, valueFormatter, isFiscalYear, selectedSeriesKey: initialSelectedCohort, selectedSeriesSubType: selectedPeriod, onCohortSelected, thumbnailMode, weightedAvgLabel, cohortsReferences, isARR, isCompareMode, ...rest }) {
  const [selectedCohort, setSelectedCohort] = useState(initialSelectedCohort?.replace('c ', '').replace('-', ' '));
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState();
  const periodKind = determinePeriodKind(yLabels[0]);

  useEffect(() => {
    const cohort = initialSelectedCohort?.replace('c ', '').replace('-', ' ');
    setSelectedCohort(cohort);
    if (cohort && selectedPeriod)
      setSelectedPeriodIndex(convertPeriodToCellIndex(cohort, selectedPeriod, periodKind));
    else
      setSelectedPeriodIndex(undefined);
  }, [initialSelectedCohort, selectedPeriod]);

  const handleCohortClick = (cohort, periodCellIndex) => {
    let actualPeriod = periodCellIndex;
    let actualCohort = cohort;
    if (periodCellIndex >= 0) {
      if (cohort === selectedCohort && actualPeriod === selectedPeriodIndex) {
        actualPeriod = undefined;
        actualCohort = undefined;
      }
      setSelectedPeriodIndex(actualPeriod);
      setSelectedCohort(actualCohort);
    } else {
      actualCohort = cohort === selectedCohort && selectedPeriodIndex === undefined ? undefined : cohort;
      setSelectedCohort(actualCohort);
      setSelectedPeriodIndex();
    }
    if (onCohortSelected) {
      const currentCohort = actualCohort ? `c ${actualCohort.replace(' ', '-')}` : undefined;
      const currentPeriod = actualPeriod || actualPeriod === 0 ? convertCellIndexToPeriod(actualCohort, actualPeriod, periodKind) : undefined;
      onCohortSelected(currentCohort, currentPeriod);
    }
  };

  const maxValue = Math.max(...data.map(x =>Math.max(...x.map(x => x))));
  const minValue = Math.min(...data.map(x =>Math.min(...x.map(x => x))));
  const minMaxGap = Math.max(maxValue - minValue, 1);
  const numberOfDataColumns =  Math.max(...data.map(x => Math.max(x.length)));
  const firstColumnWidth = (isCompareMode ? 50 : 70) + (isFiscalYear ? 5 : 0);
  const cellWidth = `calc(${(100/(numberOfDataColumns + 1))}% - ${firstColumnWidth/(numberOfDataColumns + 2)}px - ${(16/numberOfDataColumns)}px)`;
  const rgbColor = convertToRGB(color);

  const renderLabel = (label, index) => {
    return <div key={`label_${index}`} className="column-header" style={{width: cellWidth}}>{label}</div>;
  };

  const onMouseOnOut = (evt, className, isOver) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (isOver) {
      if (evt.target.className === 'row-label')
        evt.target.parentNode.className += ' hover';
    } else {
      evt.target.parentNode.className = evt.target.parentNode.className.replace(' hover', '');
    }
  };

  const getRatioStyle = (ratio) => {
    if (colorScheme && colorScheme.background && colorScheme.background.length) {
      const fixedRatio = Math.round(Math.floor(ratio * 100) / (100 / (colorScheme.background.length-1)));
      return { background: colorScheme.background[fixedRatio], color: colorScheme.color[fixedRatio] || "#000" };
    }
    if (rgbColor && rgbColor.length === 3) {
      return {background: `rgb(${rgbColor[0]},${rgbColor[1]},${rgbColor[2]}, ${ratio+0.05})`, color: `rgba(0, 0, 0, ${ratio + 0.4})`};
    } else {
      // as the function expects a value between 0 and 1, and red = 0° and green = 120°
      // we convert the input to the appropriate hue value
      const hue = ratio * 1.2 / 3.6;
      // we convert hsl to rgb (saturation 100%, lightness 50%)
      const rgb = hslToRgb(hue, 1, .5);
      // we format to css value and return
      return {background: `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`, color: `rgba(0, 0, 0, ${ratio + 0.4})`};
    }
  };

  const renderReference = ({referenceValue, weight} = {}) => {
    return <div className='ref-cell' style={{width: cellWidth, color: colorScheme.color[0]}}>
      <div className='ref-weight' style={{right: `${100-weight}%`}}></div>
      <span>{referenceValue}</span>
    </div>;
  };

  const renderPeriodCell = (value, rowIndex, cellIndex, cohort, isWeightedAvg) => {
    const isDefined = !isNaN(value);
    if (!isDefined)
      return null;
    const ratio = ((value - minValue) / minMaxGap);
    const styleProps = !isNaN(ratio) ? getRatioStyle(ratio) : undefined;
    return <div key={`cell_${rowIndex}_${cellIndex}`}
                className={cx("cell", {small: thumbnailMode || isCompareMode, selected: selectedPeriodIndex === cellIndex && selectedCohort === cohort})}
                style={Object.assign({width: cellWidth}, styleProps)}
                data-cell-index={value ? cellIndex : undefined}
                onClick={cohort && !isWeightedAvg ? (evt) => handleCohortClick(cohort, cellIndex) : undefined}
    >
      {!thumbnailMode || selectedCohort === yLabels[rowIndex] && (selectedPeriodIndex === undefined || selectedPeriodIndex === cellIndex) ? valueFormatter ? valueFormatter(value) : value : ' '}
    </div>;
  };

  const renderRow = (row, rowIndex) => {
    const cohort = yLabels[rowIndex];
    let labelCohort = cohort;
    if (isFiscalYear && labelCohort !== 'Weighted Avg') {
      if (periodKind === enums.Period.Quarterly)
        labelCohort = labelCohort.replace('Q', 'FQ');
      else if (periodKind === enums.Period.Yearly)
        labelCohort = `FY ${labelCohort}`;
    }
    const isWeightedAvg = cohort === weightedAvgLabel;
    const content = <>
      {!thumbnailMode && isWeightedAvg ? <><div className="row-label" style={{width: `calc(${cellWidth} + ${firstColumnWidth}px + 1px)`}}>{labelCohort}</div></>: null}
      {!thumbnailMode && !isWeightedAvg ? <div className="row-label" style={{width: firstColumnWidth}} onClick={(evt) => handleCohortClick(cohort)}>{labelCohort}</div> : null}
      {!thumbnailMode && !isWeightedAvg && cohortsReferences ? renderReference(cohortsReferences[cohort]) : null}
      {row.map((cell, cellIndex) => renderPeriodCell(cell, rowIndex, cellIndex, cohort, isWeightedAvg))}
    </>;

    if (isWeightedAvg) {
      return <div key={'row_'+ rowIndex} className="row non-selectable" >
        {content}
      </div>;
    }

    return <div key={'row_'+ rowIndex} className={cx("row", {selected: selectedPeriodIndex === undefined && selectedCohort === cohort})} onMouseOver={(evt) => onMouseOnOut(evt, 'hover', true)} onMouseOut={(evt) => onMouseOnOut(evt, 'hover', false)}>
      {content}
    </div>;
  };

  const classes = {
    selectable: !!onCohortSelected,
    hasSelection: !!selectedCohort
  };

  let prefix;
  switch (periodKind) {
    case enums.Period.Monthly:
      prefix = 'M';
      break;
    case enums.Period.Quarterly:
      prefix = 'Q';
      break;
    default:
      prefix = 'Year';
      break;
  }

  return (
    <StyledHeatMap className={cx(classes)} thumbnailMode={thumbnailMode || isCompareMode} {...rest}>
      {!thumbnailMode ? <div>
        <div className="cell-label column-header" style={{width: firstColumnWidth}}>{yTitle}</div>
        <div className="cell column-header" style={{width: cellWidth}}>{prefix}0 {isARR ? 'ARR' : 'Logos'}</div>
        {xLabels.map(renderLabel)}
        {[...new Array(Math.max(numberOfDataColumns - xLabels.length - 1, 0))].map((label, index) => renderLabel('', xLabels.length + index))}
      </div> : null}
      {data.map(renderRow)}
    </StyledHeatMap>
  );
}

HeatMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  xLabels: PropTypes.array,
  yLabels: PropTypes.array,
  color: PropTypes.string,
  colorScheme: PropTypes.shape({
    background: PropTypes.array,
    color: PropTypes.array
  })
};

HeatMap.defaultProps = {
  data: [[]],
  xLabels: [],
  yLabels: []
};

export default HeatMap;
