import styled from 'styled-components';

export const StyledTeammateJoin = styled.div`

  width: 800px;
  .join-content {
    display: flex;

  }
  .input-container {
    margin-bottom: 10px;
    width: 400px;
  }
  .agreement {
    color: ${props => props.theme.colors.label};
    padding: 20px 0;
    font-size: 12px;
  }
  button {
    margin-top: 10px;
  }
  .error {
    margin-top: 10px;
    font-size: 13px;
  }
`;

