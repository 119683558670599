import styled from "styled-components";

export const StyledErrorBoundary = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: rgba(0,0,0,0.1);
    > div {
      position: absolute;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 0 3px 10px #666;
      background: #fff;
      h1 {
        font-size: 24px;
        padding: 20px;
      }

      h2 {
        padding: 20px;
        font-size: 20px;

        span {
          color: #0a3666;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
`;
