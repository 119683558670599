const general = {
  smBreakpoint: '600px',
  mdBreakpoint: '768px',
  lgBreakpoint: '992px',
  xlBreakpoint: '1200px',
  xxlBreakpoint:'1680px',


  // Media Queries
  // -------------------------
  '$grid-breakpoints': `(
    'xs': '0',
    'sm': '576px',
    'md': '768px',
    'lg': '990px',
    'xl': '1200px'
  )`,
  '$m-xs': `map - get($grid - breakpoints, xs)`,
  '$m-sm': `map - get($grid - breakpoints, sm)`,
  '$m-md': `map - get($grid - breakpoints, md)`,
  '$m-lg': `map - get($grid - breakpoints, lg)`,
  '$m-xl': `map - get($grid - breakpoints, xl)`,
};

export default general;
