import styled from 'styled-components';

export const StyledInviteTeammate = styled.div`
  max-width: 700px;
  p {
    font-size: 14px;
    margin: 40px 0;
  }
`;

