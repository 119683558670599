import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from "../FormLabel";
import { StyledSelect } from './Select.style';
import cx from 'classnames';

function Select({ children, label, className, error, ...rest }) {
    return (
        <StyledSelect className={className} error={error}>
            <FormLabel label={label} error={error} />
            <select className='form__select' {...rest}>
                {children}
            </select>
        </StyledSelect>
    );
}

Select.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool
};

export default Select;
