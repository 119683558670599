import React from "react";
import MsgBox from "../MsgBox";
import Button from "../../Button";

export default function SubmitSupportRequestMsgBox({ open, onClose }){
  return <MsgBox title={<strong>Your request has been submitted !</strong>}
                 open={open}
                 modal={true}
                 footer={<Button onClick={onClose}>OK</Button>}
  >
      We will inform you via email once the analytics are ready to view.
  </MsgBox>;
};

