import React, {useEffect, useState} from "react";
import { StyledCompanyDetails } from './CompanyDetails.style';
import PageTitle from "../../../components/PageTitle";
import Recommendations from "../../../components/Recommendations";
import {useSelector} from "react-redux";
import { StartupsAPI, InvestorsAPI } from "../../../api";
import Loading from "../../../components/Loading/Loading";
import FormLabel from "../../../components/FormLabel";
import CompanyLogo from "../../../components/CompanyLogo";
import { useLocation, useParams } from "react-router-dom";
import routes from "../../routes";
import { OpenInNewIcon } from "../../../assets/mui-icons";
import Icon from "../../../components/Icon";
import AddEditStartupPopup from "../../investors/Watchlist/AddEditStartupPopup";
import Error from "../../../components/Error";
import CompanyNavigation from "../CompanyNavigation";


export default function CompanyDetails() {
  const { id: companyId, watchlistId } = useParams();
  const location = useLocation();
  const isProduction = window.location.href.startsWith('https://www.novvo.ai/') || window.location.href.startsWith('https://app.novvo.ai/');
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.isAdmin;
  const isSupportUser = user?.isSupportUser;
  const isStartup = user?.isStartupMode;
  const returnPath = isStartup ? routes.Startups.Network : routes.Investors.Network;
  const [busy, setBusy] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(null);
  const [companyDescription, setCompanyDescription] = useState();
  const [selectedGroup, setSelectedGroup] = useState('DESCRIPTION');
  const API = isStartup ? StartupsAPI : InvestorsAPI;

  useEffect(() => {
    if (companyId || watchlistId) {
      setBusy(true);
      if (watchlistId) {
        API.getWatchlistDescription(watchlistId).then(({success, data, error}) => {
          if (success) {
            setCompanyDescription(data);
          } else {
            setError(error);
          }
          setBusy(false);
        });
      } else {
        API.getCompanyDescription(companyId).then(({success, data, error}) => {
          if (success) {
            setCompanyDescription(data);
          } else {
            setError(error);
          }
          setBusy(false);
        });
      }
    }
  }, [companyId, API, watchlistId]);

  const saveStartup = (id, startupData) => {
    if (id) {
      InvestorsAPI.updateWatchlistCompany(id, startupData).then(
        ({ success, data, error }) => {
          if (success) {
            setCompanyDescription(data);
            setEdit(false);
          } else {
            setError(error);
          }
        }
      );
    }
  };

  const renderItem = (label, value) => {
    return value !== undefined ? <div className="item">
      <FormLabel label={label} />
      <div>{value}</div>
    </div> : null;
  };

  const cleanFilename = (value) => {
    const valueParts = value.split('/');
    value = valueParts[valueParts.length-1];
    return value;
  }

  const renderLink = (label, value, shouldCleanFilename = false) => {
    return value ? <div className="item">
      <FormLabel label={label} />
      <div className="link"><a href={value.startsWith('http') ? value : `https://${value}`} target="_blank" rel="noopener noreferrer"><span>{shouldCleanFilename ? cleanFilename(value) : value}</span><OpenInNewIcon /></a></div>
    </div> : null;
  };

  const renderDescriptionCard = () => {
    const { name, websiteUrl, city, industry, location, updatedAt, description, longDescription, presentationUrl } = companyDescription;

    return <div className="card">
      {isAdmin && !isSupportUser && watchlistId ? <div className="edit"><Icon type="edit" size="sm" tooltip="Edit" onClick={() => setEdit(true)} /></div> : null}
      <div>
        <div className="name-cnt">
          {renderItem('Company name', name)}
          {renderItem('Location', location)}
        </div>
        <div>
          <CompanyLogo company={companyDescription} size={128}/>
        </div>
      </div>

      {renderLink('Web site URL', websiteUrl)}
      {renderLink('Presentation URL', presentationUrl, true)}
      {renderItem('Industry', industry)}
      {renderItem('Short company description', description)}
      {renderItem('Full company description', longDescription)}
      </div>;
    };

  const renderLeadershipCard = () => {
      return <div className="card">
        LEADERSHIP
      </div>;
  };

  const renderAnalyticsCard = () => {
    return <div className="card">
      SAAS_METRICS
    </div>;
  };

  const renderDocsCard = () => {
      return <div className="card">
        DOCUMENTS
      </div>;
  };

  const renderMainContent = () => {
    if (!companyDescription)
      return null;

    switch (selectedGroup) {
      case 'DESCRIPTION': return renderDescriptionCard();
      case 'LEADERSHIP': return renderLeadershipCard();
      case 'SAAS_METRICS': return renderAnalyticsCard();
      case 'DOCS': return renderDocsCard();
    }
  };

  if (!companyDescription) {
    if (error)
      return <StyledCompanyDetails><Error className="error" error={error} /></StyledCompanyDetails>;

    return <Loading />;
  }

  return (
    <StyledCompanyDetails>
      <PageTitle icon="back" variant="h1" lineheight="1" path={returnPath}><CompanyLogo company={companyDescription} size={44}/> {companyDescription.name}</PageTitle>
      <CompanyNavigation currentSelection="DESCRIPTION" showProfile={companyDescription?.isViewProfileAllowed} showAnalytics={companyDescription?.isViewAnalyticsAllowed} />
      <div className="page-content">
        <div className="main">
          {busy ? <Loading size={'small'} /> : renderMainContent()}
        </div>
        <div className="suggestions">
          {!isProduction &&
          <Recommendations type="investors" />
          }
        </div>
      </div>
      {edit ? (
          <AddEditStartupPopup
            startup={companyDescription}
            onClose={() => {
              setEdit(false);
              setError(null);
            }}
            onSave={saveStartup}
            error={error}
          />
        ) : null}
    </StyledCompanyDetails>
  );
}
