import React, { useEffect, useRef, useState } from "react";
import {useSelector} from "react-redux";
import {StartupsAPI, InvestorsAPI} from "../../../api";
import Loading from "../../Loading/Loading";
import {StyledExcelViewer} from "./ExcelViewer.style";
import RadioButton from "../../RadioButton";
import { MoreVert } from "../../../assets/mui-icons";
import PropTypes from "prop-types";

ExcelViewer.propTypes = {
  fileName: PropTypes.string,
  sourceFileType: PropTypes.string,
  sheet: PropTypes.string,
  selectionMode: PropTypes.oneOf(['none','singleRow','multipleRows','singleCell','multipleCells']),
  selectedRows: PropTypes.array,
  selectedColumns: PropTypes.array,
  onRowColumnsSelection: PropTypes.func,
  onFileLoaded: PropTypes.func,
  onFileLoadCancel: PropTypes.func
};

ExcelViewer.defaultProps = {
  selectionMode: 'none',
  selectedRows: [],
  selectedColumns: []
};

const ExcelCell = ({rowIndex, cellIndex, isDummyCell, isHeaderCell, onCellClick, value, className, colSpan}) => {
  const onClick = (e) => {
    if (onCellClick)
      onCellClick(e, rowIndex, cellIndex);
  };
  return <td onClick={onClick} className={className} colSpan={colSpan} data-row={rowIndex} data-col={cellIndex}>
    {isHeaderCell && !isDummyCell ? value + 1 : isDummyCell ? <MoreVert /> : value}
  </td>;
}

const ExcelRow = ({rowIndex, className, onRowClick, children}) => {
  const onClick = (e) => {
    if (onRowClick)
      onRowClick(e, rowIndex);
  };
  return <tr className={className} onClick={onClick} data-row={rowIndex}>
    {children}
  </tr>;
};


export default function ExcelViewer({fileName, sourceFileType, watchlistId, sheet, currentSelections, resetScrollView, onSheetSelected, viewSelection, allSelections,
                                      selectionMode, onRowColumnsSelection, onFileLoaded, onCancel, onError}) {
  const {user} = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState();
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(sheet);
  const [showSheetSelection, setShowSheetSelection] = useState(false);
  const [lastCellClick, setLastCellClick] = useState();
  const [hasError, setHasError] = useState(false);
  const refContent = useRef();

  useEffect(() => {
    setSelectedSheet(sheet);
  }, [sheet]);

  useEffect(() => {
    loadFile();
  }, [fileName]);

  useEffect(() => {
    if (resetScrollView && refContent && refContent.current) {
      if (refContent.current.scrollTo)
        refContent.current.scrollTo({top: 0, left:0, behavior: 'smooth'});
      else {
        refContent.current.scrollTop = 0;
        refContent.current.scrollLeft = 0;
      }
    }
  }, [resetScrollView]);

  const getHighlightClass = (rowIndex = -1, cellIndex = -1) => {
    const classNames = [];
    const highlight = shouldHighlight(rowIndex, cellIndex);
    if (highlight)
      classNames.push('highlight');
    const current = shouldHighlightCurrent(rowIndex, cellIndex);
    if (current)
      classNames.push('current');
    if (classNames.length)
      return classNames.join(' ');
    return undefined;
  };

  useEffect(() => {
    if (refContent && refContent.current) {
      const trs = refContent.current.getElementsByTagName('tr');
      for (let i=0,len=trs.length ; i<len ; i++) {
        const tr = trs[i];
        const classNames = getHighlightClass(i-1);
        tr.className = classNames || '';
        const tds = tr.getElementsByTagName('td');
        for (let j=0,jlen=tds.length ; j<jlen ; j++){
          const td = tds[j];
          const classNames = getHighlightClass(i-1, j);
          td.className = classNames || '';
        }
      }
    }
  }, [allSelections, currentSelections]);

  useEffect(() => {
    const viewSheetSelection = sheets.length > 1 && viewSelection;
    const shouldReloadFile = showSheetSelection && !viewSheetSelection;
    setShowSheetSelection(viewSheetSelection);
    if (shouldReloadFile) {
      loadFile();
    }
  }, [viewSelection, showSheetSelection]);

  const loadFile = () => {
    setIsLoading(true);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    API.readDataFile(fileName, sourceFileType, selectedSheet, watchlistId).then(({success, data, error}) => {
      if (success) {
        if (data.actionRequired === "SHEET_SELECTION") {
          setSheets(data.sheets);
          setSelectedSheet(data.previouslySelectedSheet || data.sheets?.[0]?.name);
          setShowSheetSelection(true);
          if (onFileLoaded)
            onFileLoaded(data.templates, null, null, true);
        } else {
          setSelectedFileData(data.rows);
          if (onFileLoaded) {
            const dataRowsToRowIndexMap = {};
            data.rows.forEach((row, rowIndex) => {
              dataRowsToRowIndexMap[data.rows[rowIndex][0]] = rowIndex;
            });
            onFileLoaded(data.templates, dataRowsToRowIndexMap, selectedSheet, sheets.length > 1);
          }
        }
      } else {
        setHasError(true);
        if (onError)
          onError(error);
      }
      setIsLoading(false);
    });
  };

  const isRowSelected = (selections, rowIndex) => {
    return selections?.rows?.includes(rowIndex);
  };

  const isCellSelected = (selections, rowIndex, colIndex) => {
    return selections?.cells?.some(cell => cell.r === rowIndex && cell.c === colIndex);
  };

  const shouldHighlight = (rowIndex = -1, colIndex = -1) => {
    if (isRowSelected(allSelections, rowIndex)) {
      if (colIndex === -1 && !allSelections.cells?.length)
        return true;

      if (allSelections.cells?.some(cell => cell.c === colIndex))
        return true;
    }

    return isCellSelected(allSelections, rowIndex, colIndex);
  };

  const shouldHighlightCurrent = (rowIndex = -1, colIndex = -1) => {
    if (isRowSelected(currentSelections, rowIndex)) {
      // if (colIndex === -1 && !currentSelections?.cells)
      //   return true;

      if (!allSelections?.cells?.length || allSelections?.cells?.some(cell => cell.c === colIndex))
        return true;
    }

    return isCellSelected(currentSelections, rowIndex, colIndex);
  };

  const getDataRowIndex = (rowIndex) => {
    return selectedFileData[rowIndex][0];
  };

  const createSelectionFromDataRows = (rows) => {
    const dataRows = [];
    let startRowIndex = null;
    rows.forEach((rowIndex, index) => {
      if (startRowIndex === null)
        startRowIndex = rowIndex;
      const nextRowIndex = rows[index + 1];
      if ((rowIndex + 1) !== nextRowIndex) {
        if (startRowIndex !== rowIndex)
          dataRows.push(`${getDataRowIndex(startRowIndex)}-${getDataRowIndex(rowIndex)}`);
        else
          dataRows.push(getDataRowIndex(rowIndex));
        startRowIndex = null;
      }
    });

    return dataRows;
  };

  const onRowClick = (e, rowIndex) => {
    if (['singleRow', 'multipleRows'].includes(selectionMode)) {
      let {rows = []} = currentSelections || {rows: []};

      if (selectionMode === 'singleRow') {
        rows = [rowIndex];
      }

      if (selectionMode === 'multipleRows') {
        const isSelected = isRowSelected(currentSelections, rowIndex);
        const wasCtrlPressed = e.nativeEvent.ctrlKey || e.nativeEvent.metaKey;
        const wasShiftPressed = e.nativeEvent.shiftKey;

        if (wasCtrlPressed) {
          if (isSelected) {
            rows.splice(rows.indexOf(rowIndex), 1);
          } else {
            rows.push(rowIndex);
          }
        } else if (wasShiftPressed && rows.length) {
          const lastSelected = lastCellClick?.r || rows[0];
          const minIndex = Math.min(rowIndex, lastSelected);
          const maxIndex = Math.max(rowIndex, lastSelected);
          for (let i=minIndex; i<=maxIndex; i++) {
            if (!rows.includes(i))
              rows.push(i);
          }
        } else {
          rows = [rowIndex];
        }
      }
      if (onRowColumnsSelection) {
        rows.sort((r1, r2) => r1-r2);
        const dataRows = createSelectionFromDataRows(rows);
        onRowColumnsSelection({ rows, dataRows });
      }
    }
  };

  const onCellClick = (e, rowIndex, colIndex) => {
    if (['singleCell', 'multipleCells'].includes(selectionMode)) {
      let {cells} = currentSelections || {cells: []};
      cells = cells || [];
      const wasShiftPressed = e.nativeEvent.shiftKey;
      const wasCtrlPressed = e.nativeEvent.ctrlKey || e.nativeEvent.metaKey;
      const isSelected = isCellSelected(currentSelections, rowIndex, colIndex);

      if (selectionMode === 'singleCell') {
        if (isSelected && (wasCtrlPressed || wasShiftPressed))
          cells = [];
        else
          cells = [{r: rowIndex, c: colIndex}];
      }

      if (selectionMode === 'multipleCells') {
        if (wasCtrlPressed) {
          if (isSelected) {
            cells.splice(cells.findIndex(c => c.r === rowIndex && c.c === colIndex), 1);
          } else {
            cells.push({r: rowIndex, c: colIndex});
          }
        } else if (wasShiftPressed && lastCellClick) {
          if (colIndex === lastCellClick.c) {
            const minIndex = Math.min(rowIndex, lastCellClick.r);
            const maxIndex = Math.max(rowIndex, lastCellClick.r);
            for (let i=minIndex; i<=maxIndex; i++) {
              if (lastCellClick.r !== i)
                cells.push({r: i, c: colIndex});
            }
          } else if (rowIndex === lastCellClick.r) {
            const minIndex = Math.min(colIndex, lastCellClick.c);
            const maxIndex = Math.max(colIndex, lastCellClick.c);
            for (let i=minIndex; i<=maxIndex; i++) {
              if (lastCellClick.c !== i)
                cells.push({r: rowIndex, c: i});
            }
          }
        } else {
          cells = [{r: rowIndex, c: colIndex}];
        }
      }
      if (onRowColumnsSelection)
        onRowColumnsSelection({ cells });
    }
    setLastCellClick({r: rowIndex, c: colIndex});
  };

  const renderExcelTable = () => {
    if (!selectedFileData)
      return null;

    const numberOfColumns = selectedFileData.reduce((acc, row) => {
      if (row.length > acc)
        acc = row.length;
      return acc
    }, 0);

    const headers = [];
    for (let i=0; i<numberOfColumns-1; i++) {
      headers.push(getExcelColumnHeader(i));
    }

    const getAdditionalCells = (currentCells, rowIndex) => {
      const isDummyCell = currentCells[0] === '...';
      if (isDummyCell) {
        return <ExcelCell className={"dummy"} colSpan={numberOfColumns - currentCells.length} />
      } else {
        const cells = [];
        for (let cellIndex=currentCells.length; cellIndex<numberOfColumns; cellIndex++)
          cells.push(<ExcelCell key={cellIndex} />);
        return cells;
      }
    };

    return <div className="table-content">
      <table cellPadding="0" cellSpacing="0">
        <thead>
        <tr>
          <th></th>
          {headers.map((header, index) =>
            <th key={`header-${index}`}>
              <div>{header}</div>
            </th>
          )}
        </tr>
        </thead>
        <tbody>
        {selectedFileData.map((row, rowIndex) => {
          const isDummyRow = row[0] === '...';
          const isNextRowDummy = !isDummyRow && selectedFileData[rowIndex+1] && selectedFileData[rowIndex+1][0] === '...';
          return <ExcelRow key={rowIndex} rowIndex={rowIndex} className={isDummyRow ? 'dummy-row' : isNextRowDummy ? 'next-row-dummy' : ''} onRowClick={onRowClick}>
            {row.map((cellValue, cellIndex) => <ExcelCell key={rowIndex + '_' + cellIndex} rowIndex={rowIndex} cellIndex={cellIndex} isDummyCell={cellValue === '...'} isHeaderCell={cellIndex === 0} onCellClick={onCellClick} value={cellValue} />)}
            {getAdditionalCells(row, rowIndex)}
          </ExcelRow>
        })}
        </tbody>
      </table>
    </div>;
  };

  const renderSheetsOptions = () => {
    const options = sheets.sort((s1 ,s2) => s1.hidden ? 1 : s2.hidden ? -1 : 0).map(s => {
      const disabled = s.empty;
      const value = s.name;
      const label = s.hidden || s.empty ? <>{value} <em>{s.hidden ? 'hidden' : ''}{s.empty && s.hidden ? ', ' : ''}{s.empty ? 'empty' : ''} sheet</em></> : value;
      return {label, value, disabled};
    });
    return <div className="files-list">
      <RadioButton layout="vertical" options={options} selectedValue={selectedSheet} onChange={(e) => {
        setSelectedSheet(e.target.value);
        if (onSheetSelected)
          onSheetSelected(e.target.value);
      }} />
    </div>;
  };

  const renderSheetContent = () => {
    if (hasError)
      return null;

    return <div className="wizard-content" tabIndex={0} ref={refContent}>
      {renderExcelTable()}
    </div>;
  };

  return (
    <StyledExcelViewer showBorder={!isLoading && !showSheetSelection && !hasError}>
      {isLoading && <div className="excel-loading"><Loading size="medium">Loading file...</Loading></div>}
      {!isLoading ? showSheetSelection ?
          <div className="sheet-selection-content">
            {renderSheetsOptions()}
          </div>
        : renderSheetContent() : null}
    </StyledExcelViewer>
  );
}

function getExcelColumnHeader(colIndex) {
  const indexToLetter = (index) => String.fromCharCode(65 + index);
  if (colIndex > 25)
    return `${indexToLetter(Math.floor(colIndex / 26) - 1)}${indexToLetter((colIndex % 26))}`;
  else
    return indexToLetter(colIndex);
}

