import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from "react-router-dom";
import Master from '../layout/Master/Master';

function PublicRoute({ mode, layout }) {
  return <Master mode={mode} layout={layout}>
          <Outlet />
        </Master>;
}

PublicRoute.propTypes = {
    mode: PropTypes.oneOf(['light', 'dark']),
    layout: PropTypes.oneOf(['normal', 'fullScreen']),
};

PublicRoute.defaultProps = {
    mode: 'light',
    layout: 'normal',
};

export default PublicRoute;
