import React from 'react';
import { MsgBox } from "../index";
import Button from "../../Button";

export default function OfflineGenerationNotification({ open, limitation, fileType, onClose }){
  return <MsgBox
    showCloseIcon={true}
    open={open}
    title="You are all set!"
    onClose={onClose}
    footer={<Button onClick={onClose} color="white" borderColor="primary">Close</Button> }
  >
    As the file has more than {limitation} {fileType === 'mrr' ? 'cells' : 'rows'} processing may take several hours.<br />We will notify you by email when the job is done
  </MsgBox>;
};

