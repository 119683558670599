const routes = {
  Investors: {
    Profile: '/app/investor/profile',
    UsersManagement: '/app/investor/team',
    WatchlistDrillDown: '/app/investor/watchlist/:watchlistId',
    Startup: '/app/investor/startup/:id',
    Network: '/app/investor/network',
    Feed: '/app/investor/feed',
    InviteTeammate: '/app/investor/team/invite',
    Invite: '/app/investor/invite',
    FileUploadHelp: '/app/investor/file-upload-tips',
    BaseStartupAnalytics: '/app/investor/startup/:id/analytics',
    StartupAnalytics: '/app/investor/startup/:id/analytics/:analyticsKind',
    BaseWatchlistAnalytics: '/app/investor/watchlist/:watchlistId/analytics',
    WatchlistAnalytics: '/app/investor/watchlist/:watchlistId/analytics/:analyticsKind',
    MyAccount: '/app/investor/me',
    getStartupUrl(id) {
      return this.Startup.replace(':id', id);
    },
    getWatchlistUrl(id) {
      return this.WatchlistDrillDown.replace(':watchlistId', id);
    },
    getStartupAnalyticsUrl(id, analyticsKind = 'saas-metrics') {
      return this.StartupAnalytics.replace(':id', id).replace(':analyticsKind', analyticsKind);
    },
    getWatchlistAnalyticsUrl(id, analyticsKind = 'saas-metrics') {
      return this.WatchlistAnalytics.replace(':watchlistId', id).replace(':analyticsKind', analyticsKind);
    }
  },
  Startups: {
    BaseAnalytics: '/app/startup/analytics',
    Analytics: '/app/startup/analytics/:analyticsKind',
    Network: '/app/startup/network',
    Profile: '/app/startup/profile',
    UsersManagement: '/app/startup/team',
    Investor: '/app/startup/investor/:id',
    Feed: '/app/startup/feed',
    Invite: '/app/startup/invite',
    FileUploadHelp: '/app/startup/file-upload-tips',
    InviteTeammate: '/app/startup/team/invite',
    MyAccount: '/app/startup/me',
    DataRoom: '/app/startup/data-room',
    getInvestorUrl(id) {
      return this.Investor.replace(':id', id);
    },
    getStartupAnalyticsUrl(analyticsKind = 'saas-metrics') {
      return this.Analytics.replace(':analyticsKind', analyticsKind);
    }
  },
  Public: {
    Login: '/login',
    ResetPassword: '/reset/:sig',
    ForgotPassword: '/forgot',
    GetStarted: '/getstarted',
    Signup: '/signup',
    TeammateJoin: '/app/team/join'
  },
  Admin: {
  },
  Support: {
    Home: '/app/support/home'
  }
};

export default routes;
