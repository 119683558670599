import React, { useEffect, useState } from "react";
import { StyledSearchAnalytics } from './SearchAnalytics.style';
import {Search} from "../../../../assets/mui-icons";
import TextInput from "../../../../components/TextInput";

export default function SearchAnalytics({ placeholder = 'Search for a chart', searchConfig = {}, onChange}) {

  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activeResult, setActiveResult] = useState(0);
  const keys = Object.keys(searchConfig || {});

  useEffect(() => {
    const keyword = (searchKeywords || '').toLowerCase().trim();
    if (!keyword || !keys.length)
      return;
    const keywordParts = keyword.split(' ').map(kw => kw.trim());
    let results = [];
    keys.forEach(analyticsKind => {
      const options = searchConfig[analyticsKind].kpis.filter(config => !config.kpiType.includes(':customers')).filter(config => (config.title || '').toLowerCase().includes(keyword) || (keywordParts.length > 1 && keywordParts.map(kw => (config.title || '').toLowerCase().includes(kw)).filter(r => !!r).length === keywordParts.length)).reduce((acc, config) => {
        if (!Object.values(acc).map(i => i.title).includes(config.title))
          acc.push(config);
          config.analyticsKind = analyticsKind;
          const lowercaseTitle = config.title.toLowerCase();

          config._search_score = 0;
          if (lowercaseTitle === keyword)
            config._search_score += 100;
          else if (lowercaseTitle.startsWith(keyword)) {
            config._search_score += 50;
          } else if (keywordParts.map(kw => lowercaseTitle.startsWith(kw)).filter(r => !!r).length === keywordParts.length) {
            config._search_score += (keywordParts.length*2);
          } else if (lowercaseTitle.includes(keyword)) {
            config._search_score += 1;
          }

          return acc;
        }, []);
        results.push(...options);
    });

    results.sort((a, b) => {
      const baseSort = b._search_score - a._search_score;
      if (baseSort !== 0)
        return baseSort;
      let fa = a.title.toLowerCase(),
        fb = b.title.toLowerCase();
      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
    });

    setSearchResults(results);
    setActiveResult(0);
  }, [searchKeywords, searchConfig]);

  const onSearchKeyDown = (evt) => {

    const keyCode = evt.keyCode;
    if (keyCode === 38 || keyCode === 40) {
      evt.preventDefault();
      evt.stopPropagation();
      const isDown = keyCode === 40;
      let newIndex = activeResult === null ? -1 : activeResult;
      newIndex += (isDown ? 1 : -1);
      if (newIndex > searchResults.length-1)
        newIndex = 0;
      if (newIndex < 0)
        newIndex = searchResults.length - 1;

      setActiveResult(newIndex);
      setTimeout(() => {
        const element = document.getElementsByClassName('search-result-active')[0];
        if (element) {
          element.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});
        }
      }, 20);
    } else if (keyCode === 13) {
      evt.preventDefault();
      evt.stopPropagation();
      const kpiConfig = searchResults[activeResult];
      if (kpiConfig)
        onSearchResultClicked(kpiConfig);
    } else if (keyCode === 27) {
      evt.preventDefault();
      evt.stopPropagation();
      onCloseSearch();
    }
  };

  const onCloseSearch = () => {
    setSearchKeywords('');
    setActiveResult(0);
  };

  const onSearchResultClicked = (kpiConfig) => {
    if (onChange)
      onChange(kpiConfig);
    onCloseSearch();
  };

  const renderSearchResults = () => {
    return <div className="search_results">
      {searchResults.map((result, index) => {
        return <div tabIndex={-1} className={activeResult === index ? 'search-result-active' : ''} key={`search_result_${index}`} onClick={() => onSearchResultClicked(result)}>{result.title}</div>;
      })}
    </div>;
  };

  if (!keys.length)
    return null;

  return (
    <StyledSearchAnalytics>
        <Search className="search-icon" />
        <TextInput className="search-input" type="text" placeholder={placeholder} value={searchKeywords} onKeyDown={onSearchKeyDown} onChange={(evt) => {setSearchKeywords(evt.target.value);}} />
        {searchKeywords && searchResults.length ? renderSearchResults() : null}
    </StyledSearchAnalytics>
  );
};
