import Converters from "../../utils/converters";
import React from 'react';
import KPIsAPI from '../../api/kpis';
import colors from '../../styles/colors';
import ColorsHelper from "../../utils/colors-helper";
import General from "../../utils/general";

const getDataFormatter = (dataType, isFiscalYear = false) => {
  switch (dataType) {
    case 'percent':
      return Converters.toPercentage;
    case 'currency':
      return Converters.toCurrencyString;
    case 'number':
      return Converters.toDecimalString;
    case 'category':
      return isFiscalYear ? Converters.formatFiscalPeriodString : Converters.formatPeriodString;
    default:
      return (val) => val;
  }
};



const colorMap = {
  "#C7A822": {
    0: '#E7CE63',
    1: "#C7A822",
    2: "#F17E32"
  },
  Segments: [
    '#C060A1',
    '#3D72B0',
    '#00A1DE',
    '#12999E',
    '#02C4C3',
    '#73AF55',
    '#9ECE62',
    '#E7CE63',
    '#ED7E5D',
    '#D53C21',
    '#A41180',
    '#28467E',
    '#0083BD',
    '#10625E',
    '#00A8A3',
    '#508741',
    '#7DB53E',
    '#D2A200',
    '#E75C18',
    '#BB3019',
    '#E5BAD6',
    '#4E91CF',
    '#50BEE5',
    '#10B3C9',
    '#ADE5E6',
    '#93C974',
    '#C6E2A4',
    '#F1E2A2',
    '#F2A28A',
    '#F1492A'
  ]
};

const SpecificChartHelpers = {
  [KPIsAPI.Types.ARR_Waterfall]: {
    'transparent': { color: 'transparent', stackId: 'stack1', tooltipExclude: true},
    'startingBalance': {colorKey: 'primary', color: colors.chartsPalettes.revenue.primary, stackId: 'stack1', order: 0, useForLabels: true, labelValue: 'startingBalance'},
    'new': {colorKey: 'primary', color: colors.chartsPalettes.revenue.lightest, stackId: 'stack1', order: 1, useForLabels: true, labelValue: 'new'},
    'reactivation': {colorKey: 'primary', color: colors.chartsPalettes.revenue.lighter, stackId: 'stack1', order: 2, useForLabels: true, labelValue: 'reactivation'},
    'expansion': {colorKey: 'primary', color: colors.chartsPalettes.revenue.light, stackId: 'stack1', order: 3, useForLabels: true, labelValue: 'expansion'},
    'contraction': {colorKey: 'primary', color: colors.chartsPalettes.revenue.dark, stackId: 'stack1', order: 5, useForLabels: true, labelValue: 'contraction', revertRadius: true},
    'churn': {colorKey: 'primary', color: colors.chartsPalettes.revenue.darker, stackId: 'stack1', order: 6, useForLabels: true, labelValue: 'churn', revertRadius: true},
    'endingBalance': {colorKey: 'primary', color: colors.chartsPalettes.revenue.primary, stackId: 'stack1', total: true, useForLabels: true, labelValue: 'endingBalance'}
  },
  [KPIsAPI.Types.ARR_Breakdown]: {
    'new': {colorKey: 'lightest', color: colors.chartsPalettes.revenue.lightest, stackId: 'stack1', order: 1, useForLabels: true},
    'reactivation': {colorKey: 'lighter', color: colors.chartsPalettes.revenue.lighter, stackId: 'stack1', order: 2},
    'expansion': {colorKey: 'light', color: colors.chartsPalettes.revenue.light, stackId: 'stack1', order: 3},
    'startingBalance': {colorKey: 'primary', color: colors.chartsPalettes.revenue.primary, stackId: 'stack1', order: 4},
    'contraction': {colorKey: 'dark', color: colors.chartsPalettes.revenue.dark, stackId: 'stack1', order: 5},
    'churn': {colorKey: 'darker', color: colors.chartsPalettes.revenue.darker, stackId: 'stack1', order: 6},
    'endingBalance': {colorKey: 'darkest', color: colors.chartsPalettes.revenue.darkest, type: 'line', total: true}
  },
  [KPIsAPI.Types.LogosBreakdown]: {
    'new': {colorKey: 'lightest', color: colors.chartsPalettes.logos.lightest, stackId: 'stack1', order: 1, useForLabels: true},
    'reactivation': {colorKey: 'lighter', color: colors.chartsPalettes.logos.lighter, stackId: 'stack1', order: 2},
    'add': {colorKey: 'light', color: colors.chartsPalettes.logos.light, stackId: 'stack1', order: 3},
    'startingBalance': {colorKey: 'primary', color: colors.chartsPalettes.logos.primary, stackId: 'stack1', order: 4},
    'sub': {colorKey: 'dark', color: colors.chartsPalettes.logos.dark, stackId: 'stack1', order: 5},
    'churn': {colorKey: 'darker', color: colors.chartsPalettes.logos.darker, stackId: 'stack1', order: 6},
    'endingBalance': {colorKey: 'darkest', color: colors.chartsPalettes.logos.darkest, type: 'line', total: true}
  },
  [KPIsAPI.Types.ARR_NetNewBreakdown]: {
    'new': {colorKey: 'lightest', color: colors.chartsPalettes.revenue.lightest, stackId: 'stack1', order: 1, useForLabels: true},
    'reactivation': {colorKey: 'lighter', color: colors.chartsPalettes.revenue.lighter, stackId: 'stack1', order: 2},
    'expansion': {colorKey: 'light', color: colors.chartsPalettes.revenue.light, stackId: 'stack1', order: 3},
    'startingBalance': {colorKey: 'primary', color: colors.chartsPalettes.revenue.primary, stackId: 'stack1', order: 4},
    'contraction': {colorKey: 'dark', color: colors.chartsPalettes.revenue.dark, stackId: 'stack1', order: 5},
    'churn': {colorKey: 'darker', color: colors.chartsPalettes.revenue.darker, stackId: 'stack1', order: 6},
    'endingBalance': {colorKey: 'darkest', color: colors.chartsPalettes.revenue.darkest, type: 'line', total: true}
  },
  [KPIsAPI.Types.ARR_GrossChurnBreakdown]: {
    'new': {colorKey: 'lightest', color: colors.chartsPalettes.revenue.lightest, stackId: 'stack1', order: 20},
    'reactivation': {colorKey: 'lighter', color: colors.chartsPalettes.revenue.lighter, stackId: 'stack1', order: 21},
    'expansion': {colorKey: 'light', color: colors.chartsPalettes.revenue.light, stackId: 'stack1', order: 22},
    'startingBalance': {colorKey: 'primary', color: colors.chartsPalettes.revenue.primary, stackId: 'stack1', order: 23},
    'contraction': {colorKey: 'dark', color: colors.chartsPalettes.revenue.dark, stackId: 'stack1', order: 1},
    'churn': {colorKey: 'darker', color: colors.chartsPalettes.revenue.darker, stackId: 'stack1', order: 2, useForLabels: true},
    'endingBalance': {colorKey: 'darkest', color: colors.chartsPalettes.revenue.darkest, type: 'line', total: true}
  },
  [KPIsAPI.Types.PnlBreakdown]: {
    'revenue': {colorKey: 'primary', color: colors.chartsPalettes.pnl.primary, stackId: 'stack1', order: 0, revertRadius: true},
    'cogs': {colorKey: 'lightest', color: colors.chartsPalettes.pnl.lightest, stackId: 'stack1', order: 1, revertRadius: true},
    'otherOpex': {colorKey: 'lighter', color: colors.chartsPalettes.pnl.lighter, stackId: 'stack1', order: 2, revertRadius: true},
    'gna': {colorKey: 'light', color: colors.chartsPalettes.pnl.light, stackId: 'stack1', order: 3, revertRadius: true},
    'rnd': {colorKey: 'dark', color: colors.chartsPalettes.pnl.dark, stackId: 'stack1', order: 5, revertRadius: true},
    'snm': {colorKey: 'darker', color: colors.chartsPalettes.pnl.darker, stackId: 'stack1', order: 6, revertRadius: true},
  },
};

export const ChartHelpers = SpecificChartHelpers;

const getColorByKPI = (kpiType, baseColor, key, seriesIndex, kpiCategory, stackId, label) => {
  let colorPalette = colors.chartsPalettes[kpiCategory];

  // Hard coded search for palette based on KPI Type
  if (!colorPalette) {
    if (kpiType.includes('custom:'))
      colorPalette = colors.chartsPalettes.custom;
    else if (kpiType.includes('mrr') || kpiType.includes('arr') || kpiType.includes('tcv') || kpiType.includes('acv') || kpiType.includes('ltm'))
      colorPalette = colors.chartsPalettes.revenue;
    else if (kpiType.includes('logo'))
      colorPalette = colors.chartsPalettes.logos;
    else if (kpiType.includes('pnl-'))
      colorPalette = colors.chartsPalettes.pnl;
  }

  if (colorPalette) {
    if (key === 'YoY' || key === 'waNdr')
      return colorPalette.darkest;
    if (key === 'QoQ' || key === 'waNdrLtm')
      return colorPalette.lighter;

    if (stackId) {
      return getColorPaletteByStackId(stackId, label).primary;
    }

    return colorPalette.primary;
  }

  return getColor(baseColor, key, seriesIndex);
};

const getColorPaletteByCompareValue = (value, index) => {
  if (value) {
    const palette = ColorsHelper.getColorPalette(value);
    if (palette)
      return palette;
  }
  return Object.values(colors.chartsPalettes)[index];
};

const getLabelColorByKPI = (kpiType, stackId, key) => {
  if (stackId) {
    const value = key.split("']")[2];
    return getColorPaletteByCompareValue(value, (+stackId.replace('stack', ''))).darkest;
  }

  if (kpiType.includes('custom:')) {
    return colors.chartsPalettes.custom.darkest;
  }

  if (kpiType.includes('mrr') || kpiType.includes('arr') || kpiType.includes('tcv') || kpiType.includes('acv')) {
    return colors.chartsPalettes.revenue.darkest;
  }

  if (kpiType.includes('logo')) {
    return colors.chartsPalettes.logos.darkest;
  }

  if (kpiType.includes('pnl-')) {
    return colors.chartsPalettes.pnl.darkest;
  }

  return colors.black;
}

const getColor = (baseColor, key, seriesIndex) => {
  if (key === 'YoY')
    return '#E75C18';

  const colors = colorMap[baseColor];
  const color = colors && (colors[key] || colors[seriesIndex]);
  return color || baseColor;
};

const generateSeries = (kpiType, data, color, chartType, kpiCategory) => {
  const {hasGrowthValues} = data;
  const firstPoint = data.data && data.data[0];
  if (firstPoint) {
    const keys = Object.keys(firstPoint).filter(v => !['period', 'benchmark'].includes(v) && (!hasGrowthValues || !['QoQ', 'YoY'].includes(v)));
    return keys.map((key, index) => {return {key, label: (keys.length > 1 ? key : data.title), color: (keys.length > 1 ? getColorByKPI(kpiType, color, index, null, kpiCategory) : (getColorByKPI((kpiType || color), null, null, null, kpiCategory))), type: chartType, labelColor: getLabelColorByKPI(kpiType)};});
  }
  return [{key: 'value', label: data.title, color: color, type: chartType, labelColor: getLabelColorByKPI(kpiType)}];
};

const getColorPaletteByStackId = (stackId, value) => {
  if (stackId) {
    return getColorPaletteByCompareValue(value, +stackId.replace('stack', ''));
  }
};

const getChartKey = (dataKey) => {
  if (dataKey.includes('segments[')) {
    const key = dataKey.split(`']`)[1];
    return key || ((dataKey || '').split(`['`)[1] || '').split(`']`)[0];
  }
  return dataKey;
};

const getChartHelperKey = (kpiType, dataKey, colorPalette, dataKeyPointIndex, maxKeyPoints) => {
  if (kpiType.startsWith('pnl-') && kpiType.endsWith('-breakdown')) {
    const palette = Object.values(colors.chartsPalettes.pnl);
    return {
      color: maxKeyPoints > 1 ? palette[maxKeyPoints-dataKeyPointIndex] : colors.chartsPalettes.pnl.primary,
      stackId: maxKeyPoints > 1 ? 'stack1' : undefined,
      order: maxKeyPoints-dataKeyPointIndex,
      addRadius: dataKeyPointIndex === maxKeyPoints-1,
      useForLabels: dataKeyPointIndex === maxKeyPoints-1
    };
  }

  const chartHelper = SpecificChartHelpers[kpiType] || {};
  const key = getChartKey(dataKey);
  const chartHelperKeys = {...chartHelper[key]} || {};
  if (colorPalette && chartHelperKeys.colorKey)
    chartHelperKeys.color = colorPalette[chartHelperKeys.colorKey];
  return chartHelperKeys;
};

const getColorByValue = (valueIndex, value) => {
  return (getColorPaletteByStackId(valueIndex, value) || {}).primary;
};

const getBenchmarkColor = (keyPoint) => {
  switch (keyPoint) {
    case 'benchmark_median_high':
      return colors.good;
    case 'benchmark_low_median':
     return colors.bad;
    case 'benchmark_median':
      return colors.warning;
    case 'benchmark_high_low':
      return colors.warning;
    case 'benchmark_avg':
      return colors.warning;
  }
};

const getBenchmarkChartType = (keyPoint) => {
  switch (keyPoint) {
    case 'benchmark_median_high':
    case 'benchmark_low_median':
    case 'benchmark_high_low':
      return 'area';
    case 'benchmark_median':
    case 'benchmark_avg':
      return 'referenceLine';
  }
};

const getCompareValue = (key, label) => {
  return key.includes('segments[') ? (key.split(`'`)[1] || '').split(`'`)[0] : label;
};

const appendBenchmarkSeries = (series, data, {isMainChart, kpiType, chartType, kpiCategory, color, colorIndexMap}) => {
  const dataKeyPoints = data.dataKeyPoints.filter(item => item.key === 'transparent');
  dataKeyPoints.push(...data.dataKeyPoints.filter(item => item.key !== 'transparent' && !item.key.endsWith(`insight['value']`)));

  const disableHighlight = !isMainChart && kpiType.includes('waterfall');
  series.push(...dataKeyPoints.map((d, index) => {
    const benchmarkChartType = getBenchmarkChartType(d.key);
    const compareValue = getCompareValue(d.key, d.label);
    const s = {
      key: d.key,
      label:d.label,
      color: benchmarkChartType ? getBenchmarkColor(d.key) : getColorByKPI(kpiType, color, d.key, index, kpiCategory, d.stackId, compareValue),
      labelColor: getLabelColorByKPI(kpiType, d.stackId, d.label),
      useForLabels: d.useForLabels,
      colorFetcher: d.color === 'byValue' ? (value) => getColorByValue(`stack${colorIndexMap.indexOf(value)}`, value) : null,
      type: benchmarkChartType || chartType,
      labelValue: data.totalFieldName || 'value',
      ...getChartHelperKey(kpiType, d.key, getColorPaletteByStackId(d.stackId, compareValue), index, dataKeyPoints.length)
    };

    if (disableHighlight)
      s.defaultSelected = true;

    if (SpecificChartHelpers.hasOwnProperty(kpiType) && d.stackId)
      s.stackId = d.stackId;
    return s;
  }));
};

const appendInsightsSeries = (series, data, {isMainChart, kpiType}) => {
  const dataKeyPoints = data.dataKeyPoints.filter(item => item.key === 'transparent');
  dataKeyPoints.push(...data.dataKeyPoints.filter(item => item.key !== 'transparent' && item.key.endsWith(`insight['value']`)));
  const disableHighlight = !isMainChart && kpiType.includes('waterfall');
  if (!kpiType.includes('waterfall')) {
    series.push(...dataKeyPoints.map((d, index) => {
      const compareValue = getCompareValue(d.key, d.label);
      const s = {
        key: d.key,
        type: 'line',
        color: 'transparent',
        tooltipExclude: true,
        ...getChartHelperKey(kpiType, d.key, getColorPaletteByStackId(d.stackId, compareValue), index, dataKeyPoints.length)
      };
      if (disableHighlight)
        s.defaultSelected = true;

      if (SpecificChartHelpers.hasOwnProperty(kpiType) && d.stackId)
        s.stackId = d.stackId;
      return s;
    }));
  }
};

const getChartSeries = (data, color, kpiType, chartType, kpiCategory, colorIndexMap, isMainChart, watchlistId) => {
  const series = [];
  if (data.dataKeyPoints) {
    appendBenchmarkSeries(series, data, {isMainChart, kpiType, chartType, kpiCategory, color, colorIndexMap});
    if (General.validateFeatureFlag('insights', watchlistId))
      appendInsightsSeries(series, data, {isMainChart, kpiType});
  }
  else {
    series.push(...generateSeries(kpiType, data, color, chartType, kpiCategory));
  }
  return series;
};

const getCurrentValueText = (data, valueFormatter) => {
  const { current = {}, dataKeyPoints = [] } = data;
  if (dataKeyPoints && dataKeyPoints.length > 1) {
    return dataKeyPoints.map((dkp, index) => <span key={`current_`+index}>{valueFormatter(current[dkp.key])} {dkp.label}</span>);
  }
  return valueFormatter(current);
};

const generateChartMetadata = (chartType, dataType, fieldDataType, kpiType, data, color, hideAxisLabels, kpiCategory, colorIndexMap, isMainChart, watchlistId, isFiscalYear) => {
  if (data.data?.length && data.data[0].hasOwnProperty('segment')) {
    data.data.forEach((d, index) => {
      d.segment = d.segment === '@UNKNOWN_NAME@' ? 'Unknown' : d.segment;

      if (d.hasOwnProperty('min') || d.hasOwnProperty('max')) {
        d.segment = Converters.rangeToString(d.min, d.max, fieldDataType);
      }

      if (chartType === 'pie')
        d.color = colorMap.Segments[index];
    });
  }
  if (chartType === 'pie') {
    return {
      key : "value",
      nameKey : "segment",
      label : "Revenue",
      valueFormatter: getDataFormatter(dataType, isFiscalYear)
    };
  }

  const metadata = {
    series: getChartSeries(data, color, kpiType, chartType, kpiCategory, colorIndexMap, isMainChart, watchlistId),
    dataType,
    dataKey: data.dataKey,
    dataKeyType: data.dataKeyDataType,
    mainColor: getLabelColorByKPI(kpiType),
    dataFormatter: getDataFormatter(data.dataKeyDataType, data.dataKey === 'period' && data.isFiscalYear),
    valueFormatter: getDataFormatter(dataType, data.isFiscalYear),
    disableTooltipActiveMode: data.disableTooltipActiveMode,
    totalFieldName: data.totalFieldName,
    tooltipTitle: data.tooltipTitle,
    showTotalInTooltip: data.showTotalInTooltip,
    tooltipTotalLabel: data.tooltipTotalLabel
  };

  if (data.chartLayout === 'vertical') {
    metadata.xAxis = { label: hideAxisLabels ? null : data.axisLabels.xAxis || data.title };
    if (!hideAxisLabels)
      metadata.yAxis = { label: hideAxisLabels ? null : data.axisLabels.yAxis || data.title };
    metadata.percentageLabel = 'percent';
    metadata.percentageFormatter = getDataFormatter('percent', data.isFiscalYear);
  }
  else {
    metadata.yAxis = { label: hideAxisLabels ? null :data.axisLabels.yAxis || data.title, align: 'left'};
    if (!hideAxisLabels)
      metadata.xAxis = { label: hideAxisLabels ? null : data.axisLabels.xAxis || data.title };
  }

  return metadata;
};

export default function CreateChartData(data, color, kpiType, chartType, dataType, hideAxisLabels, kpiCategory, filters, displayKeyFilter, isMainChart, watchlistId, isFiscalYear) {
  const { compareBySegment, criteria } = filters;
  const colorIndexMap = compareBySegment && criteria ? (Object.values(criteria.must || {}).find(item => item.leftOperand === compareBySegment) || Object.values(criteria.should || {}).find(item => item.leftOperand === compareBySegment)).rightOperand.map(value => value === '@UNKNOWN_NAME@' ? 'Unknown' : value) : [];
  const valueFormatter = getDataFormatter(dataType, isFiscalYear);
  const metadata = generateChartMetadata(chartType, dataType, data.segmentFieldDataType, kpiType, data, color, hideAxisLabels, kpiCategory, colorIndexMap, isMainChart, watchlistId, isFiscalYear);
  const showLegend = metadata.series && metadata.series.length > 1 || compareBySegment;
  const current = {
    value: getCurrentValueText(data, valueFormatter)
  };
  let rightSubtitle = null;

  if (data.dataKey === 'segment' && data.chartLayout === 'vertical') {
    data.data.forEach(d => {
      d.valueTooltip = getValueTooltip(kpiType, d.segment);
      d.percentTooltip = getPercentTooltip(kpiType, d.segment);
    });
      metadata.tickFormatter = getDataFormatter('percent', isFiscalYear);
  }

  return {
    data: data.data,
    periodsClassifications: data.periodsClassifications,
    title: createChartTitleElement(data.title, metadata, displayKeyFilter),
    current,
    showLegend,
    rightSubtitle,
    metadata,
    allowSelectingCategories: kpiCategory !== 'pnl'
  }
}

export const getHeatmapColorScheme = (kpiType, segmentName) => {
  const colorPalette = segmentName ? ColorsHelper.getColorPalette(segmentName) : kpiType.includes('arr') ? colors.chartsPalettes.revenue : colors.chartsPalettes.logos;
  const scheme = Object.values(colorPalette);
  return { background: scheme, color: scheme.map((c, index) => index > 3 ? colors['background-color']: colorPalette.darkest) };
};


function createChartTitleElement(title, metadata, displayKeyFilter) {
  let titlePostfix = null;
  if (displayKeyFilter) {
    const relevantSeries = metadata.series.find(s => s.key.endsWith(displayKeyFilter));
    if (relevantSeries) {
      titlePostfix = <span style={{fontWeight: "normal", fontSize: "12px"}}>({relevantSeries.label})</span>
    }
  }
  if (title && title.includes('(') && title.includes(')')) {
    const segments = title.match(/(.*)\((.*)\)(.*)/);
    return <>{segments[1]} {titlePostfix} <span style={{fontWeight: "normal", fontSize: "12px"}}>({segments[2]})</span> {segments[3]}</>;
  }
  return <>{title} {titlePostfix}</>;
}


function getValueTooltip(kpiType, segment) {
  let prefix;
  if (kpiType.includes('-ndr'))
    prefix = 'NDR';
  else if (kpiType.startsWith('arr:'))
    prefix = 'ARR';
  else if (kpiType.includes('arr-expansion:'))
    prefix = 'ARR expansion';
  else if (kpiType.includes('arr-contraction:'))
    prefix = 'ARR contraction';
  else if (kpiType.includes('arr-new:'))
    prefix = 'New ARR';
  else if (kpiType.includes('arr-churn:'))
    prefix = 'ARR churn';
  else if (kpiType.includes('arr-reactivation:'))
    prefix = 'ARR reactivation';
  else if (kpiType.includes('logos-count:'))
    prefix = 'Logos count';
  else if (kpiType.includes('logos-new:'))
    prefix = 'New logos';
  else if (kpiType.includes('logos-expansion:'))
    prefix = 'Logos expansion';
  else if (kpiType.includes('logos-contraction:'))
    prefix = 'Logos contraction';
  else if (kpiType.includes('logos-churn:'))
    prefix = 'Churned logos';

  if (prefix)
    return `${prefix} of ${segment} segment`;
}

function getPercentTooltip(kpiType, segment) {
  return `Concentration of ${segment} segment`;
}

