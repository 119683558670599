import styled from 'styled-components';

export const StyledDataGenerationNotification = styled.div`
  padding: 30px;
  font-size: 14px;
  text-align: center;

  .loading-msg {
    > div {
      display: inline;
      margin-left: 10px;
    }
  }

  .loading-indication {
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
  }

  .reload-note {
    color: ${props => props.theme.colors.label};
    margin-top: 2rem;
  }
`;

