import styled from "styled-components";

export const StyledNovvoSupport = styled.div`
  font-size: 12px;
  .link {
    color: ${props => props.theme.colors.link};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: underline;
    }
  }
`;
