/* eslint-disable no-param-reassign */
import moment from "moment";
import Preferences from './preferences';

class Converters {
  static formatNumber(num, isBytes = false) {
    const base = isBytes ? 1024 : 1000;
    let formattedNumber = num;
    let sign = '';
    const absNumber = Math.abs(num);
    if(absNumber > 999 && absNumber < (base*base)){
      // formattedNumber = (num/base).toFixed(0);
      formattedNumber = (num/base).toFixed(2);
      sign = isBytes ? 'KB' : 'K'; // convert to K for number from > 1000 < 1 million
    } else if(absNumber >= (base*base) && absNumber < (base*base*base)){
      formattedNumber = (num/(base*base));
      sign = isBytes ? 'MB' : 'M'; // convert to M for number from > 1 million
    } else if(absNumber >= (base*base*base)){
      formattedNumber = (num/(base*base*base));
      sign = isBytes ? 'GB' : 'B'; // convert to B for number from > 1 billion
    }
    return {sign, formattedNumber, original: num, formattedNumberString: `${parseInt(formattedNumber, 10)}${sign}`};
  }

  static toCurrencyString(num = 0, {currency = Preferences.get('Currency'), doEstimate = true, positiveOnly = false, addCurrencySymbol = true, isBytes = false, maximumFractionDigits = 2} = {}) {
    if (isNaN(num))
      num = 0;
    let formattedNum = num;
    const isNegative = num < 0;
    let sign = '';
    if (doEstimate) {
      const formattedNumber = Converters.formatNumber(num, isBytes);
      sign = formattedNumber.sign;
      formattedNum = formattedNumber.formattedNumber;
    }
    if (positiveOnly)
      formattedNum = Math.abs(formattedNum);

    const formattingOptions = { minimumFractionDigits: 0, maximumFractionDigits };
    if (addCurrencySymbol && currency)
      Object.assign(formattingOptions, { style: 'currency', currency });
    const result = new Intl.NumberFormat('en-US', formattingOptions).format(formattedNum) + sign;
    return positiveOnly && isNegative ? `(${result})` : result;
  }

  static toFinancialNegativeString(num) {
    num = Math.round(Number(num));
    return num < 0 ? `(${Converters.thousandSeparator(Math.abs(num))})` : `${Converters.thousandSeparator(num)}`;
  }

  static currencyStringToNumber(str) {
    const num = Number(str.replace(/[^\d.-]/g, ''));
    if (isNaN(num))
      return 0;

    if (str.includes('K'))
      return num * 1000;
    if (str.includes('M'))
      return num * 1000000;
    if (str.includes('B'))
      return num * 1000000000;

    return num;
  }

  static toDecimalString(value, { maximumFractionDigits = 2 } = {}) {
    return Converters.toCurrencyString(value, {addCurrencySymbol: false, maximumFractionDigits});
  }

  static normalizeCohortName(cohort, isFiscalYear) {
    const isQuarter = cohort.includes('-Q');
    cohort = cohort.replace('c ', '').replace('-', ' ');
    const isYearly = cohort.length === 4;
    if (isFiscalYear) {
      if (isQuarter) {
        cohort = cohort.replace('Q', 'FQ');
      } else if (isYearly) {
        cohort = `FY ${cohort}`;
      }
    }
    return cohort;
  }

  static formatPeriodString(value) {
    if (String(value).includes('-Q')) {
      const date = moment(value, 'YYYY-QQ');
      if (date.isValid()) {
        return date.format('[Q]Q YYYY');
      }
    } else {
      const parts = String(value).split('-');
      if (parts.length === 2 && parts[0].length === 4 && parts[1].length === 2) {
        const date = moment(value, 'YYYY-MM');
        if (date.isValid())
          return date.format('MMM YYYY');
      }
    }
    return value;
  }

  static formatFiscalPeriodString(value) {
    if (String(value).includes('-Q')) {
      const date = moment(value, 'YYYY-QQ');
      if (date.isValid())
        return date.format('[FQ]Q YYYY');
    } else {
      const parts = String(value).split('-');
      if (parts.length === 2 && parts[0].length === 4 && parts[1].length === 2) {
        const date = moment(value, 'YYYY-MM');
        if (date.isValid())
          return date.format('MMM YYYY');
      }
    }
    if (value && moment(value, 'YYYY').isValid()) {
      return `FY ${value}`;
    }
    return value;
  }

  static toDateString(date, options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) {
    const locale = ['en-US', 'he'];
    return new Date(date).toLocaleDateString(locale, options);
  }

  static toShortDateTime(date) {
    const mDate = moment(date);
    const startOfToday = moment().startOf('day');
    const endOfToday = moment().endOf('day');

    if (mDate > startOfToday && mDate < endOfToday)
      return mDate.format('hh:mm A');

    const startOfYesterday = moment().subtract(1, 'days').startOf('day');
    if (mDate > startOfYesterday && mDate < startOfToday)
      return mDate.localeData().calendar('lastDay').split(' ')[0].replace('[','')

    const locale = navigator?.languages ||  ['en-US', 'he'];
    return new Date(date).toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric'});
  }

  static toElapsedTime(date) {
    const mDate = moment(date);
    const now = moment();

    const tryDiff = (interval) => {
      const result = now.diff(mDate, interval);
      if (result)
        return `${result} ${result === 1 ? interval.slice(0, -1) : interval}`;
    };

    const intervals =  ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
    for (const interval of intervals.values()) {
      const res = tryDiff(interval);
      if (res)
        return res;
    }
  }

  static isZero(value) {
    return Math.abs(value) < 0.001;
  }

  static thousandSeparator(value) {
      if (!isNaN(value)) return (+value).toLocaleString('en-US');
      return value;
  }

  static toPercentage(value) {
    if (!isNaN(value))
      return `${Number(value.toFixed(2))}%`; // `${Math.floor(value*10)/10}%`;
    return value;
  }

  static rangeToString(min, max, fieldDataType, delimiter = '—') {
    if (fieldDataType === 'currency') {
      if (min !== max)
        return `${Converters.toCurrencyString(min)}${delimiter}${Converters.toCurrencyString(max, {addCurrencySymbol:false})}`;
      return Converters.toCurrencyString(min);
    }
    if (fieldDataType === 'percent') {
      if (min !== max)
        return `${min}${delimiter}${max}%`;
      return `${min}%`;
    }
    if (min !== max)
      return `${min}${delimiter}${max}`;
    return `${min}`;
  }
}

export default Converters;
