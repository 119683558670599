import styled from 'styled-components';

export const StyledFilesUpload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .header {
    margin-bottom: 30px;
    font-size: 12px;

  }

  .files-area {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    height: 100%;

    .link {
      margin: 20px 0;
      font-size: 12px;
    }

    .available-files {
      width: 610px;
      font-size: 12px;

      .title {
        font-weight: bold;
      }

      .files-list {
        overflow: auto;
        .uploaded-file {
          display: grid;
          grid-template-columns: 400px 70px 100px 40px;
          align-items: center;
          grid-column: 1 / -1;
          margin: 0;
          padding: 0;
          height: 35px;
          > div:first-child > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 380px;
          }

          .uploading {
            color: ${props => props.theme.colors.warning};
          }
        }
      }
    }
  }

  .hint {
    font-size: 12px;
    color: ${props => props.theme.colors.label};
    max-width: 500px;
  }

  .error {
    margin: 1.2rem 0;
    padding: 0.6rem 1.2rem;
    font-size: 1.4rem;
    color: #d53c21;
    background-color: rgba(213, 60, 33, 0.05);
    border-radius: 4px;
  }

  .footer {
    width: 100%;
    font-size: 12px;
    padding: 20px 0;

    .link {
      cursor: pointer;
      color: ${props => props.theme.colors.link};
    }

    .actions {
      margin-top: 20px;
    }
  }

  .comment {
    font-size: 10px;
    color: ${props => props.theme.colors.legend};
  }
`;
