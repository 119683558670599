/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { StyledSidenav } from "./Sidenav.style";
import Logo from "../../Logo/Logo";
import enums from "../../../utils/enums";

const getLogoLink = (user) => {
    if (user?.isStartupMode) return "/app/startup";
    if (user?.isInvestorMode) return "/app/investor";
    return "/";
};

export default function Sidenav({ items, mode, isOpen, closeHandler }) {
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const isAdmin = user?.isAdmin;

    return (
        <StyledSidenav items mode={mode} isOpen={isOpen}>

            {/* nav mask */}
            <div className="sidenav__mask" onClick={closeHandler}/>
            {/* nav */}
            <div className="sidenav__content">
                <div className="sidenav__logo">
                    <NavLink to={getLogoLink(user)}>
                        <Logo />
                    </NavLink>
                </div>
                <div className="sidenav__links">
                {items
                    .filter((item) => !item.role || [enums.Role.Admin, enums.Role.SuperUser].includes(item.role) && isAdmin)
                    .map((item, index) => (
                        <NavLink
                            key={`menu_${index}`}
                            end={item.exact || false}
                            className={(navigationData) => navigationData.isActive ? 'active' : null }
                            to={item.to}
                            onClick={item.handler ? () => {item.handler(); closeHandler();} : closeHandler}
                        >
                            {item.name}
                        </NavLink>
                    ))}
                </div>

                {!isAuthenticated ?
                <div className="sidenav__user">
                    <NavLink className="n-btn n-btn-primary n-btn--outline mb-1" to="/login" onClick={closeHandler}>Log in</NavLink>
                    <NavLink className="n-btn n-btn-primary" to="/signup" onClick={closeHandler}>Get early access</NavLink>
                </div>
                : null}

            </div>

        </StyledSidenav>
    );
}

Sidenav.propTypes = {
  items: PropTypes.any.isRequired,
  mode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
};
