import React, { useEffect, useState } from "react";
import {StyledDataTypesManager} from './DataTypesManager.style';
import enums from "../../../utils/enums";
import Converters from "../../../utils/converters";
import routes from "../../../pages/routes";
import { useSelector } from "react-redux";
import { OpenInNewIcon } from '../../../assets/mui-icons';
import SingleRadio from "../../RadioButton/SingleRadio";


export default function DataTypesManager({status, selectedFileType: initialSelectedFileType, onTypeSelected}) {

  const {user} = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const helpRoute = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;
  const [selectedFileType, setSelectedFileType] = useState(initialSelectedFileType);

  useEffect(() => {
    if (!selectedFileType) {
      const statusKeys = Object.keys(status);
      if (statusKeys.length === 1) {
        onSelectSourceType(statusKeys[0]);
      }
    }
  }, [status, selectedFileType]);

  const onSelectSourceType = (fileType) => {
    setSelectedFileType(fileType);
    if (onTypeSelected)
      onTypeSelected(fileType);
  };

  const renderDataItemStatus = (sourceFileType) => {
    const statusItem = status[sourceFileType];
    if (statusItem) {
      return <>
        <div className="connected">Data source connected</div>
      </>;
    }
  };

  const renderDataItem = (sourceFileType) => {
    const statusItem = status[sourceFileType];
    let dataItem, comment;
    switch (sourceFileType) {
      case enums.SourceFileType.MRR:
        dataItem = 'MRR';
        break;
      case enums.SourceFileType.Bookings:
        dataItem = 'Bookings';
        break;
      case enums.SourceFileType.PnL:
        dataItem = 'P&L';
        break;
      case enums.SourceFileType.CashFlow:
        dataItem = 'Cash flow';
        break;
      case enums.SourceFileType.BalanceSheet:
        dataItem = 'Balance sheet';
        break;
      case enums.SourceFileType.Customers:
        dataItem = 'Customers enrichment';
        break;
    }

    if (dataItem) {
      return <div className="data-item">
        <div className="item-name">
          <div onClick={() => onSelectSourceType(sourceFileType)}><SingleRadio checked={selectedFileType === sourceFileType} readOnly /> {dataItem}</div>
          <div className="comment">{comment}</div>
        </div>
        <div className="item-status">{renderDataItemStatus(sourceFileType)}</div>
        <div className="item-source-file">{statusItem?.sourceFile}</div>
        <div className="item-date">{statusItem?.modified ? Converters.toShortDateTime(statusItem?.modified) : ''}</div>
      </div>;
    }
  };


  return (
    <StyledDataTypesManager>
      <div className="data-files-container">
        <div className="data-item header">
          <div>Data format</div>
          <div>Status</div>
          <div>File</div>
          <div>Uploaded</div>
          {/*<div></div>*/}
        </div>
        {renderDataItem(enums.SourceFileType.MRR)}
        {renderDataItem(enums.SourceFileType.Bookings)}
        {renderDataItem(enums.SourceFileType.PnL)}
        {/*{renderDataItem(enums.SourceFileType.CashFlow)}*/}
        {/*{renderDataItem(enums.SourceFileType.BalanceSheet)}*/}
        {/*{renderDataItem(enums.SourceFileType.Customers)}*/}
      </div>
      <p className="help">Not sure about data formats? View our templates and upload tips <a href={helpRoute} target="_blank">templates and upload tips <OpenInNewIcon /></a>.</p>
    </StyledDataTypesManager>
  );
}

