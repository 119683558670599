import styled from "styled-components";

export const StyledPageTitle = styled.div`
  position: relative;
  > h1 {
    display: flex;
    align-items: center;
    font-size: 32px;
    > *:first-child {
      margin-right: 10px;
    }
  }
  > i {
    cursor: pointer;
    position: absolute;
    left: -5rem;
    top: calc(50% - 12px);
  }
`;
