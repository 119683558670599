import React, { useEffect, Fragment, useState } from "react";
import {ThemeProvider} from 'styled-components';
import {BrowserRouter, Routes, Navigate, Route} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import GlobalStyle from './styles/globalStyle';
import MuiStyle from './styles/muiStyle';
import theme from './styles/theme';
import {verifyTokenAsync} from './asyncActions/authAsyncActions';
import ScrollToTop from './hooks/ScrollToTop';
import LogoutListener from './hooks/LogoutListener';

import Login from './pages/public/Login';
import Signup from './pages/public/Signup/Signup';
import GetStarted from './pages/public/Signup/GetStarted/GetStarted';
import ForgotPassword from './pages/public/ForgotPassword/ForgotPassword';
import Reset from './pages/public/Reset';
import TeammateJoin from "./pages/common/TeammateJoin";
import NotFoundPage from './pages/public/404/404';
import StartupRoute from './components/routes/StartupRoute';
import InvestorRoute from './components/routes/InvestorRoute';
import SupportRoute from './components/routes/SupportRoute';
import PublicRoute from './components/routes/PublicRoute';
import EnvironmentTag from './components/EnvironmentTag';
import InitialPageLoadProgressBar from './components/InitialPageLoadProgressBar';
import ErrorBoundary from "./components/helpers/ErrorBoundary";

import routes from './pages/routes';

// Common components
import Network from './pages/common/Network';
import InviteCompany from './pages/common/InviteCompany';
import InviteTeammate from './pages/common/InviteTeammate';
import CompanyDetails from './pages/common/CompanyDetails';
import CompanyProfile from './pages/common/Profile';
import MyAccount from './pages/common/MyAccount';
import DataRoom from './pages/common/DataRoom/DataRoom';
import UsersManagement from './pages/common/UsersManagement';
import FileUploadHelp from './pages/common/FileUploadHelp';
import Analytics from './pages/common/Analytics/Analytics';

// Support
import SupportRequests from './pages/support/SupportRequests';


function App() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const {authLoading, user} = authObj;
  const isAdmin = user?.isAdmin;
  const isSupportUser = user?.isSupportUser;
  const isExternalAdmin = user?.isExternalUser && !isSupportUser;
  const [showLoading, setShowLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  // verify token on app load
  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, []);

  useEffect(() => {
    if (authLoading) {
      if (!timer) {
        const timeout = setTimeout(() => {
          setShowLoading(true);
        }, 300);
        setTimer(timeout);
      }
    }
    else {
      if (timer)
        clearTimeout(timer);
    }
  }, [authLoading, user, timer]);

  // checking authentication
  if (showLoading) {
    const progress = authLoading ? 50 : 100;
    return <ThemeProvider theme={theme}>
      <InitialPageLoadProgressBar progress={progress} onComplete={() => setShowLoading(false)} />
    </ThemeProvider>;
  }

  if (authLoading)
    return null;

  const ConditionalRedirect = () => {
    if (user) {
      if (user.isStartupMode)
        return <Navigate exact replace from="/" to="/app/startup" />;
      if (user.isInvestorMode)
        return <Navigate exact replace from="/" to="/app/investor"/>;
      if (user.isSupportMode)
        return <Navigate exact replace from="/" to="/app/support"/>;
    }
    return <Navigate exact replace from="/" to="/login" />;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <GlobalStyle/>
          <MuiStyle />
          <BrowserRouter>
            <Fragment>
              <ScrollToTop />
              <LogoutListener />
              <Routes>
                {/* PUBLIC ROUTES */}
                <Route exact path="/" element={<ConditionalRedirect />} />

                <Route element={<PublicRoute layout="fullScreen" />}>
                  <Route exact path={routes.Public.TeammateJoin} element={<TeammateJoin />} />
                  <Route path={routes.Public.Signup} element={<Signup />} />
                </Route>

                {/* NO LAYOUT PUBLIC PAGES */}
                <Route>
                  <Route path={routes.Public.ResetPassword} element={<Reset />} />
                  <Route path={routes.Public.Login} element={<Login />} />
                  <Route path={routes.Public.ForgotPassword} element={<ForgotPassword />} />
                  <Route exact path={routes.Public.GetStarted} element={<GetStarted />} />
                </Route>

                <Route element={<StartupRoute allowExternal allowSupport />}>
                  <Route exact path="/app/startup" element={<Navigate replace to={isExternalAdmin ? routes.Startups.DataRoom : routes.Startups.getStartupAnalyticsUrl()} />} />
                </Route>

                <Route element={<StartupRoute allowExternal />}>
                  <Route exact path={routes.Startups.DataRoom} element={<DataRoom />}/>
                  <Route exact path={routes.Startups.Profile} element={<CompanyProfile />}/>
                  <Route exact path={routes.Startups.MyAccount} element={<MyAccount />}/>
                </Route>

                <Route element={<StartupRoute allowSupport />}>
                  <Route exact path={routes.Startups.BaseAnalytics} element={<Analytics />}/>
                  <Route exact path={routes.Startups.Analytics} element={<Analytics />}/>
                </Route>

                {/* STARTUP SECURED ROUTES */}
                <Route element={<StartupRoute />}>
                  <Route exact path={routes.Startups.InviteTeammate} element={<InviteTeammate />}/>
                  <Route exact path={routes.Startups.Network} element={<Network />}/>
                  <Route exact path={routes.Startups.UsersManagement} element={<UsersManagement />}/>
                  <Route exact path={routes.Startups.Invite} element={<InviteCompany />}/>
                  <Route path={routes.Startups.Investor} element={<CompanyDetails />}/>
                </Route>

                 {/*/!* STARTUP FULLSCREEN SECURED ROUTES *!/*/}
                <Route element={<StartupRoute layout="fullScreen" allowExternal />}>
                  <Route exact path={routes.Startups.FileUploadHelp} element={<FileUploadHelp />} />
                </Route>

                {/*/!* INVESTOR SECURED ROUTES *!/*/}
                <Route element={<InvestorRoute allowExternal />}>
                  <Route exact path={routes.Investors.Network} element={<Network />}/>
                  <Route exact path={routes.Investors.BaseWatchlistAnalytics} element={<Analytics />}/>
                  <Route exact path={routes.Investors.WatchlistAnalytics} element={<Analytics />}/>
                  <Route exact path="/app/investor" element={<Navigate replace from="/app/investor" to={routes.Investors.Network} />} />
                </Route>

                <Route element={<InvestorRoute />}>
                  <Route exact path={routes.Investors.BaseStartupAnalytics} element={<Analytics />}/>
                  <Route exact path={routes.Investors.StartupAnalytics} element={<Analytics />}/>
                  <Route exact path={routes.Investors.WatchlistDrillDown} element={<CompanyDetails />} />
                  <Route exact path={routes.Investors.InviteTeammate} element={<InviteTeammate />}/>
                  <Route path={routes.Investors.Startup} element={<CompanyDetails />}/>
                  <Route exact path={routes.Investors.Profile} element={<CompanyProfile />}/>
                  <Route exact path={routes.Investors.MyAccount} element={<MyAccount />}/>
                  <Route exact path={routes.Investors.UsersManagement} element={<UsersManagement />}/>
                  <Route path={routes.Investors.Invite} element={<InviteCompany />}/>
                </Route>

                {/*/!* INVESTOR FULLSCREEN SECURED ROUTES *!/*/}
                <Route element={<InvestorRoute layout="fullScreen" allowExternal />}>
                  <Route exact path={routes.Investors.FileUploadHelp} element={<FileUploadHelp />} />
                </Route>

                {/*/!* INVESTOR SECURED ROUTES *!/*/}
                <Route element={<SupportRoute />}>
                  <Route path={routes.Support.Home} element={<SupportRequests />}/>
                  <Route exact path="/app/support" element={<Navigate replace from="/app/support" to={routes.Support.Home} />} />
                </Route>

                {isAdmin && <>
                  {/* ADMIN SECURED ROUTES */}
                </>}

                {/* GENERAL */}
                <Route path="*" element={<NotFoundPage />}/>
              </Routes>
            </Fragment>
          </BrowserRouter>
          <EnvironmentTag/>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
}

export default App;
