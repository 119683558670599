import styled from 'styled-components';

export const StyledCheckbox = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    margin-right: 0.7rem;
    vertical-align: middle;
  }

  input[type="checkbox"]:checked {
    background: black;
    color: white;
    border: 1px solid ${props => props.theme.colors.gray};
  }
  input[type="checkbox"]:disabled {
    background: ${props => props.theme.colors.lighterGray};
    border: 1px solid ${props => props.theme.colors.lightGray};
  }
  input[type="checkbox"] {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: transparent;
    height: 16px;
    width: 16px;
    border-radius: 5px;
    color: white;
  }

  input[type="checkbox"]:after {
    font-family: monospace;
    content: '✔';
    font-size: 14px;
    display: none;
    margin-left: 1px;
    margin-top: -1px;
  }

  input[type="checkbox"]:not(:checked) {
    border: 1px solid ${props => props.theme.colors.gray};
  }

  input[type="checkbox"]:checked {
    border: 1px solid transparent;
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }

  span {
    vertical-align: middle;
  }
`;

