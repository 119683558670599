import BaseApi from './base-api';

class StartupsAPI extends BaseApi{
  constructor() {
    super('/activity-log');
  }

  getActivitiesSummary() {
    return this.get('summary');
  }

  getActivities(orgId = null, watchlistId = null, offset = 0) {
    return this.get('/', {orgId, wlId: watchlistId, offset});
  }

}

export default new StartupsAPI();


