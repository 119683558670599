import React from "react";
import { StyledNotReadyPanel } from './NotReadyPanel.style';
import enums from "../../../../utils/enums";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import routes from "../../../routes";

export default function NotReadyPanel({ isStartup, isAdmin, watchlistId, analyticsKind, companyName, dataGenerationStatus, onClickUploadHandler }) {

  const helpRoute = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;
  const title = getAnalyticsTitle(analyticsKind);
  let action = null;
  let offlineStatus;
  if ([enums.DataGenerationStatus.UpdateBySystemInProgress, enums.DataGenerationStatus.ViewsUpgradeInProgress].includes(dataGenerationStatus)) {
    if (isStartup)
      offlineStatus = <>Your analytics require a system update.<br/>When the update is complete, you will be able to view the analytics.</>;
    else if (watchlistId)
      offlineStatus = <>The watchlist item {companyName} requires a system update.<br/>When the update is complete, you will be able to view the analytics.</>;
    else
      offlineStatus = <>The company {companyName} requires a system update.<br/>When the update is complete, you
        will be able to view the analytics.</>;
  } else if ([enums.DataGenerationStatus.PendingOfflineJobBySystem, enums.DataGenerationStatus.OfflineJobInProgressBySystem].includes(dataGenerationStatus)) {
    if (isStartup)
      offlineStatus = <>Your analytics require a system update.<br/>When the update is complete, you will receive an email
        notification.</>;
    else if (watchlistId)
      offlineStatus = <>The watchlist item {companyName} requires a system update.<br/>When the update is complete, you
        will receive an email notification.</>;
    else
      offlineStatus = <>The company {companyName} requires a system update.<br/>When the update is complete, you
        will be able to view the analytics.</>;
  } else if ([enums.DataGenerationStatus.PendingOfflineJobByUser, enums.DataGenerationStatus.OfflineJobInProgressByUser].includes(dataGenerationStatus)) {
    if (isStartup)
      offlineStatus = <>Your analytics are being generated offline.<br/>When the update is complete, you will receive an
        email notification.</>;
    else if (watchlistId)
      offlineStatus = <>The watchlist item {companyName} analytics are being generated offline.<br/>When the update is
        complete, you will receive an email notification.</>;
    else
      offlineStatus = <>The company {companyName} analytics are being generated offline.<br/>When the update is
        complete, you will be able to view the analytics.</>;
  } else if (isAdmin && (isStartup || watchlistId)) {
    offlineStatus = <>
        To get started please <span className="link" onClick={onClickUploadHandler}>upload</span> {analyticsKind === enums.AnalyticsKind.PnL ? '‘P&L‘' : '‘MRR’ or ‘Booking’'} file as there is no data loaded yet.<br />
        Not sure what {analyticsKind === enums.AnalyticsKind.PnL ? '‘P&L‘' : '‘MRR’ or ‘Booking’'} files are? <a href={helpRoute} target="_blank">View our templates and upload tips.</a>
      </>;
      action = <div><Button color="primary" onClick={onClickUploadHandler}>Upload files</Button></div>;
  } else {
    offlineStatus = <>
      {isStartup ? `Your company has not loaded the ${title} data yet.` : `The company has not loaded the ${title} data yet.`}
    </>;
  }

  return (
    <StyledNotReadyPanel>
      <div>
        <Typography variant="h2">{title}</Typography>
        <p>{offlineStatus}</p>
      </div>
      {action}
    </StyledNotReadyPanel>
  );
};


function getAnalyticsTitle(analyticsKind) {
  switch (analyticsKind) {
    case enums.AnalyticsKind.PnL: return 'P&L';
    case enums.AnalyticsKind.BalanceSheet: return 'Balance sheet';
    case enums.AnalyticsKind.CapTable: return 'Cap table';
    case enums.AnalyticsKind.CashFlow: return 'Cash flow';
    case enums.AnalyticsKind.SnM_Pipeline: return 'S&M pipeline';
    case enums.AnalyticsKind.HR: return 'HR';
    default: return 'SaaS metrics';
  }
}

