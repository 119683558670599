import styled from 'styled-components';

export const StyledSupportRequests = styled.div`

  .h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .main {
    display: flex;
  }

  .groups {
    margin-top: 20px;
    min-width: 200px;
  }

  .requests-list {
  }

  .item-row {
    display: grid;
    grid-template-columns: auto 120px 50px;
    align-items: center;
    margin-left: 4rem;
    border-bottom: 1px solid;
    border-bottom-color: ${props => props.theme.colors.lighterGray};

    > div {
      padding: 10px 10px 10px 0;
    }

    .company-details {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 10px;

      .name {
        margin-left: 10px;
      }
    }
  }
`;

