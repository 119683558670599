import styled from 'styled-components';

export const StyledCheckboxFilter = styled.div`
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 5px 0 0 3px;
      font-size: 11px;
      color: ${props => props.theme.colors.legend};
      cursor: ${props => props.allowCollapse ? 'pointer' : 'default'};
      svg * {
        fill: ${props => props.theme.colors.legend};
      }
    }
  }
   .checkboxes-container {
     margin-bottom: 2rem;

     .checkboxes-list {
       max-height: 300px;
       overflow: auto;
     }

    .select-clear-section {
      display: flex;
      white-space: pre;
      margin: 0.6rem 0;
      .select-all,
      .clear-all,
      .compare,
      .label {
        padding: 2px 5px;
        margin: 0 3px 0 -2px;
        border-radius: 4px;
        font-size: 12px;
        transition: color 0.25s ease;
        color: ${props => props.theme.colors.link};
        &:not(.label):not(.disabled) {
          cursor: pointer;
        }
        &:hover:not(.label):not(.disabled):not(.selected) {
          text-decoration: underline;
          color: ${props => props.theme.colors.linkhover};
        }
        &.selected {
          background: ${props => props.theme.colors['identity-cyan']};
          color: white;
          &:hover {
            text-decoration: none;
            color: white;
          }
        }
        &.disabled,
        &.label {
          color: ${props => props.theme.colors.legend};
        }
      }
      .compare {

      }
      .select-all:hover,
      .clear-all:hover,
      .compare:hover:not(.selected):not(.disabled ) {
        color: ${props => props.theme.colors.linkhover};
      }
    }
    .checkbox-container {
      display: flex;
      align-items: center;
      margin-left: 2px;

      .no-value {
        color: ${props => props.theme.colors.label};
      }
    }
   }
  .text-btn {
    margin: 5px 0 0 3px;
    font-weight: normal;
    font-size: 12px;
    color: ${props => props.theme.colors.link};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: underline;
    }
  }
`

