import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Master from '../layout/MasterSecured';

// handle the private routes
function PrivateRoute({ layout, allowExternal = false }) {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const isInvestor = user?.isInvestorMode;
  const isExternalUser = user?.isExternalUser;

  if (!isAuthenticated)
      return <Navigate to={'/login'} state={{ from: location }} />;
  if (!isInvestor || (isExternalUser && !allowExternal))
      return <Navigate to={'/'} state={{ from: location }} />;
  return (
      <Master layout={layout}>
          <Outlet />
      </Master>
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  layout: PropTypes.string
};
