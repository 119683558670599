import React from "react";
import { useLocation } from "react-router-dom";
import { StyledInviteTeammate } from './invite-teammate.style';
import PageTitle from "../../../components/PageTitle";
import InviteTeammatePanel from '../../../components/InviteTeammatePanel';
import {useSelector} from "react-redux";
import routes from "../../routes";

export default function InviteTeammate() {
  const location = useLocation();
  const { user: currentUser } = useSelector((state) => state.auth);
  const isStartup = currentUser?.isStartupMode;
  const companyRoutes = routes[isStartup ? 'Startups' : 'Investors'];
  const from_path = location.state?.from_path || companyRoutes.UsersManagement;

  return (
    <StyledInviteTeammate>
      <PageTitle icon="close" variant="h1" lineheight="1" path={from_path}>Invite teammate</PageTitle>
      <p>{isStartup ? 'Work with your teammates to build the company profile and load data' : 'Work with your teammates on building company profiles or loading and analyzing companies.'}.</p>
      <div className="team-invite">
        <InviteTeammatePanel {...(location.state || {})} />
      </div>
    </StyledInviteTeammate>
  );
}
