class FilesHelper {
  static async ResizeImage(file, width, height, quality = 0.8) {
    if (file.type.match(/image.*/) && !isNaN(width) && !isNaN(height)) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          this.ResizeImageSource(file, event.target.result, width, height, quality).then(resolve);
        });
        reader.readAsDataURL(file);
      });
    }
    return file;
  }

  static async ResizeImageSource(file, imageSource, MAX_WIDTH, MAX_HEIGHT, QUALITY) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let width = img.width;
        let height = img.height;
        if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const imgSrc = canvas.toDataURL(file.name, QUALITY);
        ctx.canvas.toBlob((blob) => {
          if (blob) {
            const newFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified || Date.now() // Take the original modification date
            }); //output image as a file
            return resolve({src: imgSrc, file: newFile});
          }
          return resolve({src: imageSource, file});
        }, file.type, QUALITY);
      };
    img.src = imageSource;
    });
  }
}

export default FilesHelper;
