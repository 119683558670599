import styled from 'styled-components';

export const StyledFormLabel = styled.div`
  .form__label {
    color: ${props => props.theme.colors.label};
    font-size: 1.2rem;
    em {
      margin: -2px 0 0 2px;
      line-height: 1.2rem;
      font-style: normal;
      font-size: 1rem;
      font-weight: normal;
      color:  ${props => props.theme.colors['error']};
    }
  }
`;

