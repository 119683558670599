/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import ToolbarMenu from "./Components/ToolbarMenu/ToolbarMenu";
import Icon from "../../Icon";
import Logo from "../../Logo/Logo";
import Sidenav from "../Sidenav/Sidenav";
import {StyledHeader} from "./Header.style";
import enums from '../../../utils/enums';
import GiveFeedback from "./Components/GiveFeedback/GiveFeedback";
import CompanyLogo from "../../CompanyLogo";
import Typography from "../../Typography";
import HelpMenu from "./Components/HelpMenu/HelpMenu";

const getLogoLink = (user) => {
  if (user?.isStartupMode) return "/app/startup";
  if (user?.isInvestorMode) return "/app/investor";
  return "/";
};

export default function Header({items, layout, mode}) {
  const {isAuthenticated, user} = useSelector((state) => state.auth);
  const isAdmin = user?.isAdmin;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const isFullScreen = layout === 'fullScreen';

  const closeMenuOnResize = () => {
    setIsOpen(false);
  };

  // Make sure that if page is resized then the menu should be closed
  useEffect(() => {
    if (isOpen)
      window.addEventListener("resize", closeMenuOnResize);
    else
      window.removeEventListener("resize", closeMenuOnResize);
    return () => {
      window.removeEventListener("resize", closeMenuOnResize);
    }
  }, [isOpen]);

  return (
    <StyledHeader className={`site-header${!isOpen ? ` sticky` : ""}`} mode={mode} authenticated={isAuthenticated}>
      <div className="site-header__inner">
        <div className="site-header__nav">
          <Icon type={'menu'} size="md" onClick={handleToggle}/>
          <Sidenav mode={mode} items={items} isOpen={isOpen} closeHandler={handleToggle}/>
          <NavLink className="logo" to={getLogoLink(user)}>
            {isAuthenticated ? <>
              <CompanyLogo size={46} company={user?.Organizations?.find(o => o.id === user.organizationId)} />
              <div className="user-details">
                <Typography variant="h3">{user?.organizationName}</Typography>
                <Typography variant="p">{user?.name}</Typography>
              </div>
            </>
            : <Logo />}
          </NavLink>
        </div>

        <div className="navbar">
          <div className="left">
          {items
            .filter((item) => !item.role || [enums.Role.Admin, enums.Role.SuperUser].includes(item.role) && isAdmin)
            .map((item, index) => (
              <NavLink
                key={`menu_${index}`}
                end={item.exact || false}
                className={(navigationData) => navigationData.isActive ? 'active' : null }
                to={item.to}
                onClick={item.handler}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          {/*{!isProduction ? <div className="right">*/}
          {/*  <NavLink exact activeClassName="active" to="/startups">For startups</NavLink>*/}
          {/*  <NavLink exact activeClassName="active" to="/investors">For investors</NavLink>*/}
          {/*  <NavLink exact activeClassName="active" to="/about">About us</NavLink>*/}
          {/*</div> : null}*/}
        </div>

        {!isFullScreen ? (
          isAuthenticated ?
            <div className="auth-menu">
              <GiveFeedback mode={mode} />
              <HelpMenu mode={mode} />
              <ToolbarMenu user={user} mode={mode} />
            </div>
            :
            null
        ) : null}
      </div>
    </StyledHeader>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.any.isRequired,
  mode: PropTypes.oneOf(['light', 'dark'])
};

Header.defaultProps = {
  mode: 'light'
};
