import React from "react";
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { StyledChartCard } from './ChartCard.style';
import { ReactComponent as CheckIcon } from '../../assets/images/i-check.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/i-close.svg';
import { ReactComponent as HazardIcon } from '../../assets/images/i-hazard.svg';
import ChartLegend from "../ChartLegend";
import ComposedChart from '../ComposedChart';
import PieChart from '../PieChart';
import ChartLoading from "../ChartLoading/ChartLoading";
import HeatMap from "../HeatMap";


const CURRENT_VALUE_SIGNS = {
  POSITIVE: 'positive',
  WARNING: 'warning',
  NEGATIVE: 'negative',
};

const renderSignComponent = (sign) => {
  switch (sign) {
    case CURRENT_VALUE_SIGNS.POSITIVE:
      return <CheckIcon/>;
    case CURRENT_VALUE_SIGNS.WARNING:
      return <HazardIcon/>;
    case CURRENT_VALUE_SIGNS.NEGATIVE:
      return <CloseIcon/>;
    default:
      return null;
  }
};

const renderLeftSubtitle = (loading, { value, sign } = {}) => {
  if (!value || loading)
    return null;
  return (
    <>
      {renderSignComponent(sign)}
      <Typography size={'16px'} className="left-subtitle">{value}</Typography>
    </>
  );
};

const renderLegend = (loading, showLegend, {series, mainColor}, hasGrowthValues, growthVisibility) => {
  if (!showLegend)
    series = [];
  return (showLegend || hasGrowthValues) && !loading ? <ChartLegend series={series} growthVisibility={hasGrowthValues ? growthVisibility : null} mainColor={mainColor} /> : null;
};

export default function ChartCard({ chartType, color, loading, title, hideTitle, current, rightSubtitle, data, metadata, showLegend, displayCurrent,
                                    hideTootips, onChartClick, children, height, yTitle, yLabels, xLabels, valueFormatter, error, onClick, onMissingDataClick,
                                    onClearFilterClick, errorType, ...rest }) {

  const renderChart = (hasLegend) => {
    if (chartType === 'heatmap') {
      return <HeatMap yTitle={yTitle} yLabels={yLabels} xLabels={xLabels} data={data} valueFormatter={valueFormatter} color={color} thumbnailMode={!rest.showValueLabels} {...rest} />;
    }

    if (chartType === 'pie')
      return <PieChart data={data} metadata={metadata} hideTooltips={hideTootips} onChartClick={onChartClick} height={height} {...rest} />;

    if (hasLegend)
      height -= 18;

    return <ComposedChart data={data} metadata={metadata} hideTootips={hideTootips} onChartClick={onChartClick} height={height} {...rest} />;
  };

  const isLoadingOrError = loading || error;

  const getOnClickHandler = () => {
    if (!isLoadingOrError)
      return onClick;
    if (error) {
      switch (errorType) {
        case 'missingData':
          return onMissingDataClick;
        case 'filtersNotSupported':
          return onClearFilterClick;
        case 'regeneratePNL':
          return onMissingDataClick;
      }
    }
  };

  const {hasGrowthValues, growthVisibility} = rest;
  return (
        <StyledChartCard className={!isLoadingOrError && !!onClick ? 'clickable' : null} color={color} onClick={getOnClickHandler()}>
          {!hideTitle ? <Typography variant="h3">{loading ? 'Loading...' : title}</Typography> : null}
          {displayCurrent && (current || rightSubtitle) ? <div className={"chart-header"}>
            <div className={"left-benchmark"}>
              {renderLeftSubtitle(loading, current)}
            </div>
            <div className={"right-benchmark"}>
              <Typography size={'11px'} color={"pink"}>{rightSubtitle}</Typography>
            </div>
          </div> : null}
          {children}
          {renderLegend(isLoadingOrError, showLegend, metadata, hasGrowthValues, growthVisibility)}
          {!isLoadingOrError ?
              renderChart(showLegend)
              :
              <div className={"loading" + (error ? ' w-error' : '')} style={{height: height}}><ChartLoading size={rest.isMainChart ? "large" : "medium"} animated={!error && rest.isMainChart} message={error} isError={!errorType} /></div>
          }
        </StyledChartCard>
    );
}

ChartCard.propTypes = {
  title: PropTypes.string,
  rightSubtitle: PropTypes.string,
  data: PropTypes.array,
  metadata: PropTypes.object,
  showLegend: PropTypes.bool,
  hideTootips: PropTypes.bool,
  current: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]),
    sign: PropTypes.oneOf([...Object.values(CURRENT_VALUE_SIGNS)])
  }),
  loading: PropTypes.bool,
  onChartClick: PropTypes.func
};

ChartCard.defaultProps = {
  data: [],
  metadata: {},
  showLegend: false,
  loading: false,
  hideTootips: false
};
