import styled from 'styled-components';

export const StyledDataTypesManager = styled.div`

  .help {
    font-size: 12px;
    color: ${props => props.theme.colors.label};
    margin-top: 30px;
  }

  p a svg {
    vertical-align: middle;
  }

  p {
    margin: 0;
  }

  .data-files-container {
    .data-item {
      .item-name {
        div:first-child {
          cursor: pointer;
          display: flex;
          align-items: center;
          label {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${props => props.theme.colors.link};
    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  .card-header {
    display: flex;
    align-items: center;

    >div:first-child {
      margin-right: auto;
    }
  }

  .files-list {
    max-height: 500px;
    overflow: auto;
    padding-bottom: 40px;
    height: 100%;

    .radio {
      display: flex;
      align-items: center;

      .radio__lbl {
        width: 100%;
      }

      .radio-option-lbl {
        display: flex;
        align-items: center;

        >div:first-child {
          margin-right: auto;
        }
      }
    }

    &.disabled {
      color: ${props => props.theme.colors.lightGray};
    }
  }


`;
