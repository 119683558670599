import styled from 'styled-components';

export const StyledChartCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}px;
  cursor: ${(props) => !!props.onClick ? 'pointer' : 'default'};

  > h3.h3 {
    margin-bottom: 1rem;
  }

  &.clickable:hover {
    h3.h3 {
      text-decoration: underline;
      color: ${props => props.theme.colors['linkhover']};
    }
  }

  .loading {
    height: 100%;
    text-align: center;
    > div {
      height: 100%;
      display: flex;
      justify-content: center;
    }
    &.w-error {
      border: 1px solid ${props => props.theme.colors.lightGray};
      .error-message > div {

      }
      &:hover {
      .error-message > div {
        ${(props) => !!props.onClick ? 'color:' + props.theme.colors.link : ''};
      }
    }
  }
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .left-subtitle {
      span {
        margin-right: 20px;
      }
    }
    .left-benchmark {
      display: flex;
      align-items: center;
      min-height: 25px;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        * {
          fill: ${props => props.color};
        }
      }
    }
  }
  .recharts-tooltip-wrapper {
    width: 380px;
    font-size: 12px;
    background: rgba(0,0,0, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
    color: ${props => props.theme.colors.legend};
    .custom-tooltip {

      margin: 0;
      padding: 0;
      &.no-active {
        color: ${props => props.theme.colors.white};
      }
      h4 {
        text-align: center;
        padding: 5px 0;
        margin-bottom: 5px;
        font-size: 20px;
        color: ${props => props.theme.colors.white};
      }
      > div {
        display: flex;
        justify-content: space-between;
        &.active {
          font-weight: bold;
          color: ${props => props.theme.colors.white};
        }
        &:not(:active)
        > div {
          &:first-child {
            width: 70%;
          }
          &:not(:first-child) {
            width: 25%;
            text-align: right;
          }
        }
        &:last-child {
          margin-bottom: 5px;
        }
        span {
          vertical-align: middle;
          margin-right: 20px;
        }
        i {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 10px;
          border-radius: 3px;
          vertical-align: middle;
        }
        &.total {
          margin-top: 2px;
          border-top: 1px solid ${props => props.theme.colors.shadow}
        }

      }
    }
  }
  .recharts-legend-wrapper {
    font-size: 12px;
    li {
      vertical-align: middle;
      span {
        vertical-align: middle;
        color: black !important;
      }
      svg {
        width: 12px;
        height: 12px;
        vertical-align: middle;
      }
    }
  }
}
`;
