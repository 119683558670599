import styled from "styled-components";

export const StyledCompanyCard = styled.div`
  font-size: 12px;
  .company {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .company-details {
      display: flex;
      cursor: pointer;
      align-items: center;
      width: 300px;
      .name {
        font-size: 13px;
        padding-left: 5px;
        margin-left: 10px;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        span {
          color: #9b9b9b;
        }
      }
      &:hover {
        .name {
          strong {
            color: ${props => props.theme.colors.linkhover};
            text-decoration: underline;
          }
        }
      }
    }

    .company-additional {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 300px);
    }

    .left-side {
      width: 310px;
      display: flex;
      .status {
        text-align: left;
        width: 120px;
        margin-right: 25px;
        color: ${props => props.theme.colors.gray};

        .good {
          color: ${props => props.theme.colors.good};
        }

        .warning {
          color: ${props => props.theme.colors.warning};
        }

        .bad  {
          color: ${props => props.theme.colors.bad};
        }
      }

      .action {
        text-align: left;
        width: 140px;
        margin-right: 25px;
        span {
          color: ${props => props.theme.colors.link};
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: ${props => props.theme.colors.linkhover};
          }
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;
      width: ${props => props.isStartup ? '222px' : '127px'};
      .permissions {
        text-align: left;
        color: #aaa;
        width: 80px;
        margin-right: 15px;
      }
      .date {
        text-align: left;
        color: #aaa;
        width: 80px;
        margin-right: 15px;
      }
      > div:last-child {
        width: 32px;
      }
    }
  }
`;

export const StyledWatchlistDeleteConfirmation = styled.div`
  font-size: 12px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  span {
    margin-top: 1rem;
    color: #9b9b9b;
  }
`;

export const StyledPreferencesDialog = styled.div`
  height: calc(100vh - 13rem);
  width: calc(100vw - 60px);
`;
