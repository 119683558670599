import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import './utils/axios';
import './utils/i18n';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container);

const StrictAppRoot = () => {
  return <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
</React.StrictMode>;
};

const AppRoot = () => {
  return <Provider store={store}>
      <App />
  </Provider>;
};

if (process.env.REACT_APP_RENDER_MODE === 'strict') {
  console.log('React rendering in strict mode');
  root.render(<StrictAppRoot />);
}
else
  root.render(<AppRoot />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
