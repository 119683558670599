import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledEnvironmentTag } from './EnvironmentTag.style'

const Index = () => {
    // TODO: something went wrong with animation (regression)
    const [animate, setAnimate] = useState(false);

    const { user } = useSelector((state) => state.auth);

    setTimeout(() => setAnimate(true), 2000);

    const environmentName = process.env.REACT_APP_CLUSTER_ENV;

    const isGuest = user?.role === 'guest';
    if ((environmentName && environmentName !== 'production') || isGuest) {
        const text = isGuest ? 'Free version' : environmentName.toUpperCase();
        return (
            <StyledEnvironmentTag
                className={`environment environment--mode-${environmentName}`}
                animate={animate}
            >
                {text}
            </StyledEnvironmentTag>
        );
    }
    return null;
};

export default Index;
