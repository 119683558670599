import styled from 'styled-components';

export const StyledMaster = styled.div`

    background: ${(props) => props.mode === "dark" ? props.theme.colors['background-color-dark-mode'] : props.theme.colors['background-color']};
    header {
        background: inherit;
    }

    ${props => props.mode === "dark" && `
      .typography_root {
         color: ${props.theme.colors.white};
      }
    `}

    .content {
        max-width: ${props => props.theme.general.xxlBreakpoint};
        margin: 0 auto;
        padding: 3rem 8rem;
        @media only screen and (max-width : ${props => props.theme.general.lgBreakpoint}) {
            padding: 0 8rem;
        }
        @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
            padding: 0 2.4rem;
        }
    }

    svg {
        vertical-align: middle;
    }
`;

export default {
    StyledMaster,
};
