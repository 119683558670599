import React, { useState } from "react";
import { StyledUserDetailsPanel } from './UserDetailsPanel.style';
import {useDispatch, useSelector} from "react-redux";
import {validateEmail} from "../../utils/validators";
import Button from "../Button";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";
import {useFormInput} from "../../hooks";
import FormLabel from "../FormLabel";
import {UsersAPI} from "../../api";
import moment from "moment";
import {updateCurrentUser} from "../../actions/authActions";
import ImageEditor from "../ImageEditor/ImageEditor";
import Icon from "../Icon";
import Error from "../Error";
import UserPhoto from "../UserPhoto";

export default function UserDetailsPanel() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isBusy, setIsBusy] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [uploadedUserImageFile, setUploadedUserImageFile] = useState();
  const [uploadedImageUrlUrl, setUploadedImageUrlUrl] = useState(user.imageUrl);
  const [errors, setErrors] = useState({});

  const { name, email, updatedAt, imageUrl, isMfaEnabled } = user;

  const userName = useFormInput(name);
  const mfaEnabled = useFormInput(isMfaEnabled);
  const userEmail = useFormInput(email);

  const getEditedValue = () => {
    const editedValues = {};
    if (userName.value !== name)
      editedValues.name = userName.value;

    if (userEmail.value !== email)
      editedValues.email = userEmail.value;

    if (mfaEnabled.value !== isMfaEnabled)
      editedValues.isMfaEnabled = mfaEnabled.value;

    return editedValues;
  };

  const validate = () => {
    if (!userName.value) {
      setErrors({
        ...errors,
        userName: 'Name cannot be empty'
      });
      return false;
    }

    if (!userEmail.value) {
      setErrors({
        ...errors,
        userEmail: 'Email cannot be empty'
      });
      return false;
    } else if (!validateEmail(userEmail.value)) {
      setErrors({
        ...errors,
        userEmail: 'Email is not valid'
      });
      return false;
    }

    return true;
  };

  const updateUserData = (data) => {
    dispatch(updateCurrentUser(data));
    onCancel();
  };

  const onCancel = () => {
    setIsInEditMode(false);
    setUploadedImageUrlUrl(imageUrl);
    setUploadedUserImageFile(null);
    setErrors({});
    userName.reset();
    userEmail.reset();
  };

  const save = async () => {
    if (validate()) {
      const promisesToSave = [];
      if (uploadedUserImageFile)
        promisesToSave.push(UsersAPI.updateCurrentUserImage({avatar: uploadedUserImageFile}));

      const editedValues = getEditedValue();
      if (Object.keys(editedValues).length)
        promisesToSave.push(UsersAPI.updateCurrentUser(editedValues));

      if (promisesToSave.length) {
        setIsBusy(true);
        Promise.all(promisesToSave).then(results => {
          setIsBusy(false);
          const success = !results.some(r => !r.success);
          const validResponses = results.filter(r => r.success && r.data);
          if (validResponses.length) {
            let updatedAt;
            validResponses.forEach(({data}) => {
              if (!updatedAt || updatedAt < data.updatedAt) {
                updatedAt = data.updatedAt;
                updateUserData(data);
              }
            });
          }
          if (!success) {
            const error = results.filter(r => !r.success).map(r => r.error);
            setErrors({general: error});
          }
        });
      }
    }
  };

  const renderFormInput = (obj, error, attrs = {}) => {
    return <div className="input-container">
      <TextInput error={error} {...obj} {...attrs} />
    </div>;
  };

  const renderCheckboxInput = (obj, attrs = {}) => {
    return <div className="input-container">
      <Checkbox {...obj} {...attrs} />
    </div>;
  };

  const renderItem = (label, obj, value, isInEditMode, error, attrs) => {
    return <div className={`item ${label.toLowerCase()}`}>
      <FormLabel className="item-label" label={label} />
      {isInEditMode ? renderFormInput(obj, error, attrs) : <div className="item-value">{value}</div>}
    </div>;
  };

  const renderCheckbox = (label, obj, value, isInEditMode, error, attrs) => {
    return <div className={`item ${label.toLowerCase()}`}>
      <FormLabel className="item-label" label={label} />
      {isInEditMode ? renderCheckboxInput(obj, attrs) : <div className="item-value">{value ? '✔' : ''}</div>}
    </div>;
  };

  const renderDetailsFields = () => {
    return <>
      <div className={`left${isInEditMode ? ' edit' : ''}${uploadedImageUrlUrl || imageUrl ? '' : ' empty'}`}>
        <div className="avatar">
          {isInEditMode ?
            <ImageEditor
              defaultPhotoUrl={uploadedImageUrlUrl}
              maxHeight={128}
              maxWidth={128}
              cropAspect={1}
              cropWidth={128}
              cropHeight={128}
              onChange={({file, url}) => {
                setUploadedImageUrlUrl(url);
                setUploadedUserImageFile(file);
              }}
            />
            :
            <UserPhoto user={user} size="xxl" />
          }
        </div>
      </div>
      <div className={`center${isInEditMode ? ' edit' : ''}`}>
        {renderItem('Name', userName, name, isInEditMode, errors.userName)}
        {renderItem('Email', userEmail, email, false, errors.userEmail)}
      </div>
      <div className={`right${isInEditMode ? ' edit' : ''}`}>
        {renderCheckbox('MFA Enabled', mfaEnabled, isMfaEnabled, isInEditMode)}
      </div>
      </>;
  };

  const renderCardContent = () => {
    return <>
      <div className="card-content" >
        <div className="fields">
          {renderDetailsFields()}
        </div>
        {isInEditMode &&
          <div className="footer">
            <Button color="gray" onClick={onCancel}>Cancel</Button>
            <Button onClick={() => save()} isLoading={isBusy} disabled={isBusy}>Save</Button>
            <span className="date">Last updated {moment(updatedAt).format('h:mm A MMM DD, yyyy')}</span>
            {errors?.general ? <Error className="error-message" error={errors.general} /> : null}
          </div>
        }
      </div>
    </>;
  };

  const renderCardHeader = () => {
    return <div className="header" >
      {!isInEditMode ? <div className="edit"><Icon type="edit" size="md" tooltip="Edit" onClick={() => {onCancel(); setIsInEditMode(true);}} /></div> : null}
    </div>;
  };

  return (
    <StyledUserDetailsPanel>
      <div className="card">
        {renderCardHeader()}
        {renderCardContent()}
      </div>
    </StyledUserDetailsPanel>
  );
}
