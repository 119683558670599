import React from 'react';
import PropTypes from 'prop-types';
import { StyledTypography } from './Typography.style'

const variants = ['h1', 'h2', 'h3', 'text', 'label', 'legend', 'tag', 'p', 'b', 'div', 'small'];
const componentMap = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    text: 'div',
    label: 'label',
    legend: 'span',
    tag: 'span',
    p: 'p',
    b: 'strong',
    strong: 'strong',
    div: 'div',
    small: 'small'
};

// eslint-disable-next-line react/prop-types
const Typography = ({ variant, size, weight, lineheight, children, color, className, ...rest }) => {
    return <StyledTypography
            as={componentMap[variant]}
            size={size}
            weight={weight}
            lineHeight={lineheight}
            color={color}
            className={`typography_root ${componentMap[variant]} ${className}`}
            {...rest}>
                {children}
            </StyledTypography>
};

Typography.propTypes = {
    variant: PropTypes.oneOf(variants).isRequired,
    weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
    size: PropTypes.string,
    lineHeight: PropTypes.string,
    color: PropTypes.oneOf(['', 'lightBlue', 'white', 'legend', 'pink', 'label']),
    className: PropTypes.string,
};


Typography.defaultProps = {
    variant: 'div',
    weight: 'normal',
    className: ''
};

export default Typography;
