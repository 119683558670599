
const Period = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Yearly: 'yearly'
};

export default {
  Role: {
    User: 'user',
    Admin: 'admin',
    SuperUser: 'superuser'
  },
  OrganizationType: {
    Investor: 'Investor',
    Startup: 'Startup'
  },
  Period,
  InvitationStatus: {
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
  },
  ConnectionType: {
    Current: 'Current',
    Prospect: 'Prospect',
    InternalTeam: 'Internal team'
  },
  PermissionOptions: {
    ProfileOnly: {
      text: 'Profile only',
      value: 1,
      comment: 'Recipient will only have access to your company profile (description, industry, location, profile media)'
    },
    ProfileAndAnalytics: {
      text: 'Profile and analytics',
      value: 3,
      comment: 'Recipient will have access to your company profile, pitch deck, cap table and analytics'
    }
  },
  Permissions: {
    Profile: 1,
    Analytics: 2,
    All: 3
  },
  Activity: {
    AccountCreated: 'ACCOUNT_CREATED',
    InvitationAccepted: 'INVITATION_ACCEPTED',
    InvitationRejected: 'INVITATION_REJECTED',
    InvitationAcceptedByTeammate: 'INVITATION_ACCEPTED_BY_TEAMMATE',
    InvitationResend: 'INVITATION_RESEND',
    InvitationSent: 'INVITATION_SENT',
    TeammateInvitationSent: 'TEAMMATE_INVITATION_SENT',
    ViewProfile: 'PROFILE_VIEW',
    ProfileContentAdded: 'PROFILE_CONTENT_ADDED',
    ProfileUpdated: 'PROFILE_UPDATED',
    WatchlistCompanyCreated: 'WL_CREATED',
    WatchlistDeleted: 'WL_DELETED',
    DataGenerated: 'DATA_GENERATED',
    PermissionChanged: 'PERMISSION_CHANGED',
    ConnectionRevoked: 'CONNECTION_REVOKED',
    SupportRequested: 'SUPPORT_REQUESTED',
    SupportRevoked: 'SUPPORT_REVOKED',
    SupportStatusChanged: 'SUPPORT_STATUS_CHANGED'
  },
  FieldDataType: {
    COLOR_LEGEND: 'colorLegend',
    LEGEND: 'legend',
    DATE: 'date',
    CURRENCY: 'currency',
    POSITIVE_CURRENCY: 'positive_currency',
    NUMBER: 'number',
    NUMERIC: 'numeric',
    TEXT: 'text',
    PERCENTAGE: 'percentage',
    PERCENT: 'percent'
  },
  DataGenerationStatus: {
    NotReady: 'not-ready',
    Ready: 'ready',
    HasError: 'has-error',
    UpdateBySystemInProgress: 'update-by-system-in-progress',
    ViewsUpgradeInProgress: 'views-upgrade-in-progress',
    PendingOfflineJobByUser: 'pending-offline-job-by-user',
    PendingOfflineJobBySystem: 'pending-offline-job-by-system',
    OfflineJobInProgressByUser: 'offline-job-in-progress-by-user',
    OfflineJobInProgressBySystem: 'offline-job-in-progress-by-system'
  },
  DATE_FORMATS: [
    // Day/month/year formats
    'D/M/Y', 'D/M/YY',
    'D/M/Y HH:mm', 'D/M/YY HH:mm',
    'D/M/Y HH:mm:ss', 'D/M/YY HH:mm:ss',
    'D-M-Y', 'D-M-YY',
    'D-M-Y HH:mm', 'D-M-YY HH:mm',
    'D-M-Y HH:mm:ss', 'D-M-YY HH:mm:ss',
    'D.M.Y', 'D.M.YY',
    'D.M.Y HH:mm', 'D.M.YY HH:mm',
    'D.M.Y HH:mm:ss', 'D.M.YY HH:mm:ss',
    'D/MMM/Y', 'D/MMM/YY',
    'D-MMM-Y', 'D-MMM-YY',
    'D MMM, Y', 'D MMM, YY',
    'D, MMM Y', 'D, MMM YY',
    'D MMM Y', 'D MMM YY',
    'D MMMM, Y', 'D MMMM, YY',
    'D, MMMM Y', 'D, MMMM YY',
    'D-MMMM-Y', 'D-MMMM-YY',
    'D MMMM Y', 'D MMMM YY',

    // Month/day/year formats
    'M/D/Y', 'M/D/YY',
    'M/D/Y HH:mm', 'M/D/YY HH:mm',
    'M/D/Y HH:mm:ss', 'M/D/YY HH:mm:ss',
    'M-D-Y', 'M-D-YY',
    'M-D-Y HH:mm', 'M-D-YY HH:mm',
    'M-D-Y HH:mm:ss', 'M-D-YY HH:mm:ss',
    'M.D.Y', 'M.D.YY',
    'M.D.Y HH:mm', 'M.D.YY HH:mm',
    'M.D.Y HH:mm:ss', 'M.D.YY HH:mm:ss',
    'MMM/D/Y', 'MMM/D/YY',
    'MMM-D-Y', 'MMM-D-YY',
    'MMM.D.Y', 'MMM.D.YY',
    'MMM D, Y', 'MMM D, YY',
    'MMMM D, Y', 'MMMM D, YY',

    // Month/year formats
    'M/Y', 'M/YY',
    'M-Y', 'M-YY',
    'M.Y', 'M.YY',
    'M Y', 'M YY',
    'M_Y', 'M_YY',
    'MY', 'MYY',
    'MMM/Y', 'MMM/YY',
    'MMM-Y', 'MMM-YY',
    'MMM - Y', 'MMM - YY',
    'MMM, Y', 'MMM, YY',
    'MMM Y', 'MMM YY',
    'MMM.Y', 'MMM.YY',
    'MMMM, Y', 'MMMM, YY',
    'MMMM Y', 'MMMM YY',
    'MMMM-Y', 'MMMM-YY',

    // Year/month formats
    'Y/M', 'YY/M',
    'Y-M', 'YY-M',
    'Y.M', 'YY.M',
    'Y M', 'YY M',
    'Y_M', 'YY_M',
    'YM', 'YYM',
    'Y/MMM', 'YY/MMM',
    'Y-MMM', 'YY-MMM',
    'Y MMM', 'YY MMM',
    'Y/MMMM', 'YY/MMMM',
    'Y-MMMM', 'YY-MMMM',
    'Y MMMM', 'YY MMMM',

    // Year/month/date formats
    'Y/M/D', 'YY/M/D',
    'Y/M/D HH:mm', 'YY/M/D HH:mm',
    'Y/M/D HH:mm:ss', 'YY/M/D HH:mm:ss',
    'Y-M-D', 'YY-M-D',
    'Y-M-D HH:mm', 'YY-M-D HH:mm',
    'Y-M-D HH:mm:ss', 'YY-M-D HH:mm:ss',
    'Y.M.D', 'YY.M.D',
    'Y.M.D HH:mm', 'YY.M.D HH:mm',
    'Y.M.D HH:mm:ss', 'YY.M.D HH:mm:ss',
    'Y/MMM/D', 'YY/MMM/D',
    'Y-MMM-D', 'YY-MMM-D',
    'Y.MMM.D', 'YY.MMM.D',

    // Year/Quarter formats
    'Y/[Q]Q', 'YY/[Q]Q',
    'Y-[Q]Q', 'YY-[Q]Q',
    'Y.[Q]Q', 'YY.[Q]Q',
    'Y [Q]Q', 'YY [Q]Q',
    'Y[Q]Q', 'YY[Q]Q',

    // Fiscal Year/Quarter formats
    'Y/[FQ]Q', 'YY/[FQ]Q',
    'Y-[FQ]Q', 'YY-[FQ]Q',
    'Y.[FQ]Q', 'YY.[FQ]Q',
    'Y [FQ]Q', 'YY [FQ]Q',
    'Y[FQ]Q', 'YY[FQ]Q',

    // Quarter/year formats
    '[Q]Q/Y', '[Q]Q/YY',
    '[Q]Q-Y', '[Q]Q-YY',
    '[Q]Q[\']Y', '[Q]Q[\']YY',
    '[Q]Q - Y', '[Q]Q - YY',
    '[Q]Q.Y', '[Q]Q.YY',
    '[Q]Q Y', '[Q]Q YY',
    '[Q]QY', '[Q]QYY',
    'Q[Q]Y', 'Q[Q]YY',
    '[Q]Q[\']Y[ (A)]', '[Q]Q[\']YY[ (A)]',
    '[Q]Q[\']Y[ (E)]', '[Q]Q[\']YY[ (E)]',
    '[Q]Q[\']Y[ (P)]', '[Q]Q[\']YY[ (P)]',
    '[Q]Q[\']Y[ (F)]', '[Q]Q[\']YY[ (F)]',

    // Fiscal Quarter/year formats
    '[FQ]Q/Y', '[FQ]Q/YY',
    '[FQ]Q-Y', '[FQ]Q-YY',
    '[FQ]Q - Y', '[FQ]Q - YY',
    '[FQ]Q.Y', '[FQ]Q.YY',
    '[FQ]Q Y', '[FQ]Q YY',
    '[FQ]QY', '[FQ]QYY',
    '[FQ]Q[\']Y[ (A)]', '[FQ]Q[\']YY[ (A)]',
    '[FQ]Q[\']Y[ (E)]', '[FQ]Q[\']YY[ (E)]',
    '[FQ]Q[\']Y[ (P)]', '[FQ]Q[\']YY[ (P)]',
    '[FQ]Q[\']Y[ (F)]', '[FQ]Q[\']YY[ (F)]',

    // Year formats
    'Y', 'YY',

    // Fiscal year formats
    '[F]Y', '[F]YY',
    '[F] Y', '[F] YY',
    '[FY]Y', '[FY]YY',
    '[FY] Y', '[FY] YY'
  ],
  NUMBER_VALUE_FORMATS: [
    {label: '$', value: '-'},
    {label: '$K', value: 'K'},
    {label: '$M', value: 'M'},
    // {label: '$B', value: 'B'}
  ],
  FieldMappingOptions: [
    {label: ' -- Clear selection -- ', value: ''},
    {label: 'Ignore 0 values', value: 'IGNORE_0_VALUES'}
  ],
  CURRENCY_SYMBOLS: ['$', '€', '£', '₪'],
  DATA_TYPES_OPTIONS: [
    {label: 'Currency', value: 'currency'},
    // {label: 'Date', value: 'date'},
    {label: 'Number', value: 'number'},
    {label: 'Percent', value: 'percent'},
    {label: 'Text', value: 'text'}
  ],
  REVENUE_TYPES_OPTIONS: [
    {label: 'Total booking', value: 'TOTAL'},
    {label: 'ARR', value: 'ARR'},
    {label: 'MRR', value: 'MRR'},
    {label: 'Recurring', value: 'recurring'},
    {label: 'Non recurring', value: 'non-recurring'}
  ],
  PERIODS: [
    {label: 'Months', value: Period.Monthly},
    {label: 'Quarters', value: Period.Quarterly},
    {label: 'Years', value: Period.Yearly}
  ],
  FISCAL_CALENDAR_OPTIONS: [
    {label: 'Calendar', value: 'calendar'},
    {label: 'Fiscal', value: 'fiscal'}
  ],
  FIRST_MONTH_CALCULATION_OPTIONS: [
    {label: 'New MRR', value: 'new'},
    {label: 'Ending MRR', value: 'existing'},
  ],
  FISCAL_YEAR_END_MONTH_OPTIONS: [
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December (default)', value: 12}
  ],
  INDICATORS: [
    {label: 'No indicators', value: 'NONE'},
    {label: 'Trend line', value: 'TREND_LINE'},
    {label: 'Average', value: 'AVERAGE'}
  ],
  AnalyticsKind: {
    BalanceSheet: 'balance-sheet',
    CapTable: 'cap-table',
    CashFlow: 'cash-flow',
    HR: 'hr',
    PnL: 'pnl',
    SaasMetrics: 'saas-metrics',
    SnM_Pipeline: 'mrr'
  },
  SourceFileType: {
    Bookings: 'bookings',
    Customers: 'customers',
    MRR: 'mrr',
    PnL: 'pnl',
    CashFlow: 'cash-flow',
    BalanceSheet: 'balance-sheet'
  },
  LoadFileWizardKeys: {
    MRR: {
      DataRows: 'DATA_ROWS',
      Dates: 'DATES',
      Customers: 'CUSTOMERS',
      RevenueCategories: 'REVENUE_CATEGORIES',
      CustomAttributes: 'CUSTOM_ATTRIBUTES'
    },
    Bookings: {
      DataRows: 'DATA_ROWS',
      AttributionDate: 'ATTRIBUTION_DATE',
      RevenueColumns: 'REVENUE_COLUMNS',
      PeriodMonthsLength: 'SUBSCRIPTION_MONTHS_LENGTH',
      EndDate: 'END_DATE',
      Customers: 'CUSTOMERS',
      RevenueCategories: 'REVENUE_CATEGORIES',
      TCV: 'TCV',
      CustomAttributes: 'CUSTOM_ATTRIBUTES'
    },
    Customers: {
      Name: 'NAME',
      Size: 'SIZE',
      Country: 'COUNTRY',
      State: 'STATE',
      City: 'CITY',
      Industry: 'INDUSTRY'
    },
    PnL: {
      ActualDates: 'ACTUAL_DATES',
      ExpectedDates: 'EXPECTED_DATES',
      ForecastDates: 'FORECAST_DATES',
      RevenueDataRows: 'REVENUE_DATA_ROWS',
      COGSDataRows: 'COGS_DATA_ROWS',
      SnMDataRows: 'SNM_DATA_ROWS',
      RnDDataRows: 'RND_DATA_ROWS',
      GnADataRows: 'GNA_DATA_ROWS',
      OtherOpexDataRows: 'OTHER_OPEX_DATA_ROWS'
    },
    CashFlow: {
      BeginingCash: 'BEGINING_CASH',
      OperatingActivities: 'OPERATING_ACTIVITIES',
      InvestingActivities: 'INVESTING_ACTIVITIES',
      FinancingActivities: 'FINANCING_ACTIVITIES',
      ActualDates: 'ACTUAL_DATES',
      ExpectedDates: 'EXPECTED_DATES',
      ForecastDates: 'FORECAST_DATES'
    },
    BalanceSheet: {
      Cash: 'CASH',
      AccountReceivable: 'ACCOUNT_RECEIVABLE',
      ShortTermLiabilities: 'SHORT_TERM_LIABILITIES',
      DeferredRevenue: 'DEFERRED_REVENUE',
      ActualDates: 'ACTUAL_DATES',
      ExpectedDates: 'EXPECTED_DATES',
      ForecastDates: 'FORECAST_DATES'
    }
  },
  SupportStatus: {
    Aborted: 'aborted',
    Done: 'done',
    InProgress: 'in-progress',
    Pending: 'pending',
  },
  CURRENCIES: [
    {label: 'United States Dollar ($)', value: 'USD'},
    {label: 'Euro (€)', value: 'EUR'},
    {label: 'United Kingdom Pound (£)', value: 'GBP'},
    {label: 'Switzerland Franc (CHF)', value: 'CHF'},
    {label: 'Israel Shekel (₪)', value: 'ILS'}
  ],
  LAST_ACTUAL_CHURN_ASSUMPTION: [
    {label: 'Churn', value: 'churn'},
    {label: 'Renewal', value: 'renewal'}
  ]
};

// Currencies codes ->   https://www.xe.com/symbols/
