import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAsync } from '../asyncActions/authAsyncActions';
import BroadcastHelper from '../utils/BroadcastChannelHelper';

function LogoutListener() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigateToLoginPage = (evt) => {
    if (evt?.data === 'logout') {
      if (isAuthenticated)
        dispatch(userLogoutAsync(false));
    } else if (evt?.data === 'login') {
      window.location.reload();
    }
  };

   useEffect(() => {
    BroadcastHelper.subscribe(navigateToLoginPage);
    return () => {
      BroadcastHelper.unsubscribe(navigateToLoginPage);
    };
   }, [window.localStorage]);
  }

export default LogoutListener;
