import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "react-image-crop/dist/ReactCrop.css";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import Converters from "../../utils/converters";
import {makeStyles} from "@mui/styles";
import MsgBox from "../dialogs/MsgBox";
import Button from "../Button";

const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB
const MAX_FILE_SIZE_ERROR_CODE = 'file-size-too-large';
const ZERO_SIZE_ERROR_CODE = 'file-size-zero';

const useStyles = makeStyles((theme) => ({
  content: {
    width: 610,
  },
  dropArea: {
    display: "flex",
  },
  dropzone: {
    padding: 20,
    color: "#272727",
    textAlign: "center",
    border: `2px dotted #00A1DE`,
    borderRadius: 4,
    fontSize: 14,
    position: "relative",
    p: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  dragActive: {
    background: "#ccc",
    border: `2px solid #00A1DE`,
  },
  withFiles: {
    border: `2px solid #9ECE62`,
  },
  link: {
    color: "#00A1DE",
    fontWeight: "bold",
    cursor: "pointer",
    '&:hover': {
      textDecoration: 'underline',
      color: '#0081BE'
    }
  },
  smalllink: {
    color: "#00A1DE",
    cursor: "pointer",
  },
  small: {
    marginBottom: 10,
    color: "#7b7b7b",
    fontSize: 11,
  },
  error: {
    margin: "1.2rem 0",
    padding: "0.6rem 1.2rem",
    fontSize: "1.4rem",
    color: "#d53c21",
    backgroundColor: "rgba(213, 60, 33, 0.05)",
    borderRadius: 4,
  },
  confirmContainer: {
    padding: '20px',
    fontSize: '14px'
  },
  confirmActions: {
    marginTop: '40px'
  }
}));

FilesUploadDropZone.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.object,
  onFilesSelected: PropTypes.func
};

FilesUploadDropZone.defaultProps = {
  multiple: false,
  accept: {'text/csv' :['.csv'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 'application/vnd.ms-excel': ['.xls']},
  clearFilesAfterSelection: false,
  uploadEmptyFileForLargeFiles: false
};

function FilesUploadDropZone({multiple, accept, onFilesSelected, clearFilesAfterSelection, uploadEmptyFileForLargeFiles}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [sizeLimitConfirmationConfig, setSizeLimitConfirmationConfig] = useState({open: false});
  const maxFilesString = Converters.formatNumber(MAX_FILE_SIZE, true).formattedNumberString;

  const fileSizeValidator = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      return {
        code: MAX_FILE_SIZE_ERROR_CODE,
        message: `Sorry, the file has reached the maximum file limit (max file size limit: ${maxFilesString}). | Please contact support@novvo.ai.`,
      };
    } else if (file.size === 0) {
      return {
        code: ZERO_SIZE_ERROR_CODE,
        message: `It seems like the file you are trying to upload has no data (file size = 0) |
                  Please review the file and try again. |
                  Still doesn't work ? Contact support@novvo.ai`,
      };
    }

    return null;
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (onFilesSelected) {
      if (uploadEmptyFileForLargeFiles) {
        let tooLargeFiles = fileRejections.filter(f => f.errors.some(e => e.code === MAX_FILE_SIZE_ERROR_CODE));
        tooLargeFiles = tooLargeFiles.map(f => {
          const file = new File([""], `${f.file.name}.large`, {type: f.file.type, lastModified: f.file.lastModified});
          file.originalSize = f.file.size;
          return file;
        });
        acceptedFiles.push(...tooLargeFiles);
        fileRejections = fileRejections.filter(f => f.errors.some(e => e.code !== MAX_FILE_SIZE_ERROR_CODE));
        if (tooLargeFiles.length) {
          setSizeLimitConfirmationConfig({open: true, fileName: tooLargeFiles[0].name})
        }
      }

      await onFilesSelected(acceptedFiles, fileRejections);
      if (clearFilesAfterSelection) {
        setFiles([]);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    open: onBrowseClick,
  } = useDropzone({
    noClick: true,
    accept,
    maxFiles: multiple ? 10 : 1,
    validator: fileSizeValidator,
    onDrop,
  });

  useEffect(() => {
    setFiles([...acceptedFiles]);
  }, [acceptedFiles]);

  return <div
    {...getRootProps({
      className: cx(classes.content, classes.dropzone, {[classes.withFiles]: !!files.length, [classes.dragActive]: !!isDragActive})
    })}
  >
    <input data-testid={"uploadInput"} {...getInputProps()} />
    {files.length ? (
      <p>
        {!clearFilesAfterSelection ? <>{files.map((f) => (
          <span key={f.name}>{f.name.replace(/.large$/gm,'')}</span>
        ))}<br /></> : null}
        <small onClick={!clearFilesAfterSelection ? onBrowseClick : null} className={cx({[classes.smalllink]: !clearFilesAfterSelection})}>
          {clearFilesAfterSelection ? 'Uploading...' : 'Select a different file'}
        </small>
      </p>
    ) : isDragActive ? (
      <p>
        Drop the file here
        <br />
        <small className={classes.small}>Supports CSV or XLS files (up to {maxFilesString})</small>
      </p>
    ) : (
      <p>
        Drag a file here or{" "}
        <span onClick={onBrowseClick} className={classes.link}>
              browse...
            </span>
        <br />
        <small className={classes.small}>Supports CSV or XLS files (up to {maxFilesString})</small>
      </p>
    )}

    {uploadEmptyFileForLargeFiles ? <MsgBox title={<strong>File size limit has been exceeded</strong>}
                                      open={sizeLimitConfirmationConfig.open}
                                      showCloseIcon
                                      modal={true}
                                      onClose={() => setSizeLimitConfirmationConfig({open: false})}
    >
      <div className={classes.confirmContainer}>
        <div>The size of ‘{sizeLimitConfirmationConfig.fileName?.replace(/.large$/gm,'')}’ has exceeded the 25MB limit</div>
        <div>and the support team of Novvo is required.</div>
        <div>Upload the remaining files and click the ‘Submit support request’ button.</div>

        <div className={classes.confirmActions}>
          <Button onClick={() => setSizeLimitConfirmationConfig({open: false})}>Got it</Button>
        </div>
      </div>
    </MsgBox> : null}
  </div>;
}

export default FilesUploadDropZone;
