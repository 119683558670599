import styled from 'styled-components';

export const StyledHeatMap = styled.div`
  display: flex;
  flex-direction: column;
  border-top: none;
  font-size: 10px;
  > div {
    display: flex;
    > div {
      padding: 0.5rem 0;
      text-align: center;
      justify-content: center;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .column-header, .row-label {
    font-weight: normal;
    color: ${props => props.theme.colors['legend']};
  }
  .column-header {
    width: 70px;
    &:first-child {
      width: 70px;
    }
  }
  .row-label {
    text-align: right;
    padding-right: 1rem;
    width: 70px;
  }
  .row {
    display: table-row;
    height: ${props => props.thumbnailMode ? '17px' : '26px'};
    line-height: ${props => props.thumbnailMode ? '14px' : '26px'};
    margin-bottom: 1px;
    > div {
      display: inline-table;
      vertical-align: top;
      line-height: ${props => props.thumbnailMode ? '7px' : '16px'};
    }
  }
  .cell {
    &:not(:last-child):not(.empty) {
      margin-right: 1px;
    }
    &.small {
      font-size: 10px;
      height: 17px;
    }
  }

  &.selectable {
    .row:not(.selected):not(.non-selectable) {
      > .cell:hover {
        cursor: pointer;
        outline: 1px solid ${props => props.theme.colors['navigation']};
        &:not(.selected) {
          opacity: 0.5;
        }
      }
    }
    .row:not(.non-selectable) {
      > .row-label:hover, > .cell {
        cursor: pointer;
      }
      &.hover {
        outline: 1px solid ${props => props.theme.colors['navigation']};
      }
    }

    &.hasSelection {
      .row {
        > .ref-cell,
        > .cell:not(.selected),
        > .row-label {
          opacity: 0.5;
        }

        > .cell.selected {
          opacity: 1;
          outline: 1px solid ${props => props.theme.colors['navigation']};
        }

        &.selected {
          opacity: 1;
          outline: 1px solid ${props => props.theme.colors['navigation']};
          > .cell, > .ref-cell, > .row-label {
            opacity: 1;
          }
        }
      }
    }
  }

  .ref-cell {
    display: flex;
    padding: ${props => props.thumbnailMode ? '0.5rem 0' : '0.5rem 0'};
    align-items: center;
    position: relative;
    font-weight: normal;
    margin-right: 1px;

    .ref-weight {
      background-color: rgba(0,0,0,0.1);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
    span {
      font-weight: bold;
    }
  }
`;

