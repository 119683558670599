import React from 'react';
import { StyledCheckbox } from './Checkbox.style';
import Typography from "../Typography";

function Checkbox({ children, ...rest }) {
    return (
        <StyledCheckbox>
          <input type="checkbox" {...rest} />
          <Typography variant="tag">
            {children}
          </Typography>
        </StyledCheckbox>
    );
}

export default Checkbox;
