import axios from 'axios';

class BaseAPI {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (Array.isArray(value) && value.length)
        value.forEach(v => {
          formData.append(key, v);
          if (v.lastModified)
            formData.append(`${key}_lastModified`, v.lastModified);
          if (v.originalSize)
            formData.append(`${key}_originalSize`, v.originalSize);
        });
      else if (value !== undefined) {
        formData.append(key, value);
        if (value.lastModified)
          formData.append(`${key}_lastModified`, value.lastModified);
        if (value.originalSize)
          formData.append(`${key}_originalSize`, value.originalSize);
      }
    });
    return formData;
  }

  get(url, queryParams = {}) {
    return axios.get(`${this.baseUrl}/${url}`, { params: queryParams });
  }

  patch(url, body, options = {}) {
    return axios.patch(`${this.baseUrl}/${url}`, body, options);
  }

  put(url, body, options = {}) {
    return axios.put(`${this.baseUrl}/${url}`, body, options);
  }

  post(url, body, options = {}) {
    return axios.post(`${this.baseUrl}/${url}`, body, options);
  }

  delete(url, options = {}) {
    return axios.delete(`${this.baseUrl}/${url}`, options);
  }
}

export default BaseAPI;
