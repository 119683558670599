import styled from 'styled-components';

export const StyledInviteTeammate = styled.div`
  width: 400px;

  .input-container {
    margin-top: 20px;
    > p {
      font-size: 12px;
      margin: 5px 0;
    }
  }

  .form__error {
    margin: 1.2rem 0;
    padding: 0.6rem 1.2rem;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.error};
    background-color: rgba(213, 60, 33, 0.05);
    border-radius: 4px;
  }

  .send-action {
    margin-top: 40px;
  }
`;

