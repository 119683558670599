import styled from 'styled-components';

export const StyledKpiDescription = styled.div`
  font-size: 13px;
  min-height: 85px;
  > pre {
    span {
      display: block;
      &.divider {
        line-height: 5px;
        display: block;
      }
    }
  }
`;

