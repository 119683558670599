import React, { useEffect, useState } from "react";
import { StyledBreadcrumbs } from './Breadcrumbs.style';
import MoreMenu from "../../../../components/MoreMenu";
import {ArrowDropDown} from "../../../../assets/mui-icons";
import IconButton from "../../../../components/IconButton";
import Converters from "../../../../utils/converters";
import cx from "classnames";
import enums from "../../../../utils/enums";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

export default function Breadcrumbs({ path = [], rootOptions = [], kpiConfigs = [], filters, onChange, onClearFilters,
                                      onShowSettings, info, chartExplanation, mainChartTitle, onShowDescription, rootTitle, isFiscalYear}) {


  const [alternateTitle, setAlternateTitle] = useState(mainChartTitle);

  useEffect(() => {
    setAlternateTitle(mainChartTitle);
  }, [mainChartTitle]);

  useEffect(() => {
    setAlternateTitle(null);
  }, [path]);

  const filterKpis = (kpiTypes) => {
    if (kpiTypes?.length) {
      kpiTypes = kpiTypes.map(k => (k.segmentBy ? `${k.kpiType}::${k.segmentBy}` : k.kpiType));
      return kpiConfigs.filter(kpiConfig => kpiTypes.includes(kpiConfig.kpiType));
    }
    return [];
  };

  const getRootMenuOptions = () => {
    const kpis = kpiConfigs.filter(kpiConfig => rootOptions.includes(kpiConfig.kpiType));
    if (kpis?.length) {
      return kpis.map(kpi => ({
        label: generateTitleElement(kpi.title || kpi.kpiType),
        value: kpi.kpiType,
        selected: kpi.kpiType === path[1].kpiType && kpi.segmentBy === path[1].segmentBy,
        onClick: () => {
          if (onChange)
            onChange([...path.slice(0, 1), kpi]);
        }
      }));
    }
  };

  const getKpiMenuOptions = (index) => {
    const parentKpiConfig = kpiConfigs.find(k => k.kpiType === path[index - 1].kpiType && k.segmentBy === path[index - 1].segmentBy);
    const kpisOptions = [
      {section: 'Overview', kpis: filterKpis(parentKpiConfig?.tabs?.overview)},
      {section: 'Customers Kpis', kpis: filterKpis(parentKpiConfig?.tabs?.customersKpis)},
      {section: 'Concentration', kpis: filterKpis(parentKpiConfig?.tabs?.concentration)},
      {section: 'UnitEconomics', kpis: filterKpis(parentKpiConfig?.tabs?.unitEconomics)},
      {section: 'Cohorts', kpis: filterKpis(parentKpiConfig?.tabs?.cohorts)},
      {section: 'Custom', kpis: filterKpis(parentKpiConfig?.tabs?.custom)}
    ];

    if (kpisOptions?.length) {
      const options = [];
      kpisOptions.forEach(kpiOption => {
        const {section, kpis} = kpiOption;
        if (kpis?.length) {
          options.push({
            label: section,
            type: 'section'
          });

          options.push(...kpis.map(kpi => ({
            label: generateTitleElement(kpi.title || kpi.kpiType),
            value: kpi.kpiType,
            selected: kpi.kpiType === path[index].kpiType && kpi.segmentBy === path[index].segmentBy,
            onClick: () => {
              if (onChange) {
                const newPath = [];
                for (let i=0; i<path.length-1; i++) {
                  if (path[i].kpiType === kpi.kpiType && path[i].segmentBy === kpi.segmentBy)
                    break;

                  newPath.push(path[i]);
                }
                newPath.push(kpi);
                onChange(newPath);
              }
            }
          })));
        }
      });
      return options;
    }
  };

  const getPathMenuOptions = (index = path.length - 1) => {
    if (index > 0) {
      const options = index === 1 ? getRootMenuOptions() : getKpiMenuOptions(index);
      if (options?.length)
        return <MoreMenu options={options} triggerIcon={<ArrowDropDown />} showSelected/>;
    }
  };

  const generateTitleElement = (title) => {
    const { selectedCohort, selectedPeriod } = filters || {};
    if (title && title.includes('(') && title.includes(')')) {
      const segments = title.match(/(.*)\((.*)\)(.*)/);
      if (selectedCohort || selectedPeriod)
        return <>{segments[1]} {segments[3]}</>;
      return <>{segments[1]} <span style={{padding: "0 3px", fontWeight: "normal", fontSize: "12px", whiteSpace: "nowrap"}}>({segments[2]})</span> {segments[3]}</>;
    }
    return title;
  };

  const renderPathItem = (item, index) => {
    const isLastItem = index === path.length - 1;
    let caption = rootTitle;
    if (index > 0) {
      const kpi = kpiConfigs.find(c => c.kpiType === item.kpiType);
      caption = kpi?.title || kpi?.kpiType || 'N/A';
      if (isLastItem && alternateTitle)
        caption = alternateTitle;
    }

    return (
      <div key={`path-${index}`}>
        <div className={cx("parent-path", {"current": isLastItem})}>
          {index > 0 && <div className="separator">></div>}
          <div className={cx({"link-cnt": !isLastItem})} onClick={isLastItem ? undefined : () => {
            if (onChange)
              onChange([...path.slice(0, index+1)]);
          }}>
            <div className="link-caption">{generateTitleElement(caption)}</div>
          </div>

        </div>
      </div>
    );
  };

  const resolveFiscalPeriod = (period) => {
    return isFiscalYear ? Converters.formatFiscalPeriodString(period) : Converters.formatPeriodString(period);
  };

  const renderFilters = () => {
    const { selectedCohort, selectedPeriod } = filters || {};
    let tooltip;
    if (selectedCohort || selectedPeriod) {
      let text;
      if (selectedCohort) {
        const cohort = resolveFiscalPeriod(selectedCohort).replace('c ', '');
        text = `Cohort ${cohort}`;
        if (selectedPeriod) {
          const cohortPeriod = convertPeriodToCohortPeriod(selectedCohort, selectedPeriod);
          text = `${text} in period ${cohortPeriod}`;
          tooltip = `${cohortPeriod} for cohort ${cohort} is ${resolveFiscalPeriod(selectedPeriod)}`;
        }
      } else if (selectedPeriod) {
        text = resolveFiscalPeriod(selectedPeriod);
      }

      const tagElement = <div className="parent-path current tag">
        {text}
        <div className="delete" onClick={() => {
          if (onClearFilters)
            onClearFilters();
        }}><span>x</span></div>
      </div>;

      return tooltip ? <Tooltip title={tooltip}  arrow placement="bottom" >{tagElement}</Tooltip> : tagElement;
    }
    return (
      <div className="parent-path current">
        {getPathMenuOptions()}
      </div>
    );
  };

  return (
    <StyledBreadcrumbs>
      {path.filter(p => !!p.kpiType || !!p.title).map(renderPathItem)}
      {renderFilters()}
      {info &&
        <div className="info">
          {info.error && <IconButton className="error-indicator" iconName="Warning" tooltip={info.error} onClick={onShowSettings} />}
          {info.text}
          {chartExplanation ? <IconButton iconName="HelpOutline" tooltip="Description" onClick={onShowDescription} /> : null}
        </div>
      }
    </StyledBreadcrumbs>
  );
};



function determinePeriodKind(period) {
  if (period.includes('Q'))
    return enums.Period.Quarterly;
  else if (period.includes(' ') || period.includes('-'))
    return enums.Period.Monthly;
  else
    return enums.Period.Yearly;
}

function convertPeriodToCohortPeriod(cohort, period) {
  const periodKind = determinePeriodKind(period);
  switch (periodKind) {
    case enums.Period.Monthly: return `M${moment(period, 'YYYY MM').diff(moment(cohort, 'YYYY MM'), 'months', true)}`;
    case enums.Period.Quarterly: return `Q${moment(period, 'YYYY-[Q]Q').diff(moment(cohort, 'YYYY-[Q]Q'), 'quarter', true)}`;
    default: return `Year${moment(period, 'YYYY').diff(moment(cohort, 'YYYY'), 'year', true)}`;
  }
}
