import React from "react";
import Dialog from "../Dialog";
import {StyledGiveFeedbackDialog} from "./GiveFeedbackDialog.style";

export default function GiveFeedbackDialog({ open, onClose }){
  return <Dialog showCloseIcon
                onClose={onClose}
                open={open}
                modal
  >
    <StyledGiveFeedbackDialog>
      <iframe src="https://forms.monday.com/forms/embed/4dd907e8e62130f9e0e666459b7a4a29?r=euc1" width="650" height="650">
      </iframe>
    </StyledGiveFeedbackDialog>
  </Dialog>;
};

