import React, { Fragment } from "react";
import {StyledErrorMsgBox} from "./ErrorMsgBox.style";
import MsgBox from "../MsgBox";
import Button from "../../Button";
import Error from "../../Error";

export default function ErrorMsgBox({ title, open, error, onClose }){

  return <MsgBox
    title={title}
    open={open}
    modal={false}
    onClose={onClose}
    showCloseIcon={true}
    footer={<div><Button color="white" borderColor="primary" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); if (onClose) onClose();}}>Close</Button></div> }
  >
    <StyledErrorMsgBox>
      <Error className="error-msg" error={error} />
    </StyledErrorMsgBox>
  </MsgBox>;
};

