import React, {useState} from 'react';
import { Autocomplete, createFilterOptions } from '@mui/material';
import {TextField} from "@mui/material";
import {StyledMultiSelect} from './MultiSelect.style';

export default function MultiSelect ({ options, defaultValue, restrictToList, label, onChange, onCancel, onKeyDown, onBlur, placeholder = 'Search',
                                             autoFocus = false, variant='outlined', error, ...rest }) {
  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [focus, setFocus] = useState(autoFocus);
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.text || option,
  });

  const onInternalKeyDown = (evt) => {
    if (isOpen || evt.target.value)
      return;
    handleOnBlur(evt);
    if (onKeyDown)
      onKeyDown(evt);
  };

  const hasChanges = () => {
    return (defaultValue || []).toString() !== (selectedValues || []).toString();
  };

  const getHandlerValues = (values) => {
    return values.map(v => v.value);
  };

  const handleOnChange = (e, values) => {
    setFocus(true);
    const itemRemoved = values.length < selectedValues.length;
    values = values.map(v => v.value ? v : ({text: v, value: v}));
    setSelectedValues(values);
    if (onChange)
      onChange(getHandlerValues(values));
    else if (onBlur && itemRemoved)
      onBlur(getHandlerValues(values));
  };

  const handleOnBlur = (e) => {
    let addedNew = false;
    if (e) {
      const value = e.target.value;
      if (value && !restrictToList) {
        addedNew = true;
        selectedValues.push({text: value, value});
        setSelectedValues(selectedValues);
      }
    }
    if (onChange) {
      if (addedNew || hasChanges())
        onChange(getHandlerValues(selectedValues));
    } else if (onBlur)
      onBlur(getHandlerValues(selectedValues));
  };


  return <StyledMultiSelect>
    <Autocomplete
      size={'small'}
      options={options}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      freeSolo={!restrictToList}
      getOptionLabel={(option) => option.text || option}
      value={selectedValues}
      filterSelectedOptions
      filterOptions={filterOptions}
      onKeyDown={onInternalKeyDown}
      multiple
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          autoFocus={focus}
          error={error}
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  </StyledMultiSelect>;

};
