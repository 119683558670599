import styled from 'styled-components';

export const StyledIndustriesSelector = styled.div`
  input {
    font-size: 1.4rem;
  }
  span {
    font-size: 1.4rem;
  }
  .label {
    color: ${props => props.theme.colors.label};
    font-size: 1.2rem;
  }
`




