import styled from "styled-components";

export const StyledSidenav = styled.nav`

.sidenav__mask,
.sidenav__content {
  display: none;
}

@media only screen and (max-width : ${props => props.theme.general.lgBreakpoint}) {
  .sidenav__mask {
    position: fixed;
    z-index: 9;
    top:0;
    left:0;
    right:0;
    bottom:0;
    ${(props) => props.mode === "dark" && `
      background-color: rgba(0,0,0,0.5);
    `}
    ${(props) => props.mode !== "dark" && `
      background-color: rgba(0,0,0,0.2);
    `}
  }

  .sidenav__content {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    top: 0;
    bottom:0;
    left: 0;
    width: 80%;
    padding: 0 2.4rem;
    ${(props) => props.mode === "dark" && `
      background-color: rgba(0,0,0,.96);
    `}
    ${(props) => props.mode !== "dark" && `
      background-color: rgba(255,255,255,.96);
    `}
    box-shadow: none;
    transform: translateX(-100%);
    transition: transform 0.35s ease-in-out;
  }
  .sidenav__logo {
    min-height: 8rem;
    display: flex;
    align-items: center;
  }
  .sidenav__links {
    display: flex;
    flex-direction: column;
    overflow: auto;
    a {
      font-size: 1.4rem;
      outline: none;
      margin: 1px 0;
      padding: 1.2rem 2.4rem;
      border-radius: 4px;
    }
    a:link,
    a:visited {
      color: ${props => props.theme.colors.label};
      &.active {
        ${(props) => props.mode === "dark" && `color: white;`}
        ${(props) => props.mode !== "dark" && `color: #333;`}
        ${(props) => props.mode === "dark" && `background-color: #2d2d2d;`}
        ${(props) => props.mode !== "dark" && `background-color: #eee;`}
      }
    }
    a:hover {
      ${(props) => props.mode === "dark" && `color: white;`}
      ${(props) => props.mode !== "dark" && `color: #333;`}
      ${(props) => props.mode === "dark" && `background-color: #2d2d2d;`}
      ${(props) => props.mode !== "dark" && `background-color: #eee;`}
      text-decoration: none;
    }
  }
  .sidenav__user {
    width:100%;
    display:flex;
    flex-direction: column;
    margin-top: auto;
    padding: 2.4rem 0;
    .mb-1 {
      margin-bottom: 1.2rem;
    }

    .n-btn {
      color: white;
      padding: 1.2rem 2.4rem;
      font-family: ${props => props.theme.fonts['primary-font']};
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: 0.05rem;
      text-align: center;
      text-decoration: none;
      border: 0;
      border-radius: 0.4rem;
      white-space: nowrap;
      appearance: none;
      transition: background-color 0.25s ease, box-shadow 0.25s ease;
      &.n-btn-primary {
        background-color: ${props => props.theme.colors.primary};
        &:hover {
          background-color: ${props => props.theme.colors.primaryHover};
        }
      }
      &.n-btn--outline {
        ${(props) => props.mode === "dark" && (`color:` + props.theme.colors.primary)};
        ${(props) => props.mode !== "dark" && (`color:` + props.theme.colors.primary)};
        background-color: transparent;
        &.n-btn-primary {
          border: 1px solid ${props => props.theme.colors.primary};
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

${props => props.isOpen && `
  .sidenav__mask {
    display: block;
  }
  .sidenav__content {
    transform: translateX(0);
    ${(props) => props.mode === "dark" && `
      box-shadow: 5px 0 3px rgba(0,0,0,0.3);
    `}
    ${(props) => props.mode !== "dark" && `
      box-shadow: 5px 0 3px rgba(0,0,0,0.1);
    `}
  }`}
`;

export default {
    StyledSidenav,
};
