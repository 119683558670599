import React from 'react';
import Dialog from './Dialog';
import Button from '../Button';

const Confirm = ({ open, title, onYes, onClose, className, children, isLoading, yesText='Ok', noText='Cancel' }) => {
  return <Dialog
    open={open}
    header={title}
    onClose={onClose}
    className={className}
    modal={true}
    footer={
      <div className='footer'>
        <Button color="white" borderColor="primary" onClick={onClose} >{noText}</Button>
        <Button disabled={isLoading} isLoading={isLoading} onClick={onYes} >{yesText}</Button>
      </div>
    }
  >
    {children}
  </Dialog>;
};

export default Confirm;
