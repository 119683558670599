import React, { useEffect, useState } from "react";
import Logo from '../Logo/Logo';
import { StyledInitialPageLoadProgressBar } from './InitialPageLoadProgressBar.style'
import ProgressBar from "../ProgressBar";
import colors from '../../styles/colors';
import PropTypes from "prop-types";

const InitialPageLoadProgressBar = ({progress: externalProgress, onComplete}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(externalProgress);
    if (externalProgress >= 100 && onComplete)
      setTimeout(onComplete, 1800);
  }, [externalProgress]);

  return <StyledInitialPageLoadProgressBar className={(progress >= 100 ? 'fade-out' : '')}>
      <Logo className="logo-content" />
      <ProgressBar value={progress} maximum={100} color={colors['identity-green']} />
  </StyledInitialPageLoadProgressBar>;

};

InitialPageLoadProgressBar.propTypes = {
  progress: PropTypes.number
};

InitialPageLoadProgressBar.defaultProps = {
  progress: 0
};

export default InitialPageLoadProgressBar;
