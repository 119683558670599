import styled from 'styled-components';

export const StyledDatePicker = styled.div`
  font-family: ${props => props.theme.fonts['primary-font']};
  input {
    width: 100%;
    margin: 0;
    padding: 0.6rem 1.2rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-family: ${props => props.theme.fonts['primary-font']};
    line-height: 2.6rem;
    color: rgba(0,0,0,0.9);
    background-color: white;
    &:focus {
      box-shadow: 0 0 0 0.4rem rgba(0, 161, 222, 0.2);
      outline: 0;
      border-color: rgba(0, 161, 222, 0.8);
    }
    ${props => props.error ?  `{
      border-color: rgba(213, 60, 63, 0.8);
      &:focus {
        box-shadow: 0 0 0 0.4rem rgba(213, 60, 63, 0.2);
        outline: 0;
        border-color: rgba(213, 60, 63, 0.8);
      }
    }` : ''}
  }
  .rdt {
    position: unset;
  }
  .rdtPicker {
    margin-top: 4px;
  }
`;

