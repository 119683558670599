import React, { useState } from "react";
import { StyledNovvoSupport } from "./NovvoSupport.style";
import SubmitSupportRequestMsgBox from "../../dialogs/SubmitSupportRequestMsgBox";
import { SupportAPI } from "../../../api";
import Error from "../../Error";
import Button from "../../Button";

export default function NovvoSupport({watchlistId, buttonMode, className, onSuccess}) {
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const [supportData, setSupportData] = useState(null);


  const sendSupportRequest = () => {
    SupportAPI.inviteNovvoSupport(watchlistId).then(({success, data, error}) => {
      if (success) {
        setSupportData(data);
        setShowSubmitConfirmation(true);
      } else {
        setError(error);
      }
    });
  }

  return (
    <StyledNovvoSupport className={className}>
      {showSubmitConfirmation ? <SubmitSupportRequestMsgBox open
        onClose={() => {
          if (onSuccess) {
            onSuccess(supportData);
          }
          setShowSubmitConfirmation(false);
        }}
      /> : null}
      {buttonMode ? <Button color="primary" onClick={sendSupportRequest}>Submit support request</Button> : <>
      Not sure how to generate analytics? <span className="link" onClick={sendSupportRequest}>Request Novvo support</span>
      </>}
      {error ? <Error error={error} /> : null}
    </StyledNovvoSupport>
  );
}
