import React, {useEffect, useState} from "react";
import { StyledSupportRequests } from './SupportRequests.style';
import PageTitle from "../../../components/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import { SupportAPI } from "../../../api";
import EmptyList from "../../../components/EmptyList";
import CompanySupportCard from "./CompanySupportCard";
import NavigationPanel from "../../../components/NavigationPanel";
import enums from "../../../utils/enums";

const NAVIGATION_OPTIONS = {
  ACTIVE: {
    label: 'Pending requests',
    empty: 'No pending requests'
  },
  COMPLETED: {
    label: 'Inactive requests',
    empty: 'No inactive requests'
  }
};

export default function SupportRequests() {
  const { user } = useSelector((state) => state.auth);
  const isSupport = user?.isSupportMode;
  const [requests, setRequests] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(NAVIGATION_OPTIONS)[0]);


  useEffect(() => {
    SupportAPI.getRequestsList().then(({success, data}) => {
      if (success)
        setRequests(data);
    });
  }, []);

  const updateRequestStatus = (requestId, newStatus) => {
    SupportAPI.updateRequestsStatus(requestId, newStatus).then(({success, data}) => {
      if (success) {
        const newState = [...requests];
        const {status, updatedAt, watchlistId} = data;
        for (let i=0; i<newState.length; i++) {
          if ([enums.SupportStatus.Aborted, enums.SupportStatus.Done].includes(newStatus))
            newState[i].items = newState[i].items.filter(r => r.watchlistId !== watchlistId || r.status !== newStatus);

          const request = newState[i].items.find(r => r.id === requestId);
          if (request) {
            request.status = status;
            request.updatedAt = updatedAt;
            setRequests(newState);
            break;
          }
        }
      }
    });
  };

  if (!isSupport)
    return null;

  const getRelevantRequests = () => {
    const relevantStatuses = selectedGroup === 'ACTIVE' ? [enums.SupportStatus.InProgress, enums.SupportStatus.Pending] : [enums.SupportStatus.Aborted, enums.SupportStatus.Done];
    return requests.filter(companyRequests => companyRequests.items.some(r => relevantStatuses.includes(r.status)));
  };

  const filteredRequests = getRelevantRequests();

  return (
    <StyledSupportRequests>
      <PageTitle variant="h1" lineheight="1">Support requests</PageTitle>
      <div className="main">
        <div className="groups">
          <NavigationPanel
            options={Object.keys(NAVIGATION_OPTIONS).map(key => ({value: key, ...NAVIGATION_OPTIONS[key]}))}
            selectedValue={selectedGroup}
            onSelect={(value) => setSelectedGroup(value)}
          />
        </div>
        {filteredRequests.length ?
          <div className="requests-list">
            {filteredRequests.map((companyRequests, index) => <CompanySupportCard
              key={index}
              selectedGroup={selectedGroup}
              companyRequests={companyRequests}
              onRequestChanged={updateRequestStatus}
            />)}
          </div>
          :
          <EmptyList text="There are currently no support requests." />
        }
      </div>
    </StyledSupportRequests>
  );
};
