/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import { StyledMaster } from './Master.style'


const Master = (props) => {
  const isFullScreen = props.layout === "fullScreen";

  const getToolbarItems = () => {

    return isFullScreen ? [] : [
      { to: "/startups", name: "For companies", exact: true },
      { to: "/investors", name: "For investors", exact: true },
      // { to: "/how-it-works", name: "How it works", exact: true },
      { to: "/about", name: "About", exact: true },
    ];
  };

  return (
    <StyledMaster mode={props.mode}>
      <Header items={getToolbarItems()} layout={props.layout} mode={props.mode} />
      <div className="content">
        { props.children }
      </div>
      {/*<Footer />*/}
    </StyledMaster>
  );
};

Master.propTypes = {
  children: PropTypes.element.isRequired,
  layout: PropTypes.oneOf(['normal', 'fullScreen']),
};

Master.defaultProps = {};

export default Master;
