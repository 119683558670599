import styled from 'styled-components';

export const StyledChartLoading = styled.div`
  display: flex;
  flex-direction: column;

  &:not(.w-error):hover {
    .error-message {
      > div {
        text-decoration: underline;
      }
    }
  }

  .error-message {
    margin: 5px 20px;
    > div {
      font-size: 13px;
      color: ${props => props.theme.colors.legend};
    }
  }

  img {
    &.small {
      width: 30px;
    }
    &.medium {
      width: 60px;
    }
    &.large {
      width: 150px;
    }
  }
`


