import BaseApi from './base-api';

const KPI_TYPES = {
  Revenue: 'revenue',
  RevenueGrowthRate: 'revenue:growth-rate',
  LogosBreakdown: 'logos-breakdown',
  LogosCount: 'logos-count',
  NewLogos: 'logos-new',
  LogosChurn: 'logos-churn',
  LogosExpansion: 'logos-expansion',
  LogosContraction: 'logos-contraction',
  LogosReactivation: 'logos-reactivation',
  ARR: 'arr',
  ARR_Breakdown: 'arr-breakdown',
  ARR_Accumulative: 'arr-accumulative',
  ARR_Waterfall: 'arr-waterfall',
  ARR_New: 'arr-new',
  ARR_NetNew: 'arr-net-new',
  ARR_NetNewBreakdown: 'arr-net-new-breakdown',
  ARR_Expansion: 'arr-expansion',
  ARR_Contraction: 'arr-contraction',
  ARR_Reactivation: 'arr-reactivation',
  ARR_Churn: 'arr-churn',
  ARR_Cohort: 'arr-cohort',
  ARR_GrossChurn: 'arr-gross-churn',
  ARR_GrossChurnBreakdown: 'arr-gross-churn-breakdown',
  ARR_RevenueRate: 'arr-revenue:rate',
  Custom: 'custom',

  PnlBreakdown: 'pnl-breakdown',
  PnlRevenue: 'pnl-revenue',
  PnlRevenueBreakdown: 'pnl-revenue-breakdown',
  PnlCogs: 'pnl-cogs',
  PnlCogsBreakdown: 'pnl-cogs-breakdown',
  PnlOpex: 'pnl-opex',
  PnlOpexBreakdown: 'pnl-opex-breakdown',
  PnlSnMBreakdown: 'pnl-snm-breakdown',
  PnlRnDBreakdown: 'pnl-rnd-breakdown',
  PnlGnABreakdown: 'pnl-gna-breakdown',
};

class KpisAPI extends BaseApi{
  constructor() {
    super('/kpis');
  }

  get Types() { return KPI_TYPES; }

  buildUrlPrefix(startupId, watchlistId) {
    if (watchlistId)
      return `watchlist/${watchlistId}`;
    return startupId;
  }

  getKpi(startupId, watchlistId, kpiType, options, filters) {
    return this.post(`${this.buildUrlPrefix(startupId, watchlistId)}/${kpiType}`, { options, filters });
  }

  getLastActualDate(startupId, watchlistId) {
    return this.get(`${this.buildUrlPrefix(startupId, watchlistId)}/last_actual_date`);
  }

  getOverviewConfiguration(startupId, watchlistId) {
    // return Promise.resolve({data: config.dashboard, success: true});
    return this.get(`${this.buildUrlPrefix(startupId, watchlistId)}/overview/config`);
  }

  downloadReport(startupId, watchlistId, filters) {
    return this.downloadFile(`${this.buildUrlPrefix(startupId, watchlistId)}/report/generate`, { filters });
  }

  downloadChartData(startupId, watchlistId, kpiType, options = {}, filters = {}) {
    return this.downloadFile(`${this.buildUrlPrefix(startupId, watchlistId)}/chart-data/generate`, {kpiType, options, filters});
  }

  downloadFile(url, body, options = {}) {
    options.responseType = 'arraybuffer';
    return this.post(url, body, {})
      .then((response) => {
        const {success, data, error} = response || {};
        if (error)
          return {success, error};
        if (data) {
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++)
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', decodeURI(response.fileName));
          document.body.appendChild(link);
          link.click();
          link.remove();
        }

        return {success: true};
      });
  }
}

export default new KpisAPI();



function s2ab(s) {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;
}

