import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useRedirectAuthenticatedUser = (user) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (user) {
          if (user.isSupportMode)
            navigate('/app/support');
          const previousRoute = location.state?.from.pathname;
          if (previousRoute)
            navigate(previousRoute + (location.state?.from.hash ? location.state?.from.hash : ''));
          else if (user.isStartupMode)
            navigate('/app/startup');
          else if (user.isInvestorMode)
            navigate('/app/investor');
        }
    }, [user]);
};

export default useRedirectAuthenticatedUser;
