import React, { useState } from 'react';
import PropTypes from "prop-types";
import { StyledMemberPopup } from './MemberPopup.Style'
import { Close, OpenInNewIcon } from "../../assets/mui-icons";
import TextField from "../TextField";
import UserPhoto from "../UserPhoto";
import Converters from "../../utils/converters";

const MemberPopup = ({ user, onClose }) => {

  if (!user)
    return null;

  const isSupportUser = user.isSupportUser;

  return (
    <StyledMemberPopup>
      <div className="member-info">
        <div>
          <h2><span>{user.name}</span><Close onClick={onClose} /></h2>
          <div className="member-details-container">
            <div className="member-details">
              <TextField label="Company">
                {isSupportUser ? 'Novvo' : user.organizationName}
              </TextField>
              {user.role ? <TextField label="Permissions">
                {isSupportUser ? user.role : user.isAdmin ? ' Administrator': 'User'}
              </TextField> : null}
              {user.title ? <TextField label="Role">
                {user.title}
              </TextField> : null}
              {user.phone ? <TextField label="Mobile">
                {user.phone}
              </TextField> : null}
              <TextField label="Email">
                <a href={`mailto:${user.email}`} className="email"><span>{user.email}</span><OpenInNewIcon /></a>
              </TextField>
            </div>
            <UserPhoto className={isSupportUser ? 'member-photo support' : 'member-photo'} user={user} size={'xxl'} />
          </div>

          {!isSupportUser ? <div className="member-activity">Last active {Converters.toShortDateTime(user.updatedAt || user.createdAt)}</div> : null}
        </div>
      </div>
      <div className="member-info-bg" />
    </StyledMemberPopup>
  );
};

MemberPopup.propTypes = {
  size: PropTypes.oneOf(['', 'small', 'medium'])
};

MemberPopup.defaultProps = {
  size: ''
};

export default MemberPopup;
