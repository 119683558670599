import React from 'react';
import Dialog from './Dialog';

const MsgBox = ({ open, children, title, onClose, className, showCloseIcon = false , ...rest}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    className={className}
    showCloseIcon={showCloseIcon}
    header={title}
    {...rest}
  >
    {children}
  </Dialog>;
};

export default MsgBox;
