import styled from 'styled-components';

export const StyledProgressBar = styled.div`
  height: 12px;
  width: 176px;
  background: ${props => props.theme.colors.lightGray};
  border-radius: 2px;
  overflow: hidden;

  & .progress-bar_filler {
    height: 100%;
    width: ${props => props.progress}%;
    background: ${props => props.color || props.theme.colors.primary};
    border-radius: 2px 0 0 2px;
    transition: width 1s ease-in-out;
  }
`;
