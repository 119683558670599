import styled from 'styled-components';

export const StyledInvitations = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 50px;
  h3 {
    font-size: 14px;
  }
  .empty-message {
    max-width: 150px;
    color:  ${props => props.theme.colors.label};
    font-size: 12px;
    margin-top: 10px;
  }
  .company {
    display: flex;
    flex-direction: column;
    .company-info {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .company-details {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        strong {
          font-weight: 500;
        }
        span {
          font-size: 11px;
          color: ${props => props.theme.colors.label};
        }
      }
    }
    .company-actions {
      margin: 5px 0 0 40px;
      button:first-child {
        margin-right: 5px;
      }
    }
  }
`;

export const StyledInvitationConfirmation = styled.div`
  max-width: 600px;
`;
