import styled from 'styled-components';

export const StyledFileSelector = styled.div`
  > div span {
    color: ${props => props.theme.colors.link};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .h3  {
    margin: 0;
    padding: 0;
  }
  .files-list {
    max-height: 500px;
    overflow: auto;
    height: 100%;
    width: 50%;

    .radio {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 10px 0;

      .radio__inp svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      .radio__lbl {
        width: 100%;
      }

      .radio-option-lbl {
        display: flex;
        align-items: center;

        >div:first-child {
          margin-right: auto;
        }
      }
    }

    &.disabled {
      color: ${props => props.theme.colors.lightGray};
    }

    .comment {
      font-size: 10px;
      color: ${props => props.theme.colors.legend};
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .trigger {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${props => props.theme.colors.link};

      svg {
        font-size: 24px;
        margin-right: 10px;
      }
    }

    >div:last-child {
      margin-left: auto;
    }
  }


`;
