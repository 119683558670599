
export const validateEmail = (email) => {
  const isValid = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/g.test(email);
  return {
    valid: isValid,
    error: isValid ? null : 'Email is not valid'
  };
};

export const validatePassword = (password) => {
  const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&])/.test(password);
  return {
    valid: isValid,
    error: isValid ? null : 'Password must contain 8-16 characters, contain at least one number, one special character, one lowercase and one uppercase'
  };
};
