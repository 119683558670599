import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import { StyledInviteTeammate } from './invite-teammate.style';
import Button from "../Button";
import TextInput from "../TextInput";
import {useFormInput} from "../../hooks";
import { UsersAPI } from "../../api";
import {validateEmail} from "../../utils/validators";
import enums from "../../utils/enums";
import Select from "../Select";
import Typography from "../Typography";
import { useSelector } from "react-redux";
import Error from "../Error";

export default function InviteTeammatePanel( {invitationId, email: initialEmail, name: initialName, note: initialNote, role: initialRole}) {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');


  const getEmailDomain = (e) => {
    return ((e || '').split('@')[1] || '').toLowerCase();
  };

  const currentUserDomain = getEmailDomain(currentUser.email);

  const name = useFormInput(initialName);
  const email = useFormInput(initialEmail);
  const note = useFormInput(initialNote);
  const role = useFormInput(initialRole || enums.Role.Admin);

  const [showEmailWarning, setShowEmailWarning] = useState(initialEmail && currentUserDomain !== getEmailDomain(initialEmail));

  const validateForm = () => {
    if (!email.value)
      return setFormError('Please fill in email');
    const validation = validateEmail(email.value);
    if (!validation.valid)
      return setFormError(validation.error);
    setFormError('');
    return true;
  };

  // handle button click of login form
  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (validateForm()) {
      setSubmitting(true);
      UsersAPI.inviteTeammate(name.value, email.value, role.value, note.value, invitationId).then(({success, data, error}) => {
        if (success) {
          navigate(-1);
        }
        else {
          setFormError(error);
        }
        setSubmitting(false);
      });
    }
  };

  const handleKeypress = (e) => {
    // it triggers by pressing the enter key
    if (e.target.tagName !== 'textarea' && e.charCode === 13) {
      handleSubmit(e);
      return;
    }
    if (e.target.type === 'email') {
      const enteredEmailDomain = getEmailDomain(email.value);
      const isDifferentDomain = enteredEmailDomain && currentUserDomain !== enteredEmailDomain;
      setShowEmailWarning(isDifferentDomain);
    }
  };

  const renderFormInput = (label, obj, attrs = {}) => {
    const onKeypress = attrs.type !== 'textarea' ? handleKeypress : undefined;
    return <div className="input-container note">
      <TextInput
        label={label}
        onKeyPress={onKeypress}
        {...obj}
        {...attrs}
      />
    </div>;
  };

  const getRoleNote = () => {
    if (role.value === enums.Role.Admin) {
      return 'Recipient will have full access to data and network actions (invite, accept invite, revoke etc.)';
    }
    if (role.value === "ExternalAdmin") {
      return 'Recipient will have permissions to upload/download data files.'
    }
    return 'Recipient will only have permission to edit company profile and back office integration.'
  };

  return (
    <StyledInviteTeammate>
      <div className="input-container">
        <TextInput
          label="Name"
          autoComplete="new-password"
          onKeyPress={handleKeypress}
          {...name}
        />
      </div>
      <div className="input-container">
        <TextInput
          type="email"
          label="Email"
          autoComplete="new-password"
          onKeyUp={handleKeypress}
          {...email}
        />
        {showEmailWarning ? <Typography color="secondary" variant="p">
          It seems like your teammate has different email domain.
        </Typography> : null}
      </div>
      <div className="input-container">
        <Select label="Permissions" {...role}>
          <option value={enums.Role.User}>User</option>
          <option value={enums.Role.Admin}>Administrator</option>
          {currentUser.isStartupMode ? <option value="ExternalAdmin">Data manager</option> : null}
        </Select>
        <Typography color="secondary" variant="p">
          {getRoleNote()}
        </Typography>
      </div>
      {renderFormInput('Personal note (optional)', note, {type: 'textarea', maxLength: 200})}
      {formError && <Error className="form__error" error={formError} />}
      <div className="send-action">
        <Button disabled={submitting || !email.value} onClick={handleSubmit} isLoading={submitting} >Send</Button>
      </div>
    </StyledInviteTeammate>
  );
}

