import React from 'react';
import PropTypes from "prop-types";
import Loading from '../Loading/Loading';
import Logo from '../Logo/Logo';
import { StyledPageSpinner } from './PageSpinner.style'

const PageSpinner = ({ size, mode, caption = null }) => {

  if (mode === 'initial')
    return <StyledPageSpinner className="page-spinner logo-loading">
      <Loading className="loading-indication" size={size} />
      <div className="logo-content">
        <Logo />
        {caption ? <div className="caption">{caption}</div> : null}
      </div>
    </StyledPageSpinner>;

  return (
        <StyledPageSpinner className="page-spinner">
            <Loading size={size} />
            <div className="caption">{caption}</div>
        </StyledPageSpinner>
    );
};

PageSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'xlarge']),
  mode: PropTypes.string
};

PageSpinner.defaultProps = {
  size: 'small',
  mode: null
};

export default PageSpinner;
