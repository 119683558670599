import React from 'react';
import PropTypes from "prop-types";
import ChartLoadingAnimation from '../../assets/icons/chart-loading-animated.svg';
import ChartLoadingStatic from '../../assets/icons/chart-loading-static.svg';
import { StyledChartLoading } from "./ChartLoading.Style";
import Error from "../Error";

const ChartLoading = ({ size, className, animated, message, isError }) => {
    return (
      <StyledChartLoading className={className + (isError ? ' w-error' : '')}>
        <div><img src={animated ? ChartLoadingAnimation : ChartLoadingStatic} alt="Loading..." className={size} /></div>
        {message && <Error className="error-message" error={message} />}
      </StyledChartLoading>
    );
};

ChartLoading.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  animated: PropTypes.bool
};

ChartLoading.defaultProps = {
  size: 'medium',
  animated: false
};

export default ChartLoading;
