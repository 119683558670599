import styled from 'styled-components';

export const StyledChartToolbar = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.legend};
  font-size: 11px;

  svg {
    font-size: 16px;
    margin-right: 5px;
    path {
      fill: ${props => props.theme.colors.legend};
    }
  }
  a {
    color: ${props => props.theme.colors.legend};
  }

  span {
    height: 16px;
  }

  .display-options,
  .chart-display-options {
    display: flex;
    align-items: center;
    .filters,
    .date-range,
    .indicators {
      &.active {
        color: ${props => props.theme.colors.navigation};
      }

      display: flex;
      align-items: center;
      margin-right: 23px;

      fieldset {
        display: none;
      }

      .MuiSelect-select {
        font-size: 11px;
        font-weight: bold;
        padding: 2px 20px 0 0;
        color: ${props => props.theme.colors.legend};
        height: auto;
        min-height: auto;
      }

      .MuiSelect-nativeInput {
        display: none;
      }

      .MuiSvgIcon-root {
        right: 0;
      }
    }
  }

  .chart-display-options {
    margin-left: 3px;
    > svg {
      font-size: 15px;
      border-left: 1px solid ${props => props.theme.colors.legend};
      border-bottom: 1px solid ${props => props.theme.colors.legend};
      border-radius: 0 0 0 2px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    .download-report {
      display: flex;
      align-items: center;
      cursor: pointer;


      &.disabled {
        cursor: default;
        color: ${props => props.theme.colors.gray};
        text-decoration: unset;
      }
    }
    > a {
      margin-left: 20px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    cursor:pointer;
    padding: 0 10px;
    svg {
      path {
        fill: ${props => props.theme.colors.legend};
      }
    }
    span {
      height: 1.5rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .selector {
    display: inline;
    margin-left: 4px;

    > div {
      background: none;
      height: 25px;

      > div {
        padding-left: 0;
      }
    }
  }


`;
