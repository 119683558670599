import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Master from '../layout/MasterSecured';

// handle the private routes
function PrivateRoute({ layout, allowExternal = false, allowSupport = false }) {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const isExternalUser = user?.isExternalUser;
  const isSupportUser = user?.isSupportUser;
  const isSupportUserAllowed = !isSupportUser || allowSupport;
  const isExternalUserAllowed = !isSupportUser && !isExternalUser || allowExternal;

  if (!isAuthenticated)
      return <Navigate to={'/login'} state={{ from: location }} />;
  if (!isStartup || !isSupportUserAllowed && !isExternalUserAllowed)
      return <Navigate to={'/'} state={{ from: location }} />;
  return (
      <Master layout={layout}>
          <Outlet />
      </Master>
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  layout: PropTypes.string
};
