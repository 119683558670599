import styled from 'styled-components';

export const StyledCompanyDetails = styled.div`
  .error div {
    color: ${props => props.theme.colors.black};
  }
  .h1 {
    font-size: 32px;
    margin-bottom: 25px;
  }
  .nav-panel {
    position: absolute;
    left: 35px;
  }
  .page-content {
    display: flex;
    margin: 20px 0 0 0;

    .main {
      flex: 1;
      margin: 0 20px 0 0;

      .card {
        flex: 1;
        margin-bottom: 20px;
        padding: 16px 24px;
        background-color: #fff;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
        border-radius: 4px;
        position: relative;

        > .edit {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 10px;
          border-radius: 50%;
          padding: 5px;
          &:hover {
            background-color: ${props => props.theme.colors.lighterGray};
          }
        }

        >div:first-child {
          display: flex;

          .name-cnt {
            margin-right: auto;
          }

          >div:last-child {
            margin-right: 50px;
            margin-top: 20px;
          }
        }

        .item {
          margin: 20px 0;
          .link a {
            display: inline-block;
            svg {
              margin-left: 5px;
              vertical-align: middle;
            }
            span {
              max-width: 250px;
              white-space: nowrap;
              word-break: keep-all;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }

    .suggestions {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      width: 250px;
    }
  }

`;

