import styled from 'styled-components';

export const StyledTeamListPanel = styled.div`
  .card {
    flex: 1;
    margin-bottom: 20px;
    padding: 13px 20px 45px 20px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
    border-radius: 4px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .edit {
      cursor: pointer;
    }

    h2 {
      margin-bottom: unset;
      margin-right: auto;
    }

    .MuiSvgIcon-root {
      font-size: 2.0rem;
      color: #00c4c3;
    }
  }

  .team-list {
    font-size: 12px;

    .headers {
      display: flex;
      align-items: center;
      padding: 10px 0;
      color: ${props => props.theme.colors.legend};
      font-weight: normal;
      .name {
        padding-left: 25px;
      }
    }

    .user-card {
      display: flex;
      align-items: center;
      padding: 10px 0;

      .support {
        img {
          border-radius: unset;
        }
      }

      .name {
        margin-left: 10px;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        width: 30%;
        &:hover {
          text-decoration: underline;
          color: ${props => props.theme.colors.link};
        }
        span {
          color: #9b9b9b;
        }
        strong {
          cursor: pointer;
        }
      }

      .status-cnt {
        width: 80px;
        margin-right: 40px;

        text-align: right;

        .status {
          font-weight: bold;
          &.accepted {
            color: green;
          }

          &.pending {
            color: #ffc859;
          }

          &.revoked {
            color: red;
          }
        }

      }


      .role {
        width: 120px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        .menu__list {
          min-width: 10rem;
        }
      }

      .date {
        margin-right: 20px;

        width: 80px;
      }

      &:not(.headers) {
        .date {
          color: #aaa;
        }
      }

      .member-menu {
        width: 50px;
        margin-left: 10px;

        .menu__list {
          min-width: 10rem;
        }
      }
    }

  }

`

