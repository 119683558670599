import React from 'react';
import PropTypes from 'prop-types';
import { StyledFormLabel } from './FormLabel.style';

function FormLabel({ label, error, className, ...rest }) {
    return (
        <StyledFormLabel className={(className ? ` ${className}`: '')}>
          {label ? (<label className="form__label" {...rest}>{label}{error ? <em>*</em> : null}</label>) : null}
        </StyledFormLabel>
    );
}

FormLabel.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool
};

FormLabel.defaultProps = {
  error: false
};

export default FormLabel;
