import React, { useState } from "react";
import { StyledGiveFeedback } from "./GiveFeedback.style";
import { VolunteerActivism } from "../../../../../assets/mui-icons";
import GiveFeedbackDialog from '../../../../dialogs/GiveFeedbackDialog';

const GiveFeedback = ({ mode }) => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  return (
    <StyledGiveFeedback
      mode={mode}
      onClick={() => setShowFeedbackDialog(true)}
    >
      {showFeedbackDialog ? <GiveFeedbackDialog onClose={() => setShowFeedbackDialog(false)} open /> : null}
      <VolunteerActivism /> Give feedback
    </StyledGiveFeedback>
  );
};

export default GiveFeedback;
