import styled from 'styled-components';
export const StyledDataRoom = styled.div`




  .page-content {
    display: flex;
    margin: 15px 0;
    font-size: 14px;

    .groups {
    }

    .main {
      .h2 {
        font-size: 24px;
        margin-bottom: 3rem;
      }
      max-width: 550px;
      flex: 1;
      margin: 0;

      span.link {
        color: ${props => props.theme.colors.link};
        cursor: pointer;
        &:hover {
          color: ${props => props.theme.colors.linkhover};
          text-decoration: underline;
        }
      }
      .data-files-list {
        margin-top: 5rem;

        ol {
          margin: 2rem 0 5rem 4rem;
          li {
            margin: 1.5rem 0;
          }
        }

      }
      .buttons {
        margin-top: 5rem;
      }
    }

    .upload-content {
      background: white;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
      padding: 3rem;
    }

    .suggestions {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      width: 250px;
    }
  }

`;

