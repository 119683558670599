import styled from 'styled-components';

export const StyledToolbarMenu = styled.div`
  &.menu {
    position: relative;
  }
  .menu__opener {
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    > svg {
      width: 32px;
      height: 32px;
      ${(props) => props.mode === "dark" && `fill:#efefef;`}
    }
  }
  .menu__list {
    min-width: 18rem;
    position: absolute;
    z-index: 12;
    right: 5px;
    top: 3.2rem;
    background-color: #efefef;
    padding:0.2rem;
    border-radius: 0.4rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    transform: scale(0.8);
    transition-property: opacity, transform, visibility;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.26, 0.20, 0.020, 1.6);
    visibility: hidden;
    opacity: 0;
    > ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        line-height: normal;
        margin: 0;
        padding: 0;
        > li {
          font-size: 1.4rem;
          color: #333;
          display: inline-block;
          text-decoration: none;
          padding: 0.8rem 1.2rem;
          border-radius: 0.2rem;
          &.separator {
            border-top: 1px solid #ccc;
          }
        }
        .action {
          cursor: pointer;
          &:hover {
            cursor: pointer;
            background-color: #fff;
          }
          &.sub-menu {
            position: relative;
            > ul {
              display: flex;
              flex-direction: column;
              list-style-type: none;
              line-height: normal;
              margin: 0;
              padding: 0;
              min-width: 18rem;
              position: absolute;
              display: none;
              left: -18rem;
              top: 0;
              background-color: #efefef;
              padding:0.2rem;
              border-radius: 0.4rem;
              transition-property: opacity, transform, visibility;
              transition-duration: 200ms;
              transition-timing-function: cubic-bezier(0.26, 0.20, 0.020, 1.6);
              > li {
                font-size: 1.4rem;
                color: #333;
                display: inline-block;
                text-decoration: none;
                padding: 0.8rem 1.2rem;
                border-radius: 0.2rem;
                width: 100%;
                &.separator {
                  border-top: 1px solid #ccc;
                }
              }
            }
            &.active {
              > ul {
                display: block;
              }
            }
          }
        }
        .title {
          font-size: 14px;
          font-weight: bold;
          cursor: default;
          &:hover {
            cursor: default;
            background: inherit;
          }
        }
        .user {
          display: flex;
          color: #12999E;
          cursor: default;
          align-items: center;
          svg {
            width: 32px;
            height: 32px;
          }
          .user__details {

            display: flex;
            flex-direction: column;
            margin-left: 6px;
          }
          &__name {
            color: #000;
            font-size: 1.2rem;
          }
          &__email {
            font-size: 1rem;
            color: ${props => props.theme.colors.label};
          }
        }
    }
  }

  &.menu--open .menu__list {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  .menu__buttons {
    display: flex;
    gap: 0.6rem;
    justify-content: space-between;
    padding: 0.6rem 0 1.2rem;
    .btn {
      width: 100%;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 500;
      border-radius: 0.4rem;
      color: #fff;
      background-color: #02c4c3;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #03aead;
      }
      &:active {
        position: relative;
        top: 1px;
      }
      &--link {
        background-color: transparent;
        border-color: transparent;
        color: gray;
        &:hover {
          background-color: transparent;
          color: #333;
        }

      }

    }
  }
`
