import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading/Loading';
import './Button.style';
import { StyledButton } from './Button.style'


function Button({ children, variant, isLoading, className, disabled, ...rest }) {
    return (
        // eslint-disable-next-line react/button-has-type
        <StyledButton {...rest} disabled={disabled || isLoading} className={`button-container ${isLoading ? 'loading' : ''} ${className}`}>
          {children}
          {isLoading ? <Loading size="small" /> : null}
        </StyledButton>
    );
}

Button.propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(['', 'gray', 'primary', 'secondary','identity-green', 'white', 'green']),
    borderColor: PropTypes.oneOf(['', 'primary', 'secondary','green', 'lightBlue']),
    size: PropTypes.oneOf(['', 'small', 'large']),
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
};

Button.defaultProps = {
    isLoading: false,
    color: 'primary',
    borderColor: '',
    className: ''
};

export default Button;
