import React from 'react';
import { NavLink } from 'react-router-dom';
import { Styled404 } from './404.style'

const NotFoundPage = () => {
    return (
        <Styled404 className="notfound-page">
            <div>
                <h1>(404) Page Not Found Error</h1>
                <p>We could not locate the requested page on our servers</p>
                <p>
                    Go back to <NavLink to="/">safety</NavLink>
                </p>
            </div>
        </Styled404>
    );
};

export default NotFoundPage;
