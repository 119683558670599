import React from "react";
import MsgBox from "../MsgBox";
import {StyledGetStartedTutorialDemoDialog} from "./GetStartedTutorialDemoDialog.style";
import { useSelector } from "react-redux";

export default function GetStartedTutorialDemoDialog({ open, onClose }){
  const { user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const videoLink = isStartup ? 'fzhyg-aEkwA' : 'fzhyg-aEkwA';
  return <MsgBox showCloseIcon
                onClose={onClose}
                open={open}
                modal={true}
  >
    <StyledGetStartedTutorialDemoDialog>
      <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + videoLink} title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen></iframe>
    </StyledGetStartedTutorialDemoDialog>
  </MsgBox>;
};

