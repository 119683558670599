import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { StyledCompanyCard, StyledWatchlistDeleteConfirmation, StyledPreferencesDialog } from "./CompanyCard.style";
import CompanyLogo from "../CompanyLogo";
import UserPhoto from "../UserPhoto";
import enums from "../../utils/enums";
import Converters from "../../utils/converters";
import { useSelector } from "react-redux";
import MoreMenu from "../MoreMenu";
import { InvestorsAPI, UsersAPI, SupportAPI } from "../../api";
import routes from "../../pages/routes";
import { Confirm, DataFilesDialog, PermissionsEditor } from "../dialogs";
import MemberPopup from "../MemberPopup";
import AddEditStartupPopup from "../../pages/investors/Watchlist/AddEditStartupPopup";
import FullWidthDialog from "../dialogs/FullWidthDialog";
import PreferencesCard from "../PreferencesCard";
import SubmitSupportRequestMsgBox from "../dialogs/SubmitSupportRequestMsgBox";
import Error from "../Error";

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
};

export default function CompanyCard({company: initialCompany, connectionType, shouldOpenUploadDialog, onRevoke, onConnectionTypeChange, onPermissionsChange}) {
  const navigate = useNavigate();
  const [company, setCompany] = useState(initialCompany);
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.isAdmin;
  const isSupportUser = user?.isSupportUser;
  const [permissions, setPermissions] = useState(company?.permissions);
  const [error, setError] = useState(null);
  const [showMemberInfo, setShowMemberInfo] = useState(null);
  const [selectedStartup, setSelectedStartup] = useState(false);
  const [candidateForDeletion, setCandidateForDeletion] = useState(false);
  const [showEditPermissionsDialog, setShowEditPermissionsDialog] = useState(false);
  const [shouldOpenDatafilesDialog, setShowOpenDatafilesDialog] = useState(shouldOpenUploadDialog || false);
  const [shouldOpenPreferencesDialog, setShowPreferencesDialog] = useState(false);
  const [showRevokeConnection, setShowRevokeConnection] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [showRevokeSupportConfirmation, setShowRevokeSupportConfirmation] = useState(false);
  const [showNeedToRevokeBeforeGenerateDialog, setShowNeedToRevokeBeforeGenerateDialog] = useState(false);

  const isStartup = user?.isStartupMode;
  const companyRoutes = routes[isStartup ? "Startups" : "Investors"];

  if (!company) return null;

  const { id, aclId, invitationId, name, createdAt, updatedAt, members = [], status,
    isExpired, isInternal, isDemo, supportRequested, supportStatus, supportUpdatedAt, dataReady, BookingsGenerationError, BookingsDataGenerationStatus } = company;

  const revoke = () => {
    setShowRevokeConnection(false);
    if (id) {
      UsersAPI.revokeConnection(id).then(({ success, data }) => {
        if (success && data && onRevoke) onRevoke();
      });
    } else {
      UsersAPI.revokeInvitation(invitationId).then(({ success, data }) => {
        if (success && data && onRevoke) onRevoke();
      });
    }
  };

  const onCloseDataFilesDialog = (isOfflineGenerate) => {
    setShowOpenDatafilesDialog(false);
    navigate(`${companyRoutes.Network}#${connectionType}`);
    if (isOfflineGenerate) {
      setCompany({...company, BookingsDataGenerationStatus: enums.DataGenerationStatus.PendingOfflineJobByUser});
    }
  };

  const resendInvitation = (resetValues = false) => {
    const state = { invitationId, connectionType, invitationMode: true };
    if (!resetValues) {
      state.name = company.name;
      state.email = company.members[0]?.email;
      state.permissions = permissions;
      state.note = company.note;
    }

    navigate(companyRoutes.Invite, { state });
  };

  const changeConnectionType = () => {
    const newType =
      connectionType === enums.ConnectionType.Prospect
        ? enums.ConnectionType.Current
        : enums.ConnectionType.Prospect;
    if (isInternal) {
      InvestorsAPI.updateWatchlistCompany(id, { connectionType: newType }).then(
        ({ success, data }) => {
          if (success && data && onConnectionTypeChange)
            onConnectionTypeChange(newType);
        }
      );
    } else {
      UsersAPI.changeConnectionType(id, invitationId, newType).then(
        ({ success, data }) => {
          if (success && data && onConnectionTypeChange)
            onConnectionTypeChange(newType);
        }
      );
    }
  };

  const inviteNovvoSupport = () => {
    SupportAPI.inviteNovvoSupport(id).then(({success, error}) => {
      if (success) {
        setShowSubmitConfirmation(true);
        updateCompanyDataAfterSupport();
      } else {
        setError(error);
      }
    });
  };

  const updateCompanyDataAfterSupport = () => {
    setCompany({...company, supportRequested: true, supportStatus: enums.SupportStatus.Pending, supportUpdatedAt: new Date()});
  };

  const revokeNovvoSupport = () => {
    SupportAPI.revokeNovvoSupport(id).then(({success, error}) => {
      if (success) {
        setShowRevokeSupportConfirmation(false);
        setCompany({...company, supportRequested: false, supportStatus: 'N/A', supportUpdatedAt: new Date()});
      }
      else
        setError(error);
    });
  };

  const changePermissions = (newValue) => {
    if (newValue !== permissions) {
      UsersAPI.updateCompanyPermissions(id, invitationId, newValue).then(
        ({ success, data }) => {
          if (success && data) {
            setPermissions(newValue);
            if (onPermissionsChange) onPermissionsChange(newValue);
          }
        }
      );
    }
  };

  const getCompanyLink = () => {
    if (isInternal) return routes.Investors.getWatchlistUrl(id);
    if (isStartup) return companyRoutes.getInvestorUrl(id);
    return companyRoutes.getStartupUrl(id);
  };

  const navigateToOrganizationProfile = () => {
    if (id) {
      const currentLocation = window.location.pathname + window.location.hash;
      navigate(getCompanyLink(), { state: { from_path: currentLocation } });
      return;
    }
    alert(
      `${
        isStartup ? "Investor" : "Company"
      } hasn't accepted your invitation yet`
    );
  };

  const getMoveGroupCaption = () => {
    if (isStartup) {
      if (connectionType === enums.ConnectionType.Prospect)
        return "Move to current investors group";
      return "Move to prospect investors group";
    }

    if (connectionType === enums.ConnectionType.Prospect)
      return "Move to portfolio companies group";
    return "Move to prospect companies group";
  };

  const openDataSources = () => {
    if (supportRequested)
      setShowNeedToRevokeBeforeGenerateDialog(true);
    else
      setShowOpenDatafilesDialog(true);
  };

  const getMenuOptions = () => {
    const options = [];

    if (isInternal) {
      if (isAdmin && !isSupportUser) {
        options.push({
          label: 'Generate analytics',
          onClick: () => {
            openDataSources();
          }
        });
      }
      if (isAdmin && isSupportUser) {
        options.push({
          label: "Generate analytics",
          onClick: () => openDataSources(),
        });
      }
      if (isAdmin && !isSupportUser) {
        if (supportRequested && supportStatus !== enums.SupportStatus.Done) {
          options.push({
            label: 'Revoke support request',
            onClick: () => setShowRevokeSupportConfirmation(true),
          });
        } else {
          options.push({
            label: 'Request support',
            onClick: () => inviteNovvoSupport(),
          });
        }
      }

      if (!isSupportUser) {
        options.push({
          label: getMoveGroupCaption(),
          onClick: () => changeConnectionType(),
        });
      }

      if (!isSupportUser && !supportRequested) {
        options.push({
          label: "Delete",
          onClick: () => setCandidateForDeletion(true),
        });
      }

      return options;
    }

    if (isStartup && !isDemo)
      options.push({
        label: "Edit permissions",
        onClick: () => setShowEditPermissionsDialog(true),
      });

    if (!isDemo) {
      switch (status) {
        case enums.InvitationStatus.Pending:
          options.push({ label: "Cancel invitation", onClick: () => setShowRevokeConnection(true) });
          options.push({
            label: "Resend invitation",
            onClick: () => resendInvitation(),
          });
          options.push({
            label: "Change invitee",
            onClick: () => resendInvitation(true),
          });
          break;
        case enums.InvitationStatus.Accepted:
          options.push({
            label: isStartup ? "Disconnect investor" : "Disconnect startup",
            onClick: () => setShowRevokeConnection(true),
          });
          break;
      }
    } else {
      options.push({
        label: "Delete",
        onClick: () => setShowRevokeConnection(true),
      });
    }

    if (!isDemo) {
      options.push({
        label: getMoveGroupCaption(),
        onClick: () => changeConnectionType(),
      });
    }

    return options;
  };

  const navigateToAnalytics = () => {
    const url = isInternal ? routes.Investors.getWatchlistAnalyticsUrl(id) : routes.Investors.getStartupAnalyticsUrl(id);
    const currentLocation = window.location.pathname + window.location.hash;
    navigate(url, {state: {from_path: currentLocation}});
  };

  const isOfflineJobInProgress = () => {
    return [enums.DataGenerationStatus.PendingOfflineJobBySystem, enums.DataGenerationStatus.OfflineJobInProgressBySystem, enums.DataGenerationStatus.PendingOfflineJobByUser, enums.DataGenerationStatus.OfflineJobInProgressByUser].includes(BookingsDataGenerationStatus);
  };

  const getStatus = () => {
    if (isInternal) {
      if (supportRequested && [enums.SupportStatus.Pending, enums.SupportStatus.InProgress].includes(supportStatus)) {
        if (supportStatus === enums.SupportStatus.Pending)
          return <strong className="warning">Support requested</strong>;
        return <strong className="warning">Support in progress</strong>;
      }
      if (BookingsGenerationError)
        return <strong className="bad">Processing error</strong>;
      if (isOfflineJobInProgress())
        return <strong className="warning">Processing offline</strong>;
      if (dataReady)
        return <strong className="good">Analytics ready</strong>;
      return <strong className="bad">Incomplete</strong>;
    }

    if (status === enums.InvitationStatus.Pending) {
      if (isExpired)
        return <strong className="bad">Invitation expired</strong>;
      return <strong className="warning">Invitation sent</strong>;
    }

    if (status === enums.InvitationStatus.Accepted) {
      if (permissions > enums.Permissions.Profile) {
        if (dataReady)
          return <strong className="good">Analytics ready</strong>;
        return <strong className="good">Connected</strong>;
      }
      if (permissions === enums.Permissions.Profile) {
        return <strong className="good">Connected</strong>;
      }
    }

    if (status === enums.InvitationStatus.Rejected) {
      return <strong className="bad">Invitation rejected</strong>;
    }
  };

  const getAction = () => {
    if (isInternal) {
      if (supportRequested && [enums.SupportStatus.Pending, enums.SupportStatus.InProgress].includes(supportStatus))
        return <span onClick={() => setShowRevokeSupportConfirmation(true)}>Revoke request</span>;
      if (BookingsGenerationError)
        return <span onClick={inviteNovvoSupport}>Request support</span>;
      if (!dataReady || isOfflineJobInProgress())
        return <span onClick={openDataSources}>Generate analytics</span>;
      return <span onClick={navigateToAnalytics}>View analytics</span>;
    }

    if (isExpired || status === enums.InvitationStatus.Pending) {
      return <span onClick={() => resendInvitation()}>Resend invitation</span>;
    }

    if (status === enums.InvitationStatus.Accepted) {
      if (permissions > enums.Permissions.Profile) {
        if (dataReady)
          return <span onClick={navigateToAnalytics}>View analytics</span>;
        return <span onClick={navigateToOrganizationProfile}>View profile</span>;
      }
      if (permissions === enums.Permissions.Profile) {
        return <span onClick={navigateToOrganizationProfile}>View profile</span>;
      }
    }

    if (status === enums.InvitationStatus.Rejected) {
      return <span onClick={() => resendInvitation()}>Resend invitation</span>;
    }
  };

  const renderStatus = () => {
    return (
      <div className="status">
        <div>{getStatus()}</div>
      </div>
    );
  };

  const renderAction = () => {
    return (
      <div className="action">
        <div>{getAction()}</div>
      </div>
    );
  };

  const renderMemberName = (member) => {
    const displayName = member.name
      ? `${member.name} (${member.email})`
      : member.email;
    return <strong>{displayName}</strong>;
  };

  const renderMemberInfoDialog = () => {
    return (
      <MemberPopup
        user={showMemberInfo}
        organizationName={name}
        onClose={() => setShowMemberInfo(null)}
      />
    );
  };

  const renderCompany = () => {
    return (
      <>
        <div className={"company"}>
          <div
            className="company-details"
            onClick={navigateToOrganizationProfile}
          >
            <CompanyLogo company={company} size={24} />
            <div className="name">
              <strong>{name}</strong>
            </div>
          </div>
          <div className="company-additional">
            <div className="left-side">
              {renderStatus()}
              {renderAction()}
            </div>
            <div className="right-side">
              {isStartup ? <div className="permissions">{permissions > enums.Permissions.Profile ? 'Analytics' : 'Profile'}</div> : null}
              <div className="date">{Converters.toShortDateTime((supportRequested ? (supportUpdatedAt || updatedAt) : updatedAt) || createdAt)}</div>
              <div className="actions">
                {isAdmin && menuOptions.length ? <MoreMenu options={menuOptions} /> : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderInvitationCard = () => {
    return (
      <div className="company">
        <div className="company-details">
          <UserPhoto
            user={members[0]}
            size={24}
            onClick={() => setShowMemberInfo(members[0])}
          />
          <div className="name" onClick={() => setShowMemberInfo(members[0])}>
            {renderMemberName(members[0])}
          </div>
        </div>
        <div className="company-additional">
          <div className="left-side">
            {renderStatus()}
            {renderAction()}
          </div>
          <div className="right-side">
            {isStartup ? <div className="permissions">{permissions > enums.Permissions.Profile ? 'Analytics' : 'Profile'}</div> : null}
            <div className="date">{Converters.toShortDateTime(createdAt)}</div>
            <div className="actions">
              {isAdmin && menuOptions.length ? <MoreMenu options={menuOptions} /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteStartup = () => {
    if (candidateForDeletion && company && company.id) {
      InvestorsAPI.deleteWatchlistCompany(company.id).then(() => {
        setCompany(null);
        setCandidateForDeletion(false);
      });
    } else {
      setCandidateForDeletion(false);
    }
  };

  const renderPromptDelete = () => {
    if (candidateForDeletion && company && company.id) {
      return (
        <Confirm
          open
          title={`Remove from watchlist?`}
          yesText="Remove"
          noText="Cancel"
          onClose={() => setCandidateForDeletion(false)}
          onYes={deleteStartup}
        >
          <StyledWatchlistDeleteConfirmation>
            Are you sure you would like to remove the startup '{company.name}'
            from your watchlist?
            <span>
              This action will remove all data associated to the startup, and
              you would lose access to it
            </span>
          </StyledWatchlistDeleteConfirmation>
        </Confirm>
      );
    }
    return null;
  };

  const saveStartup = (id, startupData) => {
    if (id) {
      InvestorsAPI.updateWatchlistCompany(id, startupData).then(
        ({ success, data, error }) => {
          if (success) {
            setCompany(data);
            setSelectedStartup(false);
          } else {
            setError(error);
          }
        }
      );
    }
  };

  const renderWatchlistCard = () => {
    return (
      <>
        {renderPromptDelete()}
        {selectedStartup ? (
          <AddEditStartupPopup
            startup={company}
            onClose={() => {
              setSelectedStartup(null);
              setError(null);
            }}
            onSave={saveStartup}
            error={error}
          />
        ) : null}
        <div data-testid={"companyFlag"} className="company">
          <div
            className="company-details"
            onClick={isSupportUser ? navigateToAnalytics : navigateToOrganizationProfile}
          >
            <CompanyLogo company={company} size={24} />
            <div className="name">
              <strong>{name}</strong>
            </div>
          </div>
          <div className="company-additional">
            <div className="left-side">
              {renderStatus()}
              {renderAction()}
            </div>
            <div className="right-side">
              <div className="date">{Converters.toShortDateTime(supportRequested ? (supportUpdatedAt || updatedAt) : updatedAt)}</div>
              <div className="actions">
                {isAdmin && menuOptions.length ? <MoreMenu options={menuOptions} /> : null}
              </div>
            </div>
          </div>
        </div>
        {shouldOpenDatafilesDialog ? <DataFilesDialog
          className="datafiles-dialog"
          open
          watchlistId={id}
          onClose={onCloseDataFilesDialog}
          onSubmitSupportRequest={updateCompanyDataAfterSupport}
        /> : null}
        {shouldOpenPreferencesDialog ? <FullWidthDialog
          title="Settings"
          open
          onClose={() => setShowPreferencesDialog(false)}
        >
          <StyledPreferencesDialog>
            <PreferencesCard
              watchlistId={id}
              onSaveComplete={() => setShowPreferencesDialog(false)}
            />
          </StyledPreferencesDialog>
        </FullWidthDialog> : null}
      </>
    );
  };

  const renderCompanyCard = () => {
    if (isInternal)
      return renderWatchlistCard();
    if (aclId || name)
      return renderCompany();
    return renderInvitationCard();
  };

  const renderSupportRequestSuccess = () => {
    if (showSubmitConfirmation)
      return <SubmitSupportRequestMsgBox open onClose={() => setShowSubmitConfirmation(false)} />;
    return null;
  };

  const renderRevokeSupportRequestConfirmation = () => {
    if (showRevokeSupportConfirmation)
      return <Confirm open yesText="Revoke request" noText="Cancel" title="Revoke support request" onYes={revokeNovvoSupport} onClose={() => setShowRevokeSupportConfirmation(false)}>
        Your support request will be revoked. You can always submit a new support request.
      </Confirm>;
    return null;
  };

  const renderShowNeedToRevokeBeforeGenerateDialog = () => {
    if (showNeedToRevokeBeforeGenerateDialog) {
      return <Confirm open yesText="Generate analytics" noText="Cancel" title="Generate analytics" onYes={() => {revokeNovvoSupport(); setShowOpenDatafilesDialog(true); setShowNeedToRevokeBeforeGenerateDialog(false);} } onClose={() => setShowNeedToRevokeBeforeGenerateDialog(false)}>
        <StyledWatchlistDeleteConfirmation>
          A support request has been submitted for this company. The 'Generate analytics' button will revoke the support request and allow you to generate analytics.
        </StyledWatchlistDeleteConfirmation>
      </Confirm>;
    }
    return null;
  }

  const menuOptions = getMenuOptions();

  return (
    <StyledCompanyCard isStartup={isStartup}>
      {renderSupportRequestSuccess()}
      {renderRevokeSupportRequestConfirmation()}
      {renderShowNeedToRevokeBeforeGenerateDialog()}
      {renderCompanyCard()}
      {renderMemberInfoDialog()}
      <Error error={error} />
      {showEditPermissionsDialog ? (
        <PermissionsEditor
          permissions={permissions}
          open
          onClose={() => setShowEditPermissionsDialog(false)}
          onChange={(newPermissions) => changePermissions(newPermissions)}
        />
      ) : null}
      {showRevokeConnection ? <Confirm
        open
        title={isDemo ? `Remove from watchlist?` : status === enums.InvitationStatus.Pending ? 'Cancel invitation' : `Disconnect ${isStartup ? 'investor' : 'company'}`}
        yesText={isDemo ? 'Remove' : 'Confirm'}
        onYes={() => revoke()}
        noText={'Cancel'}
        onClose={() => setShowRevokeConnection(false)}>
        {isDemo ?
          <StyledWatchlistDeleteConfirmation>
            Are you sure you would like to remove the startup '{company.name}'
            from your watchlist?
            <span>
              This action will remove all data associated to the startup, and
              you would lose access to it
            </span>
          </StyledWatchlistDeleteConfirmation>
          : status === enums.InvitationStatus.Pending ? `By clicking confirm you are cancelling the invitation to connect to '${name || company.members[0]?.email}'` : isStartup ? `Disconnecting an investor will revoke all sort of permission and remove '${name}' from the investor watchlist` : `Disconnecting a company will revoke all sort of permission and remove '${name}' from the company watchlist`}
      </Confirm> : null}
    </StyledCompanyCard>
  );
}
