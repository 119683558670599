import axios from 'axios';
import BaseApi from './base-api';

class UsersAPI extends BaseApi{
  constructor() {
    super('/users');
  }

  getCurrentUser() {
    return axios.get(`${this.baseUrl}/me`);
  }

  updateCurrentUser(data) {
    return axios.patch(`${this.baseUrl}/me`, data);
  }

  updateCurrentUserImage(data) {
    return axios.patch(`${this.baseUrl}/me`, this.getFormData(data), { headers: {'Content-Type': 'multipart/form-data' }});
  }

  getAll() {
    return axios.get(`${this.baseUrl}`);
  }

  getById(id) {
    return axios.get(`${this.baseUrl}/${id}`);
  }



  update(id, data) {
    return axios.patch(`${this.baseUrl}/${id}`, data);
  }

  updateMyOrganization(data) {
    return this.patch('organization', data);
  }

  updateMyOrganizationImages(data) {
    return this.patch('organization', this.getFormData(data), { headers: {'Content-Type': 'multipart/form-data' }});
  }

  updateMyOrganizationFiles(files) {
    return this.patch('organization', this.getFormData(files), { headers: {'Content-Type': 'multipart/form-data' }});
  }

  updateMany(users) {
    return axios.post(`${this.baseUrl}/bulk`, users);
  }

  create(data) {
    return axios.post(`${this.baseUrl}`, data);
  }

  invite(data) {
    return axios.post(`${this.baseUrl}/invite`, data);
  }

  delete(id) {
    return axios.delete(`${this.baseUrl}/${id}`);
  }

  getMyTeam() {
    return this.get('team');
  }

  getMyConnections() {
    return this.get('connections');
  }

  revokeConnection(orgId) {
    return this.patch(`connections/${orgId}/revoke`);
  }

  changeConnectionType(orgId, invitationId, newType) {
    return this.patch(`connections/type`, { orgId, invitationId, newType });
  }

  inviteTeammate(name, email, role, note, invitationId) {
    return this.post(`/team/invite`, { invitationId, name, email, role, note });
  }

  changeTeammateRole(userId, invitationId, role) {
    return this.patch(`/team/role`, { userId, invitationId, role });
  }

  revokeTeammate(userId) {
    return this.patch(`/team/${userId}/revoke`);
  }

  reenableTeammate(userId) {
    return this.patch(`/team/${userId}/reenable`);
  }

  revokeTeammateInvitation(invitationId) {
    return this.patch(`/team/invitations/${invitationId}/revoke`);
  }

  rejectInvitation(invitationId) {
    return this.patch(`invitations/${invitationId}/reject`);
  }

  revokeInvitation(invitationId) {
    return this.patch(`invitations/${invitationId}/revoke`);
  }

  acceptInvitation(invitationId) {
    return this.patch(`invitations/${invitationId}/accept`);
  }

  updateCompanyPermissions(orgId, invitationId, permissions) {
    return this.patch(`acl/permissions`, { orgId, invitationId, permissions });
  }

  getMyInvitationsReceived() {
    return this.get('invitations/received');
  }

  inviteCompany(invitationData) {
    return this.post(`company/invite`, invitationData);
  }

  markActivityDone(activity) {
    return this.post(`preferences`, { activity });
  }

}

export default new UsersAPI();
