import { createGlobalStyle } from 'styled-components';

const MuiStyle = createGlobalStyle`

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      height: 40px;
      font-size: 14px;
      background: white;

      .MuiSelect-root {
        &:focus {
          background-color: unset;
        }
      }

      .MuiSelect-icon {
        top: unset;
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0 0 0 0.4rem rgb(0 161 222 / 20%);
        outline: 0;
        border-color: rgba(0, 161, 222, 0.8);
        border-width: 1px;
      }
    }

    .MuiInputLabel-formControl.MuiInputLabel-shrink  {
      font-size: 1.4rem;
      z-index: 1;
    }

    &.MuiTextField-root {
      .MuiInputLabel-formControl.MuiInputLabel-shrink  {
        top: unset;
        left: unset;
      }
    }
  }

  .MuiPaper-root.MuiDialog-paper {
    overflow-y: unset;
  }

`;

export default MuiStyle;
