import BaseApi from './base-api';

class StartupsAPI extends BaseApi{
  constructor() {
    super('/startups');
  }

  getCompanyDescription(companyId) {
    return this.get(`company/${companyId}/description`);
  }

  getFieldsMappings() {
    return this.get('mapping');
  }

  updateFieldsMappings(sourceFileType, mappings) {
    return this.post('mapping', { sourceFileType, mappings });
  }

  fetchBreakdownRecords(filters) {
    return this.post(`kpi/breakdown/records`, { filters });
  }

  getFilters(startupId, sortByLogos = true) {
    const sortBy = sortByLogos ? 'customers' : 'mrr';
    return this.get(`filters`, { sortBy });
  }

  getUploadedDataFiles() {
    return this.get('startup-data/data-files/list');
  }

  getDataFilesStatus() {
    return this.get('startup-data/data-files/status');
  }

  getDataReadiness() {
    return this.get(`analytics/readiness`);
  }

  getInsights() {
    return this.get(`insights`);
  }

  getRawDataStatus() {
    return this.get('startup-data/raw/status');
  }

  loadRawData(options) {
    return this.post(`startup-data/raw/load`, options);
  }

  readDataFile(fileName, sourceFileType, sheet) {
    return this.post('data-files/read', {fileName, sourceFileType, sheet});
  }

  saveFileTemplate(sourceFileType, fileName, selectedSheet, mapping) {
    return this.post('data-files/template', {sourceFileType, fileName, selectedSheet, mapping});
  }

  generateData(sourceFileType, selectedFile, selectedSheet, fileMapping, fieldsMapping) {
    return this.post('data-files/generate', {sourceFileType, selectedFile, selectedSheet, fileMapping, fieldsMapping});
  }

  regenerateData() {
    return this.post('data-files/regenerate');
  }

  submitForReview() {
    return this.post('data-files/submit');
  }

  updatePreferences(preferences) {
    return this.patch('preferences', preferences);
  }

  getDownloadUrl(filename) {
    return `${process.env.REACT_APP_API_URL || ''}${this.baseUrl}/startup-data/data-files/download/${encodeURIComponent(filename)}`;
  }

}

export default new StartupsAPI();


