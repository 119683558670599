import React, {useEffect, useState} from "react";
import KpiDataExplanation from "./index";
import Loading from "../Loading/Loading";
import {KpisAPI} from "../../api";

export function KpiDataExplanationPanel({ kpiType, startupId, watchlistId, segmentBy, filters = {} }) {
  const [kpiResult, setKpiResult] = useState(null);
  const [isLoading, setIisLoading] = useState(false);

  useEffect(() => {
    setIisLoading(true);
    KpisAPI.getKpi(startupId, watchlistId, kpiType, {segmentBy, includeDataExplanation: true}, filters).then(({success, data}) => {
      if (success) {
        setKpiResult(data);
      }
      setIisLoading(false);
    });
  }, [startupId, watchlistId, kpiType, segmentBy]);

  if (isLoading)
    return <div><Loading size="medium">Loading data...</Loading></div>;

  if (!kpiResult)
    return <div>No data</div>;

  const onChartDownload = async () => {
    return KpisAPI.downloadChartData(startupId, watchlistId, kpiType, {segmentBy, includeDataExplanation: true}, filters);
  };

  return (
    <KpiDataExplanation
      segmentBy={segmentBy}
      kpiType={kpiType}
      kpiResult={kpiResult}
      onChartDownload={onChartDownload}
      autoFormatValues={false}
    />
    );
}

