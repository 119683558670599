import styled from 'styled-components';

export const StyledDataFilesPanel = styled.div`
  width: 100%;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span.link {
    color: ${props => props.theme.colors.link};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: underline;
    }
  }

  .error-message {
    margin-bottom: 20px;
  }

  .panel-header {
    font-size: 18px;
    font-weight: bold;

    .panel-header-title {
      display: flex;
      align-items: center;
      >div.first-action {
        width: 40px;
      }

      .title {
        margin-right: auto;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    .wizard-steps {
      margin-top: 20px;
    }


  }

  .tab-content {
    max-height: 800px;
    overflow: auto;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    min-height: 32px;

    .edit {
      cursor: pointer;
    }

    h2 {
      margin-bottom: unset;
      margin-right: auto;
    }

    .MuiSvgIcon-root {
      font-size: 2.0rem;
      color: #00c4c3;
    }
  }

  .content {
    height: calc(100% - 59px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    margin: 10px 0;
    &:not(.w-error) > div {
      height: 100%;
    }
   .fields-definition-cnt {
      width: 1000px;
      height: 100%;
      .fields-definition-error {
        span {
          color: ${(props) => props.theme.colors.link};
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &.w-error {
      .fields-definition-cnt {
        height: calc(100% - 90px);
      }
    }
    .error-info {
      margin: 5px 0;
      a > * {
        vertical-align: middle;
      }
    }
  }

  .loading-indication i {
    margin-right: 10px;
  }

  .data-files-container {
    .data-item {
      display: grid;
      grid-template-columns: 300px 1fr 1fr 120px 50px;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 0;
      color: #888;
      font-size: 12px;

      &.header {
        color: #aaa;
        margin-bottom: unset;
      }

      .item-name {
        font-weight: bold;
        color: black;
        font-size: 14px;

        .comment {
          font-size: 10px;
          color: #aaa;
        }
      }

      .item-status {
        .connected {
          font-weight: bold;
          color: ${props => props.theme.colors.green};
        }
      }

      .item-source-file {
      }

      .item-date {
      }

    }
    .trigger {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${props => props.theme.colors.link};
      svg {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .timestamp {
      margin-top: 3px;
      font-size: 11px;
      color: ${props => props.theme.colors.label};
    }
  }

  .button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.link};
    margin-top: 10px;
  }

`;

export const StyledWizard = styled.div`

  .wizard-steps {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

    .explanation {
      > span:first-child {
        font-weight: normal;
        a svg {
          vertical-align: middle;
        }
      }

      .sub-text {
        font-weight: normal;
        color: ${props => props.theme.colors.linkhover};
        font-size: 12px;
      }
    }
  }

  .wizard-content {
    overflow: auto;
    height: 100%;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;

  .nav-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > button {
      margin-left: 0;
    }
    .error-txt {
      font-size: 11px;
    }
    .next-button {
      margin-right: 15px;
    }
  }
`;
