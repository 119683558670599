import styled from 'styled-components';

export const StyledAddEditStartupPopup = styled.div`
   .member-info-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }

  .startup-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 14px;
    > .container {
      width: 40vw;
      background: white;
      border-radius: 10px;
      padding: 20px 20px 0 20px;
      > h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0 0 25px 0;
        font-size: 18px;
        svg {
          cursor: pointer;
          color: ${props => props.theme.colors.label};
          font-size: 35px;
          padding: 5px;
          &:hover {
            background: #eee;
            border-radius: 50%;
          }
        }
      }
      .popup-content {
        display: flex;
        justify-content: space-between;
        > .startup-content {
          display: flex;
          justify-content: space-between;
          width: 100%;
          > .name {
            width: 65%;
          }
          > div:last-child {
            text-align: center;
            margin-right: 20px;
            .container {
              width: 112px;
              height: 112px;
              border: 1px solid #ccc;
              border-radius: 4px;
            }
          }
          .form__label {
            font-size: 11px;
          }
        }
      }
      .footer {
        display: flex;
        align-items: center;
        color: #9b9b9b;
        margin: 40px 0 20px 0;
        .note {
          margin-left: 10px;
          font-size: 11px;
        }
        .error {
          margin-left: 10px;
          font-size: 11px;
          color: ${props => props.theme.colors.error};
        }
      }
    }
  }
`;


