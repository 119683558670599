/* eslint-disable */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { userLogoutAsync, switchOrganizationAsync } from "../../../../../asyncActions/authAsyncActions";
import { useNavigate } from "react-router-dom";
import ClickOutsideHelper from "../../../../helpers/ClickOutsideHelper";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/user.svg";
import { StyledToolbarMenu } from "./ToolbarMenu.style";
import routes from '../../../../../pages/routes';
import UserPhoto from "../../../../UserPhoto";

const ToolbarMenu = ({ user, mode }) => {
  const isStartup = user?.isStartupMode;
  const isSupportUser = user?.isSupportUser;
  const isExternalUser = !isSupportUser && user?.isExternalUser;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  ClickOutsideHelper(wrapperRef, () => setIsOpen(false));
  const dispatch = useDispatch();
  const currentLocation = window.location.pathname + window.location.hash;
  const handleLogout = () => {
    dispatch(userLogoutAsync());
    navigate('/');
  };

  const handleSwitchOrganization = (orgId) => {
    dispatch(switchOrganizationAsync(orgId));
    navigate('/');
  };

  const handleNavigate = (route) => {
    navigate(route, {state: {from_path: currentLocation}});
    setIsOpen(false);
  };

  return (
    <StyledToolbarMenu
      className={`menu ${isOpen ? "menu--open" : ""}`}
      mode={mode}
      ref={wrapperRef}
    >
      <button
        className="menu__opener"
        data-testid={"accountIcon"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <UserIcon />
      </button>
      <div className="menu__list">
        <ul>
          <li className="title">ACCOUNT</li>
          <li className="user">
            <UserPhoto user={user} />
            <div className="user__details">
              <span className="user__name">{user.name}</span>
              <span className="user__email">{user.email}</span>
            </div>
          </li>
          {!isSupportUser && user?.Organizations.length > 1 ? <li className="action sub-menu" onMouseEnter={(e) => e.target.className += ' active'} onMouseLeave={(e) => e.target.className = 'action sub-menu'}>
            <span>Switch account</span>
            <ul>
              {user.Organizations.filter(o => o.id !== user.organizationId).map(o =>
                <li key={`switch_${o.id}`} className="action" onClick={() => handleSwitchOrganization(o.id)}>
                <span>{o.name}</span>
              </li>)}
            </ul>
          </li> : null}
          {!isSupportUser ? <>
          <li className="action" onClick={() => handleNavigate(isStartup ? routes.Startups.MyAccount : routes.Investors.MyAccount)}>
            <span>Manage account</span>
          </li>
          <li className="action" onClick={() => handleNavigate(isStartup ? routes.Startups.Profile : routes.Investors.Profile)}>
            <span>Company profile</span>
          </li>
          {!isExternalUser ? <li className="action" onClick={() => handleNavigate(isStartup ? routes.Startups.UsersManagement : routes.Investors.UsersManagement)}>
            <span>Team management</span>
          </li> : null}
          </> : null}
          <li onClick={handleLogout} className="action separator">
            <span>Log out</span>
          </li>
        </ul>
      </div>
    </StyledToolbarMenu>
  );
};

ToolbarMenu.propTypes = {
  user: PropTypes.object,
};

export default ToolbarMenu;
