import styled from 'styled-components';

export const StyledTabs = styled.div`
  .header {
    display: flex;
    > div {
      padding: 10px 20px;
      font-weight: bold;
      cursor: pointer;
    }

    .selected {
      color: ${props => props.theme.colors.brand};
      border-bottom: 3px solid;
      border-bottom-color: ${props => props.theme.colors.brand};
    }
  }

  .tab-content {
    padding: 20px 10px;
    //max-height: 200px;
    overflow: auto;

    > div {
      display: none;

      &.selected {
        display: unset;
      }
    }
  }
`

export const StyledTab = styled.div`
  padding: 22px 16px;
  color: ${props => props.isSelected ? props.theme.colors.primary : props.theme.colors.legend};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  ${props => props.isSelected ? `border-bottom: 4px solid ${props.theme.colors.primary};` : ''};
  user-select: none;
`
