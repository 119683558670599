import React, { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { StyledInviteCompany } from './InviteCompany.style';
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { InvestorsAPI, UsersAPI } from "../../../api";
import PageTitle from "../../../components/PageTitle";
import {useFormInput} from "../../../hooks";
import {validateEmail} from "../../../utils/validators";
import enums from "../../../utils/enums";
import Select from "../../../components/Select";
import {useSelector} from "react-redux";
import Error from "../../../components/Error";
import IndustriesSelector from "../../../components/IndustriesSelector";
import routes from '../../routes';


function InviteCompany() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [busy, setBusy] = useState(false);
  const [formError, setFormError] = useState('');
  const invitationId = location.state?.invitationId;
  const connectionType = location.state?.connectionType || enums.ConnectionType.Prospect;
  const defaultInvitationMode = location.state?.invitationMode || false;
  const isStartup = user?.isStartupMode;
  const from_path = location.state?.from_path || (isStartup ? `${routes.Startups.Network}#${connectionType}` : `${routes.Investors.Network}#${connectionType}`);
  const [isInvitationMode, setInvitationMode] = useState(isStartup || defaultInvitationMode);

  // const recipientName = useFormInput(location.state?.contactName);
  const permissions = useFormInput(location.state?.permissions || enums.PermissionOptions.ProfileOnly.value);
  const name = useFormInput(location.state?.name || '');
  const email = useFormInput(location.state?.email || '');
  // const companyName = useFormInput(location.state?.companyName);
  const note = useFormInput(location.state?.note || '');
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    if (!user.isAdmin)
      navigate('/');
  }, [user, navigate]);

  const isEmailValid = () => {
    if (email.value) {
      const validation = validateEmail(email.value);
      return validation.valid;
    }
    return false;
  };

  const getFormError = () => {
    let isValid = true;
    if (!isInvitationMode && !name.value)
      isValid = false;
    if (isInvitationMode && !email.value)
      isValid = false;
    if (email.value) {
      const validation = validateEmail(email.value);
      if (!validation.valid)
        return validation.error;
    }
    return isValid ? '' : 'Please fill in all mandatory fields';
  };

  const validateForm = () => {
    const error = getFormError();
    setFormError(error);
    return !error;
  };

  const getPermissionsComment = () => {
    for (let p in enums.PermissionOptions) {
      if (enums.PermissionOptions[p].value === permissions.value)
        return enums.PermissionOptions[p].comment;
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setBusy(true);
      if (email.value && isInvitationMode) {
        const postData = {invitationId, name: name.value, email: email.value, note: note.value, connectionType, permissions: permissions.value};
        UsersAPI.inviteCompany(postData).then(({success, error}) => {
          if (success) {
            navigate(-1);
          } else {
            setFormError(error.toString());
          }
          setBusy(false);
        });
      } else if (!isStartup && !isInvitationMode) {
        InvestorsAPI.addWatchlistCompany({ name: name.value, connectionType }).then(({success, data, error}) => {
          if (success) {
            navigate(from_path, {state: {open_file_dialog: data.id}});
          } else {
            setFormError(error.toString());
          }
          setBusy(false);
        });
      }
    }
  };

  const handleKeypress = (e) => {
    if (e.target.type !== 'textarea' && e.charCode === 13) {
      handleSubmit();
    }
  };

  const renderFormInput = (label, obj, mandatory, attrs = {}) => {
    const onKeypress = attrs.type !== 'textarea' ? handleKeypress : undefined;
    return <div className="input-container">
      <TextInput
        mandatory={mandatory}
        label={label}
        onKeyPress={onKeypress}
        {...obj}
        {...attrs}
      />
    </div>;
  };

  const renderIndustriesSelector = () => {
    return <div className="input-container">
      <IndustriesSelector
            error={formError && !industries.length}
            label={'Industry'}
            selectedIndustries={[]}
            placeholder={'e.g. FinTech'}
            onChange={(values) => setIndustries(values.join(','))}
          />
    </div>;
  };

  const getTitle = () => {
    if (isStartup)
      return `Send an invitation to ${connectionType === enums.ConnectionType.Current ? 'current' : 'prospect'} investor`;

    return `Company details`;
  };

  const sendDisabled = busy || !!getFormError();

  return (
    <StyledInviteCompany>
      <PageTitle icon="close" variant="h1" lineheight="1" path={from_path}>{getTitle()}</PageTitle>
      <div>
        {isInvitationMode && isStartup ?
          <p>Send an invitation to connect securely and ask for permissions to access the company's full profile and live analytics.</p> : null}
        {isInvitationMode ? renderFormInput('Email', email, true, {focus: isInvitationMode}) : null}
        {renderFormInput((isStartup ? `Please specify the investor name` : isInvitationMode ? 'Company name' : 'Name'), name, !isInvitationMode, {focus: !isInvitationMode})}
        {isStartup &&
          <>
            <div className="input-container">
              <Select label="Permissions" {...permissions}>
                <option
                  value={enums.PermissionOptions.ProfileOnly.value}>{enums.PermissionOptions.ProfileOnly.text}</option>
                <option
                  value={enums.PermissionOptions.ProfileAndAnalytics.value}>{enums.PermissionOptions.ProfileAndAnalytics.text}</option>
              </Select>
            </div>
            <div className="permissions-comment">{getPermissionsComment()}</div>
          </>
        }
        {/*{!isInvitationMode && renderIndustriesSelector()}*/}
        {/*{!isInvitationMode && renderFormInput('Location', loc, false, {placeholder: 'e.g. San Francisco'})}*/}
        {isInvitationMode && renderFormInput('Personal note (optional)', note, false, {type: 'textarea', maxLength: 200, disabled: !isEmailValid()})}
        <p className="input-container">
          {isStartup ? <>
            The invitation will be shown in your {connectionType === enums.ConnectionType.Prospect ? 'prospect' : 'current'} investors list and an invitation will be sent to <em>{email.value}</em> once you click the ‘send invitation’ button.
          </> : <>
            The invitation will be shown in your {connectionType === enums.ConnectionType.Prospect ? 'prospect' : 'portfolio'} companies list and an invitation will be sent to <em>{email.value}</em> once you click the 'send invitation' button.
          </>}
        </p>
        <div className="send-action">
          <Button className="btn-filters-opener" disabled={sendDisabled} onClick={handleSubmit}>{isInvitationMode ? 'Send invitation' : 'Upload files'}</Button>
          {!isInvitationMode ? <span>Don’t have the files? <span className="link" onClick={() => setInvitationMode(true)}>Invite</span> the company to upload data</span> : null}
        </div>
        {formError && <Error className="form__error" error={formError} />}
      </div>
    </StyledInviteCompany>
  );
}

export default InviteCompany;
