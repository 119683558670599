import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import Header from '../Header/Header';
import {StyledMasterSecured} from './Master.style'
import routes from '../../../pages/routes';
import { switchOrganizationAsync } from "../../../asyncActions/authAsyncActions";

const Master = ({children, layout, mode}) => {
  const {user} = useSelector((state) => state.auth);
  const isFullScreen = layout === "fullScreen";
  const dispatch = useDispatch();

  const getToolbarItems = () => {
    if (isFullScreen)
      return [];

    const items = [];

    const isSupportUser = user?.isSupportUser;
    const isExternalUser = !isSupportUser && user?.isExternalUser;

    const switchToSupport = () => {
      if (isSupportUser)
        dispatch(switchOrganizationAsync(user.Organizations.find(o => o.type === 'Support').id));
    };

    if (user?.isStartupMode) {
      if (isSupportUser) {
        items.push({to: routes.Support.Home, name: 'Support requests', handler: switchToSupport});
        items.push({to: routes.Startups.getStartupAnalyticsUrl(), name: 'Analytics', exact: true});
      } else if (!isExternalUser) {
        items.push(...[
          {to: routes.Startups.getStartupAnalyticsUrl(), name: 'Analytics', exact: true},
          {to: routes.Startups.Network, name: 'Network', exact: true}
        ]);
      }
    } else if (user?.isInvestorMode) {
      if (isSupportUser) {
        items.push({to: routes.Support.Home, name: 'Support requests', handler: switchToSupport});
        items.push({to: routes.Investors.Network, name: 'Watchlist', exact: true});
      }
    }

    if (user.isAdmin) {
      // items.push({to: routes.Admin.Team, name: 'Team', exact: true});
    }

    return items.filter(item => item !== null);
  };

  return (
    <StyledMasterSecured className="master-theme" mode={mode}>
      <Header items={getToolbarItems()} layout={layout} mode={mode} />
      <div className="content">
        <aside/>
        {children}
      </div>
    </StyledMasterSecured>
  );
};

Master.propTypes = {
  children: PropTypes.element.isRequired,
};

Master.defaultProps = {};

export default Master;
