import React, { useState } from "react";
import { StyledDatePicker } from "./DatePicker.style";
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import PropTypes from 'prop-types';
import moment from 'moment';

export default function DatePicker({date = new Date(), dateFormat,  onChange, maxDate = null, minDate = null}) {
  const [value, setValue] = useState(date);

  const onDateChanged = (value) => {
    setValue(value);
    if (onChange) {
      const date = value.toDate ? value : moment(value, dateFormat);
      if (date.isValid())
        onChange(date.toDate());
    }
  };

  const isValidDate = (potentialDate) => {
    if (maxDate || minDate) {
      const max = moment(maxDate);
      const min = moment(minDate);
      const date = moment(potentialDate);
      if (maxDate && minDate)
        return max >= date && min <= date;
      if (maxDate)
        return max >= date;
      return min <= date;
    }
    return true;
  };

  return (
    <StyledDatePicker>
      <DateTime
        dateFormat={dateFormat}
        timeFormat={false}
        closeOnSelect
        isValidDate={isValidDate}
        onChange={onDateChanged}
        value={value} />
    </StyledDatePicker>
  );
}

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
  onChange: PropTypes.func
};

DatePicker.defaultProps = {
  date: new Date(),
  dateFormat: 'DD-MM-YYYY',
  onChange: null
};
