import styled from 'styled-components';

export const StyledNotReadyPanel = styled.div`
  padding: 15px 10px 10px 10px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 14px;
    line-height: 3rem;
  }
  .link {
    color: ${props => props.theme.colors.link};
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: underline;
    }
  }
  > div {
    box-shadow: none;
    padding: 0 0 10px 10px;
    .content {
      margin: 0 0 30px 0;
      padding: 0;
    }
    .header {
      > div {
        padding: 16px 0;
      }
    }
  }
`;

