import styled from 'styled-components';

export const StyledMasterSecured = styled.div`
  background: ${(props) => props.mode === "dark" ? props.theme.colors['background-color-dark-mode'] : props.theme.colors['background-color']};
  ${props => props.mode === "dark" && `
    .typography_root {
       color: ${props.theme.colors.white};
    }
  `}


  a:link,
  a:visited {
      color:$c-brand;
      text-decoration:none;
  }
  a:hover {text-decoration: underline;}

  .content {
    max-width: ${props => props.theme.general.xxlBreakpoint};
    padding: 2.4rem 2.4rem;
    @media only screen and (min-width: ${(props) =>
        props.theme.general.lgBreakpoint}) {
      padding-left: 8rem;
    }

    //display: flex;
    aside {
      width: 5rem;
      min-width: 5rem;
      display: flex;
      justify-content: center;
      img {
        padding-top: 12px;
      }
    }
  }
`

