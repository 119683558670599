import styled from 'styled-components';

export const StyledCustomersTable = styled.div`
  margin-top: 20px;
  max-height: 600px;
  overflow: auto;

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.link {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: ${props => props.theme.colors.link};
      cursor: pointer;
      font-weight: normal;
      svg {
        margin-right: 5px;
      }
      &:hover {
        color: ${props => props.theme.colors.linkhover};
        text-decoration: underline;
      }
    }
  }

  .error-msg {
    text-align: center;
    color: ${props => props.theme.colors.legend};
    font-size: 13px;
    padding: 50px 0;
  }

  .title {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  > div {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 20px;
  }

  .compare-value {
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      border-radius: 3px;
      margin-right: 5px;
      min-width: 12px;
      height: 12px;
      width: 12px;
    }
  }

  tbody td, th {
    text-align: left !important;
  }
`;

