import styled from 'styled-components';

export const StyledGiveFeedbackDialog = styled.div`
  padding: 2px 10px;
  iframe {
    border: 0;
    box-shadow: 5px 5px 56px 0 rgba(0,0,0,0.25);
  }
`;

