import React, {useState, useEffect} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types'
import Converters from '../../utils/converters'
import Typography from "../Typography";
import cx from "classnames";
import {StyledCohortChart} from './CohortChart.style';

export default function CohortChart({ data, metadata, hideTootips, onChartClick, onCohortSelected, selectedSeriesKey, height, title, hideTitle, onClick }) {
    const [selectedCohort, setSelectedCohort] = useState(selectedSeriesKey);

  useEffect(() => {
    setSelectedCohort(selectedSeriesKey);
  }, [selectedSeriesKey]);

  const handleAreaClick = (cohort) => {
    if (onCohortSelected) {
      const actualCohort = cohort === selectedCohort ? null : cohort;
      onCohortSelected(actualCohort);
      setSelectedCohort(actualCohort);
    }
  };

  const dimensions = height ? {height} : {aspect:2};
  const classes = {
    selectable: !!onCohortSelected,
    hasSelection: !!selectedCohort
  };

  return (
    <StyledCohortChart className={!!onClick ? 'clickable' : null} mainColor={metadata?.palette?.primary} onClick={onClick}>
      {!hideTitle ? <Typography variant="h3">{title}</Typography> : null}
      <ResponsiveContainer className={cx('area-chart', classes)} width="99%" {...dimensions} >
        <AreaChart
          onClick={onChartClick}
          data={data}
          stroke={"#9B9B9B"}
          fontSize={'12px'}
        >
          <CartesianGrid vertical={false}/>
          <XAxis tick={{ fontSize: '12px', width: 30, textAnchor: "middle", verticalAnchor:"start" }} dataKey="name" axisLine={false} tickLine={false} stroke={"#9B9B9B"}/>
          <YAxis tickFormatter={(val) => Converters.toCurrencyString(val)} tick={{ fontSize: '12px' }} axisLine={false} tickLine={false} stroke={"#9B9B9B"} />
          {!hideTootips && <Tooltip formatter={(val, key) => [Converters.toCurrencyString(val), metadata?.cohorts.find(key => key === key).label || key]}/>}
          {metadata?.cohorts.map((meta, index) => (
            <Area key={`${meta.key}_area_${index}`}
                  className={cx({selected: meta.key.split('[')[0] === selectedCohort})}
                  type={"monotone"}
                  dataKey={meta.key}
                  onClick={() => handleAreaClick(meta.key.split('[')[0])}
                  stackId={1}
                  stroke={meta.color}
                  fill={meta.color}
            />
          ))}
        </AreaChart>
       </ResponsiveContainer>
    </StyledCohortChart>
  );
}

CohortChart.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.object,
  hideTootips: PropTypes.bool,
  onChartClick: PropTypes.func,
  onCohortSelected: PropTypes.func
};

CohortChart.defaultProps = {
  data: [],
  metadata: [],
  hideTootips: false
};
