import styled from 'styled-components';

export const StyledUsersManagement = styled.div`
  .h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .page-content {
    display: flex;
    margin: 15px 0;

    .main {
      flex: 1;
      margin: 0 30px 0 0;
    }

    .suggestions {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      width: 250px;
    }
  }

`;

