const PREFERENCES_KEY = 'preferences';
const _preferencesDefaults = {
  currency: 'USD'
};

const setOrganizationPreferences = (preferences) => {
  window.sessionStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences));
  // set('preferences', preferences);
};

const getOrganizationPreferences = () => {
  let preferences = window.sessionStorage.getItem(PREFERENCES_KEY);
  if (preferences === null)
    preferences = _preferencesDefaults;
  else
    preferences = JSON.parse(preferences);
  return preferences;
};

const get = (preferenceName) => {
  const preferences = getOrganizationPreferences();
  return preferences[preferenceName];
};

const set = (preferenceName, value) => {
  const preferences = getOrganizationPreferences();
  preferences[preferenceName] = value;
  window.sessionStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences));
};

export default {
  get,
  set,
  getOrganizationPreferences,
  setOrganizationPreferences
}
