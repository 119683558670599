import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import { StyledTeamListPanel } from './team-list-panel.style';
import {UsersAPI} from "../../../api";
import enums from "../../../utils/enums";
import UserPhoto from "../../../components/UserPhoto";
import Converters from "../../../utils/converters";
import cx from 'classnames';
import MoreMenu from "../../../components/MoreMenu";
import routes from "../../routes";
import { ArrowDropDown } from "../../../assets/mui-icons";
import MemberPopup from "../../../components/MemberPopup";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import { ErrorMsgBox } from "../../../components/dialogs";

export default function TeamListPanel() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [team, setTeam] = useState([]);
  const [error, setError] = useState('');
  const [showMemberInfo, setShowMemberInfo] = useState(null);
  const isAdmin = user?.isAdmin && !user?.isExternalUser;
  const isStartup = user?.isStartupMode;
  const companyRoutes = isStartup ? routes.Startups : routes.Investors;

  useEffect(() => {
    UsersAPI.getMyTeam().then(({ success, data, error }) => {
      if (success) {
        setTeam(data);
      } else {
        setError(error);
      }
    });
  }, [UsersAPI]);

  const changeRole = (member, newRole) => {
    UsersAPI.changeTeammateRole(member.id, member.invitationId, newRole).then(({ success, data, error }) => {
      if (success) {
        member.role = newRole;
        setTeam([...team]);
      } else {
        setError(error);
      }
    });
  };

  const resendInvitation = (member) => {
    const email = member?.email;
    const name = member?.name;
    const role = member?.role;
    const note = member?.note;
    const invitationId = member?.invitationId;
    navigate(routes[isStartup ? 'Startups' : 'Investors'].InviteTeammate, {state: {invitationId, email, name, role, note}});
  };

  const revoke = (member) => {
    if (member.id) {
      UsersAPI.revokeTeammate(member.id).then(({success, data, error}) => {
        if (success) {
          member.status = enums.InvitationStatus.Revoked;
          setTeam([...team]);
        } else {
          setError(error);
        }
      });
    }
    else {
      UsersAPI.revokeTeammateInvitation(member.invitationId).then(({success, data, error}) => {
        if (success) {
          setTeam([...team.filter(m => m.invitationId !== member.invitationId)]);
        } else {
          setError(error);
        }
      });
    }
  };

  const reenableTeammate = (member) => {
    UsersAPI.reenableTeammate(member.id).then(({success, data, error}) => {
      if (success) {
        member.status = enums.InvitationStatus.Accepted;
        setTeam([...team]);
      } else {
        setError(error);
      }
    });
  };

  const getMenuOptions = (member) => {
    const options = [];
    switch (member.status) {
      case enums.InvitationStatus.Pending:
        options.push({ label: 'Revoke', onClick: () => revoke(member)});
        options.push({ label: 'Resend invitation', onClick: () => resendInvitation(member)});
        options.push({ label: 'Change invitee', onClick: () => resendInvitation()});
        break;
      case enums.InvitationStatus.Accepted:
        options.push({ label: 'Revoke', onClick: () => revoke(member)});
        break;
      case enums.InvitationStatus.Revoked:
        options.push({ label: 'Enable', onClick: () => reenableTeammate(member)});
        break;
    }
    return options;
  };

  const getRoleOptions = (member) => {
    const roles = [
      {label: 'Administrator', value: 'admin', onClick: () => changeRole(member, 'admin')},
      {label: 'User', value: 'user', onClick: () => changeRole(member, 'user')}
    ];
    return roles;
  };

  const getStatusText = (member) => {
    switch (member.status) {
      case enums.InvitationStatus.Pending: return 'Invited';
      case enums.InvitationStatus.Accepted: return 'Active';
      case enums.InvitationStatus.Revoked: return 'Revoked';
    }
  };

  const renderMemberInfoDialog = () => {
    return <MemberPopup user={showMemberInfo} organizationName={user.organizationName} onClose={() => setShowMemberInfo(null)} />;
  };

  const renderMemberName = (member) => {
    return <strong onClick={() => setShowMemberInfo(member)}>{member.name || member.email} {member.id === user.id ? '(me)' : ''}</strong>;
  };

  const getRoleText = (member) => {
    const isSupport = member.isSupportUser;
    if (isSupport)
      return member.role;
    const isExternalAdmin = member.role === 'ExternalAdmin' || member.role === 'admin' && member.isExternalUser;
    if (isExternalAdmin)
      return 'Data manager';
    return ['admin', 'superuser'].includes(member.role) ? 'Administrator': 'User';
  };

  const renderTeamMember = (member, index) => {
    const isSupport = member.isSupportUser;

    const isRoleChangeAllowed = isAdmin && member.id !== user.id && !member.isExternalUser && member.role !== 'ExternalAdmin';
    return (
      <div key={`member_${index}`} className="user-card">
        <UserPhoto user={member} size={'md'} className={isSupport ? 'support' :  undefined} />
        <div className="name">
          {renderMemberName(member)}
          <span>{member.title}</span>
        </div>
        <div className="status-cnt">
          <div className={cx("status", member.status)}>{getStatusText(member)}</div>
        </div>
        <div className="role">
          <div>{getRoleText(member)}</div>
          {isRoleChangeAllowed && <MoreMenu options={getRoleOptions(member)} triggerIcon={<ArrowDropDown />} />}
        </div>
        <div className="date">{Converters.toShortDateTime(member.createdAt)}</div>
        <div className="member-menu">
          {isAdmin && member.id !== user.id && !isSupport ? <MoreMenu options={getMenuOptions(member)} /> : null}
        </div>
      </div>
    );
  };

  return (
    <StyledTeamListPanel>
      <div className="card">
        <div className="header">
          <Typography variant="h2">Team members</Typography>
          {isAdmin ? <div className="edit"><Button size="small" onClick={() => navigate(companyRoutes.InviteTeammate)}>Invite teammate</Button></div> : null}
        </div>
        {renderMemberInfoDialog()}
        <div className="team-list">
          <div className="headers user-card">
            <div className="name">Name</div>
            <div className="status-cnt">Status</div>
            <div className="role">Role</div>
            <div className="date">Updated</div>
            <div className="member-menu" />
          </div>
          {team.map((member, index) => renderTeamMember(member, index))}
        </div>
        {error ? <ErrorMsgBox error={error} open title="Error" onClose={() => setError('')} /> : null }
      </div>
    </StyledTeamListPanel>
  );
}

