import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as Icons from "../../assets/mui-icons";

export default function IconButtonComponent ({ iconName, icon, onClick, tooltip, className, placement, arrow, disabled=false }) {
  const Icon = icon || Icons[iconName];
  const btn = <IconButton className={className} onClick={onClick} disabled={disabled} >
    <Icon />
  </IconButton>;

  if (tooltip) {
    return <Tooltip title={tooltip} placement={placement} arrow={arrow}>
      {btn}
    </Tooltip>;
  }

  return btn;
}

