import styled from 'styled-components';

export const StyledCompanyNavigation = styled.div`
  a,
  div.disabled {
    span {
      display: none;
    }
    strong {
      display: none;
    }
  }
`;

