import styled from 'styled-components';

export const StyledInviteCompany = styled.div`
  width: 700px;
  h1.h1 {
    margin-bottom: 3rem;
  }
  .input-container {
    width: 400px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin-bottom: 30px;
    em {
      font-style: normal;
      color: #666;
    }
    &.new-section {
      margin-top: 5rem;
      margin-bottom: 10px;
      font-size: 1.4rem;
    }
  }

  textarea {
    height: 70px;
  }

  .permissions-comment {
    margin: -5px 0 10px 0;
    color: #00c4c3;
    font-size: 1.2rem;
  }

  .form__error {
    margin: 1.2rem 0;
    padding: 0.6rem 1.2rem;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.error};
    background-color: rgba(213, 60, 33, 0.05);
    border-radius: 4px;
    width: 400px;
  }

  .send-action {
    margin-top: 40px;
    > span {
      font-size: 12px;
      margin-left: 20px;
      .link {
        color: ${props => props.theme.colors.link};
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

  }
`;

