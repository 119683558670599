import BaseApi from './base-api';
import axios from "axios";

class InvestorsAPI extends BaseApi{
  constructor() {
    super('/investors');
  }

  getCompanyDescription(companyId) {
    return this.get(`company/${companyId}/description`);
  }

  fetchBreakdownRecords(filters, companyId) {
    return this.post(`company/${companyId}/kpi/breakdown/records`, { filters });
  }

  getFilters(startupId, watchlistId, sortByLogos = true) {
    const sortBy = sortByLogos ? 'customers' : 'mrr';
    if (watchlistId)
      return this.get(`watchlist/${watchlistId}/filters`, { sortBy });
    return this.get(`company/${startupId}/filters`, { sortBy });
  }

  getDataReadiness(startupId) {
    return this.get(`company/${startupId}/analytics/readiness`);
  }

  getWatchlistCompanies() {
    return this.get('watchlist');
  }

  getWatchlistCompany(watchlistId) {
    return this.get(`watchlist/${watchlistId}`);
  }

  getInsights(startupId, watchlistId) {
    if (watchlistId)
      return this.get(`watchlist/${watchlistId}/insights`);
    return this.get(`company/${startupId}/insights`);
  }

  addWatchlistCompany(companyData) {
    return this.post('watchlist', this.getFormData(companyData), { headers: {'Content-Type': 'multipart/form-data' }});
  }

  updateWatchlistCompany(id, companyData) {
    return this.patch(`watchlist/${id}`, this.getFormData(companyData), { headers: {'Content-Type': 'multipart/form-data' }});
  }

  deleteWatchlistCompany(id) {
    return this.delete(`watchlist/${id}`);
  }

  getUploadedDataFiles(id) {
    return this.get(`watchlist/${id}/data-files/list`);
  }

  getDataFilesStatus(watchlistId) {
    return this.get(`watchlist/${watchlistId}/data-files/status`);
  }

  saveFileTemplate(sourceFileType, fileName, selectedSheet, mapping, watchlistId) {
    return this.post(`watchlist/${watchlistId}/data-files/template`, {sourceFileType, fileName, selectedSheet, mapping});
  }

  readDataFile(fileName, sourceFileType, sheet, watchlistId) {
    return this.post(`watchlist/${watchlistId}/data-files/read`, {fileName, sourceFileType, sheet});
  }

  generateData(sourceFileType, selectedFile, selectedSheet, fileMapping, fieldsMapping, watchlistId) {
    return this.post(`watchlist/${watchlistId}/data-files/generate`, {sourceFileType, selectedFile, selectedSheet, fileMapping, fieldsMapping});
  }

  getWatchlistDescription(watchlistId) {
    return this.get(`watchlist/${watchlistId}/description`);
  }

  getWatchlistDataReadiness(watchlistId) {
    return this.get(`watchlist/${watchlistId}/analytics/readiness`);
  }

  regenerateData(watchlistId) {
     return this.post(`watchlist/${watchlistId}/regenerate`);
  }

  updatePreferences(preferences, watchlistId, startupId) {
    if (watchlistId)
      return this.patch(`watchlist/${watchlistId}/preferences`, preferences);
    else
      return this.patch(`company/${startupId}/preferences`, preferences);
  }

  getDownloadUrl(filename, watchlistId) {
    return `${process.env.REACT_APP_API_URL || ''}${this.baseUrl}/watchlist/${watchlistId}/data-files/download/${encodeURIComponent(filename)}`;
  }

}

export default new InvestorsAPI();


