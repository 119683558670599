import React from "react";
import { StyledMyAccount } from "./MyAccount.style";
import PageTitle from "../../../components/PageTitle";
import UserDetailsPanel from "../../../components/UserDetailsPanel";
import Recommendations from "../../../components/Recommendations";
import { useLocation } from "react-router-dom";

export default function MyAccount() {
  const location = useLocation();
  const from_path = location.state?.from_path || `/`;

  return (
    <StyledMyAccount>
      <PageTitle icon="close" variant="h1" lineheight="1" path={from_path}>Account</PageTitle>
      <div className="page-content">
        <div className="groups">
        </div>
        <div className="main">
          <UserDetailsPanel />
        </div>
        <div className="suggestions">
          <Recommendations type="investors" />
        </div>
      </div>
    </StyledMyAccount>
  );
};
