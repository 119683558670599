import React, {useEffect, useState} from "react";
import { StyledCompanyNavigation } from './CompanyNavigation.style';
import NavigationPanel from "../../../components/NavigationPanel";
import {useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import routes from "../../routes";
import enums from "../../../utils/enums";
import {ReactComponent as ProfileIcon} from '../../../assets/icons/i-building.svg';
import {ReactComponent as SaaSMetrics} from '../../../assets/icons/i-cloud.svg';
import {ReactComponent as PNL} from '../../../assets/icons/i-balance.svg';
import Tooltip from "@mui/material/Tooltip";

export default function CompanyNavigation({className, currentSelection, showProfile, showAnalytics}) {
  const { id: companyId, watchlistId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const [selectedGroup, setSelectedGroup] = useState(currentSelection || 'DESCRIPTION');

  useEffect(() => {
    setSelectedGroup(currentSelection);
  }, [currentSelection]);

  const getAnalyticsCaption = (analyticsKind) => {
    switch (analyticsKind) {
      case enums.AnalyticsKind.SaasMetrics: return 'SaaS metrics';
      case enums.AnalyticsKind.PnL: return 'P&L metrics';
      case enums.AnalyticsKind.BalanceSheet: return 'Balance sheet';
      case enums.AnalyticsKind.CapTable: return 'Cap table';
      case enums.AnalyticsKind.CashFlow: return 'Cashflow';
      case enums.AnalyticsKind.SnM_Pipeline: return 'S&M pipeline';
      case enums.AnalyticsKind.HR: return 'HR';
      default: return '';
    }
  };

  const getAnalyticsIcon = (analyticsKind) => {
    switch (analyticsKind) {
      case enums.AnalyticsKind.SaasMetrics: return <Tooltip title={getAnalyticsCaption(analyticsKind)} arrow placement="left-start"><SaaSMetrics /></Tooltip>;
      case enums.AnalyticsKind.PnL: return <Tooltip title={getAnalyticsCaption(analyticsKind)} arrow placement="left-start"><PNL /></Tooltip>;
      default: return null;
    }
  };

  const getAnalyticsOption = (analyticsKind) => {
    let redirectTo;
    if (isStartup || watchlistId) {
      if (isStartup)
        redirectTo = routes.Startups.getStartupAnalyticsUrl(analyticsKind);
      else if (watchlistId)
        redirectTo = routes.Investors.getWatchlistAnalyticsUrl(watchlistId, analyticsKind);
    } else if (companyId) {
      redirectTo = routes.Investors.getStartupAnalyticsUrl(companyId, analyticsKind);
    }

    if (redirectTo)
      return { label: getAnalyticsCaption(analyticsKind), icon: getAnalyticsIcon(analyticsKind), redirectTo };
  };

  const getNavigationOptions = () => {
    const options = {};

    if (!isStartup && showProfile) {
      options['DESCRIPTION'] = {
        icon: <Tooltip title="Company overview" arrow placement="left-start"><ProfileIcon /></Tooltip>,
        label: 'Company description',
        redirectTo: watchlistId ? routes.Investors.getWatchlistUrl(watchlistId) : routes.Investors.getStartupUrl(companyId)
      };
    }

    if (showAnalytics) {
      options[enums.AnalyticsKind.SaasMetrics] = getAnalyticsOption(enums.AnalyticsKind.SaasMetrics);
      options[enums.AnalyticsKind.PnL] = getAnalyticsOption(enums.AnalyticsKind.PnL);
    }
    // options.CASH_FLOW = getAnalyticsOption(enums.AnalyticsKind.CashFlow);
    // options.BALANCE_SHEET = getAnalyticsOption(enums.AnalyticsKind.BalanceSheet);
    // options.SNM_PIPELINE = getAnalyticsOption(enums.AnalyticsKind.SnM_Pipeline);
    // options.CAP_TABLE = getAnalyticsOption(enums.AnalyticsKind.CapTable);
    // options.HR = getAnalyticsOption(enums.AnalyticsKind.HR);

    return options;
  };

  const navigationOptions = getNavigationOptions();

  return (
    <StyledCompanyNavigation className={className}>
      <NavigationPanel
        options={Object.keys(navigationOptions).map(key => {return {value: key, ...navigationOptions[key]};})}
        selectedValue={selectedGroup}
        onSelect={(value) => {
          if (value !== selectedGroup)
            setSelectedGroup(value);
      }} />
    </StyledCompanyNavigation>
  );
}
