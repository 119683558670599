import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {StyledChartSettingsPanel} from './ChartSettingsPanel.style';
import CheckboxFilters from "../ChecboxFilters";
import Converters from "../../utils/converters";
import {useSelector} from "react-redux";
import {InvestorsAPI, StartupsAPI} from "../../api";
import { FilterAltOutlinedIcon } from "../../assets/mui-icons";
import General from "../../utils/general";

const UNKNOWN_NAME = '@UNKNOWN_NAME@';

const prepareFiltersOptions = (options) => {
  return options.reduce((acc, cur) => {
    const totalValue = cur.data.reduce((acc, item) => acc + item.value, 0);
    acc[cur.segmentBy] = {
      segmentBy: cur.segmentBy,
      fieldDataType: cur.fieldDataType,
      valueType: cur.valueType,
      filterByLabel: cur.filterByLabel,
      options: cur.data.map(option => {
        const name = option.segment;
        let label = name === UNKNOWN_NAME ? 'Unknown' : name;
        if (option.hasOwnProperty('min') || option.hasOwnProperty('max'))
          label = Converters.rangeToString(option.min, option.max, cur.fieldDataType);
        label = <span className="filter-value-container">{label}{/*<span className="list-item-percentage">{cur.valueType === 'currency' ? Converters.toCurrencyString(option.value) : option.value}</span>*/}</span>;
        return {
          label,
          name,
          min: option.min,
          max: option.max
        };
      })
    };
    return acc;
  }, {});
};

function generateCriteriaItems(fieldDataType, leftOperand, operator, selectedOptions) {
  const result = [];
  if (['number', 'currency', 'percent'].includes(fieldDataType) && operator === 'In' && selectedOptions.some(option => option.hasOwnProperty('min') || option.hasOwnProperty('max'))) {  // case of values like ['0-25', '26-50']
    const should = [];
    selectedOptions.forEach(option => {
      if (option.hasOwnProperty('min') || option.hasOwnProperty('max')) {
        should.push({ leftOperand, operator: 'Between', rightOperand: [option.min, option.max] });
      } else {
        should.push({ leftOperand, operator: 'Equals', rightOperand: option.name });
      }
    });

    result.push({ should });
  } else {
    result.push({leftOperand, operator, rightOperand: selectedOptions.map(option => option.name)});
  }
  return result;
}

function createFiltersCriteria(filters, filtersOptions, allowOptimization) {
  let criteria;
  Object.keys(filters).filter(key => !['period', 'periodRange'].includes(key)).forEach(key => {
    const filter = filters[key];
    if (filter && filter.length && filtersOptions[key]) {
      const {fieldDataType, options} = filtersOptions[key];
      let selectedOptions = options.filter(o => filter.includes(o.name));
      if (allowOptimization && options.length === selectedOptions.length)
        selectedOptions = [];
      let operator = 'In';
      if (filter.includes('Other')) {
        operator = 'NotIn';
        selectedOptions = options.filter(o => !filter.includes(o.name));
      }
      if (allowOptimization && operator === 'In' && selectedOptions.length > options.length/2) {
        operator = 'NotIn';
        selectedOptions = options.filter(o => !filter.includes(o.name));
      }
      if (selectedOptions.length) {
        if (!criteria)
          criteria = {must: []};

        criteria.must.push(...generateCriteriaItems(fieldDataType, key, operator, selectedOptions));
      }
    }
  });
  return criteria;
}


ChartSettingsPanel.propTypes = {
  startupId: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

export default function ChartSettingsPanel({startupId, watchlistId, onChange, settings = {}, onFiltersReady, supportsFilters, compareBySegment: initialCompareBySegment = null}) {
  const {user} = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const API = isStartup ? StartupsAPI : InvestorsAPI;
  const [busy, setBusy] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [filters, setFilters] = useState(settings);
  const [filtersOptions, setFiltersOptions] = useState();
  const [sortByLogos, setSortByLogos] = useState(true);
  const [applyTimeout, setApplyTimeout] = useState(-1);
  const [compareBySegment, setCompareBySegment] = useState(initialCompareBySegment);

  useEffect(() => {
    if (initialCompareBySegment !== compareBySegment)
      setCompareBySegment(initialCompareBySegment);
  }, [initialCompareBySegment]);

  useEffect(() => {
    if (!General.isEqualObject(filters, settings))
      setFilters(settings);
  }, [settings]);

  useEffect(() => {
    setBusy(true);
    if (supportsFilters) {
      API.getFilters(startupId, watchlistId, sortByLogos).then(({success, data}) => {
        if (success) {
          const options = prepareFiltersOptions(data);
          setFiltersOptions(options);
        }
        setBusy(false);
      });
    } else {
      setFiltersOptions({});
      setBusy(false);
    }
  }, [startupId, watchlistId, sortByLogos, supportsFilters]);

  useEffect(() => {
    if (filtersOptions && !dataReady) {
      applyFilters(null, compareBySegment);
      setDataReady(true);
      if (onFiltersReady)
        onFiltersReady(filtersOptions);
    }

  }, [filtersOptions, dataReady]);

  const applyFilters = (currentFilters, newCompareBySegmentValue) => {
    if (onChange) {
      currentFilters = currentFilters || filters;

      onChange({
        // period: currentFilters.period,
        // periodRange: currentFilters.periodRange,
        criteria: createFiltersCriteria(currentFilters, filtersOptions, !newCompareBySegmentValue),
        compareBySegment: newCompareBySegmentValue
      }, currentFilters);
    }
  };

  if (busy || !dataReady) {
    return null;
  }

  const autoApplyFilters = (newFiltersState, newCompareBySegmentValue) => {
    clearTimeout(applyTimeout);
    const timer = setTimeout(() => {
      applyFilters(newFiltersState, newCompareBySegmentValue);
    }, 10 );
    setApplyTimeout(timer);
  };

  const updateFiltersOnSelection = (filterName, checked, selectedValues, isCompare) => {
    const newCompareBySegmentValue = isCompare ? filterName : filterName === compareBySegment ? null : compareBySegment;
    setCompareBySegment(newCompareBySegmentValue);
    let filterValues = [...(filters[filterName] || [])];
    if (checked)
      filterValues.push(...selectedValues.filter(v => !filterValues.includes(v)));
    else
      filterValues = filterValues.filter(v => !selectedValues.includes(v));

    const newFiltersState = {
      ...filters,
      [filterName]: filterValues
    };

    setFilters(newFiltersState);

    // Disable in case we decide to use a button to apply the changes
    autoApplyFilters(newFiltersState, newCompareBySegmentValue);
  };

  const renderList = (segmentBy, index) => {
    const { filterByLabel, options } = filtersOptions[segmentBy];
    return (
      <div key={`filter-list-${index}`}>
        <CheckboxFilters className="list"
                         title={filterByLabel}
                         values={filters[segmentBy]}
                         options={options}
                         allowCollapse={false}
                         isDefaultOpen={true}
                         maxVisibleItems={5}
                         isCompareModeSet={compareBySegment === segmentBy}
                         onChange={(checked, values, isCompareMode) => updateFiltersOnSelection(segmentBy, checked, values, isCompareMode)}/>
      </div>
    );
  };

  const filterKeys = Object.keys(filtersOptions);

  if (!filterKeys.length)
    return null;

   const getFiltersIndicator = () => {
    const numFilters = Object.keys(filters).filter(field => filters[field].length > 0).length;
    return <div className={"filters" + (numFilters > 0 ? ' active' : '')}>
      <FilterAltOutlinedIcon /> Segments {numFilters > 0 ? <span>({numFilters})</span> : null}
    </div>;
  };

  return (
    <StyledChartSettingsPanel className="settings">
      {getFiltersIndicator()}
      {filterKeys.map(renderList)}
    </StyledChartSettingsPanel>
  );
}

