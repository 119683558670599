const channel = window.BroadcastChannel ? new BroadcastChannel("auth") : null;
export default {
  postMessage: (message) => {
    channel && channel.postMessage(message);
  },
  subscribe: (handler) => {
    channel && channel.addEventListener("message", handler);
  },
  unsubscribe: (handler) => {
    channel && channel.removeEventListener("message", handler);
  }
};
