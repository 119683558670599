import React from 'react';
import Dialog from '../Dialog';

export default function FullWidthDialog({ open, onClose, className, title, showCloseIcon= true, children }){
  return <Dialog
    open={open}
    fullWidth
    header={title}
    className={className}
    onClose={onClose}
    showCloseIcon={showCloseIcon}
    // modal={false}
    // maxContentHeight='600px'
    maxWidth="lgg"
  >
    {children}
  </Dialog>;
};

