import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { StyledTabs, StyledTab } from './Tabs.style'
import cx from 'classnames';


function Tab({ selected, children }) {
  if (!selected)
    return null;
  return <div className="selected">
    {children}
  </div>;
}

export default function Tabs({ selectedTab:initialTabSelection = null, children, onChange, className }) {
  const [selectedTab, setSelectedTab] = useState(initialTabSelection);
  const allowedChildren = children.filter(c => !!c?.props?.caption);
  const captions = allowedChildren.map(c => c.props.caption);
  const tabs = allowedChildren.map(c => c.props.children);

  const handleTabSelection = (tabCaption) => {
    if (tabCaption === selectedTab)
      return;

    setSelectedTab(tabCaption);
    if (onChange)
      onChange(tabCaption);
  };

  useEffect(() => {
    setSelectedTab(initialTabSelection);
  }, [initialTabSelection]);

  useEffect(() => {
    if (selectedTab && !captions.includes(selectedTab))
      setSelectedTab(null);
  }, [captions]);

  const isSelected = (index) => {
    if (!selectedTab || !captions.includes(selectedTab))
      return index === 0;
    const caption = captions[index];
    return caption === selectedTab;
  };

  return (
    <StyledTabs className={className}>
      <div className="header">
        {captions.map((caption, index) => <div key={`header-${index}`} className={cx({selected: isSelected(index)})} onClick={() => handleTabSelection(caption)}>{caption}</div>)}
      </div>
      <div className="tab-content">
        {tabs.map((tab, index) => <Tab key={`tab-${index}`} caption={captions[index]} selected={isSelected(index)}>{tab}</Tab>)}
      </div>
    </StyledTabs>
  );
}

Tabs.propTypes = {
  selectedTab: PropTypes.number,
  children: PropTypes.arrayOf(Tab),
  className: PropTypes.string,
  onChange: PropTypes.func
};

Tab.propTypes = {
  caption: PropTypes.string.isRequired
};

Tabs.Tab = Tab;
