import React, { useEffect } from "react";
import { StyledFileUploadHelp } from './file-upload-help.style';
import PageTitle from "../../../components/PageTitle";
import Typography from "../../../components/Typography";
import { DownloadOutline } from "../../../assets/mui-icons";
import { useLocation } from "react-router-dom";

export default function FileUploadHelp() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element)
          element.scrollIntoView({block: "start", inline: "nearest"});
      }, 500);
    }
  }, [location]);

  return (
    <StyledFileUploadHelp>
      <PageTitle variant="h1" lineheight="1">Novvo file upload tips</PageTitle>
      <div className="page-content">
        <div className="templates-download">
          <Typography variant="h3">Download templates</Typography>
          <div>
            <a href="https://static.novvo.ai/help/upload/templates/MRR%20Template.xlsx" target="_blank"><DownloadOutline /> <span>MRR</span></a>
            <a href="https://static.novvo.ai/help/upload/templates/Booking%20Template.xlsx" target="_blank"><DownloadOutline /> Booking</a>
            <a href="https://static.novvo.ai/help/upload/templates/Income%20Statement%20Template.xlsx" target="_blank"><DownloadOutline /> P&L</a>
            {/*<a href="javascript:" target="_blank"><DownloadOutline /> Cash flow</a>*/}
            {/*<a href="javascript:" target="_blank"><DownloadOutline /> Balance sheet</a>*/}
          </div>
        </div>
        <div className="main">
          <Typography variant="h2">Supported data formats</Typography>
          <p>MRR data format specifies a list of customers along with their spending over time. It may contain more details about the customers and their spending (e.g. customer location, product plan).</p>
          <p>Here is an example of how MRR data format may look like.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-Example.png" alt="MRR example" /></p>
          <p>Booking data format is basically a log of purchase orders. Each purchase order must specify at least the customer ID, subscription start date, subscription period (or subscription end date) and the amount (can be specified as MRR or ARR). It may contain more details about the customers and their spending (e.g. customer location, product plan). </p>
          <p>Here is an example of how Booking data format may look like.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-Example.png" alt="Booking example" /></p>
          <p>P&L data format is a standard financial statement format, also called P&L. Sales and Marketing expenses must be separated from the rest of the operating expenses in separate rows.  </p>
          <p>Here is an example of how income statement data format may look like.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-Example.png" alt="P&L example" /></p>
          <Typography variant="h2">Generating analytics</Typography>
          <Typography variant="h3">Stage 1: Uploading</Typography>
          <p className="no-margin">To get the base SaaS metrics the first stage is to upload a <a href="#mrr-data-loading">MRR</a> or <a href="#booking-data-loading">Booking</a> file and <a href="#income-statement-data-loading">P&L</a> in Excel or csv formats. <a href="#cash-flow-data-loading">Cash flow data</a> is required to conduct ‘what if’ analysis for debt use case and <a href="#balance-sheet-data-loading">balance sheet data</a> is required to conduct ‘what if’ analysis on MRR line use case.</p>
          <Typography variant="h3">Stage 2: Mapping</Typography>
          <p className="no-margin">Mapping is the process of associating cells in the file to internal data structure so the platform knows how to read it. Throughout the process you’ll be required to select rows, columns or individual cells.</p>
          <Typography variant="h3">Stage 3: Format</Typography>
          <p className="no-margin">Lastly, you’ll be required to specify the format of a few data sets (e.g. date format) so the system will know how to handle it properly. Once this step is completed you’ll be able to view analytics and conduct through analysis of the data.</p>

          <Typography id="mrr-data-loading" variant="h2" className="new-section">MRR data loading</Typography>
          <p>The MRR file provide a list of customers with their MRR over months. By viewing the file, one can tell when new customer has joined, what is the MRR from that customer in each month, was there any Upsell or Downsell and much more. Click the link to download <a href="https://static.novvo.ai/help/upload/templates/Booking%20Template.xlsx">MRR template</a>.</p>
          <p>The figure below show an example of MRR file with the terminology used to map it throughout the loading process. While it show the required and optional fields the system is flexible to accept files with richer data as long as the format of the required fields is as described here.</p>
          <p id="mrr-data_rows"><strong>‘Data Range’</strong> is the range of rows that contains all the data with column headers excluded (e.g. row 2 to row 20 in this example).</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-DataRange.png" alt="MRR Data Range" /></p>
          <p id="mrr-customers"><strong>‘Customer ID’</strong> is the cell containing the header of the customers column. Each customer in that column shall be unique (e.g. name or ID). We recommend to use customer name to simplify follow through the analysis.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-CustomerID.png" alt="MRR Customer ID" /></p>
          <p id="mrr-dates"><strong>‘MRR months’</strong> are the cells containing the months of the MRR column. The ‘MRR month’ must include the month and the year and can be given in variety of formats. Click the link to view all supported <a href="javascript:">date formats</a>.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-MRRMonths.png" alt="MRR Months" /></p>
          <p id="mrr-revenue_categories"><strong>‘Revenue Categories’</strong> is optional and is defined as the header cell of the revenue type column. Revenue type refer to the classification of the revenue to recurring or non-recurring components.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-RevenueType.png" alt="MRR Revenue Type" /></p>
          <p id="mrr-custom_attributes"><strong>‘Customer and Booking attributes’</strong> are optional and are defined as the headers of the columns that contain customer or booking attributes (e.g. number of licenses, subscription plan). A file may include one or more booking attributes, represented by a column for each. We highly recommend to load booking attributes as they are essential to classify customers by their booking attributes and better understand the business performance through segmentation (e.g. analyze the growth of ARR from ‘Basic’ subscription plan)</p>
          <p><img src="https://static.novvo.ai/help/upload/images/MRR-BookingAndCustomerAttributes.png" alt="MRR Customer and Booking Attributes" /></p>

          <Typography id="booking-data-loading" variant="h2" className="new-section">Booking data loading</Typography>
          <p>The Booking file provide the list of contracts along a timeline. Each line represent a customer contract along with the details of that contract (e.g. customer name, subscription starting date etc.). Click the link to download <a href="https://static.novvo.ai/help/upload/templates/MRR%20Template.xlsx">Booking template</a>.</p>
          <p>The figure below show an example of Booking file with the terminology used to map it throughout the loading process. While it shows the required, optional and alternate fields the system is flexible to accept files with richer data as long as the format of the required fields is as described here.</p>
          <p id="bookings-data_rows"><strong>‘Data Range’</strong> is the range of rows that contains all the data with column headers excluded (e.g. row 2 to row 20 in this example).</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-DataRange.png" alt="Booking Data Range" /></p>
          <p id="bookings-customers"><strong>‘Customer ID’</strong> is the cell containing the header of the customers column. Each customer in that column shall be unique (e.g. name or ID). We recommend to use customer name to simplify follow through the analysis.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-CustomerID.png" alt="Booking Customer ID" /></p>
          <p id="bookings-attribution_date"><strong>‘Start date’</strong> is the cell containing the header of the column that specifies the first day of the subscription. The date must include at least the month and the year and can be given in variety of formats. Click the link to view all supported <a href="javascript:">date formats</a>.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-StartDate.png" alt="Booking Start Date" /></p>
          <p id="bookings-subscription_months_length"><strong>‘Period’</strong> is the cell containing the header of the column that specifies the length of the subscription period, specified in number of months.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-Period.png" alt="Booking Period" /></p>
          <p id="bookings-end_date"><strong>‘End date’</strong>  is the cell containing the header of the column that specifies the last day of the subscription and can be alternate with ‘Period’ (at least one of ‘End date’ or ‘Period’ is required). The date must include at least the month and the year and can be given in variety of formats. Click the link to view all supported <a href="javascript:">date formats</a>.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-EndDate.png" alt="Booking End Date" /></p>
          <p id="bookings-revenue_columns"><strong>‘Revenue item’</strong> is a cell containing the header of the column that specifies the value of the contract. We highly recommend to have multiple ‘Revenue item’ columns to enable the classification of customers by their spending.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-RevenueItems.png" alt="Booking Revenue Items" /></p>
          <p id="bookings-revenue_categories"><strong>‘Revenue Categories’</strong> is optional and refer to the cell containing the header of the column that specifies the type of revenue by that contract. It is available for mapping only if a single ‘Revenue item’ was selected in previous step.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-RevenueType.png" alt="Revenue Type" /></p>
          <p id="bookings-tcv"><strong>‘TCV’</strong> is optional and refer to the cell containing the header of the column that specifies the Total Contract Value.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-TCV.png" alt="Booking TCV" /></p>
          <p id="bookings-custom_attributes"><strong>‘Customer and Booking attributes’</strong> are optional and are defined as the headers of the columns that contain customer or booking attributes (e.g. number of licenses, subscription plan). A file may include one or more booking attributes, represented by a column for each. We highly recommend to load booking attributes as they are essential to classify customers by their booking attributes and better understand the business performance through segmentation (e.g. analyze the growth of ARR from ‘Basic’ subscription plan)</p>
          <p><img src="https://static.novvo.ai/help/upload/images/Booking-BookingAndCustomerAttributes.png" alt="Customer and Booking Attributes" /></p>

          <Typography id="income-statement-data-loading" variant="h2" className="new-section">P&L data loading</Typography>
          <p>The income statement file is a standard GAAP report (called also P&L) and provide information about the revenue, expenses and profit. You can load ‘Actual’, ‘Planned’ and ‘Forecast’ data. Click the link to download a <a href="https://static.novvo.ai/help/upload/templates/Income%20Statement%20Template.xlsx">income statement template</a>.</p>
          <p>The figure below show an example of income statement file with the terminology used to map, Actual and Forecast data, throughout the loading process. Please click the link to learn more about <a href="#pnl-expected_dates">loading income statement with ‘Planned’ data</a>.</p>
          <p><strong>Data sets</strong><br />
            <span>‘Actual’</span> data set refer to the actual performance of the company and is based on data from operational systems.<br />
            <span>‘Forecast’</span> data set refers to the forecasted performance and is based on estimates.<br />
            <span>‘Planned’</span> data set refers to the forecasted performance of the past. In other words, a ‘Forecast’ become ‘Planned’ if it refer to past date (e.g. A ‘Forecast’ for Jan-2022 become ‘Actual’ on 1.2.2022).
          </p>
          <p id="pnl-revenue_data_rows"><strong>‘Revenue item’</strong> is a cell containing revenue item in a row that specify the value of that item foreach period. When a file contain more than one item, all items shall be selected. A row that sum the revenue items should be excluded. If there is only one ‘Revenue item’ (typically the total revenue) that cell should be selected.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-RevenueItems.png" alt="Income Statement Revenue Items" /></p>
          <p id="pnl-cogs_data_rows"><strong>‘COGS items’</strong> is/are a/ cell/s containing COGS (Cost of Sold Goods) expense in a row that specify the value of that expense foreach period. When a file contain more than one item, all items shall be selected. A row that sum the COGS expenses should be excluded. If there is only one ‘COGS item’ (typically the total COGS) that cell should be selected.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-COGSItems.png" alt="Income Statement COGS Items" /></p>
          <p id="pnl-snm_data_rows"><strong>‘Sales & Marketing’</strong> is/are a/ cell/s containing sales and marketing expense in a row/s that specify the value/s of that/those expense/s foreach period.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-SM.png" alt="Income Statement Sales & Marketing" /></p>
          <p id="pnl-other_opex_data_rows"><strong>‘OPEX item’</strong> is a cell containing operational expense in a row that specify the value of that item foreach period. When a file contain more than one item, all items shall be selected. A row that sum the OPEX items should be excluded.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-OtherOPEX.png" alt="Income Statement Other OPEX" /></p>
          {/*<p id="pnl-actual_dates"><strong>‘Actual Period’</strong> are the cells containing the period of Actual performance columns. A period can be specified in month+year, quarter+year or year.</p>*/}
          {/*<p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-ActualPeriods.png" alt="Income Statement Actual Periods" /></p>*/}
          <p id="pnl-expected_dates"><strong>‘Planned Period’</strong> are the cells containing the period of Planned performance columns. A period can be specified in month+year, quarter+year or year.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-PlannedPeriods.png" alt="Income Statement Planned Periods" /></p>
          <p id="pnl-forecast_dates"><strong>‘Forecast Period’</strong> are the cells containing the period of Forecasted performance columns. A period can be specified in month+year, quarter+year or year.</p>
          <p><img src="https://static.novvo.ai/help/upload/images/IncomeStatement-ForecastPeriods.png" alt="Income Statement Forecast Periods" /></p>

          {/*<Typography id="cash-flow-data-loading" variant="h2" className="new-section">Cash flow data loading</Typography>*/}
          {/*<p>content</p>*/}

          {/*<Typography id="balance-sheet-data-loading" variant="h2" className="new-section">Balance sheet data loading</Typography>*/}
          {/*<p>content</p>*/}

        </div>
      </div>
    </StyledFileUploadHelp>
  );
}
