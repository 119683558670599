import React, { useState } from 'react';
import PropTypes from "prop-types";
import { StyledAddEditStartupPopup } from './AddEditStartupPopup.Style'
import { Close } from "../../../../assets/mui-icons";
import Button from "../../../../components/Button";
import Converters from "../../../../utils/converters";
import ImageEditor from "../../../../components/ImageEditor/ImageEditor";
import TextInput from "../../../../components/TextInput";
import FormLabel from "../../../../components/FormLabel";
import Error from "../../../../components/Error";

const AddEditStartupPopup = ({ startup, error, onClose, onSave }) => {
  const [name, setName] = useState(startup?.name);
  const [busy, setBusy] = useState(false);
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState(startup?.logo);
  const [uploadedLogoFile, setUploadedLogoFile] = useState();

  if (!startup)
    return null;

  const onSaveClicked = () => {
    if (onSave) {
      if (name) {
        const data = {name};
        if (uploadedLogoFile)
          data.logo = uploadedLogoFile;
        setBusy(true);
        onSave(startup?.id, data);
      }
    }
  };

  return (
    <StyledAddEditStartupPopup>
      <div className="startup-popup">
        <div className="container">
          <h2><span>{startup.id ? 'Edit' : 'Add'} startup</span><Close onClick={onClose} /></h2>
          <div className="popup-content">
            <div className="startup-content">
              <div className="name">
                <div className="item">
                  <FormLabel label="Company name *" />
                  <div className="input-container">
                    <TextInput value={name} onChange={(evt) => setName(evt.target.value)} focus error={!name} disable={busy && !error} />
                  </div>
                </div>
              </div>
              <div>
                <FormLabel label="Company logo/avatar" />
                <ImageEditor
                  defaultPhotoUrl={uploadedLogoUrl}
                  maxHeight={200}
                  maxWidth={200}
                  cropAspect={1}
                  cropWidth={200}
                  cropHeight={200}
                  onChange={({file, url}) => {
                    setUploadedLogoUrl(url);
                    setUploadedLogoFile(file);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <Button className="primary" onClick={onSaveClicked} disabled={busy && !error} isLoading={busy}>Save</Button>
            {error ? <Error error={error} className="error" /> : <div className="note">* Required ∙ Last updated {Converters.toShortDateTime(startup.updatedAt)}</div>}
          </div>
        </div>
      </div>
      <div className="member-info-bg" />
    </StyledAddEditStartupPopup>
  );
};

AddEditStartupPopup.propTypes = {
  size: PropTypes.oneOf(['', 'small', 'medium'])
};

AddEditStartupPopup.defaultProps = {
  size: ''
};

export default AddEditStartupPopup;
