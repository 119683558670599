import React from "react";
import { StyledKpiDescription } from './KpiDescription.style';
import MsgBox from "../../../../components/dialogs/MsgBox";

export default function KpiDescription({ explanation, open, onClose }) {

  const explanationLines = Array.isArray(explanation) ? explanation : [explanation];

  return (
    <MsgBox
      open={open}
      showCloseIcon
      title={`How is this metric calculated?`}
      onClose={onClose}>
      <StyledKpiDescription>
        {<pre>{explanationLines.map((line, index) => <span key={`explanation_${index}`} className={line.includes('= ——————') ? 'divider' : undefined}>{line}</span>)}</pre>}
      </StyledKpiDescription>
    </MsgBox>
  );
};

