import React, { useEffect, useState } from "react";
import {StyledChartToolbar} from './ChartToolbar.style';
import enums from "../../utils/enums";
import moment from 'moment';
import Select2 from "../Select2";
import { BorderAllOutlinedIcon, DateRange, Download, Timeline } from "../../assets/mui-icons";
import {KpisAPI} from "../../api";
import cx from 'classnames';
import ErrorMsgBox from "../dialogs/ErrorMsgBox";
import Converters from "../../utils/converters";
import Tooltip from "@mui/material/Tooltip";
import FullWidthDialog from "../dialogs/FullWidthDialog";
import {KpiDataExplanationPanel} from "../KpiDataExplanation/KpiDataExplanationPanel";
import {Confirm} from "../dialogs";

const FORECAST_COMMENT = ' (Forecast)';
const PARTIAL_COMMENT = ' (Partial)';


export default function ChartToolbar({
                                     startupId,
                                     watchlistId,
                                     className,
                                     displayOptions,
                                     chartOptions,
                                     onChartDisplayOptionChanged,
                                     onDisplayOptionChange,
                                     dateRange,
                                     analyticsKind,
                                     periodMinimalResolution = 'Q',
                                     hideActions = false,
                                     isFiscalYear = false,
                                     initialChartDisplayOption = 'both',
                                     benchmarkOptions
                                     }) {


  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [downloadError, setDownloadError] = useState();
  const [selectedChartDisplayOption, setSelectedChartDisplayOption] = useState(initialChartDisplayOption);
  const [showPnlDataDialog, setShowPnlDataDialog] = useState(false);
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false);
  const [defaultOffset, setDefaultOffset] = useState(getDefaultOffset(dateRange, displayOptions, isFiscalYear));

  useEffect(() => {
    const def = getDefaultOffset(dateRange, displayOptions, isFiscalYear);
    setDefaultOffset(def);
  }, [dateRange. isFiscalYear, displayOptions]);

  useEffect(() => {
    if (initialChartDisplayOption !== selectedChartDisplayOption) {
      setSelectedChartDisplayOption(initialChartDisplayOption);
      if (onChartDisplayOptionChanged)
        onChartDisplayOptionChanged({displayKeyFilter: initialChartDisplayOption});
    }
  }, [initialChartDisplayOption]);

  useEffect(() => {
    if (onDisplayOptionChange) {
      onDisplayOptionChange({periodRange: displayOptions?.periodRange, period: displayOptions?.period, offset: defaultOffset});
    }
  }, [defaultOffset]);

  const getPeriodOptions = () => {
    if (periodMinimalResolution === enums.Period.Monthly)
      return enums.PERIODS;
    else if (periodMinimalResolution === enums.Period.Quarterly)
      return enums.PERIODS.filter(o => o.value !== enums.Period.Monthly);
    return enums.PERIODS.filter(o => o.value === enums.Period.Yearly);
  };

  const getLastNumberOfPeriodsOptions = () => {
    return [12,11,10,9,8,7,6,5,4,3,2,1];
  };

  const getChartDisplayOptions = () => {
    return chartOptions;
  };

  const onChangeBenchmark = (value) => {
    const selectedValue = benchmarkOptions.find(bm => bm.label === value);
    benchmarkOptions.forEach(op => delete op.selected);
    selectedValue.selected = true;
    if (onChartDisplayOptionChanged) {
      onChartDisplayOptionChanged({displayBenchmark: selectedValue.id});
    }
  };

  const downloadReport = () => {
    if (!isDownloadingReport) {
      setIsDownloadingReport(true);
      const filters = displayOptions?.criteria ? displayOptions : null;
      KpisAPI.downloadReport(startupId, watchlistId, filters).then(({success, error}) => {
        if (error)
          setDownloadError(error || 'Failed to generate report.');
        setIsDownloadingReport(false);
      });
    }
  };

  const renderPromptDownload = () => {
    if (showDownloadPrompt) {
      return (
        <Confirm
          open
          title="Attention"
          yesText="Download filtered report"
          noText="Cancel"
          onClose={() => setShowDownloadPrompt(false)}
          onYes={() => {
            setShowDownloadPrompt(false);
            downloadReport();
          }}
        >
          Your report will include filtered data based on the segments you have selected.
        </Confirm>
      );
    }
    return null;
  };

  return (
    <StyledChartToolbar className={className}>
      <div className="display-options">
        <div className="date-range">
          <DateRange />
          <span>Last</span>
          <Select2
            className="selector"
            options={getLastNumberOfPeriodsOptions()}
            value={displayOptions?.periodRange || 12}
            onChange={(e) => {
              if (onDisplayOptionChange) {
                const periodRange = e.target.value;
                onDisplayOptionChange({ periodRange, period: displayOptions?.period, offset: 0 });
              }
            }}
          />
          <Select2
            className="selector"
            options={getPeriodOptions()}
            value={displayOptions?.period || enums.Period.Quarterly}
            onChange={(e) => {
              if (onDisplayOptionChange) {
                const period = e.target.value;
                onDisplayOptionChange({ periodRange: displayOptions?.periodRange, period, offset: 0 });
              }
            }}
          />
          {dateRange ? <><span>ending</span>
          <Select2
            className="selector"
            options={getDateRangeOptions(dateRange, displayOptions, isFiscalYear)}
            value={displayOptions?.offset || 0}
            onChange={(e) => {
              if (onDisplayOptionChange) {
                const offset = e.target.value;
                onDisplayOptionChange({ periodRange: displayOptions?.periodRange, period: displayOptions?.period, offset });
              }
            }}
          /> </>: null}
          {chartOptions ? <div className="chart-display-options">
            <Timeline />
            <Select2
              className="selector"
              options={getChartDisplayOptions()}
              value={selectedChartDisplayOption}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedChartDisplayOption(selectedValue);
                if (onChartDisplayOptionChanged) {
                  onChartDisplayOptionChanged({displayKeyFilter: selectedValue});
                }
              }}
            /></div> : null}
          {benchmarkOptions?.length ? (
            <div className="chart-display-options">
              <Select2
                className="selector"
                options={benchmarkOptions.map(bm => bm.label)}
                value={benchmarkOptions.find(b => b.selected)?.label}
                onChange={(evt) => onChangeBenchmark(evt.target.value)}
              />
            </div>
          ) : null}
        </div>
        {/*<div className="indicators">*/}
        {/*  <IndicatorLines />*/}
        {/*  <Select2*/}
        {/*    className="selector"*/}
        {/*    options={enums.INDICATORS}*/}
        {/*    value={displayOptions?.indicators || 'NONE'}*/}
        {/*    onChange={(e) => {*/}
        {/*      if (onDisplayOptionChange) {*/}
        {/*        onDisplayOptionChange({ indicators: e.target.value });*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {hideActions && <div className="actions">
        {analyticsKind === enums.AnalyticsKind.PnL &&
          <>
            <Tooltip title="View P&L data in table format" arrow placement="top">
              <div className="action" onClick={() => {
                setShowPnlDataDialog(true);
              }}><BorderAllOutlinedIcon /><span>P&L</span></div>
            </Tooltip>
            {showPnlDataDialog ? <FullWidthDialog
              title="P&L"
              open
              onClose={() => setShowPnlDataDialog(false)}
            >
              <KpiDataExplanationPanel kpiType={KpisAPI.Types.PnlBreakdown} startupId={startupId} watchlistId={watchlistId} filters={displayOptions || {}} />
            </FullWidthDialog> : null}
          </>}
        <a className={cx("download-report", {disabled: isDownloadingReport})} onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (displayOptions?.criteria)
            setShowDownloadPrompt(true);
          else
            downloadReport();
        }} href={'#'} target={"_self"}>
          <Download /><span>{isDownloadingReport ? 'Generating...' : 'Download'}</span>
          {downloadError ? <ErrorMsgBox title={String(downloadError).includes('Generating this report can take up to a few hours.') ? 'You\'re all set!' : "Error generating report"} open error={downloadError} onClose={() => {setDownloadError(null); setIsDownloadingReport(false);} } /> : null}
        </a>
      </div>}
      {renderPromptDownload()}
    </StyledChartToolbar>
  );
}

const generateDateRangeOption = (dateRange, maxDate, endDate, periodFormat, periodUnits, isFiscalYear) => {
  const options = [];
  const lastActualDate = moment(dateRange.lastActualDate).add(dateRange.fiscalOffset, 'months').endOf('month').toDate();
  let currentDate = moment(maxDate);
  do {
    let label = currentDate.format(periodFormat);
    const startOfCurrentPeriod = currentDate.startOf(periodUnits).toDate();
    const endOfCurrentPeriod = currentDate.endOf(periodUnits).toDate();
    label = isFiscalYear ? Converters.formatFiscalPeriodString(label) : Converters.formatPeriodString(label);

    if (startOfCurrentPeriod > lastActualDate) {
      label += FORECAST_COMMENT;
    }
    else if (endOfCurrentPeriod > lastActualDate) {
      label += PARTIAL_COMMENT;
    }

    options.push({label, value: options.length} );
    currentDate = currentDate.subtract(1, periodUnits);
  } while (currentDate.toDate() >= endDate)
  return options;
};


const getDateRangeOptions = (dateRange, displayOptions, isFiscalYear) => {
  if (dateRange) {
    const period = displayOptions?.period;
    switch (period) {
      case enums.Period.Yearly: {
        let year = moment(dateRange.maxDate).add(dateRange.fiscalOffset, 'months').endOf('year');
        const endDate = moment(dateRange.minDate).add(dateRange.fiscalOffset, 'months').startOf('year').toDate();
        return generateDateRangeOption(dateRange, year, endDate, 'YYYY', 'year', isFiscalYear);
      }

      case enums.Period.Quarterly: {
        let quarter = moment(dateRange.maxDate).add(dateRange.fiscalOffset, 'months').endOf('quarter');
        const endDate = moment(dateRange.minDate).add(dateRange.fiscalOffset, 'months').startOf('quarter').toDate();
        return generateDateRangeOption(dateRange, quarter, endDate, 'YYYY-[Q]Q', 'quarter', isFiscalYear);
      }

      case enums.Period.Monthly: {
        let month = moment(dateRange.maxDate).endOf('month');
        const endDate = moment(dateRange.minDate).startOf('month').toDate();
        return generateDateRangeOption(dateRange, month, endDate, 'YYYY-MM', 'month', isFiscalYear);
      }
    }
  }
  return [];
};

const getDefaultOffset = (dateRange, displayOptions, isFiscalYear) => {
  const options = getDateRangeOptions(dateRange, displayOptions, isFiscalYear);
  const firstNonForecastOption = options.find(o => !o.label.endsWith(FORECAST_COMMENT));
  return firstNonForecastOption ? firstNonForecastOption.value : 0;
};

