import React from "react";
import PropTypes from "prop-types";
import { StyledSingleRadio } from "./SingleRadio.style";

function SingleRadio({ value, label, disabled, hasError, optionalProps, ...rest }) {
  const inputName = `input_` + Date.now();

  return (
      <StyledSingleRadio error={hasError}>
          <span className="input">
            <input type="radio" name={inputName} value={value} {...rest} {...optionalProps} />
            <svg xmlns="http://www.w3.org/2000/svg" style={{visibility: disabled ? 'hidden' : 'visible'}}>
            <path d="M8,1A7,7,0,1,1,1,8,7.00793,7.00793,0,0,1,8,1M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Z"/>
            <path d="M12,8A4,4,0,1,1,8,4,4,4,0,0,1,12,8Z"/>
            </svg>
          </span>
        {label ? <div className="radio__lbl">{label}</div> : null}
      </StyledSingleRadio>
  );
}

SingleRadio.propTypes = {
  label: PropTypes.object,
  disabled: PropTypes.bool,
  optionalProps: PropTypes.object,
  value: PropTypes.bool
};

export default SingleRadio;
