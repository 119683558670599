import styled from 'styled-components';

export const StyledPermissionsEditorsDialog = styled.div`
  height: 150px;
  width: 350px;
  padding: 20px;
  span {
    font-size: 14px;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledPermissionsEditorsDialogFooter = styled.div`
  width: 100%;
  text-align: left;
  button.button-container {
    margin: 0;
  }
`;

