import styled from 'styled-components';

export const StyledMemberPopup = styled.div`
   .member-info-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }

  .member-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 14px;
    > div {
      width: 40vw;
      background: white;
      border-radius: 10px;
      padding: 20px;
      > h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0 0 25px 0;
        font-size: 18px;
        svg {
          cursor: pointer;
          color: ${props => props.theme.colors.label};
          font-size: 26px;
        }
      }
      .member-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .member-details {
          > div {
            margin-bottom: 20px;
          }
          .form__label {
            display: inline-block;
            margin-bottom: 5px;
          }
          .email {
            display: flex;
            align-items: center;
            span {
              margin-right: 5px;
            }
          }
        }


        .member-photo {
          margin: 0 60px 0 0;

          img {
            margin-top: -50px;
          }

          &.support {
            img {
              border-radius: unset;
            }
          }


        }
      }
      .member-activity {
        color: #9b9b9b;
        margin: 40px 0 20px 0;
        font-size: 14px;
      }
    }
  }
`;


