import styled from 'styled-components';

export const StyledCohortChart = styled.div`
  width: 100%;

  &:hover.clickable {
    h3.h3 {
      text-decoration: underline;
      color: ${props => props.theme.colors['link']};
    }
    .recharts-wrapper {
      cursor: pointer !important;
    }
  }

  .area-chart {

    .recharts-area {
      path {
        fill-opacity: 1;
      }
    }

    &.selectable {
      .recharts-area {
        cursor: pointer;
        &:hover {
          path {
            stroke: ${props => props.mainColor};
            stroke-width: 2;
          }
        }
      }
    }

    &.hasSelection {
      .recharts-area {
        path {
          fill-opacity: .4;
          fill: ${props => props.mainColor};
        }

        &.selected {
          path {
            fill-opacity: 1;
          }
        }
      }
    }

  }

`;

