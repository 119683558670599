import React from "react";
import { StyledEmptyList } from "./EmptyList.style";
import { CheckCircle } from '../../assets/mui-icons';

export default function EmptyList({ text }) {
  return (
    <StyledEmptyList>
      <CheckCircle />
      <div>{text}</div>
    </StyledEmptyList>
  );
};
