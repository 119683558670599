import styled from 'styled-components';

export const StyledPageSpinner = styled.div`
  &.page-spinner {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    text-align: center;
    .caption {
      margin-top: 0.7rem;
      font-size: 0.8rem;
      color: #666;
    }
    &.logo-loading {
      .loading-indication {
        position: absolute;
        left: 0;
        right: 0;
        i {
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
      > div.logo-content {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
`


