import styled from 'styled-components';

export const StyledPublicPages = styled.div`
  display: flex;
  > div {
    width: 50%;
    height: 100vh;
    &.marketing-note {
      position: relative;
      background: #153b48;
      color: white;
      display: flex;
      align-items: center;
      svg {
        position: absolute;
        top: 20px;
        left: 20px;
        fill: white;
      }
      .marketing-content {
        padding: 50px;
        text-align: center;
        margin: auto;
        h3 {
          color: white;
          font-size: 30px;
          margin-bottom: 60px;
        }
        p {
          font-size: 20px;
          max-width: 530px;
          &.start-up {
            text-align: left;
            ul {
              list-style: none;
              margin-left: 20px;
              li {
                margin: 10px 0;
              }
            }
          }
        }
      }
    }

    &.form {
      padding: 10px 100px;
      position: relative;
      display: flex;
      align-items: center;
      background: #d9d9d9;
      color: #666;
      a, a:link, .link {
        color: #153b48;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      h1 {
        color: #666;
      }

      .upper-links {
        position: absolute;
        right: 100px;
        top: 20px;
        text-align: right;
        font-size: 14px;
      }
      .links {
        margin-top: 40px;
        font-size: 14px;
        span {
          color: #153b48;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        a {
          font-weight: normal;
        }
      }
      .form-content {
        width: 100%;
        .input-container {
          margin: 80px 0 100px 0;
        }
        > p {
          margin-bottom: 30px;
          color: #666;
          font-size: 12px;
          &.success-message {
            font-size: 15px;
          }
        }
        .button {
          margin-top: 50px;
          &:not(:disabled) {
            color: #153b48;
            &:hover {
              color: white;
            }
          }
          a, a:link {
            color: unset;
            &:hover {
              color: unset;
            }
          }
          &.homepage-link {
            margin-top: 50px;
          }
        }
        .form__error {
          font-size: 12px;
          margin-top: 20px;
        }
      }
    }

    @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
      width: 100%;
      &.marketing-note {
        display: none;
      }

      &.form {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        .upper-links {
          position: initial;
          margin-bottom: 20%;
        }
      }
    }
  }
`

