import React from 'react';
import PropTypes from "prop-types";
import { StyledLoading } from './Loading.Style'
import cx from 'classnames';

const Loading = ({ size, className, children }) => {
    return (
      <StyledLoading className={cx(className, size)}>
        <i className={`spinner${size ? ` ${size}`: ''}`} />
        <span>{children}</span>
      </StyledLoading>
    );
};

Loading.propTypes = {
  size: PropTypes.oneOf(['', 'small', 'medium', 'xlarge'])
};

Loading.defaultProps = {
  size: ''
};

export default Loading;
