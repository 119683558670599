import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { StyledAnalytics, StyledPreferencesDialog, StyledAnalyticsContent } from './Analytics.style';
import PageTitle from "../../../components/PageTitle";
import { InvestorsAPI, KpisAPI, StartupsAPI, SupportAPI } from "../../../api";
import ChartSettingsPanel from "../../../components/ChartSettingsPanel";
import Loading from "../../../components/Loading/Loading";
import IconButton from "../../../components/IconButton";
import { DataFilesDialog, ErrorMsgBox } from "../../../components/dialogs";
import OfflineGenerationNotification from "../../../components/dialogs/OfflineGenerationNotification";
import MoreMenu from "../../../components/MoreMenu";
import enums from "../../../utils/enums";
import Dialog from "../../../components/dialogs/Dialog";
import PreferencesCard from "../../../components/PreferencesCard";
import Converters from "../../../utils/converters";
import Preferences from "../../../utils/preferences";
import CompanyNavigation from "../CompanyNavigation";
import General from "../../../utils/general";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import routes from "../../routes";
import KpiValue from "../../../components/KpiValue";
import LazyChartCard from "../../../components/LazyChartCard";
import KpiDataExplanation from "../../../components/KpiDataExplanation";
import cx from "classnames";
import Tabs from "../../../components/Tabs";
import Breadcrumbs from "./Breadcrumbs";
import ChartToolbar from "../../../components/ChartToolbar";
import colors from "../../../styles/colors";
import SearchAnalytics from "./SearchAnalytics";
import NotReadyPanel from "./NotReadyPanel";
import KpiDescription from "./KpiDescription";
import Insights from '../../../components/Insights/Insights';

const getStateFromHash = (hash) => {
  const initialValues = General.getHashValuesFromUrl(hash);
  const filters = {period: initialValues?.period || enums.Period.Quarterly, periodRange: initialValues?.periodRange || 12, offset: initialValues?.offset || 0, compareBySegment: initialValues?.compareBySegment, criteria: initialValues?.criteria};
  const settings = initialValues?.settings;
  const path = initialValues?.path || [{kpiType: 'root'}];
  const selectedTab = initialValues?.selectedTab || '';
  const mainChartSelection = initialValues?.mainChartSelection;
  return {filters, settings, path, selectedTab, mainChartSelection};
};


export default function Analytics() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const isStartup = user?.isStartupMode;
  const isSupportUser = user?.isSupportUser;
  const isAdmin = user?.isAdmin;
  const API = isStartup ? StartupsAPI : InvestorsAPI;
  const { id, watchlistId, analyticsKind: passedParameterAnalyticsKind } = useParams();
  const startupId = Number(isStartup ? user.organizationId :  id);

  const [isBusy, setIsBusy] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState('');

  const [analyticsKind, setAnalyticsKind] = useState(passedParameterAnalyticsKind);
  const [pageState, setPageState] = useState(getStateFromHash(location.hash))
  const [metricsDate, setMetricsDate] = useState(null);
  const [showDataFilesDialog, setShowDataFilesDialog] = useState(false);
  const [showPreferencesDialog, setShowPreferencesDialog] = useState(false);
  const [showGeneratingOfflineNotification, setShowGeneratingOfflineNotification] = useState(0);
  const [companyData, setCompanyData] = useState(null);
  const [readinessState, setReadinessState] = useState({});
  const [isForbidden, setIsForbidden] = useState(false);
  const [isFiscalYear, setIsFiscalYear] = useState(false);
  const [filtersReady, setFiltersReady] = useState(false);
  const [supportInfo, setSupportInfo] = useState({requested: false, status: 'N/A', updatedAt: null});

  const [dashboardConfig, setDashboardConfig] = useState();
  const [kpiConfigs, setKpiConfigs] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [customerDataReady, setCustomerDataReady] = useState(false);
  const [periodMinimalResolution, setPeriodMinimalResolution] = useState();
  const [dateRange, setDateRange] = useState({  });
  const [searchConfig, setSearchConfig] = useState();
  const [mainChartKpiResult, setMainChartKpiResult] = useState({});
  const [isInsightsOpen, setIsInsightsOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState(-1);
  const [kpiConfigToSelect, setKpiConfigToSelect] = useState(location.state?.kpiConfig);

  const settingsOptions = [];
  const isDataReady = readinessState[analyticsKind]?.ready || false;
  const dataGenerationStatus = readinessState[analyticsKind]?.status;

  const currentConfig = kpiConfigs.find(c => c.kpiType === pageState?.path[pageState?.path.length - 1].kpiType);
  const allowOpenShowDialog = isAdmin && (isStartup || watchlistId);
  const hasInsights = General.validateFeatureFlag('insights', watchlistId);

  useEffect(() => {
    setKpiConfigToSelect(location.state?.kpiConfig);
  }, [location.state?.kpiConfig]);

  useEffect(() => {
    setIsBusy(true);
    const promise = watchlistId ? API.getWatchlistDataReadiness(watchlistId) : API.getDataReadiness(startupId);
    promise.then(({success, data, statusCode, error}) => {
      setIsBusy(false);
      if (success) {
        Preferences.setOrganizationPreferences(data.preferences);
        setReadiness(data);
        setMetricsDate(data.lastActualDate);
        setSupportInfo({
          ...supportInfo,
          requested: data.supportRequested,
          status: data.supportStatus,
          updatedAt: data.supportUpdatedAt
        });

        if (data.dataReady)
          setCustomerDataReady(true);

        if (watchlistId || startupId !== user.organizationId || isSupportUser)
          setCompanyData(data);

      } else if ([403, 422].includes(statusCode))
        setIsForbidden(true);
      else {
        setReadiness();
      }

      setIsBusy(false);
    });
  }, [startupId, user, API, watchlistId]);

  useEffect(() => {
    if (customerDataReady && !searchConfig) {
      setIsBusy(true);
      KpisAPI.getOverviewConfiguration(startupId, watchlistId).then(({success, data, error}) => {
        if (success) {
          setSearchConfig(data);
        } else {
          setPageError(error);
        }
        setIsBusy(false);
      });
    }
  }, [startupId, watchlistId, customerDataReady, analyticsKind]);

  useEffect(() => {
    const relevantKpiConfig = (searchConfig || {})[analyticsKind];
    if (relevantKpiConfig) {
      setDashboardConfig(relevantKpiConfig.dashboard);
      setKpiConfigs(relevantKpiConfig.kpis);
      setPeriodMinimalResolution(relevantKpiConfig.periodMinimalResolution);
      let newPeriod = pageState?.filters?.period;
      if (newPeriod === enums.Period.Monthly && relevantKpiConfig.periodMinimalResolution === enums.Period.Quarterly)
        newPeriod = enums.Period.Quarterly;
      if (newPeriod === enums.Period.Quarterly && relevantKpiConfig.periodMinimalResolution === enums.Period.Yearly)
        newPeriod = enums.Period.Yearly;
      const filters = {offset: undefined};
      if (newPeriod !== pageState?.filters?.period)
        filters.period = newPeriod;
      updatePageState(filters, null, null, null, null);
      setDateRange({minDate: relevantKpiConfig.minDate, maxDate: relevantKpiConfig.maxDate, fiscalOffset: relevantKpiConfig.fiscalOffset, lastActualDate: relevantKpiConfig.lastActualDate});
      setIsFiscalYear(relevantKpiConfig.isFiscalYear);
      if (!kpiConfigToSelect)
        setIsBusy(false);
    }
  }, [searchConfig, analyticsKind]);

  useEffect(() => {
    setAnalyticsKind(passedParameterAnalyticsKind || enums.AnalyticsKind.SaasMetrics);
  }, [passedParameterAnalyticsKind]);

  useEffect(() => {
    if (kpiConfigToSelect) {
      const kpiConfig = findKpiConfig(kpiConfigToSelect.kpiType, kpiConfigToSelect.segmentBy);
      if (kpiConfig) {
        changePath(kpiConfig, {offset: undefined, selectedPeriod: kpiConfigToSelect.selectedPeriod}, false, kpiConfigToSelect.selectedTab);
      }
      setIsBusy(false);
    }
  }, [kpiConfigs]);

  useEffect(() => {
    const newState = getStateFromHash(location.hash);
    if (JSON.stringify(newState) !== JSON.stringify(pageState))
      setPageState(newState);
  }, [location]);


  const clearFilters = () => {
    const newState = {...pageState};
    newState.filters = Object.assign({}, newState.filters);
    delete newState.filters.criteria;
    delete newState.filters.compareBySegment;
    Object.keys(newState.settings).forEach(k => {
      newState.settings[k] = [];
    });
    setPageState(newState);
    updateUrl(newState);
  };

  const updateUrl = ({settings,  path, selectedTab, filters, mainChartSelection}) => {
    const urlParams = { settings, path, selectedTab, compareBySegment: filters?.compareBySegment, period: filters?.period, periodRange: filters?.periodRange, mainChartSelection, offset: filters?.offset, criteria: filters?.criteria };
    const hash = `#${General.toBase64(JSON.stringify(urlParams))}`;
    if (hash !== location.hash) {
      window.history.pushState(null, null,  hash);
    }
  };

  const updatePageState = (filters, settings, path, selectedTab, mainChartSelection = null) => {
    if (filters || settings || path || selectedTab || mainChartSelection) {
      const newState = {...pageState};
      if (mainChartSelection)
        newState.mainChartSelection = mainChartSelection;
      if (filters) {
        newState.filters = {...newState.filters, ...filters};
        if (pageState.filters?.period !== newState.filters?.period) {
          delete newState.filters.selectedCohort;
          delete newState.filters.selectedPeriod;
          delete newState.mainChartSelection?.selectedCohort;
          delete newState.mainChartSelection?.selectedPeriod;
          //   newState.mainChartSelection = { subType: pageState.mainChartSelection?.subType };
        }
        if (pageState.filters?.compareBySegment !== newState.filters?.compareBySegment && newState?.mainChartSelection?.selectedSegment)
          delete newState.mainChartSelection.selectedSegment;
        if (filters.compareBySegment && newState.mainChartSelection?.displayKeyFilter)
          delete newState.mainChartSelection.displayKeyFilter;
      }

      if (settings)
        newState.settings = settings;
      if (path)
        newState.path = path;
      if (selectedTab)
        newState.selectedTab = selectedTab;

      setPageState(newState);
      updateUrl(newState);
    }
  };

  const onKpiDataDownload = async ({kpiType, segmentBy} = currentConfig) => {
    const filters = pageState?.filters;
    const options = {segmentBy};
    if (kpiType.includes(':customers'))
      Object.assign(filters, pageState?.mainChartSelection);
    return KpisAPI.downloadChartData(startupId, watchlistId, kpiType, options, filters);
  };

  const findKpiConfig = (kpiType, segmentBy) => {
    const actualKpiType = segmentBy && !kpiType.includes(`:${segmentBy}`) ? `${kpiType}${kpiType.includes(':') ? '' : ':'}:${segmentBy}` : kpiType;
    return kpiConfigs.find(c => c.kpiType === actualKpiType && c.segmentBy === segmentBy);
  };

  const changePath = (kpiConfig, displayOptions, isRootPath = false, selectedTab = 'Overview') => {
    // If no valid kpiConfig - redirect to root analytics
    if (!kpiConfig?.kpiType) {
      const route = isStartup ? routes.Startups.getStartupAnalyticsUrl(analyticsKind) : watchlistId ? routes.Investors.getWatchlistAnalyticsUrl(watchlistId, analyticsKind) : routes.Investors.getStartupAnalyticsUrl(startupId, analyticsKind);
      return navigate(route);
    }

    // Navigate to new analytics kind
    if (kpiConfig.analyticsKind && analyticsKind !== kpiConfig.analyticsKind) {
      const kpiType = kpiConfig.relatedKpi || kpiConfig.kpiType;
      const route = isStartup ? routes.Startups.getStartupAnalyticsUrl(kpiConfig.analyticsKind) : watchlistId ? routes.Investors.getWatchlistAnalyticsUrl(watchlistId, kpiConfig.analyticsKind) : routes.Investors.getStartupAnalyticsUrl(startupId, kpiConfig.analyticsKind);
      return navigate(route, { state: { kpiConfig: { kpiType, segmentBy: kpiConfig.segmentBy, selectedPeriod: kpiConfig.selectedPeriod, selectedTab } } });
    }

    let mainChartSelection = null;
    if (!displayOptions &&
      pageState?.mainChartSelection?.selectedPeriod &&
      (kpiConfig.kpiType.includes('-cohort') || kpiConfig.kpiType.includes('-retention'))) {
      mainChartSelection = {selectedCohort: `c ${pageState?.mainChartSelection.selectedPeriod}`};
    }

    if (pageState?.mainChartSelection?.selectedSegment) {
      if (!mainChartSelection)
        mainChartSelection = {...pageState.mainChartSelection};
      delete mainChartSelection.selectedSegment;
    }

    if (displayOptions?.displayKeyFilter) {
      mainChartSelection = mainChartSelection || {};
      mainChartSelection.displayKeyFilter = displayOptions?.displayKeyFilter;
    } else {
      mainChartSelection = Object.assign({}, pageState?.mainChartSelection, mainChartSelection);
      delete mainChartSelection.displayKeyFilter;
    }

    if (displayOptions?.selectedPeriod)
      mainChartSelection.selectedPeriod = displayOptions.selectedPeriod;

    if (displayOptions?.selectedCohort)
      mainChartSelection.selectedCohort = displayOptions.selectedCohort;

    if (displayOptions?.selectedBenchmark) {
      mainChartSelection.displayBenchmark = displayOptions.selectedBenchmark;
    }

    let settings = null;
    // Is insight
    if (kpiConfig.id) {
      if (!displayOptions?.selectedPeriod)
        delete mainChartSelection.selectedPeriod;
      if (!displayOptions?.selectedCohort)
        delete mainChartSelection.selectedCohort;
      if (!displayOptions?.selectedBenchmark)
        delete mainChartSelection.selectedBenchmark;
      if (kpiConfig.compareBySegment && kpiConfig.criteria && kpiConfig.settings) {
        displayOptions.compareBySegment = kpiConfig.compareBySegment;
        displayOptions.criteria = kpiConfig.criteria;
        settings = kpiConfig.settings;
      } else {
        displayOptions.compareBySegment = null;
        displayOptions.criteria = {};
        settings = {};
      }
    }
    // Set selected insight if needed
    setSelectedInsight(kpiConfig.id || -1);


    kpiConfig = findKpiConfig(kpiConfig.kpiType, kpiConfig.segmentBy);
    const newPath = [];
    if (!isRootPath) {
      for (let i=0; i<pageState?.path.length; i++) {
        const pathConfig = pageState?.path[i];
        if (pathConfig.kpiType === kpiConfig.kpiType && pathConfig.segmentBy === kpiConfig.segmentBy)
          break;

        newPath.push(pathConfig);
      }
    } else {
      newPath.push(pageState?.path[0]);
    }
    newPath.push({
      ...kpiConfig
    });

    updatePageState(displayOptions, settings, newPath, selectedTab, mainChartSelection);
    window.scrollTo(0, 0);
  };

  const onMainChartDataFetched = (data) => {
    setMainChartKpiResult(data);
  };

  const onInsightClicked = (insight) => {
    changePath(insight, {selectedPeriod: insight.selectedPeriod, selectedCohort: insight.selectedCohort, selectedBenchmark: insight.selectedBenchmark}, true, insight.selectedTab || 'Overview');
  };

  const onPeriodSelected = (selectedPeriod, subType) => {
    const isCompareMode = !!pageState?.filters?.compareBySegment;
    let selectedSegment = null;
    if (isCompareMode && subType && selectedPeriod) {
      const segmentName = subType.includes('segments[') ? subType.split(`'`)[1].split(`'`)[0] : subType;
      if (currentConfig.kpiType.includes('breakdown') || currentConfig.kpiType.includes('waterfall')) {
        const subtypeParts = subType.split("']");
        selectedSegment = segmentName;
        subType = subtypeParts[1];
      } else {
        selectedSegment = segmentName;
      }
    }
    const isWaterfall = currentConfig.kpiType.includes('-waterfall');
    const calculatedSubType = !['startingBalance', 'endingBalance', 'transparent'].includes(subType) && (currentConfig.kpiType.includes('-breakdown') || isWaterfall) && subType || null;
    if (isWaterfall && mainChartKpiResult && selectedPeriod) {
      if (mainChartKpiResult.data[0]?.startingPeriod !== mainChartKpiResult.data[0]?.endingPeriod)
        selectedPeriod = `${mainChartKpiResult.data[0]?.startingPeriod}:${mainChartKpiResult.data[0]?.endingPeriod}`;
      else
        selectedPeriod = mainChartKpiResult.data[0]?.startingPeriod;
    }
    if (selectedPeriod !== pageState?.mainChartSelection?.selectedPeriod || pageState?.mainChartSelection?.subType !== calculatedSubType || selectedSegment !== pageState?.mainChartSelection?.selectedSegment) {
      const mainChartSelection = {
        ...pageState?.mainChartSelection,
        selectedPeriod,
        subType: calculatedSubType,
        selectedSegment
      };
      updatePageState(null, null, null, undefined, mainChartSelection);
      setSelectedInsight(-1);
    }
  };

  const onCohortSelected = (selectedCohort, selectedPeriod) => {
    if (selectedCohort !== pageState?.mainChartSelection?.selectedCohort || selectedPeriod !== pageState?.mainChartSelection?.selectedPeriod) {
      const mainChartSelection = {
        ...pageState?.mainChartSelection,
        selectedCohort,
        selectedPeriod
      };
      const filters = pageState?.filters.selectedCohort !== selectedCohort ? {...pageState?.filters, selectedCohort} : null;
      updatePageState(filters, null, null, undefined, mainChartSelection);
      setSelectedInsight(-1);
    }
  };

  const renderDashboard = () => {
    const background = getKpiValueColor(analyticsKind);
    return <>
      {/*<GoNoGoWidget companyId={startupId} />*/}
      {dashboardConfig.values?.length > 0 &&
        <div className="dashboard-values">
          <div>
            {dashboardConfig.values.map((kpiConfig, index) => <KpiValue key={`dashboard-value-${index}`} style={{background}} companyId={startupId} watchlistId={watchlistId} kpiType={kpiConfig.kpiType} fetchValue title={kpiConfig.title} filters={pageState?.filters} onClick={(options) => {
              const displayOptions = kpiConfig.kpiType.includes(':growth') ? Object.assign({}, pageState?.mainChartSelection, {displayKeyFilter: 'YoY'}) : null;
              changePath(kpiConfig, displayOptions);
            }} />)}
          </div>
        </div>
      }
      {dashboardConfig.charts?.length > 0 &&
        <div className="dashboard-charts">
          {dashboardConfig.charts.map((kpiConfig, index) => {
              if (!kpiConfig.kpiType)
                return null;
              return <div key={`dashboard-chart-${index}`} className="kpi-chart">
                <LazyChartCard
                  kpiType={kpiConfig.kpiType}
                  segmentBy={kpiConfig?.segmentBy}
                  startupId={startupId}
                  watchlistId={watchlistId}
                  filters={pageState?.filters}
                  hideTootips
                  hideAxisLabels={kpiConfig.kpiType !== 'arr-accumulative'}
                  displayCurrent={false}
                  height={200}
                  displayLegend={false}
                  onClick={() => changePath(kpiConfig)}
                  onMissingDataClick={allowOpenShowDialog ? () => setShowDataFilesDialog(true) : undefined}
                  onClearFilterClick={clearFilters}
                  isFiscalYear={isFiscalYear}
                />
              </div>;
            }
          )}
        </div>
      }
    </>;
  };

  const renderDrilldown = () => {
    const allowSelection = !currentConfig.segmentBy;
    const highlightOptions = {...pageState?.mainChartSelection};
    if (currentConfig.kpiType.includes(':growth')) {
      if (!highlightOptions.displayKeyFilter)
        Object.assign(highlightOptions, {displayKeyFilter: 'QoQ'});
      else if (pageState?.filters?.compareBySegment && highlightOptions.displayKeyFilter === 'both') {
        Object.assign(highlightOptions, {displayKeyFilter: 'QoQ'});
      }
    }
    if (currentConfig.kpiType.includes('-ndr-wa') && pageState?.filters?.compareBySegment && (!highlightOptions?.displayKeyFilter || highlightOptions.displayKeyFilter === 'both')) {
      Object.assign(highlightOptions, {displayKeyFilter: 'waNdr'});
    }
    if (['both', 'none'].includes(highlightOptions.displayKeyFilter)) {
      delete highlightOptions.displayKeyFilter;
    }
    if (highlightOptions.selectedPeriod?.includes(':'))
      delete highlightOptions.selectedPeriod;
    const showTooltips = (pageState?.filters?.compareBySegment && pageState?.filters?.compareBySegment !== currentConfig.segmentBy && !currentConfig.kpiType.includes('accumulative')) || ((currentConfig.kpiType.includes('breakdown') || currentConfig.kpiType.includes('waterfall')) && !currentConfig.kpiType.includes(':growth'));
    const showValueLabels = !pageState?.filters?.compareBySegment || currentConfig.segmentBy && currentConfig.segmentBy === pageState?.filters?.compareBySegment || currentConfig.kpiType.endsWith('-retention') || currentConfig.kpiType.includes(':growth');
    const displayLegend = currentConfig.kpiType.includes('breakdown') || currentConfig.kpiType.includes('waterfall') || currentConfig.kpiType.includes('-ndr-wa') || currentConfig.kpiType.includes(':growth') || pageState?.filters?.compareBySegment;
    return (
      <div className="drilldown">
        <div className="top-section">
          <div className="main-chart">
            <LazyChartCard
              kpiType={currentConfig.kpiType}
              segmentBy={currentConfig.segmentBy}
              startupId={startupId}
              watchlistId={watchlistId}
              filters={pageState?.filters}
              isMainChart
              hideTitle
              height={350}
              showValueLabels={showValueLabels}
              displayLegend={displayLegend}
              allowDeselectBar
              hideTootips={!showTooltips}
              hideAxisLabels={currentConfig.kpiType !== 'arr-accumulative'}
              displayCurrent={false}
              highlightOptions={highlightOptions}
              selectedBenchmark={highlightOptions.displayBenchmark}
              onBarClick={allowSelection ? onPeriodSelected : undefined}
              onMissingDataClick={allowOpenShowDialog ? () => setShowDataFilesDialog(true) : undefined}
              onCohortSelected={allowSelection ? onCohortSelected : undefined}
              onDataFetched={onMainChartDataFetched}
              onClearFilterClick={clearFilters}
              isFiscalYear={isFiscalYear}
              onInsightClick={(insightId) => setSelectedInsight(insightId)}
            />
          </div>
        </div>
        <div className="bottom-section">
          {renderTabs()}
        </div>
      </div>
    );
  };

  const shouldFilterOnSelectedPeriod = (kpiConfig) => {
    if ([KpisAPI.Types.ARR_Accumulative, KpisAPI.Types.ARR_Waterfall].includes(kpiConfig.kpiType))
      return true;

    if (currentConfig.kpiType.includes('-cohort') || currentConfig.kpiType.includes('-retention')) {
      return !kpiConfig.kpiType.includes('-cohort') && !kpiConfig.kpiType.includes('-retention');
    }

    return !!kpiConfig.segmentBy;
  };

  const extractSubType = (kpiType) => {
    if (kpiType.startsWith('arr-new') || kpiType.startsWith('logos-new'))
      return 'new';
    if (kpiType.startsWith('arr-expansion') || kpiType.startsWith('logos-expansion'))
      return 'expansion';
    if (kpiType.startsWith('arr-reactivation') || kpiType.startsWith('logos-reactivation'))
      return 'reactivation';
    if (kpiType.startsWith('arr-churn') || kpiType.startsWith('logos-churn'))
      return 'churn';
    if (kpiType.startsWith('arr-contraction') || kpiType.startsWith('logos-contraction'))
      return 'contraction';
  };

  const getParentKpi = (kpiType) => {
    kpiType = kpiType.replace('-breakdown', '');
    if (kpiType.includes('-waterfall')) {
      return kpiType.includes('arr-') ? 'arr' : 'mrr';
    }
    return kpiType;
  };

  const getRelevantTabs = () => {
    const tabs = [];
    let tabsConfig = currentConfig;
    if (pageState?.mainChartSelection?.subType && currentConfig) {
      tabsConfig = kpiConfigs.find(k => k.kpiType === `${getParentKpi(currentConfig.kpiType)}-${pageState?.mainChartSelection?.subType}`);
    }

    if (tabsConfig?.tabs) {
      if (tabsConfig.tabs.overview?.length)
        tabs.push({tabConfig: tabsConfig.tabs.overview, caption: 'Overview'});
      if (tabsConfig.tabs.customersKpis?.length)
        tabs.push({tabConfig: tabsConfig.tabs.customersKpis, caption: 'Customers Kpis'});
      if (tabsConfig.tabs.concentration?.length)
        tabs.push({tabConfig: tabsConfig.tabs.concentration, caption: 'Concentration'});
      if (tabsConfig.tabs.efficiency?.length)
        tabs.push({tabConfig: tabsConfig.tabs.efficiency, caption: 'Efficiency'});
      if (tabsConfig.tabs.cohorts?.length)
        tabs.push({tabConfig: tabsConfig.tabs.cohorts, caption: 'Cohorts'});
      if (tabsConfig.tabs.custom?.length)
        tabs.push({tabConfig: tabsConfig.tabs.custom, caption: 'Custom'});
      if (tabsConfig.tabs.customers?.length)
        tabs.push({tabConfig: tabsConfig.tabs.customers, caption: 'Customers'});
    }

    if (mainChartKpiResult?.dataExplanation)
      tabs.push({tabConfig: {}, caption: 'Data'});

    return tabs;
  };

  const renderTab = (tabConfig, caption) => {
    // return null;
    const isDataTab = caption === 'Data';
    if (isDataTab || tabConfig?.length) {
      let tabContent;
      if (isDataTab) {
        tabContent = <KpiDataExplanation
          segmentBy={currentConfig.segmentBy}
          kpiType={currentConfig.kpiType}
          kpiResult={mainChartKpiResult}
          selectedCohort={pageState?.mainChartSelection?.selectedCohort}
          selectedPeriod={pageState?.mainChartSelection?.selectedPeriod}
          isCompareMode={!!pageState?.filters?.compareBySegment}
          onDataDownload={onKpiDataDownload}
        />;
      } else {
        const isCustomersTab = caption === 'Customers';
        tabContent = <div className={cx({charts: !isCustomersTab})}>
          {
            tabConfig.map((kpiConfig, index) => {
              if (!kpiConfig.kpiType)
                return null;
              const highlightOptions = {...pageState?.mainChartSelection};
              if (kpiConfig.kpiType.includes(':growth'))
                Object.assign(highlightOptions, {displayKeyFilter: 'QoQ'});
              else if (kpiConfig.kpiType.includes('-ndr-wa') && pageState?.filters?.compareBySegment && (!highlightOptions?.displayKeyFilter || highlightOptions.displayKeyFilter === 'both'))
                Object.assign(highlightOptions, {displayKeyFilter: 'waNdr'});
              else
                delete highlightOptions?.displayKeyFilter;
              if (highlightOptions.selectedPeriod?.includes(':'))
                delete highlightOptions.selectedPeriod;
              const filterOnHighlightOptions = isCustomersTab || shouldFilterOnSelectedPeriod(kpiConfig);

              return <div key={`${kpiConfig.kpiType}-chart-${index}`}>
                <LazyChartCard
                  kpiType={kpiConfig.kpiType}
                  segmentBy={kpiConfig.segmentBy}
                  startupId={startupId}
                  watchlistId={watchlistId}
                  filters={pageState?.filters}
                  hideTootips
                  hideAxisLabels={kpiConfig.kpiType !== 'arr-accumulative'}
                  displayCurrent={false}
                  height={250}
                  displayLegend={!kpiConfig.kpiType.includes('breakdown') && !pageState?.filters?.compareBySegment}
                  highlightOptions={highlightOptions}
                  filterOnHighlightOptions={filterOnHighlightOptions}
                  onClick={isCustomersTab ? undefined : () => {
                    const displayOptions = Object.assign({}, pageState?.mainChartSelection);
                    if (displayOptions.displayKeyFilter) {
                      const chartOptions = getChartOptions();
                      if (!chartOptions || !chartOptions.some(o => o.value === displayOptions.displayKeyFilter) || kpiConfig.kpiType.includes('waterfall'))
                        delete displayOptions.displayKeyFilter;
                    }
                    changePath(kpiConfig, displayOptions);
                    setSelectedInsight(-1);
                  }}
                  onMissingDataClick={allowOpenShowDialog ? () => setShowDataFilesDialog(true) : undefined}
                  onClearFilterClick={clearFilters}
                  isFiscalYear={isFiscalYear}
                  onDataDownload={isCustomersTab ? () => onKpiDataDownload(kpiConfig) : null}
                />
              </div>;
            })
          }
        </div>;
      }

      return (
        <Tabs.Tab key={`${caption}-tab`} caption={caption}>{tabContent}</Tabs.Tab>
      );
    }
  };

  const renderTabs = () => {
    const tabs = getRelevantTabs();
    if (tabs.length) {
      return (
        <Tabs
          selectedTab={pageState?.selectedTab}
          onChange={(tabCaption) => updatePageState(null, null, null, tabCaption)}
        >
          {tabs.map(tab => renderTab(tab.tabConfig, tab.caption))}
        </Tabs>
      );
    }
  };

  const getPageTitlePath = () => {
    if (isStartup)
      return;
    return routes.Investors.Network;
  };

  const getChartOptions = () => {
    if (currentConfig?.kpiType.includes(':growth')) {
      const result = [];
      const isQoQSupported = mainChartKpiResult?.dataKeyPoints?.some(d => (d.key || '').includes('QoQ'));
      if (!pageState?.filters.compareBySegment && isQoQSupported)
        result.push({ value: 'both', label: 'Show both'});
      if (isQoQSupported)
        result.push({ value: 'QoQ', label: 'QoQ', default: true });
      result.push({ value: 'YoY', label: 'YoY', default: !isQoQSupported });
      return result;
    }
    if (currentConfig?.kpiType.includes('-wa-')) {
      const result = [];
      if (!pageState?.filters.compareBySegment)
        result.push({ value: 'both', label: 'Show both', default: true});
      result.push({ label: 'WA NDR', value: 'waNdr', default: true }, { label: 'WA NDR LTM', value: 'waNdrLtm' });
      return result;
    }
    if (!pageState?.filters.compareBySegment && currentConfig && mainChartKpiResult?.data && mainChartKpiResult?.hasGrowthValues) {
      const lastDataItem = mainChartKpiResult?.data[mainChartKpiResult?.data.length-1];
      if (lastDataItem &&
        (lastDataItem.hasOwnProperty('value') || lastDataItem.hasOwnProperty('endingBalance')) &&
        (lastDataItem.hasOwnProperty('QoQ') || lastDataItem.hasOwnProperty('YoY'))) {
        return [
          {value: 'none', label: 'None'},
          {value: 'QoQ', label: 'QoQ growth'},
          {value: 'YoY', label: 'YoY growth', default: true}
        ];
      }
    }
  };

  const getChartBenchmarks = () => {
    if (mainChartKpiResult?.availableBenchmarks?.length) {
      const selectedBenchmark = pageState?.mainChartSelection?.displayBenchmark;
      const arr = [{label: 'Select benchmark', selected: !selectedBenchmark}];
      mainChartKpiResult?.availableBenchmarks?.forEach(bm => {
        let label = `${bm.name} - `;
        switch (bm.type) {
          case 'area':
            label += 'Area';
            break;
          case 'minmax':
            label += 'Minimum/Maximum';
            break;
          case 'median':
            label += 'Median';
            break;
          case 'avg':
            label += 'Average';
            break;
        }
        arr.push({id: bm.id, label, selected: bm.id === selectedBenchmark});
      })
      return arr;
    }
    return null;
  }

  const isDisplayingDashboard = pageState?.path.filter(p => !!p.kpiType).length === 1 || !currentConfig;
  const pageTitlePath = getPageTitlePath();

  const setReadiness = (data = {}) => {
    const baseReadiness = data.bookingsDataReady || false;
    const readiness = {
      [enums.AnalyticsKind.SaasMetrics]: {
        ready: baseReadiness,
        status: data.bookingsDataGenerationStatus,
        error: data.bookingsGenerationError
      },
      [enums.AnalyticsKind.PnL]: {
        ready: baseReadiness && data.expensesDataReady || false,
        status: '',
        error: ''
      }
    };
    setReadinessState(readiness);
  };

  const canGenerateAnalytics = () => {
    if (isAdmin && isDataReady) {
      return isStartup || watchlistId;
    }
    return false;
  };

  const canRequestSupport = () => {
    return isAdmin && !isBusy && !isSupportUser && (isStartup || watchlistId);
  };

  if (canGenerateAnalytics()) {
    settingsOptions.push({label: 'Regenerate analytics', onClick: () => setShowDataFilesDialog(true)});
  }

  if (isDataReady)
    settingsOptions.push({label: 'Settings', onClick: () => setShowPreferencesDialog(true)});

  if (canRequestSupport()) {
    if (supportInfo.requested)
      settingsOptions.push({label: 'Revoke support request', onClick: () => revokeNovvoSupport()});
    else if (isDataReady)
      settingsOptions.push({label: 'Request support', onClick: () => inviteNovvoSupport()});
  }

  const inviteNovvoSupport = () => {
    SupportAPI.inviteNovvoSupport(companyData?.id).then(({success, data, error}) => {
      if (success) {
        setSupportInfo({
          ...supportInfo,
          requested: true,
          status: enums.SupportStatus.Pending,
          updatedAt: new Date()
        });
      }
      else
        setError(error);
    });
  };

  const revokeNovvoSupport = () => {
    SupportAPI.revokeNovvoSupport(companyData?.id).then(({success, error}) => {
      if (success) {
        setSupportInfo({
          ...supportInfo,
          requested: false,
          status: 'N/A',
          updatedAt: null
        });
      }
      else
        setError(error);
    });
  };

  const hideDataFilesDialog = () => {
    setShowDataFilesDialog(false);
    setShowDataFilesDialog(false);
  };

  const hidePreferencesDialog = () => {
    setShowPreferencesDialog(false);
    setShowPreferencesDialog(false);
  };

  const getHeaderContent = () => {
    if (isBusy || pageError)
      return null;
    const chartGrowthOptions = getChartOptions();
    let initialChartDisplayOption = pageState?.mainChartSelection?.displayKeyFilter;
    if (chartGrowthOptions) {
      if (initialChartDisplayOption && !chartGrowthOptions.some(o => o.value === initialChartDisplayOption))
        initialChartDisplayOption = undefined;
      if (!initialChartDisplayOption)
        initialChartDisplayOption = ((chartGrowthOptions || []).find(i => i.default) || {}).value;
    }

    return <div className="header">
      <Breadcrumbs
        path={pageState?.path}
        rootTitle={getDashboardTitle(analyticsKind)}
        mainChartTitle={mainChartKpiResult?.title}
        kpiConfigs={kpiConfigs}
        rootOptions={getRootOptions(dashboardConfig)}
        filters={{...pageState?.filters, selectedPeriod: pageState?.mainChartSelection?.selectedPeriod}}
        onChange={(newPath) => {
          const filters = {...pageState?.filters, selectedPeriod: undefined};
          const mainChartSelection = {...pageState?.mainChartSelection, selectedPeriod: undefined, subType: undefined};
          if (newPath.length === 1)
            delete mainChartSelection.displayKeyFilter;
          updatePageState(filters, null, newPath, "Overview", mainChartSelection);
          setSelectedInsight(-1);
        }}
        onClearFilters={() => {
          const clearedSelection = {selectedCohort: undefined, selectedPeriod: undefined};
          updatePageState({...pageState?.filters, ...clearedSelection}, null, null, null, {...pageState?.mainChartSelection, ...clearedSelection});
          setSelectedInsight(-1);
        }}
        onShowSettings={() => setShowPreferencesDialog(true)}
        info={currentConfig?.info}
        chartExplanation={currentConfig?.explanation}
        onShowDescription={() => setShowDescription(true)}
        isFiscalYear={isFiscalYear}
      />

      <ChartToolbar className="upper-toolbar"
                    startupId={startupId}
                    watchlistId={watchlistId}
                    mainKpiType={!isDisplayingDashboard ? currentConfig?.kpiType : null}
                    periodMinimalResolution={periodMinimalResolution}
                    hideActions={isDisplayingDashboard}
                    displayOptions={pageState?.filters}
                    chartOptions={chartGrowthOptions}
                    initialChartDisplayOption={initialChartDisplayOption}
                    benchmarkOptions={currentConfig === undefined ? null : getChartBenchmarks()}
                    dateRange={dateRange}
                    analyticsKind={analyticsKind}
                    onDisplayOptionChange={(newFilters) => {
                      updatePageState(newFilters);
                    }}
                    onChartDisplayOptionChanged={(fieldValues) => {
                      const mainChartSelection = {...pageState?.mainChartSelection, ...fieldValues};
                      if (!mainChartSelection.displayBenchmark)
                        delete mainChartSelection.displayBenchmark;
                      updatePageState(null, null, null, null, mainChartSelection);
                    }}
                    isFiscalYear={isFiscalYear}
      />

      {showDescription && <KpiDescription explanation={currentConfig.explanation} open={showDescription} onClose={() => setShowDescription(false)} />}
    </div>;
  };

  const getMainContent = () => {
    if (isDataReady) {
      if (filtersReady) {
        return <>
          {isBusy ? <Loading /> : null}
          {pageError ? pageError : null}
          {!isBusy && !pageError &&
            <StyledAnalyticsContent>
              {getHeaderContent()}
              {isDisplayingDashboard ? renderDashboard() : renderDrilldown()}
            </StyledAnalyticsContent>
          }
        </>;
      }
      else
        return <Loading />;
    }

    return <NotReadyPanel isStartup={isStartup} isAdmin={isAdmin} watchlistId={watchlistId} analyticsKind={analyticsKind} companyName={companyData?.name} dataGenerationStatus={dataGenerationStatus} onClickUploadHandler={() => setShowDataFilesDialog(true)} />;
  };

  const getSupportNotice = () => {
    const updatedAtText = `${Converters.toShortDateTime(supportInfo.updatedAt)}`;
    if (supportInfo.status === enums.SupportStatus.Pending)
      return `Generation by Novvo is pending (last updated on ${updatedAtText})`;
    return `Generation by Novvo is in progress (last updated on ${updatedAtText})`;
  };

  const getGenerationStatus = (hasGenerationError) => {
    if (hasGenerationError)
      return 'Processing error';
    return 'Processing offline';
  };

  const onFiltersReadyHandler = (filters) => {
    setFiltersReady(true);
  };

  const renderSettingsTrigger = () => {
    if (isAdmin && (isStartup || startupId || watchlistId)) {
      return <div>
        <MoreMenu triggerIcon={<IconButton iconName="Settings" tooltip="Settings" placement="top" arrow  />} options={settingsOptions}/>
      </div>;
    }
  }

  const isOfflineJobInProgress = () => {
    const bookingsDataGenerationStatus = Preferences.get('BookingsDataGenerationStatus');
    return [enums.DataGenerationStatus.PendingOfflineJobBySystem, enums.DataGenerationStatus.OfflineJobInProgressBySystem, enums.DataGenerationStatus.PendingOfflineJobByUser, enums.DataGenerationStatus.OfflineJobInProgressByUser].includes(bookingsDataGenerationStatus);
  };

  const renderDateNotice = () => {
    if (!isDataReady)
      return <div className="date-notice"/>;
    const hasGenerationError = Preferences.get('BookingsGenerationError');
    return <div className="date-notice">
      {!isSupportUser && supportInfo.requested && [enums.SupportStatus.Pending, enums.SupportStatus.InProgress].includes(supportInfo.status) ? getSupportNotice() :
        isOfflineJobInProgress() || hasGenerationError ? getGenerationStatus(hasGenerationError) :
        metricsDate ? <div>{`Data valid as of ${metricsDate} (included)`}</div> : null}
      {renderSettingsTrigger()}
    </div>;
  };

  if (isForbidden)
    return <div >You are not allowed to view this page! </div>;

  if (isBusy)
    return <Loading size="small" />;

  return (
    <StyledAnalytics>
      {showGeneratingOfflineNotification > 0 ? <OfflineGenerationNotification open isStartup={isStartup} companyName={companyData?.name} onClose={() => {setShowGeneratingOfflineNotification(0);}} limitation={showGeneratingOfflineNotification} /> : null}
      {error ? <ErrorMsgBox title="An error occurred" error={error} onClose={() => setError('')} open /> : null }
      <PageTitle path={pageTitlePath} variant="h1" lineheight="1" icon={isStartup || isSupportUser ? undefined : "back"} className={isInsightsOpen ? 'w-insights' : undefined}>
        <div>{companyData?.name || 'Analytics'}<br />{renderDateNotice()}</div>
        {<SearchAnalytics searchConfig={searchConfig} onChange={(newPath) => changePath(newPath, null, true)} />}
        <div className="insights">{hasInsights ? <Insights startupId={companyData?.id} watchlistId={watchlistId} onCollapseExpand={(isOpen) => setIsInsightsOpen(isOpen)} onInsightClicked={onInsightClicked} selectedInsight={selectedInsight} /> : null}</div>
      </PageTitle>
      <CompanyNavigation currentSelection={analyticsKind} showProfile={companyData?.isViewProfileAllowed} showAnalytics={isStartup || companyData?.isViewAnalyticsAllowed} />
      <div className="template-content">
        <div className={"template-container" + (isInsightsOpen ? ' w-insights' : '')}>
          {isDataReady ?
            <ChartSettingsPanel startupId={startupId} watchlistId={watchlistId} onChange={updatePageState} onFiltersReady={onFiltersReadyHandler} settings={pageState?.settings} compareBySegment={pageState?.filters?.compareBySegment} supportsFilters={analyticsKind === enums.AnalyticsKind.SaasMetrics} />
          : null}
          <div className="main">
            {!isDataReady && !isAdmin ? <div className="empty-message">Analytics are not available at this time. Once the data has been provided and Novvo’s processing has been completed you’ll be able to view the charts. Contact our <a href="mailto:support@novvo.ai">support@novvo.ai</a> for more information.</div> : null}
            {getMainContent()}
          </div>
        </div>
      </div>
      {showDataFilesDialog &&
        <DataFilesDialog
          watchlistId={watchlistId}
          className="datafiles-dialog"
          open={showDataFilesDialog}
          onClose={hideDataFilesDialog}
          onSubmitSupportRequest={inviteNovvoSupport}
        />
      }
      {showPreferencesDialog &&
        <Dialog
          header="Settings"
          showCloseIcon
          open={showPreferencesDialog}
          onClose={hidePreferencesDialog}
        >
          <StyledPreferencesDialog>
            <PreferencesCard watchlistId={watchlistId} startupId={startupId} onSaveComplete={() => {
              setShowPreferencesDialog(false);
              window.location.reload();
            }} />
          </StyledPreferencesDialog>
        </Dialog>
      }
    </StyledAnalytics>
  );
}


function getRootOptions(dashboardConfig) {
  const kpis = [...dashboardConfig.charts.map(k => k.kpiType), ...dashboardConfig.values.map(k => k.kpiType)];
  return Array.from(new Set(kpis));
}

function getDashboardTitle(analyticsKind) {
  switch (analyticsKind) {
    case enums.AnalyticsKind.PnL: return 'P&L';
    case enums.AnalyticsKind.BalanceSheet: return 'Balance sheet';
    case enums.AnalyticsKind.CapTable: return 'Cap table';
    case enums.AnalyticsKind.CashFlow: return 'Cash flow';
    case enums.AnalyticsKind.SnM_Pipeline: return 'S&M pipeline';
    case enums.AnalyticsKind.HR: return 'HR';
    default: return 'SaaS metrics';
  }
}

function getKpiValueColor(analyticsKind) {
  switch (analyticsKind) {
    case enums.AnalyticsKind.PnL: return colors.chartsPalettes.pnl.darkest;
    case enums.AnalyticsKind.SaasMetrics: return colors.chartsPalettes.revenue.darkest;
  }
}

