import styled from 'styled-components';

export const StyledMultiSelect = styled.div`
  background-color: white;

  .MuiAutocomplete-endAdornment {
    top: unset;
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        box-shadow: 0 0 0 0.4rem rgb(0 161 222 / 20%);
        outline: 0;
        border-color: rgba(0, 161, 222, 0.8);
        border-width: 1px;
    }
  }
`




