import styled from 'styled-components';

export const StyledPreferencesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-size: 14px;

  .setting-items {
    overflow: auto;
    .setting-item-cnt {
      padding: 1rem 0;
      border-bottom: 1px solid;
      border-bottom-color: ${props => props.theme.colors.lighterGray};
      h4 {
        span {
          font-weight: normal;
          color: ${props => props.theme.colors.label};
        }
      }
    }
    .field-row {
      display: grid;
      grid-template-columns: 230px 80px 150px;
      align-items: center;
      margin-left: 2rem;

      font-size: 14px;

      > div {
        padding: 10px 10px 10px 0;

        &:last-child {
          grid-column: 4;
        }
      }

      .pref-err {
        margin: 5px 20px;
        font-size: 0.8em;
      }

      .fiscal-year-options {
        width: 220px;
      }
    }

    .field-note {
      margin-left: 2rem;
      padding-top: 1rem;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    color: #9b9b9b;
    button {
      margin: 0;
    }
    .note {
      margin-left: 10px;
      font-size: 11px;
    }
    .error-box {
      margin-left: 10px;
      font-size: 11px;
      color: ${props => props.theme.colors.error};
    }
  }
`;

