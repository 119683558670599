import React, { useEffect, useState } from "react";
import {
  ComposedChart as ComposedChartComponent,
  Bar,
  Cell,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Area
} from "recharts";
import PropTypes from 'prop-types';
import {StyledComposedChart} from './ComposedChart.style';
import cx from 'classnames';
import MuiTooltip from "@mui/material/Tooltip";
import Converters from "../../utils/converters";
import colors from '../../styles/colors';

const SERIES_TYPES = {
  BAR: 'bar',
  REFERENCE_LINE: 'referenceLine',
  LINE: 'line',
  AREA: "area"
};

const NO_DATA_VALUE = 'No data';

const getSegmentName = (key) => {
  return ((key || '').split(`'`)[1] || '').split(`'`)[0]
};

const labelRenderer = ({ props, showValueLabels, selectedBarKey, data, dataKey, layout, type, isConcentrationValue, growthVisibility}) => {
  let {x, y, width, height, formatter, index, ...rest} = props;
  const targetData = data[index] || {};
  let {percent, valueTooltip, percentTooltip} = targetData;
  let value = targetData[dataKey];
  if (isConcentrationValue) {
    value = percent;
  }

  if (value === undefined)
    return null;

  let additionalGrowthValue;
  if (growthVisibility && ['QoQ', 'YoY'].includes(growthVisibility)) {
    additionalGrowthValue = Converters.toPercentage(targetData[growthVisibility] || 'N/A');
  }

  if (showValueLabels || selectedBarKey && targetData['period'] === selectedBarKey) {
    let displayValue;
    if (value === null || value === 'N/A' ) {
      value = 0;
      displayValue = NO_DATA_VALUE;
      if (isNaN(height))
        height = 315;
    } else {
      displayValue = formatter ? formatter(value) : value;
      if (value > 0 && (targetData.contraction === value || targetData.churn === value))
        displayValue = `-${displayValue}`;
    }


    let dx, dy, textAnchor, position;
    if (layout === 'vertical') {
      dx = width + 5;
      dy = (height / 2) + 1;
      textAnchor = 'start';
      position = 'right';
    } else {
      dx = width / 2;
      dy = value > 0 ? -10 : (type === SERIES_TYPES.LINE ? 15 : height - 10);
      textAnchor = 'middle';
      position = 'bottom';
    }

    let tooltip = valueTooltip;
    if (isConcentrationValue) {
      dx -= 42;
      tooltip = percentTooltip;
    }

    const TextElement = ({children, ...otherProps}) => {
      return <text
        key={index}
        x={x}
        y={y}
        dx={dx}
        dy={dy}
        height={height}
        width={width}
        {...rest}
        textAnchor={textAnchor}
        position={position}
        dominantBaseline="middle"
        fontWeight="bold"
        {...otherProps}
      >
        {children}
      </text>;
    };

    let rectWidth;
    let rectX;
    if (!!additionalGrowthValue) {
      const maxGrowthRectWidth = 80;
      rectWidth = width-4;
      rectX = x;
      if (rectWidth > maxGrowthRectWidth) {
        rectX = x + (width/2) - (maxGrowthRectWidth/2);
        rectWidth = maxGrowthRectWidth;
      }
    }

    let yPos = displayValue !== 'No data' ? (value >= 0 ? y-44 : y-27) : value >= 0 ? 270 : 1

    return <g>
      {!!additionalGrowthValue && layout !== 'vertical'?
        <g>
          <rect x={rectX} y={yPos} fill={colors.lighterGray} stroke={colors.lighterGray} width={rectWidth} height={18} rx="4" ry="4"/>
          <TextElement dy={dy-25} fontWeight="normal" fontSize={10}>
            <tspan textAnchor="middle" style={{alignmentBaseline: "central"}}>{additionalGrowthValue}</tspan>
          </TextElement>
        </g>
        : null}
      <TextElement>
        <title>{tooltip}</title>
        <tspan>{displayValue}</tspan>
      </TextElement>
    </g>;
  }
  return null;
};

const dotRenderer = ({ props, isCompareMode, showValueLabels, selectedBarKey, data, dataKey, color, onClick, onInsightClick}) => {
  const {value, index, dataKey: propsDataKey} = props;
  const targetData = data[index];
  if (!value)
    return null;

  const insight = isCompareMode ? targetData.segments?.insight : targetData.insight;

  if (insight && propsDataKey !== 'YoY') {
    console.log(propsDataKey)
    if  (!propsDataKey || !propsDataKey.includes('benchmark'))
      return <CustomizedInsightDot isCompareMode={isCompareMode} props={props} insight={insight}
                                 onInsightClick={onInsightClick}/>;
    return null;
  }

  if (showValueLabels || selectedBarKey && targetData[dataKey] === selectedBarKey) {
    let elStyle, onDotClick;
    if (showValueLabels) {
      onDotClick = () => onClick({payload: {[dataKey]: targetData[dataKey]}});
      elStyle = onDotClick && {'cursor': 'pointer'};
      if (elStyle) {
        elStyle.opacity = targetData[dataKey] === selectedBarKey ? 1 : 0.4;
      }
    }

    return <svg key={index} x={props.cx-5} y={props.cy-5} width={18} height={18} fill="white" stroke={color}
                     onClick={onDotClick}
                     className={cx({selected: targetData[dataKey] === selectedBarKey})}>
      <g transform="translate(5 5)">
        <circle r="5" fill={color} />
        <circle r="2" fill="white" />
      </g>
    </svg>;
  }
  return null;
};

const CustomizedInsightDot = ({props, isCompareMode, insight, onInsightClick}) => {
  const { cx, cy, value } = props;
  if (!value || !insight) {
    return null;
  }

  const {id, tooltip, status} = insight;
  const color = status === 'bad' ? '#ED6D5D' : status === 'warning' ? '#E6C432' : '#55AF7B';
  const size = isCompareMode ? 15 : 22;
  const radius = isCompareMode ? 2 : 4;
  const borderWidth = isCompareMode ? 1 : 2;
  return <MuiTooltip title={tooltip} arrow placement="top">
    <svg className="insights"
      onClick={onInsightClick ? () => onInsightClick(id) : undefined}
    >
      <rect x={cx - 12} y={cy - 12} width={size+2} height={size+2} stroke="transparent" rx={radius} ry={radius} fill="#fff" />
      <rect x={cx - 10} y={cy - 10} width={size-2} height={size-2} stroke="transparent" rx={radius} ry={radius} fill={color} />
    </svg>
  </MuiTooltip>;
};

let activeType = null;

const BarWithBorder = (borderWidth, borderColor) => {
  return (props) => {
    const { fill, x, y, width, height, stroke, className } = props;
    return (
      <g className="bar-w-border">
        {fill !== 'transparent' ? <rect x={x - borderWidth} y={y - borderWidth/2} width={width + borderWidth*2} height={borderWidth} stroke="none" fill={"none"} /> : null}
        {fill !== 'transparent' ? <rect x={x - borderWidth} y={y} width={borderWidth} height={height} stroke="none" fill={borderColor} /> : null}
        <rect fill={fill} x={x} y={y} width={width} height={height} stroke={stroke} className={className} />
        {fill !== 'transparent' ? <rect x={width + x} y={y} width={borderWidth} height={height} stroke="none" fill={borderColor} /> : null}
        {fill !== 'transparent' ? <rect x={x - borderWidth} y={y} width={width + borderWidth*2} height={borderWidth} stroke="none" fill={borderColor} /> : null}
      </g>
    );
  };
};

const renderSeries = (index, layout, showValueLabels, valueFormatter, data, metadata, onClick, dataKey = '', selectedBarKey, selectedSubType,
                      selectedCategory, allowSelectingCategories, percentageLabel, percentageFormatter, isLast, periodsClassifications, mainColor,
                      dataKeys, isCompareMode, onInsightClick, growthVisibility) => {
  const elStyle = onClick && {'cursor': 'pointer'};

  const isSelected = (dataKeyValue, categoryDataKey) => {
    const isPeriodSelected = dataKey && selectedBarKey && selectedBarKey === dataKeyValue;
    if (isPeriodSelected && !selectedCategory)
      return true;
    if (isPeriodSelected) {
      // if (['endingBalance', 'startingBalance', 'transparent'].find(i => selectedCategory.endsWith(i)))
      //   return true;
      if (selectedCategory === categoryDataKey)
        return true;
    }
    if (selectedSubType && selectedSubType === categoryDataKey) {
      return true;
    }
    if (!dataKeyValue && selectedCategory && allowSelectingCategories && !['endingBalance', 'startingBalance', 'transparent'].find(i => selectedCategory.endsWith(i)) && selectedCategory === categoryDataKey) {
      return true;
    }
    return metadata.defaultSelected || false;
  };

  const isPartial = (period, isStacked, selectedCategory, periodsClassifications) => {
    return !!(periodsClassifications && periodsClassifications[period] && ['Partial', 'Forecast', 'Committed'].includes(periodsClassifications[period]));
  };

  const getRadius = (index) => {
    const currentBarType = metadata.key;
    const alternativeRegularRadius = layout === 'vertical' ? [0,4,4,0] : [4,4,0,0];
    const alternativeOppositeRadius = layout === 'vertical' ? [4,0,0,4] : [0,0,4,4];
    const subType = ['new', 'expansion', 'contraction', 'reactivation', 'startingBalance', 'churn', 'endingBalance', 'add', 'sub'].find(type => currentBarType.endsWith(type));
    if (metadata.stackId && subType) {
      let currentBarData;
      if (data[index].segments) {
        const segment = getSegmentName(currentBarType);
        currentBarData = Object.assign({}, data[index].segments[segment]);
      } else {
        currentBarData = Object.assign({}, data[index]);
      }
      Object.keys(currentBarData).filter(key => !dataKeys.find(dk => dk.endsWith(key))).forEach(key => {
        delete currentBarData[key];
      });

      if (!currentBarData)
        return false;
      switch (subType) {
        case 'new': {
          if (currentBarData?.new > 0)
            return alternativeRegularRadius;
          break;
        }

        case 'expansion': {
          if (!currentBarData?.new && !currentBarData?.reactivation && currentBarData?.expansion > 0)
            return alternativeRegularRadius;
          break;
        }

        case 'add': {
          if (!currentBarData?.new && !currentBarData?.reactivation && currentBarData?.add > 0)
            return alternativeRegularRadius;
          break;
        }

        case 'reactivation': {
          if (!currentBarData?.new && currentBarData?.reactivation > 0)
            return alternativeRegularRadius;
          break;
        }

        case 'sub': {
          if (!currentBarData?.new && !currentBarData?.add && !currentBarData?.startingBalance && currentBarData?.sub > 0) {
            if (!currentBarData?.churn)
              return metadata.revertRadius ? alternativeOppositeRadius : alternativeRegularRadius;
            return null;
          }

          if (!currentBarData?.new && !currentBarData?.add && !currentBarData?.startingBalance && !currentBarData?.reactivation) {
            if (currentBarData?.sub < 0)
              return alternativeOppositeRadius;
            else if (currentBarData?.contraction > 0)
              return alternativeRegularRadius;
          }
          break;
        }

        case 'contraction': {
          if (!currentBarData?.new && !currentBarData?.expansion && !currentBarData?.startingBalance && currentBarData?.contraction > 0) {
            if (!currentBarData?.churn)
              return metadata.revertRadius ? alternativeOppositeRadius : alternativeRegularRadius;
            return null;
          }

          if (!currentBarData?.new && !currentBarData?.expansion && !currentBarData?.startingBalance && !currentBarData?.reactivation) {
            if (currentBarData?.contraction < 0)
              return alternativeOppositeRadius;
            else if (currentBarData?.contraction > 0)
              return alternativeRegularRadius;
          }
          break;
        }

        case 'churn': {
          if (!currentBarData?.new && !currentBarData?.expansion && !currentBarData?.startingBalance && currentBarData?.churn > 0)
            return metadata.revertRadius ? alternativeOppositeRadius : alternativeRegularRadius;
          if (!currentBarData?.new && !currentBarData?.expansion && !currentBarData?.startingBalance && !currentBarData?.contraction && !currentBarData?.reactivation) {
            if (currentBarData?.churn < 0)
              return alternativeOppositeRadius;
            else if (currentBarData?.churn > 0)
              return alternativeRegularRadius;
          }
          break;
        }

        case 'startingBalance': {
          if (!currentBarData?.new && !currentBarData?.expansion && !currentBarData?.reactivation && currentBarData?.startingBalance > 0)
            return alternativeRegularRadius;
          break;
        }

        case 'endingBalance': {
          if (!currentBarData?.new && !currentBarData?.expansion && currentBarData?.endingBalance > 0)
            return alternativeRegularRadius;
          break;
        }
      }
    }

    if (!metadata.stackId || metadata.addRadius)
      return alternativeRegularRadius;
  };

  const isTransparent = metadata.color === 'transparent';

  switch(metadata.type) {
    case SERIES_TYPES.BAR: {
      const attrs = metadata.radius ? {radius: metadata.radius} : {};
      return (<Bar key={`composed_bar_${index}`}
                   className={metadata.stackId ? `stacked-bar ${metadata.stackId}` : undefined}
                   stackId={metadata.stackId}
                   dataKey={metadata.key}
                   fill={metadata.color}
                   onClick={isTransparent ? null : onClick}
                   style={{cursor: isTransparent ? 'default' : 'pointer'}}
                   // shape={BarWithBorder(3, mainColor)}
                   onMouseEnter={() => activeType = metadata.key}
                   onMouseLeave={() => activeType = null}
                   {...attrs}
        >
          {!metadata.stackId || metadata.useForLabels ?
            <LabelList
                       formatter={valueFormatter}
                       fill={metadata.labelColor}
                       content={(props) => labelRenderer({props, showValueLabels, selectedBarKey, data, dataKey: metadata.labelValue || (metadata.stackId ? 'endingBalance' : metadata.key), layout, type: metadata.type, growthVisibility})}
            />
            : null}
          {showValueLabels && percentageLabel ?
            <LabelList dataKey={percentageLabel}
                       formatter={(v) => {
                         const value = (percentageFormatter) ? percentageFormatter(v) : v;
                         if (v > 2.5)
                           return value;
                         return '';
                       }}
                       content={(props) => labelRenderer({props, showValueLabels, selectedBarKey, data, dataKey, layout, type: metadata.type, isConcentrationValue: true})}
                       position={layout === 'vertical' ? 'insideRight' : (metadata.stackId ? 'center' : 'top')}
                       fill="#fbfbfb" fontWeight="bold" />
            : null}

          {(Array.isArray(data) ? data : []).map((entry, index) => {
              const cellProps = metadata.cellFormatter ? metadata.cellFormatter(entry) : {};
              if (metadata.colorFetcher) {
                cellProps.fill = metadata.colorFetcher(entry[dataKey]);
              }
              const radius = getRadius(index);
              return <Cell
                  className={cx({partial: isPartial(entry[dataKey], metadata.stackId, metadata.key, periodsClassifications), selected: isSelected(entry[dataKey], metadata.key), ['w-border-selection']: selectedCategory && allowSelectingCategories && !['endingBalance', 'startingBalance', 'transparent'].find(i => selectedCategory.endsWith(i)), ['allows-selection']: allowSelectingCategories && !['endingBalance', 'startingBalance', 'transparent'].find(i => metadata.key.endsWith(i))})}
                  key={`cell-${index}`}
                  stroke={'transparent'}
                  {...cellProps}
                  radius={radius}
                />
          })}
        </Bar>
      );
    }
    case SERIES_TYPES.REFERENCE_LINE:
      return <Line key={`composed_reference_line_${index}`} type="monotone" dataKey={metadata.key} style={elStyle} onClick={onClick} stroke={metadata.color}
                   dot={false} activeDot={false} strokeDasharray="2 3" strokeWidth="2" />;
    case SERIES_TYPES.LINE:
      return <Line key={`composed_line_${index}`}
                   type="monotone"
                   dataKey={metadata.key}
                   stroke={metadata.color}
                   strokeWidth={metadata.total ? 0 : 3}
                   dot={(props) => dotRenderer({
                     props,
                     isCompareMode,
                     showValueLabels,
                     selectedBarKey,
                     data,
                     dataKey,
                     color: metadata.color,
                     onClick,
                     onInsightClick
                   })}
                   activeDot={!!metadata.dot}

      >
        <LabelList formatter={valueFormatter} fill={metadata.labelColor} strokeWidth={0}
                   content={(props) => labelRenderer({
                     props,
                     showValueLabels,
                     selectedBarKey,
                     data,
                     dataKey: metadata.key,
                     layout,
                     type: metadata.type
                   })}
        />
      </Line>;
    case SERIES_TYPES.AREA:
      return <Area type="monotone" dataKey={metadata.key} opacity={0.3} fill={metadata.color} stroke={metadata.color}/>

  }
};

const GetTooltipElement = (metadata) => {
  if (metadata.tooltip)
    return metadata.tooltip;

  const {showTotalInTooltip, totalFieldName, tooltipTotalLabel} = metadata;

  const getTotalElement = (payload, compareSegmentName, totalValue = null) => {
    if (!showTotalInTooltip || !tooltipTotalLabel)
      return;
    if (totalValue === null) {
      const obj = compareSegmentName ? payload[0]?.payload.segments[compareSegmentName] : payload[0]?.payload;
      totalValue = obj[totalFieldName];
    }
    return <div className="total">
      <div>
        <i /><span>{tooltipTotalLabel}</span>
      </div>
      <div>{metadata.valueFormatter ? metadata.valueFormatter(totalValue) : totalValue}</div>
    </div>;
  };

  return ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const activeKeyName = metadata.disableTooltipActiveMode ? null : activeType;
      const isStackedCompare = payload[0]?.payload.segments && payload[0]?.className?.includes('stacked-bar');
      const percentageLabel = metadata?.percentageLabel;
      const percentageFormatter = metadata?.percentageFormatter;
      let compareSegmentName = ''
      if (isStackedCompare) {
        if (activeKeyName)
          compareSegmentName = getSegmentName(activeKeyName);
      }
      if (compareSegmentName || !isStackedCompare) {
        const keys = payload.filter(pl => pl.value || pl.payload?.segments || ['startingBalance', 'endingBalance'].find(stype => pl.dataKey.endsWith(stype)));
        keys.sort((pl1, pl2) => metadata.series.find(s => s.key === pl1.name).order - metadata.series.find(s => s.key === pl2.name).order);
        return (
          <div className={"custom-tooltip" + (!activeKeyName ? ' no-active' : '')}>
            <h4>{compareSegmentName}{' '}{metadata.dataFormatter ? metadata.dataFormatter(metadata.tooltipTitle || label) : metadata.tooltipTitle || label}</h4>
            {
              keys.map((pl, index) => {
                const segmentName = getSegmentName(pl.name);
                const seriesItem = metadata.series.find(s => s.key === pl.name && !s.tooltipExclude && (!compareSegmentName || compareSegmentName === segmentName));
                if (!seriesItem)
                  return null;
                const label = !compareSegmentName && (pl.payload?.segments) ? segmentName : seriesItem.label || pl.name;
                const percentValue = percentageLabel ? pl.payload && pl.payload[pl.name] || pl.payload?.value : null;
                const value = percentageLabel ? pl.payload && pl.payload[`${pl.name.replace('.percent', '')}`] || pl.value : pl.value;
                const percentage = percentValue ? percentageFormatter ? percentageFormatter(percentValue) : percentValue : null;
                return (
                  <div key={`tooltip_`+index} className={activeKeyName === pl.name ? 'active' : undefined}>
                    <div>
                      <i style={{backgroundColor: pl.color || metadata.color}}/><span>{label}</span>
                    </div>
                    <div>{metadata.valueFormatter ? metadata.valueFormatter(value) : value}</div>
                    {percentage ? <div>{percentage}</div> : null}
                  </div>
                );
              })
            }
            {getTotalElement(payload, compareSegmentName)}
          </div>
        );
      } else {
        const segmentTotals = Object.keys(payload[0].payload.segments).reduce((acc, segment) => {
          const value = payload[0].payload.segments[segment].endingBalance || Object.keys(payload[0].payload.segments[segment]).filter(st => ['startingBalance', 'new', 'expansion', 'contraction', 'reactivation', 'churn'].includes(st)).reduce((acc, revenueKey) => acc + payload[0].payload.segments[segment][revenueKey], 0);
          acc[segment] = { value, color: metadata.series.find(s => !s.key.endsWith('transparent') && s.key.includes(`segments['${segment}`) && !s.tooltipExclude )?.color };
          return acc;
        }, {});
        return (
          <div className={"custom-tooltip no-active"}>
            <h4>{metadata.dataFormatter ? metadata.dataFormatter(metadata.tooltipTitle || label) : metadata.tooltipTitle || label}</h4>
            {Object.keys(segmentTotals).map((segment, index) => {
              const seriesItem = segmentTotals[segment];
              if (!seriesItem)
                return null;
              const percentage = percentageLabel ? percentageFormatter ? percentageFormatter(seriesItem.percent) : seriesItem.percent : null;
              return (
                <div key={`tooltip_`+index} className={activeKeyName === segment ? 'active' : undefined}>
                  <div>
                    <i style={{backgroundColor: seriesItem.color}}/><span>{segment}</span>
                  </div>
                  <div>{metadata.valueFormatter ? metadata.valueFormatter(seriesItem.value) : seriesItem.value}</div>
                  {percentage ? <div>{percentage}</div> : null}
                </div>
              )
            })}
            {/*{getTotalElement(null, null, Object.values(segmentTotals).reduce((acc, item) => acc + item.value, 0))}*/}
          </div>
        );
      }
    }

    return null;
  };
};

const ComposedChart = ({ data, metadata, hideTootips, onChartClick, onBarClick, height, autoSelectLastBar, showValueLabels, growthVisibility,
                         layout, periodsClassifications, selectedSeriesKey:initialSelectedSeriesKey, selectedSeriesSubType, allowDeselectBar = false, isMainChart, isCompareMode: initialCompareMode, onInsightClick, ...rest}) => {
  const [selectedSeriesKey, setSelectedSeriesKey] = useState(initialSelectedSeriesKey);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCompareMode, setIsCompareMode] = useState(initialCompareMode);
  const allowSelectingCategories = !!rest.allowSelectingCategories;
  const dataKey = metadata?.dataKey || 'name';
  if (metadata?.series?.length <= 1)
    hideTootips = true;

  useEffect(() => {
    if (initialCompareMode !== isCompareMode)
      setIsCompareMode(initialCompareMode);
  }, [initialCompareMode]);

  useEffect(() => {
    setSelectedSeriesKey(initialSelectedSeriesKey);
  }, [initialSelectedSeriesKey]);

  const handleBarClick = (e, category) => {
    if (onBarClick && e.payload) {
        const clickedBarKey = e.payload[metadata?.dataKey || 'name'];
        if (clickedBarKey !== selectedSeriesKey || (allowSelectingCategories && selectedCategory !== category)) {
          setSelectedSeriesKey(clickedBarKey);
          if (allowSelectingCategories) {
            setSelectedCategory(category);
            onBarClick(clickedBarKey, category);
          } else {
            onBarClick(clickedBarKey);
          }
        } else if (allowDeselectBar) {
          setSelectedSeriesKey(null);
          setSelectedCategory(null);
          onBarClick(null);
        }
      }
  };

  const CustomYAxisTick = ({x, y, payload, tickFormatter}) => {
    return <text transform={`translate(${0},${y})`} className="recharts-text recharts-cartesian-axis-tick-value" x={0} y={0} stroke="none" textAnchor="start" type="number" fontSize={12} fill="#9B9B9B">
      <tspan dx="0" dy="0.355em">{tickFormatter ? tickFormatter(payload.value) : payload.value}</tspan>
    </text>;
  };

  const elipsis = (value, max= 12) => {
    return value && value.length > max ? value.substring(0, (max-3)) + "..." : value;
  };

  const CustomYAxisCategoryTick = ({x, y, payload, tickFormatter}) => {
    let value = tickFormatter ? tickFormatter(payload.value) : payload.value;
    if (!value)
      return null;

    let valueParts;
    if (typeof value === 'string') {
      valueParts = (value || '').split(' ').map(v => elipsis(v.trim()));
      if (valueParts.length > 2) {
        valueParts = valueParts.splice(0, 2);
        if (!valueParts[1].endsWith('...'))
          valueParts[1] = valueParts[1].substring(0, 9) + "...";
      }
    } else {
      valueParts = [value];
    }
    return valueParts.map((t, index) => <text transform={`translate(${0},${y + index * 8 - (valueParts.length === 1 ? -3 : 4)})`} className="recharts-text recharts-cartesian-axis-tick-value" x={72} y={0} textAnchor="end" stroke="none" fontSize={12} fill="#9B9B9B">
      <title>{value}</title>
      <tspan dx="0" dy={`${0.5*index}em`}>{t}</tspan>
    </text>);
  };

  const dimensions = height ? {height} : {aspect:2};
  const dataType = metadata?.dataType;
  const isAccumulativeChart = dataType === 'percent' && metadata?.dataKeyType === 'percent';
  const showValuesOnChart = showValueLabels && !isAccumulativeChart;
  const isLineChart = metadata?.series[0]?.type === SERIES_TYPES.LINE;
  const dataKeys = metadata.series.map(s => s.key);

  const classes = {
    selectable: !!onBarClick,
    hasSelection: !!selectedSeriesKey && data.some(item => item[metadata?.dataKey] === selectedSeriesKey)
  };

  return (
    <StyledComposedChart mainColor={metadata?.mainColor}>
      <ResponsiveContainer className={cx('chart', classes)} width="99%" {...dimensions}>
        <ComposedChartComponent
          layout={layout}
          onClick={onChartClick}
          style={onChartClick && {'cursor': 'pointer'} }
          stackOffset="sign"
          data={data}
          stroke={"#9B9B9B"}
          margin={{left: 20, top: 30, bottom: metadata?.xAxis?.label ? 30 : 0}}
          barCategoryGap="10%"
          barGap="1"
          fontSize={'12px'}>
          <CartesianGrid vertical={layout === 'vertical'} horizontal={layout !== 'vertical'}/>
          <XAxis
            margin={{top:40}}
            padding={isLineChart && (dataType === 'percent' || dataType === 'currency') && layout !== 'vertical' ? {right: 25} : isLineChart || (layout === 'vertical' && showValuesOnChart) ? {right: 70} : {}}
            domain={layout === 'vertical' ? [dataMin => Math.min(0, dataMin), dataMax => Math.max(0, dataMax)] : undefined}
            type={layout === 'vertical' ? 'number' : metadata?.dataKeyType}
            dataKey={layout === 'vertical' ? null : dataKey}
            tickFormatter={layout === 'vertical' ? (metadata.tickFormatter || metadata.valueFormatter) : metadata?.dataFormatter}
            tick={{ fontSize: '12px' }}
            axisLine={layout === 'vertical'}
            tickLine={false}
            stroke={"#9B9B9B"}
            fontWeight={"normal"}
            label={{ value: metadata?.xAxis?.label, position: 'bottom', style: {stroke: 'none', fill: '#9B9B9B'} }}
          />
          <YAxis
            margin={{top:40, bottom: 40}}
            tickCount={layout !== 'vertical' && isMainChart && isCompareMode ? 12 : undefined}
            interval={layout !== 'vertical' && isMainChart && isCompareMode ? 0 : undefined}
            padding={layout !== 'vertical' ? {top: 15} : {}}
            domain={layout === 'vertical' ? undefined : [dataMin => Math.min(0, dataMin), dataMax => Math.max(0, dataMax)]}
            type={layout === 'vertical' ? metadata?.dataKeyType : 'number'}
            dataKey={layout === 'vertical' ? dataKey : null}
            tickFormatter={layout === 'vertical' ? metadata?.dataFormatter : metadata.valueFormatter}
            tick={metadata?.yAxis?.align === 'left' ? <CustomYAxisTick /> : <CustomYAxisCategoryTick />}
            axisLine={false}
            tickLine={false}
            stroke={"#9B9B9B"}
            label={{ value: metadata?.yAxis?.label, angle: -90, style: {stroke: 'none', fill: '#9B9B9B'}}}
          />
          {!hideTootips && <Tooltip content={GetTooltipElement(metadata)} offset={50} />}
          {metadata.series.map((seriesMetadata, index) => (renderSeries(index, layout, showValuesOnChart, metadata.valueFormatter, data, seriesMetadata,
                                                                              onBarClick ? (e) => handleBarClick(e, seriesMetadata.key) : null,
                                                                               dataKey, selectedSeriesKey, selectedSeriesSubType, selectedCategory, allowSelectingCategories,
                                                                              metadata?.percentageLabel, metadata?.percentageFormatter, (metadata.series.length-1 === index),
                                                                              periodsClassifications, metadata.mainColor, dataKeys, isCompareMode, onInsightClick,
                                                                              isMainChart ? growthVisibility : null)
                                                                          ))}
        </ComposedChartComponent>
      </ResponsiveContainer>
    </StyledComposedChart>
  );
};

ComposedChart.propTypes = {
  data: PropTypes.array,
  hideTootips: PropTypes.bool,
  metadata: PropTypes.shape({
      xAxis: PropTypes.shape({
        label: PropTypes.string
      }),
      yAxis: PropTypes.shape({
        label: PropTypes.string
      }),
      series: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf([...Object.values(SERIES_TYPES)]),
        cellFormatter: PropTypes.func
      })),
      valueFormatter: PropTypes.func
  }),
  onChartClick: PropTypes.func,
  onBarClick: PropTypes.func
};

ComposedChart.defaultProps = {
  data: [],
  metadata: {},
  hideTootips: false
};

export default ComposedChart;
