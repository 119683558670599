import styled from "styled-components";

export const StyledCompanySupportCard = styled.div`
  margin: 20px 0;
  flex: 1;
  background-color: white;
  box-shadow: 0 1px 5px #ccc;
  border-radius: 5px;
  font-size: 12px;

  .clickable {
    color: #272727;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.link};
    }
  }

  .company-card {
  }

  .org-name-logo {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e9e9e9;

    .name-cnt {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      width: 300px;

      span {
        color: #9b9b9b;
      }
      .organization {
        font-weight: bold;
      }
    }
  }

  .link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.link};
    }
  }

  .watchlist {
    padding: 10px 20px;
  }

  .request {
    display: grid;
    grid-template-columns: 50px 300px 150px 150px 120px 100px 100px 50px;
    align-items: center;
    padding: 10px 20px;
    .status-pending {
      color: ${props => props.theme.colors.warning};
    }

    .status-in-progress {
      color: ${props => props.theme.colors.cyan};
    }

    .status-done {
      color: ${props => props.theme.colors.good};
    }

    .status-aborted {
      color: ${props => props.theme.colors.red};
    }

    .name-cnt {
      display: flex;
      flex-direction: column;
      width: 300px;

      span {
        color: #9b9b9b;
      }
      .organization {
        font-weight: bold;
      }
    }

    .created-date {
      > div:last-child {
        color: ${props => props.theme.colors.legend};
      }
    }
  }

`;
