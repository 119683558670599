import styled from 'styled-components';

export const StyledFieldsDefinitionCard = styled.div`
  height: 100%;
  overflow: hidden;
  .fields-list {
    height: 90%;
    .fields {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 10px;
      }

      h4 {
        margin: 4rem 0 1rem 0;
      }
    }

    .field-row {
      display: grid;
      grid-template-columns: 200px 180px 180px 180px 180px 180px;
      align-items: center;
      margin-left: 4rem;
      border-bottom: 1px solid;
      border-bottom-color: ${props => props.theme.colors.lighterGray};

      > div {
        padding: 10px 10px 10px 0;
      }

      &.file-date {
        grid-template-columns: 200px 300px 180px;
        position: relative;
        .rdtPicker {
          position: absolute;
          bottom: 54px;
          right: 205px;
        }
      }
    }

    .headers {
      color: ${props => props.theme.colors.gray};
      font-size: 12px;
    }
  }

  .label {
    span {
      font-size: 10px;
      display: block;
      color: ${props => props.theme.colors.label};
    }
    em {
      color: ${props => props.theme.colors.legend};
    }
  }
  //
  //.label-input {
  //  width: 300px;
  //}

  //.datatype-input {
  //  width: 200px;
  //}
  //
  //.revenuetype-input {
  //  width: 200px;
  //}

  //.format-input {
  //  width: 140px;
  //}

  //.options-input {
  //  width: 150px;
  //}
  //
  .currency-input {
    width: 80px;
  }

  .filterable-chk-cnt {
    font-size: 1.4rem;
    margin-left: auto;
  }


`;

