import styled from 'styled-components';

export const StyledBreadcrumbs = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  min-height: 50px;

  .link-cnt {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.linkhover};
      text-decoration: underline;
    }
    .active {
      font-weight: normal;
      color: unset;
    }
  }

  .parent-path {
    display: flex;
    align-items: center;
    height: 28px;
    button {
      font-size: 18px;
      color: ${props => props.theme.colors['text-base']};
      font-family: ${props => props.theme.fonts['primary-font']};
    }
    .menu__list > ul > li {
      font-weight: normal;
    }
    &.current {
      display: flex;
      align-items: center;
      font-weight: bold;
      button {
        font-weight: bold;
      }
    }
  }


  .separator {
    margin: 0 1rem;
    font-size: 10px;
  }

  .tag {
    background: #e1e1e1;
    padding: 0 0 0 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    .delete {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      display: block;
      text-align: center;
      justify-content: center;
      padding: 0 7px;
      margin-left: 5px;
      border-radius: 0 4px 4px 0;
      line-height: 25px;
      border-left: 1px solid transparent;
      &:hover {
        background: ${props => props.theme.colors.gray};
        border-left: 1px solid ${props => props.theme.colors.lightGray};
      }
    }
  }

  .search-icon {
    cursor: pointer;
    font-size: 24px;
    margin-right: 10px;
    color: ${props => props.theme.colors['text-base']};
    &:hover {
      color: #000;
    }
  }

  .search-input {
    padding: 4px;
    width: 220px;
  }

  .close-icon {
    margin-top: 3px;
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #999;
    color: #999;
    padding: 2px;
    &:hover {
      color: #000;
      border-color: #000;
    }
  }

  .info {
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 20px;
    font-size: 12px;
    color: ${props => props.theme.colors.legend};

    .error-indicator {
      color: red;
    }

    button, svg {
      color: inherit;
    }
  }
`;

