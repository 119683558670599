import React, { Fragment } from "react";
import { StyledError } from './Error.style';

export default function Error({ error, className }) {
  if (!error)
    return null;

  return (
      <StyledError className={className}>
        <div className="error-txt">{getErrorText(error)}</div>
      </StyledError>
    );
};

function getSingleErrors(error) {
  return (error || '').split(' | ');
}

function cleanError(error) {
  return (error || '').replace('Request validation error(s):', '');
}

function getErrorText(error) {
  const errors = Array.isArray(error) ? [].concat(...error.map(e => getSingleErrors(e))) : getSingleErrors(error);
  return errors.map((e,i) => <Fragment key={`text_${i}`}>{i > 0 ? <br /> : null}{cleanError(e)}</Fragment>);
}
