import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  thead {
    position: sticky;
    top: 0;
    background: #e9e9e9;
    td {
      border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    }

    .col-header {
      display: inline-flex;
      align-items: center;
    }
  }
  tbody {
    td {
      text-align: center;
      border-bottom: 1px solid ${props => props.theme.colors.lightGray};
      em {
        color: ${props => props.theme.colors.legend};
        font-size: 12px;
      }
    }
  };
`;
