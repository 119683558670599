import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from "../FormLabel";
import { TextFieldStyle } from './TextField.style';
import Typography from '../Typography';

function TextField({label, value, children}) {
  return (
    <TextFieldStyle className={"startup-card_kpi"}>
      <FormLabel label={label} />
      {children ? children : <Typography weight={"bold"}>{value}</Typography>}
    </TextFieldStyle>);
}

TextField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.any
};

TextField.defaultProps = {
    label: '',
    value: '',
};

export default TextField;
