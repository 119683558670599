import styled from 'styled-components';

export const StyledKpiValue = styled.div`
  font-family: ${props => props.theme.fonts['primary-font']};
  display: flex;
  justify-content: center;
  flex-direction: column;
  > .h3 {
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    padding: 0;
    color: inherit;
  }
  .value {
    font-size: 18px;
  }
  ${props => !!props.loading ? `
    display: flex;
    justify-content: center;
    align-items: center;
  `: ''}
`;
