import React, {useEffect, useMemo, useRef, isValidElement} from "react";
import PropTypes from 'prop-types';
import {useTable, useSortBy} from 'react-table';
import {StyledTable} from './Table.style';
import Typography from '../Typography';
import Converters from '../../utils/converters';
import LegendFormatter from './LegendFormatter';
import {ArrowDropDown, ArrowDropUp} from "../../assets/mui-icons";
import enums from '../../utils/enums';

const formatValueByType = ({value, column}) => {
  let textElement;
  const textProps = value?.textProps || {};
  if ((value === undefined || value === null) && column.allowEmptyValue) {
    textElement = '';
  } else {
    if (typeof value !== 'object')
      value = {label: value, type: column.dataType};

    switch (value?.type || column.dataType) {
      case enums.FieldDataType.COLOR_LEGEND:
        textElement = <LegendFormatter color={value?.label}/>;
        break;
      case enums.FieldDataType.LEGEND:
        textElement = <LegendFormatter color={value?.color} label={value?.label}/>;
        break;
      case enums.FieldDataType.CURRENCY:
        if (column.allowEmptyValue && value?.label === undefined)
          textElement = '';
        else
          textElement = Converters.toCurrencyString(value?.label);
        break;
      case enums.FieldDataType.POSITIVE_CURRENCY:
        textElement = Converters.toCurrencyString(value?.label, {positiveOnly: true});
        break;
      case enums.FieldDataType.DATE:
        textElement = Converters.toDateString(value?.label);
        break;
      case enums.FieldDataType.NUMBER:
      case enums.FieldDataType.NUMERIC:
        textElement = Converters.thousandSeparator(value?.label || 0);
        break;
      case enums.FieldDataType.PERCENT:
      case enums.FieldDataType.PERCENTAGE:
        textElement = isNaN(value?.label) ? value?.label : `${Number(value?.label).toFixed(2).replace('.00', '')}%`;
        break;
      default:
        if (isValidElement(value))
          textElement = value;
        else
          textElement = value?.label || '';
        break;
    }
  }

  return <Typography size={"14px"} {...textProps}>{textElement}</Typography>;
}


const parseColumnsData = (data) => {
  return data.map(colData => {
    if (!colData.Cell) {
      colData.Cell = formatValueByType;
    }
    return colData;
  });
}

function Table({columns, data, isLoading, className, highlightClassName = 'highlight', highlightedColumns = [], initialSort = [], cbGetRowClassName, cbGetCellClassName}) {
  const columnsData = useMemo(() => parseColumnsData(columns), [columns]);
  const element = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (element && element.current)
        element.current.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});
    }, 300);
  }, [highlightedColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: columnsData,
    data,
    initialState: {
      sortBy: initialSort
    },
    autoResetSortBy: false
  }, useSortBy);

  const getHighlightClass = (header) => {
    if (highlightedColumns.includes(header))
      return highlightClassName;
  };

  const getHeaderStyles = (column, value) => {
    return {
      style: {
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
        textAlign: column.align,
        padding: '10px',
        cursor: column.sortable ? 'pointer' : 'default',
        color: Converters.isZero(value) || value === 'N/A' ? '#ccc' : ''
      }
    };
  };

  const renderSortingIndicator = (column) => {
    if (column.sortable && column.isSorted) {
      return column.isSortedDesc ? <ArrowDropDown /> : <ArrowDropUp />;
    }
  };

  return (
    <StyledTable {...getTableProps()} className={`Table-container${isLoading ? ' loading' : ''}${className ? ` ${className}` : ''}`}>
      <thead>
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <tr key={`header_group_${headerGroupIndex}_cols_${columns.length}`} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const isHighlighted = highlightedColumns.includes(column.Header);
            let headerProps = column.sortable ? column.getSortByToggleProps() : {};
            headerProps = {
              ...headerProps,
              ...getHeaderStyles(column),
            }

            return <th
              ref={isHighlighted ? element : null}
              key={`header_${column.Header}`}
              className={getHighlightClass(column.Header)}
              {...column.getHeaderProps(headerProps)}
            >
              <Typography className='col-header' size={"14px"} weight={"bold"}>
                {column.render('Header')}
                {renderSortingIndicator(column)}
              </Typography>
            </th>;
          })}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const rowClasses = [];
          if (row?.original?.summaryRow)
            rowClasses.push('summary');
          if (cbGetRowClassName)
            rowClasses.push(cbGetRowClassName(row));

          return (
            <tr {...row.getRowProps({className: rowClasses.join(' ')})}>
              {row.cells.map((cell, cellIndex) => (
                <td
                  key={`row_${row.id}_${row.name}_col_${cell.column.Header}`}
                  className={[getHighlightClass(cell.column.Header), cbGetCellClassName ? cbGetCellClassName(row, cellIndex) : ''].join(' ')}
                  {...cell.getCellProps(getHeaderStyles(cell.column, cell.value))}
                >
                  {cell.render('Cell')}
                </td>
              ))
              }
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      dataType: PropTypes.string
    })
  ),
  data: PropTypes.array,
  cbGetRowClassName: PropTypes.func,
  cbGetCellClassName: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  data: {}
};

export default Table;
