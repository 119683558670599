import styled from 'styled-components';

export const StyledLoading = styled.div`
  display: flex;
  align-items: center;

  i {
    &.spinner {
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;

      &.small {
        width: 12px;
        height: 12px;
        border-width: 2px;
      }

      &.medium {
        width: 24px;
        height: 24px;
        border-width: 3px;
      }

      &.xlarge {
        width: 15rem;
        height: 15rem;
        border-width: 3px;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.small {
    > span {
      font-size: 12px;
    }
  }

  &.medium {
    > span {
      font-size: 16px;
    }
  }

  &.xlarge {
    > span {
      font-size: 40px;
    }
  }
`


