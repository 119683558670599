import styled from 'styled-components';

export const StyledMoreMenu = styled.div`
  &.menu {
    position: relative;
  }
  .menu__opener {
    color: #aaa;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    align-items: center;
    display: flex;
    height: 32px;
    width: 32px;
    > svg {
      width: 32px;
      height: 32px;
    }
  }
  .menu__list {
    min-width: 15rem;
    max-height: 500px;
    overflow: auto;
    position: fixed;
    z-index: 12;
    background-color: #efefef;
    padding:0.2rem;
    border-radius: 0.4rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    transform: scale(0.8);
    transition-property: opacity, transform, visibility;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.26, 0.20, 0.020, 1.6);
    visibility: hidden;
    opacity: 0;
    > ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        line-height: normal;
        margin: 0;
        padding: 0;
        > li {
          font-size: 1.4rem;
          color: #333;
          display: inline-block;
          text-decoration: none;
          padding: 0.8rem 1.2rem;
          border-radius: 0.2rem;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            background-color: #fff;
          }
          &.separator {
            border-top: 1px solid #ccc;
          }

          .selected {
            display: inline-block;
            width: 20px;
          }

          &.section {
            cursor: default;
            font-size: 12px;
            color: ${props => props.theme.colors.label};
            span {
              font-weight: bold;
            }
            &:hover {
              background: none;
            }
            &:not(:first-child) {
              margin-top: 0.5rem;
            }
          }

          &.disabled {
            cursor: default;
            color: ${props => props.theme.colors.disabled};
          }
        }
        .user {
          display: flex;
          flex-direction: column;
          color: #12999E;
          cursor: default;
          &__organization {
            font-weight: 500;
            white-space: nowrap;
          }
          &__name {
            font-size: 1.2rem;
          }
          &:hover {
            background-color: #efefef;
          }
        }
        .db {
          cursor: default;
        }
    }
  }

  &.menu--open {
    .menu__list {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    svg {
      color: ${props => props.theme.colors['text-base']};
    }
  }

`;
