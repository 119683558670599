import styled from 'styled-components';

export const StyledChartSettingsPanel = styled.div`
  .h3 {
    font-size: 12px;
    color: ${props => props.theme.colors.label};
    margin-top: 2rem;
  }
  .filters {
    font-size: 18px;
    line-height: 25px;
    height: 61px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${props => props.theme.colors['text-base']};
    padding: 16px;
    svg {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    > span {
      margin-left: 5px;
      font-weight: normal;
      font-size: 16px;
    }
    &.active {
      color: ${props => props.theme.colors.navigation};
    }
  }

  .toggle-button {
    display: flex;
    margin-bottom: 2.4rem;
    button {
      width: 100%;
    }
  }

  .sort-by {
    font-weight: normal;
    font-size: 12px;
    color: #5B5B5B;

    .text-btn {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .date-range-selector {
    display: inline;
    > div {
      background: none;
      height: 25px;
      > div {
        padding-left: 0;
      }
    }
    fieldset {
      display: none;
    }
  }

  .list {
    padding: 0 16px;
    label {
      span {
        width: 100%;
      }
      width: 100%;
      .filter-value-container {
        //text-transform: capitalize;
        display: flex;
        justify-content: space-between;
      }
      .list-item-percentage {
        margin-left: 10px;
        color: ${props => props.theme.colors.gray};
        width: auto;
      }
    }
  }
`;
