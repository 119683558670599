import styled from "styled-components";

export const StyledOnboardingAlertsPanel = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
  position: relative;
  font-size: 1.5rem;
  background-color: ${props => props.theme.colors.green};
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
  border-radius: 4px;
  color: white;

  .title {
    font-size: 2.2rem;
    font-weight: bold;
  }

  .sub-title {
    color: black;
    margin-bottom: 10px;
  }

  .item {
    display: flex;
    align-items: center;
    padding-top: 10px;
    font-weight: bold;

    .link {
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;

      .bullet {
        margin-right: 10px;
      }
      &:hover {
        color: ${props => props.theme.colors.linkhover};
        .bullet {
          text-decoration: none;
        }
        span {
          text-decoration: underline;
        }
      }
    }

    &:not(.checked) {
      .bullet {
        width: 10px;
        height: 10px;
        border: 2px solid white;
        border-radius: 5px;
      }
      .link:hover {
        .bullet {
          border: 2px solid ${props => props.theme.colors.linkhover};
        }
      }
    }

    &.checked {
      .bullet::before {
        content: '✔';
      }
    }
  }
`;
