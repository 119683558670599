import React, {useEffect, useState} from "react";
import { StyledTeammateJoin } from './teammate-join.style';
import PageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from 'react-redux';
import { teamJoinAsync } from '../../../asyncActions/authAsyncActions';
import { validateJoinService } from '../../../api/auth';
import TextInput from "../../../components/TextInput";
import {useNavigate} from "react-router-dom";
import Button from "../../../components/Button";
import {useFormInput} from "../../../hooks";
import {validateEmail, validatePassword} from "../../../utils/validators";
import Typography from "../../../components/Typography";
import Error from "../../../components/Error";

export default function TeammateJoin() {
  const navigate = useNavigate();
  const {registrationLoading, registrationError, isAuthenticated, user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [urlValidationError, setUrlValidationError] = useState(null);
  const [signatureData, setSignatureData] = useState(null);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    validateJoinService(location.href).then(({success, data, error}) => {
      if (success) {
        setSignatureData(data);
      }
      else {
        setUrlValidationError(error);
      }
    }).catch(() => setUrlValidationError('Unknown error'));
  }, []);

  useEffect(() => {
    if (isAuthenticated && user)
      navigate(`/`);
  }, [isAuthenticated, user, navigate]);

  const email = useFormInput(signatureData?.email);
  const name = useFormInput(signatureData?.name);
  const password = useFormInput('');
  // const agree = useFormInput(false);

  if (registrationLoading)
    return <div>Joining the team ...</div>;

  if (urlValidationError)
    return <div>Url is no longer valid!</div>;

  if (!signatureData)
    return <div>Validating...</div>;

  const validateForm = () => {
    if (!password.value || !email.value || !name.value)
      return setFormError('Please fill in all mandatory fields');
    let validation = validateEmail(email.value);
    if (!validation.valid)
      return setFormError(validation.error);
    validation = validatePassword(password.value);
    if (!validation.valid)
      return setFormError(validation.error);
    // if (!agree.value)
    //   return setFormError(
    //     'You must agree to the Privacy Policy and the Terms and Conditions'
    //   );
    setFormError('');
    return true;
  };

  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (validateForm()) {
      dispatch(teamJoinAsync(location.href, name.value, password.value, Intl.DateTimeFormat().resolvedOptions().timeZone));
    }
  };

  const handleKeypress = (e) => {
    // it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <StyledTeammateJoin>
      <PageTitle variant="h1" lineheight="1">{signatureData.isExternal ? 'Setup your account' : <>Join your team at {signatureData.organizationName}</>}</PageTitle>
      <div className="input-container">
        <TextInput
          label="Email"
          disabled
          autoComplete="new-password"
          onKeyPress={handleKeypress}
          {...email}
        />
      </div>
      <div className="input-container">
        <TextInput
          label="Name"
          onKeyPress={handleKeypress}
          {...name}
        />
      </div>
        <div className="input-container">
          <TextInput
            label="Password"
            type="password"
            autoComplete="new-password"
            onKeyPress={handleKeypress}
            {...password}
          />
        </div>
        {/*<div className="checkbox">*/}
        {/*  <Checkbox {...agree}>*/}
        {/*    By clicking submit you are agreeing to the{' '}*/}
        {/*    <NavLink to="/privacy" rel="noopener noreferrer" target="_blank">*/}
        {/*      Privacy Policy*/}
        {/*    </NavLink>{' '}*/}
        {/*    and the{' '}*/}
        {/*    <NavLink to="/terms" rel="noopener noreferrer" target="_blank">*/}
        {/*      Terms and Conditions*/}
        {/*    </NavLink>*/}
        {/*  </Checkbox>*/}
        {/*</div>*/}

        <Typography variant="p" className="agreement">
          By clicking 'Sign up', you acknowledge that you have read and understood our <a href="https://www.novvo.ai/terms" target="_blank">Terms of Use</a> and <a href="https://www.novvo.ai/privacy" target="_blank">Privacy Policy</a>, and agree to be bound by the Terms of Use.
        </Typography>

        <Button
          disabled={registrationLoading}
          onClick={handleSubmit}
          isLoading={registrationLoading}
        >
          Sign up
        </Button>
        {formError || registrationError ?<Error className="error" error={formError || registrationError} /> : null}
    </StyledTeammateJoin>
  );
};
