import React, {useEffect, useState} from "react";
import {StyledDataFilesPanel, StyledWizard, StyledActions} from './DataFilesPanel.style';
import {InvestorsAPI, StartupsAPI} from "../../api";
import {useSelector} from "react-redux";
import moment from "moment";
import enums from "../../utils/enums";
import { NavLink, useNavigate } from "react-router-dom";
import routes from './../../pages/routes';
import Icon from "../Icon";
import Button from "../Button";
import DataGenerationNotification from "../dialogs/DataGenerationNotification";
import Loading from "../Loading/Loading";
import DataTypesManager from "./DataTypesManager";
import IconButton from "../IconButton";
import FileSelector from "./FileSelector";
import ExcelViewer from './ExcelViewer';
import FieldsDefinitionCard from "./FieldsDefinitionCard";
import Error from "../Error";
import { TranslationHelper } from '../../utils/i18n';
import {HelpOutline, OpenInNewIcon} from "../../assets/mui-icons";
import {Confirm} from "../dialogs";
import OfflineGenerationNotification from "../dialogs/OfflineGenerationNotification";
import SubmitSupportRequestMsgBox from "../dialogs/SubmitSupportRequestMsgBox";
import NovvoSupport from "./NovvoSupport";
import FilesUpload from "../dialogs/FilesUpload";

const ErrorTypes = {
  Unknown: 0,
  GetFieldMapping: 1,
  GenerateData: 2,
  ValidationError: 10
};

export default function DataFilesPanel({watchlistId, isDataReady, className, onCancel, onSubmitSupportRequest, autoSelectFileType, popupMode = false, inlineMode = false}) {
  const {user} = useSelector((state) => state.auth);
  const isAdmin = user?.isAdmin;
  const isStartup = user?.isStartupMode;
  const org = user.Organizations.find(o => o.id === user.organizationId);
  const helpRoute = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;
  const [showEdit, setShowEdit] = useState(inlineMode);
  const [status, setStatus] = useState({});
  const [allFiles, setAllFiles] = useState([]);
  const [fieldsMapping, setFieldsMapping] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDataGenerationNotification, setShowDataGenerationNotification] = useState(false);
  const [showGeneratingOfflineNotification, setShowGeneratingOfflineNotification] = useState(0);
  const [wizardStepIndex, setWizardStepIndex] = useState(0);
  const [selectedSourceFileType, setSelectedSourceFileType] = useState(autoSelectFileType && Object.values(enums.SourceFileType).includes(autoSelectFileType) ? autoSelectFileType : null);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileLastModified, setSelectedFileLastModified] = useState();
  const [selectedSheet, setSelectedSheet] = useState();
  const [isExcelFileLoaded, setIsExcelFileLoaded] = useState(false);
  const [excelWizard, setExcelWizard] = useState({currentStepIndex: 0, steps: [], mapping: []});
  const [error, setError] = useState(null);
  const [company, setCompany] = useState();
  const [confirmData, setConfirmData] = useState({open: false});
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataFilesStatus();
  }, []);

  const fetchDataFilesStatus = () => {
    setIsLoading(true);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    API.getDataFilesStatus(watchlistId).then(({success, data, error}) => {
      if (success) {
        setStatus(data.status);
        setAllFiles(data.files);
        setFieldsMapping(data.fieldsMapping);
        setCompany(data.company);
      } else {
        setError({error, type: ErrorTypes.GetFieldMapping});
      }
      setIsLoading(false);
    });
  };

  const getFileMappingToSave = () => {
    let wizardKeys;
    switch (selectedSourceFileType) {
      case enums.SourceFileType.MRR:
        wizardKeys = enums.LoadFileWizardKeys.MRR;
        break;
      case enums.SourceFileType.Bookings:
        wizardKeys = enums.LoadFileWizardKeys.Bookings;
        break;
      case enums.SourceFileType.PnL:
        wizardKeys = enums.LoadFileWizardKeys.PnL;
        break;
      case enums.SourceFileType.CashFlow:
        wizardKeys = enums.LoadFileWizardKeys.CashFlow;
        break;
      case enums.SourceFileType.BalanceSheet:
        wizardKeys = enums.LoadFileWizardKeys.BalanceSheet;
        break;
      case enums.SourceFileType.Customers:
        wizardKeys = enums.LoadFileWizardKeys.Customers;
        break;
    }
    return excelWizard.mapping.filter(m => !wizardKeys || Object.values(wizardKeys).includes(m.key)).map(m => ({key: m.key, rows: m.dataRows, cells: m.cells}));
  };

  const toMonthString = (month) => {
    return moment(`2000-${month}-15`, 'YYYY-MM-DD').format('MMMM');
  };

  const validateByCompanyPreferences = () => {
    const attributionDateField = fieldsMapping.find(f => f.name === 'attributionDate');
    if (attributionDateField) {
      // const { isFiscalYearInput, fiscalYearEndingMonth } = attributionDateField?.additionalInfo || {};
      // if (isFiscalYearInput && fiscalYearEndingMonth && fiscalYearEndingMonth !== company.FiscalYearEndingMonth && company.FiscalYearEndingMonth !== 12) {
      //   const texts = [
      //     `The fiscal year end was set to ${toMonthString(company.FiscalYearEndingMonth)}.`,
      //     `Selecting ${toMonthString(fiscalYearEndingMonth)} will overwrite previous settings.`
      //   ];
      //   setConfirmData({open: true, texts});
      //   return false;
      // }
      // let minPeriodResolution = enums.Period.Monthly;
      // if (attributionDateField.format.includes('Q'))
      //   minPeriodResolution = enums.Period.Quarterly;
      // else if (['YYYY', 'YY'].includes(attributionDateField.format))
      //   minPeriodResolution = enums.Period.Yearly;
      //
      // if ([enums.SourceFileType.MRR, enums.SourceFileType.PnL].includes(selectedSourceFileType)) {
      //   const isMRR = selectedSourceFileType === enums.SourceFileType.MRR;
      //   const comparedIsFiscalYearInput = isMRR ? company.ExpensesFiscalInput : company.BookingsFiscalInput;
      //   const comparedMinPeriodResolution = isMRR ? company.MinimumExpensesResolution : company.MinimumBookingResolution;
      //   if (!!isFiscalYearInput !== comparedIsFiscalYearInput && minPeriodResolution !== enums.Period.Monthly && comparedMinPeriodResolution !== enums.Period.Monthly) {
      //     const entityText = isMRR ? 'P&L' : 'MRR';
      //     const texts = [
      //       `Settings here differ from those applied to the ${entityText}.`,
      //       `Click 'Overwrite' to apply this setting to all files or 'Cancel' to keep the same settings applied to ${entityText}.`,
      //       'Note: Fiscal year settings shall be identical for all input data.'
      //     ];
      //     setConfirmData({open: true, texts});
      //     return false;
      //   }
      // }
    }
    return true;
  };

  const onGenerateData = async () => {
    setShowDataGenerationNotification(true);
    setError(null);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    const fields = fieldsMapping.filter(f => f.sourceFileType === selectedSourceFileType);
    const result = await API.generateData(selectedSourceFileType, selectedFile, selectedSheet, getFileMappingToSave(), fields, watchlistId);
    if (result.success) {
      setError(null);
      if (result.data.offlineMode) {
        setShowDataGenerationNotification(false);
        setShowGeneratingOfflineNotification(result.data.limit);
      } else {
        if (isStartup) {
          window.location.reload();
        } else {
          fetchDataFilesStatus();
          if (onCancel)
            onCancel();
          else
            setWizardStepIndex(0);
          if (watchlistId) {
            const watchlistUrl = routes.Investors.getWatchlistAnalyticsUrl(watchlistId, selectedSourceFileType === enums.SourceFileType.PnL ? enums.AnalyticsKind.PnL : enums.AnalyticsKind.SaasMetrics);
            window.location = `${window.location.origin}${watchlistUrl}`;
          }
        }
      }
    } else if (result.error) {
      setError({error: result.error, type: ErrorTypes.GenerateData});
    }
    setShowDataGenerationNotification(false);
    return result;
  };

  const updateExcelNextStep = () => {
    const newState = {...excelWizard};
    newState.currentStepIndex++;
    if (selectedSourceFileType === enums.SourceFileType.Bookings) {
      const currentWizardStep = newState.steps[excelWizard.currentStepIndex];
      const currentSelection = newState.mapping.find(s => s.key === currentWizardStep.key);
      switch (currentWizardStep.key) {
        case enums.LoadFileWizardKeys.Bookings.PeriodMonthsLength:
          if (currentSelection?.cells.length) {
            newState.currentStepIndex++;
            newState.mapping = newState.mapping.filter(s => s.key !== enums.LoadFileWizardKeys.Bookings.EndDate);
          }
          else
            newState.mapping = newState.mapping.filter(s => s.key !== enums.LoadFileWizardKeys.Bookings.PeriodMonthsLength);
          break;
        case enums.LoadFileWizardKeys.Bookings.RevenueColumns:
          if (currentSelection?.cells.length > 1) {
            newState.currentStepIndex++;
            newState.mapping = newState.mapping.filter(s => s.key !== enums.LoadFileWizardKeys.Bookings.RevenueCategories);
          }
          break;
      }
    }

    setExcelWizard(newState);
  };

  const updateExcelPreviousStep = () => {
    const newState = {...excelWizard};
    newState.currentStepIndex--;
    const previousWizardStep = newState.steps[newState.currentStepIndex];
    if (selectedSourceFileType === enums.SourceFileType.Bookings) {
      const previousSelection = newState.mapping.find(s => s.key === previousWizardStep.key);
      switch (previousWizardStep.key) {
        case enums.LoadFileWizardKeys.Bookings.EndDate:
          if (!previousSelection?.cells.length)
            newState.currentStepIndex--;
          break;
        case enums.LoadFileWizardKeys.Bookings.RevenueCategories:
          if (newState.mapping.find(s => s.key === enums.LoadFileWizardKeys.Bookings.RevenueColumns)?.cells.length > 1)
            newState.currentStepIndex--;
          break;
      }
    }

    setExcelWizard(newState);
  };

  const back = () => {
    if (WIZARD_STEPS[wizardStepIndex] === 'EXCEL_VIEWER' && excelWizard.currentStepIndex > 0)
      updateExcelPreviousStep();
    else {
      let nextIndex = wizardStepIndex - (WIZARD_STEPS[wizardStepIndex - 1] === 'SHEET_SELECTION' && !excelWizard.hasSheetSelection ? 2 : 1);
      if (WIZARD_STEPS[nextIndex] === 'FILE_SELECTION' && allFiles.length === 1)
        nextIndex--;
      updateWizardStepIndex(nextIndex);
    }
    setError(null);
  };

  const next = async () => {
    // Make sure when there is an error to not continue
    if (error)
      return;

    let nextStep = wizardStepIndex;
    if (WIZARD_STEPS[nextStep] === 'FILE_UPLOAD') {
      if (!allFiles.length) {
        setError('Please upload at least one file to continue');
        return;
      }
      if (autoSelectFileType && Object.values(enums.SourceFileType).includes(autoSelectFileType))
        nextStep++;
    }

    if (WIZARD_STEPS[nextStep] === 'TYPES_LIST') {
      if (selectedSourceFileType)
        onSourceFileTypeSelected();
      if (allFiles.length === 1) {
        onFileSelected(allFiles[0].name);
        updateWizardStepIndex(nextStep + 2);
        setError(null);
      }
      return;
    }
    else if (WIZARD_STEPS[nextStep] === 'EXCEL_VIEWER') {
      if (excelWizard.currentStepIndex + 1 < excelWizard.steps.length)
        return updateExcelNextStep();
      else {
        updateWizardStepIndex(nextStep + 1);
        nextStep++;
        setError(null);
        if (WIZARD_STEPS[nextStep] === 'FORMATTING') {
          setIsLoading(true);
          const API = isStartup ? StartupsAPI : InvestorsAPI;
          const result = await API.saveFileTemplate(selectedSourceFileType, selectedFile, selectedSheet, getFileMappingToSave(), watchlistId);
          if (result.error) {
            setError({error: result.error, type: ErrorTypes.GetFieldMapping});
          } else {
            result.data.forEach((f, index) => f.id = index + 1);
            setFieldsMapping(result.data);
          }
          setIsLoading(false);
        }
      }
      return;
    } else if (WIZARD_STEPS[nextStep] === 'FORMATTING') {
      if (!validateFieldsMapping())
        return;
      setError(null);
      if (!validateByCompanyPreferences())
        return;
      return onGenerateData();
    }
    updateWizardStepIndex(nextStep + 1);
    setError(null);
  };

  const updateWizardStepIndex = (newStepIndex, fileName) => {
    setWizardStepIndex(newStepIndex);
    if (newStepIndex <= 1) {
      setSelectedSheet();
      setIsExcelFileLoaded(false);
      setSelectedFile(fileName);
      setSelectedFileLastModified();
    }
  };

  const onSourceFileTypeSelected = () => {
    const templateSelectedFileName = status && Object.keys(status).includes(selectedSourceFileType) ? status[selectedSourceFileType].sourceFile : undefined;
    setExcelWizard({
      ...excelWizard,
      mapping: [],
      steps: EXCEL_WIZARD[selectedSourceFileType].wizardSteps,
      currentStepIndex: 0
    });
    updateWizardStepIndex(wizardStepIndex + 1, templateSelectedFileName);
  };

  const onFileSelected = (fileName, lastModified) => {
    setSelectedFile(fileName);
    setSelectedFileLastModified(lastModified);
    setIsExcelFileLoaded(false);
  };

  const validateFieldsMapping = () => {
    setError(null);
    const revenueFields = fieldsMapping.filter(f => f.name === 'revenue');
    const revenueCategoryFields = fieldsMapping.filter(f => f.name === 'revenueCategory');

    if(!revenueCategoryFields.length && revenueFields.some(f => !f.revenueType)) {
      setError({error: 'Revenue type must be selected.', type: ErrorTypes.ValidationError});
      return false;
    }

    if(revenueCategoryFields.some(f => !f.revenueType)) {
      setError({error: 'Revenue type must be selected.', type: ErrorTypes.ValidationError});
      return false;
    }

    if(fieldsMapping.filter(f => f.name === 'attributionDate').some(f => !f.format)) {
      setError({error: 'Date format must be selected.', type: ErrorTypes.ValidationError});
      return false;
    }

    return true;
  };

  const getAllSelections = () => {
    return excelWizard.mapping.reduce((acc, item) => {
      acc.rows.push(...item.rows || []);
      acc.cells.push(...item.cells || []);
      return acc;
    }, {rows: [], cells: []});
  };

  const updateRowsColumnsSelection = async (selection) => {
    const currentWizardStep = excelWizard.steps[excelWizard.currentStepIndex];
    setExcelWizard({
      ...excelWizard,
      mapping: [
        ...excelWizard.mapping.filter(s => s.key !== currentWizardStep?.key),
        {
          key: currentWizardStep?.key,
          ...selection
        }
      ]
    });
  };

  const onFieldsDefinitionChange = (fields) => {
    setFieldsMapping(fields);
    setError(null);
  };

  const renderFileUpload = () => {
    return <FilesUpload
          watchlistId={watchlistId}
          onFilesCollectionChanged={(files) => setAllFiles(files)}
        />
  };

  const renderTypesSelection = () => {
    return <DataTypesManager status={status} selectedFileType={selectedSourceFileType} onTypeSelected={(sourceFileType) => setSelectedSourceFileType(sourceFileType)} />;
  };

  const renderFileSelection = () => {
    return <FileSelector
      watchlistId={watchlistId}
      selectedFile={selectedFile}
      onFileSelected={onFileSelected}
    />;
  };

  const getHelpLink = (section) => {
    return <a href={(isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp) + `#${selectedSourceFileType}-${(section || '').toLowerCase()}`} target="_blank"><HelpOutline /></a>;
  };

  const renderWizardStep = () => {
    const currentWizardStep = excelWizard.steps[excelWizard.currentStepIndex];
    return <StyledWizard>
      <div className="wizard-steps">
        {currentWizardStep &&
        <div className="explanation">
          <span>{!currentWizardStep?.mandatory ? '(Optional) ': ''}{currentWizardStep?.text} {getHelpLink(currentWizardStep.key)}</span>
          <div className="sub-text">{currentWizardStep?.subText ? currentWizardStep?.subText : <>&nbsp;</>}</div>
        </div>
        }
      </div>
    </StyledWizard>;
  };

  const renderExcelViewer = (viewSelection) => {
    const currentWizardStep = !viewSelection && excelWizard.steps[excelWizard.currentStepIndex];
    const currentStepSelection = !viewSelection && excelWizard.mapping.find(s => s.key === currentWizardStep?.key);

    return <>
      <ExcelViewer
        fileName={selectedFile}
        sheet={selectedSheet}
        sourceFileType={selectedSourceFileType}
        watchlistId={watchlistId}
        selectionMode={currentWizardStep?.selectionMode}
        viewSelection={viewSelection}
        currentSelections={currentStepSelection}
        allSelections={getAllSelections()}
        resetScrollView={currentWizardStep.resetScrollView || false}
        onRowColumnsSelection={updateRowsColumnsSelection}
        onSheetSelected={(sheet) => setSelectedSheet(sheet)}
        onCancel={back}
        onError={(err) => setError({error: err, type: ErrorTypes.ValidationError})}
        onFileLoaded={(templates, dataRowToRowIndexMap, sheet, hasSheetSelection) => {
          const newState = {};
          if (templates && templates.length) {
            const template = templates.find(t => t.kind === selectedSourceFileType);
            let mapping = [];
            if (template) {
              mapping = resolveTemplateData(template.mapping, dataRowToRowIndexMap);
              newState.mapping = mapping;
            }
          }

          setExcelWizard({
            ...excelWizard,
            ...newState,
            hasSheetSelection
          });

          if (sheet)
            setSelectedSheet(sheet);

          setIsExcelFileLoaded(true);
          if (WIZARD_STEPS[wizardStepIndex] === 'SHEET_SELECTION' && (!hasSheetSelection || sheet)) {
            updateWizardStepIndex(wizardStepIndex + 1);
          }
        }}
      />
    </>;
  };

  const renderFieldsFormatting = () => {
    if (isLoading)
      return <div className="loading-indication"><Loading size="medium">Loading fields definition...</Loading></div>;

    return <div className="fields-definition-cnt">
      {error && error.type === ErrorTypes.GetFieldMapping ? <div className="error-message">
        There was an error fetching the data formatting based on your selections.<br />
        Please <span className="link" onClick={back}>go back</span> and make ensure the data selection was done according to the requirements
      </div> : <FieldsDefinitionCard
        sourceFileType={selectedSourceFileType}
        fieldsMapping={fieldsMapping}
        fileLastModified={selectedFileLastModified}
        onChange={onFieldsDefinitionChange}
        company={company}
      />}
    </div>;
  };

  const renderCurrentStep = () => {
    switch (WIZARD_STEPS[wizardStepIndex]) {
      case 'FILE_UPLOAD': return renderFileUpload();
      case 'TYPES_LIST': return renderTypesSelection();
      case 'FILE_SELECTION': return renderFileSelection();
      case 'EXCEL_VIEWER': return renderExcelViewer();
      case 'SHEET_SELECTION': return renderExcelViewer(true);
      case 'FORMATTING': return renderFieldsFormatting();
    }
  };

  const renderHeader = () => {
    const showBackButton = wizardStepIndex > 0;
    const showCloseButton = popupMode;
    let title = '';
     switch (WIZARD_STEPS[wizardStepIndex]) {
      case 'FILE_UPLOAD':
        title = 'Upload the files with the data you want to analyze';
        break;
      case 'TYPES_LIST':
        title = 'Select the data format you want to process';
        break;
      case 'FILE_SELECTION':
        title = `Select the file`;
        break;
      case 'SHEET_SELECTION':
        title = `Select the sheet`;
        break;
      case 'EXCEL_VIEWER':
        title = <>Mapping {TranslationHelper.get('MappingFileTypes', selectedSourceFileType)} data <span>({excelWizard.currentStepIndex + 1} of {excelWizard.steps.length})</span></>;
        break;
      case 'FORMATTING':
        title = `${TranslationHelper.get('MappingFileTypes', selectedSourceFileType)} settings`;
        break;
    }
    return <div className="panel-header">
      <div className="panel-header-title">
        {showBackButton ? <div className="first-action"><IconButton iconName="ArrowBack" tooltip="Back" onClick={back} /></div> : null}
        <div className="title">{title}</div>
        {showCloseButton ? <div><IconButton iconName="Close" tooltip="Close" onClick={() => onCancel()} /></div> : null}
      </div>
      {WIZARD_STEPS[wizardStepIndex] === 'EXCEL_VIEWER' ? renderWizardStep() : false}
    </div>;
  };

  /*
  const WIZARD_STEPS = [
  'FILE_UPLOAD',
  'TYPES_LIST',
  'FILE_SELECTION',
  'EXCEL_VIEWER',
  'FORMATTING'
];
   */
  const isStepValid = () => {
    if (isLoading)
      return false;

    if (WIZARD_STEPS[wizardStepIndex] === 'FILE_UPLOAD')
      return allFiles.length > 0;

    if (WIZARD_STEPS[wizardStepIndex] === 'TYPES_LIST') {
      return !!selectedSourceFileType;
    }

    if (WIZARD_STEPS[wizardStepIndex] === 'FILE_SELECTION')
      return !!selectedFile;

    if (WIZARD_STEPS[wizardStepIndex] === 'EXCEL_VIEWER') {
      if (excelWizard.hasSheetSelection && excelWizard.currentStepIndex === 0)
        return true;

      const currentWizardStep = excelWizard.steps[excelWizard.currentStepIndex];
      if (!currentWizardStep.mandatory)
        return true;

      const currentStepSelection = excelWizard.mapping.find(s => s.key === currentWizardStep.key);
      return currentStepSelection && (!!currentStepSelection.rows?.length || !!currentStepSelection.cells?.length);
    }

    return true;
  };

  const getNextButtonText = () => {
    if (WIZARD_STEPS[wizardStepIndex] === 'EXCEL_VIEWER') {
      const currentWizardStep = excelWizard.steps[excelWizard.currentStepIndex];
      if (currentWizardStep.mandatory)
        return 'Next';
      else {
        const currentStepSelection = excelWizard.mapping.find(s => s.key === currentWizardStep.key);
        if (currentStepSelection && (!!currentStepSelection.rows?.length || !!currentStepSelection.cells?.length))
          return 'Next';
        return 'Skip';
      }
    }

    if (WIZARD_STEPS[wizardStepIndex] === 'FORMATTING')
      return 'Done';

    return 'Next';
  };

  const hasLargeFiles = allFiles.some(f => f.name.endsWith('.large'));

  const showNextButton = wizardStepIndex <= WIZARD_STEPS.length-1 && (WIZARD_STEPS[wizardStepIndex] !== 'EXCEL_VIEWER' || isExcelFileLoaded) && !hasLargeFiles;

  const showNovvoSupport = WIZARD_STEPS[wizardStepIndex] !== 'FILE_UPLOAD' || hasLargeFiles;

  const showErrorPage = !!error?.error && ['FORMATTING', 'EXCEL_VIEWER', 'SHEET_SELECTION'].includes(WIZARD_STEPS[wizardStepIndex]);

  return (
    <StyledDataFilesPanel className={className}>
        {isAdmin && !showEdit ?
          <div className="header">
            <div className="edit"><Icon type="edit" size="md" tooltip="Edit" onClick={() => setShowEdit(true)}/>
          </div>
        </div> : null}
      {renderHeader()}
      <div className={'content' + (showErrorPage ? ' w-error' : '')}>
        {showEdit ? <>
            {renderCurrentStep()}
            {showErrorPage ?
                <div className="error-notes">
                  <Error error={error?.error} />
                  <div className="error-info">
                    You can <span className="link" onClick={() => {
                      setError();
                      updateWizardStepIndex(0);
                    }}>restart</span> the mapping process or view <a href={helpRoute} target="_blank">Novvo guidelines and
                    tips <OpenInNewIcon /></a>.
                  </div>
                  <div>
                    Alternatively, you can submit a support request by clicking the ‘Submit support request’ button below.
                  </div>
                </div>
              :
              null
            }

            {!inlineMode &&
              <div className="footer">
                <Button color="gray" onClick={() => setShowEdit(false)}>Close</Button>
              </div>
            }
          </>
          :
          isDataReady ?
            <p>
              You are viewing data based on booking file last
              updated {moment(org.updatedAt).format('h:mm A MMM DD, yyyy')} {isAdmin ?
              <a href="javascript:" onClick={() => setShowEdit(true)}>Edit</a> : null}<br/>
              Connections with analytics permission see the same analytics <NavLink to={routes.Startups.Network}>Manage
              permissions</NavLink>
            </p> : allFiles.length > 0 ?
              <p>
                Data files were uploaded successfully. We will notify you when the analytics are ready. This may take few
                days.
              </p> :
              <p>
                To view analytics, please <a href="javascript:" onClick={() => setShowEdit(true)}>upload</a> data files from
                operational systems such as booking, invoicing and CRM.
              </p>
        }
      </div>
      <div className="footer">
        <StyledActions className="wizard-actions-container">
          <div className="nav-buttons">
            {!showErrorPage && showNextButton ? <Button className="next-button" disabled={!isStepValid() || error} onClick={next}>{getNextButtonText()}</Button> : null}
            {showNovvoSupport ? <NovvoSupport watchlistId={watchlistId} buttonMode={hasLargeFiles || showErrorPage} onSuccess={() => {
              if (onSubmitSupportRequest)
                onSubmitSupportRequest();
              if (onCancel)
                onCancel();
            }} /> : null}
          </div>
        </StyledActions>
      </div>
      {showDataGenerationNotification ? <DataGenerationNotification open showReloadPageText={isStartup} /> : null}
      {showGeneratingOfflineNotification > 0 ? <OfflineGenerationNotification open onClose={() => {
        setShowGeneratingOfflineNotification(0);
        if (onCancel)
          onCancel(true);
        window.location.reload();
      }} limitation={showGeneratingOfflineNotification} fileType={selectedSourceFileType} /> : null}
      {confirmData.open && <Confirm
        open
        title={`Warning`}
        yesText="Overwrite"
        noText="Cancel"
        onClose={() => setConfirmData({open: false})}
        onYes={async () => {
          setConfirmData({open: false})
          await onGenerateData();
        }}
      >
        {confirmData.texts.map((s, index) => <p key={index}>{s}</p>)}
      </Confirm>
      }

      <SubmitSupportRequestMsgBox
        open={showSubmitConfirmation}
        onClose={() => {
          if (onCancel)
            onCancel();
          setShowSubmitConfirmation(false);
        }}
      />
    </StyledDataFilesPanel>
  );
}

function resolveTemplateData(template, dataRowToRowIndexMap) {
  Object.values(template).forEach(wizardStep => {
    if (wizardStep.rows) {
      wizardStep.dataRows = wizardStep.rows;
      const rows = [];
      wizardStep.rows.forEach(v => {
        if (typeof v === 'string') {
          const rangeParts = v.split('-');
          const minRow = dataRowToRowIndexMap ? dataRowToRowIndexMap[Number(rangeParts[0])] : Number(rangeParts[0]);
          const maxRow = dataRowToRowIndexMap ? dataRowToRowIndexMap[Number(rangeParts[1] || minRow)] : Number(rangeParts[1] || minRow);
          for (let i=minRow; i<=maxRow; i++)
            rows.push(i);
        } else
          rows.push(v);
      });
      wizardStep.rows = rows;
    }
  });
  return template;
}

const WIZARD_STEPS = [
  'FILE_UPLOAD',
  'TYPES_LIST',
  'FILE_SELECTION',
  'SHEET_SELECTION',
  'EXCEL_VIEWER',
  'FORMATTING'
];

const EXCEL_WIZARD = {
  [enums.SourceFileType.Bookings]: {
    name: "Bookings",
    wizardSteps: [
      {
        text: <>Please select the <strong>data range</strong> by selecting the rows containing data without headers.</>,
        key: enums.LoadFileWizardKeys.Bookings.DataRows,
        selectionMode: 'multipleRows',
        mandatory: true
      },
      {
        text: <>Please select the cell containing the header of the column containing <strong>Customer IDs</strong>.</>,
        key: enums.LoadFileWizardKeys.Bookings.Customers,
        selectionMode: 'singleCell',
        mandatory: true,
        resetScrollView: true
      },
      {
        text: <>Please select the cell containing the header of the column containing subscription <strong>start dates</strong>.</>,
        key: enums.LoadFileWizardKeys.Bookings.AttributionDate,
        selectionMode: 'singleCell',
        mandatory: true
      },
      {
        text: <>Please select the cell containing the header of the column containing <strong>subscription periods</strong>.</>,
        subText: `You can skip this step and select subscription end date in the next step instead.`,
        key: enums.LoadFileWizardKeys.Bookings.PeriodMonthsLength,
        selectionMode: 'singleCell',
        mandatory: false
      },
      {
        text: <>Please select the cell containing the header of the column containing subscription <strong>end dates</strong>.</>,
        key: enums.LoadFileWizardKeys.Bookings.EndDate,
        selectionMode: 'singleCell',
        mandatory: true
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the column(s) containing <strong>revenue</strong> (both recurring and nonrecurring).</>,
        key: enums.LoadFileWizardKeys.Bookings.RevenueColumns,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Please select the cell containing the header of the column containing <strong>revenue categories</strong>.</>,
        key: enums.LoadFileWizardKeys.Bookings.RevenueCategories,
        selectionMode: 'singleCell',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the column(s) containing <strong>booking and customers attributes</strong> (e.g. Location, Size, Package).</>,
        key: enums.LoadFileWizardKeys.Bookings.CustomAttributes,
        selectionMode: 'multipleCells',
        mandatory: false
      }
    ]
  },
  [enums.SourceFileType.MRR]: {
    name: "MRR",
    wizardSteps: [
      {
        text: <>Please select the <strong>data range</strong> by selecting the rows containing data without headers.</>,
        key: enums.LoadFileWizardKeys.MRR.DataRows,
        selectionMode: 'multipleRows',
        mandatory: true
      },
      {
        text: <>Please select the cell containing the header of the column containing <strong>Customer IDs</strong>.</>,
        key: enums.LoadFileWizardKeys.MRR.Customers,
        selectionMode: 'singleCell',
        mandatory: true,
        resetScrollView: true
      },
      {
        text: <>Please select the cells containing <strong>MRR months</strong>.</>,
        subText: `Note: MRR months shall include both the month and the year (e.g., Dec 2022).`,
        key: enums.LoadFileWizardKeys.MRR.Dates,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Please select the cell containing the header of the column containing <strong>revenue categories</strong>.</>,
        key: enums.LoadFileWizardKeys.MRR.RevenueCategories,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the column(s) containing booking and <strong>customers attributes</strong> (e.g. Location, Size, Package).</>,
        key: enums.LoadFileWizardKeys.MRR.CustomAttributes,
        selectionMode: 'multipleCells',
        mandatory: false
      }
    ]
  },
  [enums.SourceFileType.PnL]: {
    name: "P&L",
    wizardSteps: [
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing <strong>revenue item(s)</strong>.</>,
        key: enums.LoadFileWizardKeys.PnL.RevenueDataRows,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing <strong>COGS</strong> item(s).</>,
        key: enums.LoadFileWizardKeys.PnL.COGSDataRows,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing <strong>sales and marketing expenses</strong>.</>,
        key: enums.LoadFileWizardKeys.PnL.SnMDataRows,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing <strong>research and development expenses</strong>.</>,
        key: enums.LoadFileWizardKeys.PnL.RnDDataRows,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing <strong>general and administrative expenses</strong>.</>,
        key: enums.LoadFileWizardKeys.PnL.GnADataRows,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the header(s) of the row(s) containing other <strong>OPEX expenses</strong>.</>,
        key: enums.LoadFileWizardKeys.PnL.OtherOpexDataRows,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Please select the cell(s) containing the P&L <strong>actual periods</strong>.</>,
        subText: `Note: P&L periods shall include both the period and the year (e.g., Q3-2022).`,
        key: enums.LoadFileWizardKeys.PnL.ActualDates,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      // {
      //   text: <>Please select the cell(s) containing the P&L <strong>planned periods</strong>.</>,
      //   subText: `Note: P&L periods shall include both the period and the year (e.g., Q3-2022).`,
      //   key: enums.LoadFileWizardKeys.PnL.ExpectedDates,
      //   selectionMode: 'multipleCells',
      //   mandatory: false
      // },
      {
        text: <>Please select the cell(s) containing the P&L <strong>forecast periods</strong>.</>,
        subText: `Note: P&L periods shall include both the period and the year (e.g., Q3-2022).`,
        key: enums.LoadFileWizardKeys.PnL.ForecastDates,
        selectionMode: 'multipleCells',
        mandatory: false
      }
    ]
  },
  [enums.SourceFileType.Customers]: {
    name: "Customers",
    wizardSteps: [
    ]
  },
  [enums.SourceFileType.CashFlow]: {
    name: "Cash flow",
    wizardSteps: [
      {
        text: <>Select <strong>beginning cash</strong></>,
        key: enums.LoadFileWizardKeys.CashFlow.BeginingCash,
        selectionMode: 'singleCell',
        mandatory: true
      },
      {
        text: <>Select <strong>operating activities</strong> items</>,
        key: enums.LoadFileWizardKeys.CashFlow.OperatingActivities,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>investing activities</strong> items</>,
        key: enums.LoadFileWizardKeys.CashFlow.InvestingActivities,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>financing activities</strong> items</>,
        key: enums.LoadFileWizardKeys.CashFlow.FinancingActivities,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>actual period</strong> headers</>,
        key: enums.LoadFileWizardKeys.CashFlow.ActualDates,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>planned period</strong> headers</>,
        key: enums.LoadFileWizardKeys.CashFlow.ExpectedDates,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Select <strong>forecast period</strong> headers</>,
        key: enums.LoadFileWizardKeys.CashFlow.ForecastDates,
        selectionMode: 'multipleCells',
        mandatory: false
      }
    ]
  },
  [enums.SourceFileType.BalanceSheet]: {
    name: "Balance sheet",
    wizardSteps: [
      {
        text: <>Select <strong>cash</strong> items</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.Cash,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>account receivables</strong> items</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.AccountReceivable,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>short term liabilities</strong> items</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.ShortTermLiabilities,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>deferred revenue</strong> items</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.DeferredRevenue,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Select <strong>actual period</strong> headers</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.ActualDates,
        selectionMode: 'multipleCells',
        mandatory: true
      },
      {
        text: <>Select <strong>planned period</strong> headers</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.ExpectedDates,
        selectionMode: 'multipleCells',
        mandatory: false
      },
      {
        text: <>Select <strong>forecast period</strong> headers</>,
        key: enums.LoadFileWizardKeys.BalanceSheet.ForecastDates,
        selectionMode: 'multipleCells',
        mandatory: false
      }
    ]
  }
};
