import React, { useEffect, useState } from "react";
import { StyledOnboardingAlertsPanel } from "./OnboardingAlertsPanel.style";
import { useLocation, useNavigate } from "react-router-dom";
import routes from '../../pages/routes';
import UsersAPI from '../../api/users';
import {useSelector} from "react-redux";
import GetStartedTutorialDemoDialog from '../dialogs/GetStartedTutorialDemoDialog';
import { ActivitiesAPI } from "../../api";

export default function OnboardingAlertsPanel() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const isStartup = user?.isStartupMode;
  const isAdmin = user?.isAdmin;
  const companyRoutes = routes[isStartup ? 'Startups' : 'Investors'];
  const [showTutorial, setShowTutorial] = useState(false);
  const [busy, setBusy] = useState(true);
  const [activitiesSummary, setActivitiesSummary] = useState(null);
  const location = useLocation();
  const currentLocation = window.location.pathname + window.location.hash;

  useEffect(() => {
    ActivitiesAPI.getActivitiesSummary().then(({ success, data }) => {
      if (success) {
        setActivitiesSummary(data);
        if (location.hash && data) {
          invokeAction(location.hash, data);
    }
      }
      setBusy(false);
    });
  }, []);

  const updatePreferences = (activity, data) => {
    if (!data[activity]) {
      UsersAPI.markActivityDone(activity).then();
      setActivitiesSummary(Object.assign(data, {[activity]: true}));
    }
  };

  const invokeAction = (action, data = activitiesSummary) => {
    if (!isAdmin || !data)
      return;

    switch (action) {
      case '#watch':
        updatePreferences('WatchedTutorialMovie', data);
        setShowTutorial(true);
        break;
      case '#demo':
        if (!isStartup && data.demoCompanyId > 0) {
          updatePreferences('ViewedDemoAnalytics', data);
          navigate(routes.Investors.getStartupAnalyticsUrl(data.demoCompanyId), {state: {from_path: currentLocation}});
        }
        break;
      case '#analyze':
        if (isStartup)
          navigate(routes.Startups.Analytics);
        else
          navigate(routes.Investors.Invite);
        break;
      case '#invite':
        updatePreferences('ViewedTeammatesScreen', data);
        navigate(companyRoutes.UsersManagement, {state: {from_path: currentLocation}});
        break;
    }
  };

  if (!isAdmin || busy)
    return showTutorial ? <GetStartedTutorialDemoDialog open onClose={() => setShowTutorial(false)} /> : null;

  const renderItem = (text, isChecked, action) => {
    return <div className={"item" + (isChecked ? ' checked' : '')}>
      <div className="link" onClick={() => invokeAction(action)}><div className="bullet"></div><span>{text}</span></div>
    </div>;
  };

  if (!activitiesSummary)
    return null;

  return (
    <StyledOnboardingAlertsPanel>
      {showTutorial ? <GetStartedTutorialDemoDialog open onClose={() => setShowTutorial(false)} />  : null}
      <div className="title">Welcome to Novvo</div>
      <div className="sub-title">Here are a few quick things you can do right now to get started</div>
      {renderItem(isStartup ? 'Watch a short tutorial and learn how to get started' : 'Watch a short tutorial and learn how you can get from data to insights in no time', activitiesSummary.WatchedTutorialMovie, '#watch')}
      {!isStartup && activitiesSummary.demoCompanyId > 0 && renderItem('Experience the platform yourself by playing around with a demo company', activitiesSummary.ViewedDemoAnalytics,'#demo')}
      {!isStartup && renderItem('Analyze prospect’s data of your own', activitiesSummary.WatchlistCompanyCreated, '#analyze')}
      {isStartup && renderItem('Upload data and generate analytics', activitiesSummary.generatedAnalytics,'#analyze')}
      {renderItem(isStartup ? 'Invite teammates and collaborate' : 'Invite teammates', activitiesSummary.ViewedTeammatesScreen, '#invite')}
    </StyledOnboardingAlertsPanel>
  );
}
