import {
    verifyTokenStarted,
    verifyUserSuccess,
    verifyTokenEnd,
    refreshTokenStarted,
    refreshTokenEnd,
    userLoginStarted,
    userLoginFailure,
    userLogout,
    registrationStarted,
    verifyRegistrationSuccess,
    registrationFailure,
} from '../actions/authActions';
import {
  verifyTokenService,
  userLoginService,
  userLogoutService,
  userRegisterService,
  joinTeamService, switchOrganization
} from '../api/auth';
import BroadcastHelper from '../utils/BroadcastChannelHelper';

const setAppGuardIdentity = ({ user }) => {
  try {
    if (window.__AppGuard__) {
      const {id, name, email, role, organizationName : organization, organizationId} = user;
      const organizationType = user.isStartupMode ? 'Startup' : user.isSupportMode ? 'Support' : 'Investor';
      window.__AppGuard__.setIdentity({userId: id, userName: name, email, userRoles: role}, {organization, organizationType, organizationId});
      window.__AppGuard__.setMeta('environment', process.env.REACT_APP_CLUSTER_ENV);
    }
  } catch (e) {
    // Do nothing
  }
};

const clearAppGuardIdentity = () => {
  try {
    if (window.__AppGuard__)
      window.__AppGuard__.clearIdentity();
  } catch (e) {
    // Nothing
  }
};

// handle verify token
export const verifyTokenAsync = (silentAuth = false) => async (dispatch) => {
    dispatch(verifyTokenStarted(silentAuth));
    const result = await verifyTokenService();
    if (!result.success) {
        dispatch(verifyTokenEnd());
        if ([401, 403].includes(result.statusCode)) dispatch(userLogout());
        return;
    }
    setAppGuardIdentity(result.data);
    dispatch(verifyUserSuccess(result.data));
};

export const refreshToken = () => async (dispatch) => {
    dispatch(refreshTokenStarted());
    const result = await verifyTokenService();
    if (!result.success) {
        if ([401, 403].includes(result.statusCode)) dispatch(userLogout());
        return;
    }
    dispatch(refreshTokenEnd(result.data));
};

// handle user login
export const userLoginAsync = (username, password, mfaCode) => async (dispatch) => {
  dispatch(userLoginStarted());

  const result = await userLoginService(username, password, mfaCode);

  if (result.error) {
      dispatch(userLoginFailure(result.error));
      return;
  }

  setAppGuardIdentity(result.data);

  dispatch(verifyUserSuccess(result.data));
  BroadcastHelper.postMessage('login');
};

// handle user login
export const registrationAsync = (signedUrlGuid, email, password, organizationName, websiteUrl, industry, type, timezone) => async (
    dispatch
) => {
    dispatch(registrationStarted());

    const result = await userRegisterService(signedUrlGuid, email, password, organizationName, websiteUrl, industry, type, timezone);

    if (result.error) {
        dispatch(registrationFailure(result.error));
        return;
    }

    setAppGuardIdentity(result.data);

    dispatch(verifyUserSuccess(result.data));
};

// handle user login
export const teamJoinAsync = (signedUrl, name, password, timezone) => async (
    dispatch
) => {
    dispatch(registrationStarted());

    const result = await joinTeamService(signedUrl, name, password, timezone);

    if (result.error) {
        dispatch(registrationFailure(result.error));
        return;
    }

    setAppGuardIdentity(result.data);

    dispatch(verifyRegistrationSuccess(result.data));
};

// handle user logout
export const userLogoutAsync = (postLogout = true) => async (dispatch) => {
    await userLogoutService();
    dispatch(userLogout());
    clearAppGuardIdentity();
    if (postLogout)
      BroadcastHelper.postMessage('logout');
};


// handle switch organization
export const switchOrganizationAsync = (organizationId) => async (dispatch) => {
  dispatch(userLoginStarted());

  const result = await switchOrganization(organizationId);

  if (result.error) {
    dispatch(userLoginFailure(result.error));
    return;
  }

  setAppGuardIdentity(result.data);

  dispatch(verifyUserSuccess(result.data));
};

