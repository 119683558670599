/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ClickOutsideHelper from '../helpers/ClickOutsideHelper';
import { StyledMoreMenu } from './MoreMenu.style';
import { MoreHoriz, Check } from '../../assets/mui-icons';
import cx from 'classnames';

MoreMenu.propTypes = {
  options: PropTypes.array.isRequired,
  triggerIcon: PropTypes.object
};

export default function MoreMenu({ options, triggerIcon, closeOnExternalScroll = false, showSelected = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const [boundingClientRect, setBoundingClientRect] = useState({left:0, top:0});

  const scrollToPosition = (e) => {
    if (wrapperRef.current !== null && isOpen) {
      const currentBoundaries = wrapperRef.current.getBoundingClientRect();
      if (currentBoundaries.top !== boundingClientRect.top || currentBoundaries.left !== boundingClientRect.left)
        setIsOpen(false);
    }
  };

  const closeOnScroll = (e) => {
    if (isOpen)
      setIsOpen(false);
  };

  useEffect(() => {
    let timer;
    if (closeOnExternalScroll) {
      timer = setInterval(scrollToPosition, 150);
    }
    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      clearInterval(timer);
    };
  }, [closeOnExternalScroll, isOpen]);

  useEffect(() => {
    window.addEventListener("scroll", closeOnScroll);
    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", closeOnScroll);
    };
  }, [isOpen]);


  const wrapperRef = useRef(null);
  const menuRef = useRef(null);
  ClickOutsideHelper(wrapperRef, () => setIsOpen(false));

  const getMenuTop = () => {
    const menuHeight = menuRef.current?.getBoundingClientRect().height;
    const openerOffset = wrapperRef.current?.getBoundingClientRect();
    const openerBottom = openerOffset?.bottom;
    const openerHeight = openerOffset?.height;
    if ((openerBottom + menuHeight + openerHeight) > window.innerHeight)
      return openerOffset?.top - openerHeight - menuHeight;
    return openerBottom;
  };

  const getMenuLeft = () => {
    const menuWidth = menuRef.current?.getBoundingClientRect().width;
    const openerOffset = wrapperRef.current?.getBoundingClientRect();
    const openerLeft = openerOffset?.left;
    const openerWidth = openerOffset?.width;
    if ((openerLeft + menuWidth + openerWidth) > window.innerWidth)
      return openerLeft - menuWidth;
    return openerLeft;
  };

  const handleItemClick = (option) => {
    setIsOpen(false);
    if (option.onClick)
      option.onClick();
  };

  const onOpenMenu = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setBoundingClientRect(wrapperRef.current?.getBoundingClientRect());
    setIsOpen(!isOpen);
  };

  const renderOption = (option, index) => {
    if (option.type === 'section') {
      return (
          <li key={index} className='section' >
            <span>{option.label}</span>
          </li>
      );
    }

    const onClick = option.disabled ? undefined : (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      handleItemClick(option);
    };

    return (
        <li className={cx({disabled: option.disabled})} key={index} onClick={onClick}>
          {showSelected &&
          <span className="selected">{!!option.selected && <Check />}</span>
          }
          <span>{option.label}</span>
        </li>
    );
  };

  if (!options || !options.length)
    return null;

  return (
    <StyledMoreMenu className={`menu ${isOpen ? "menu--open" : ""}`} ref={wrapperRef}>
      <button className="menu__opener" onClick={onOpenMenu}>
        {triggerIcon || <MoreHoriz />}
      </button>
      <div ref={menuRef} className="menu__list" style={{top: getMenuTop(), left: getMenuLeft()}}>
        <ul>
          {options.map(renderOption)}
        </ul>
      </div>
    </StyledMoreMenu>
  );
}

