import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { StyledCompanyLogo } from "./CompanyLogo.style";

export default function CompanyLogo({ className, company = {}, size = 40, ...rest }) {
  const { logo, name, updatedAt } = company;
  const [useDefault, setUseDefault] = useState(!logo || !logo.startsWith('http'));

  useEffect(() => {
    setUseDefault(!logo || !logo.startsWith('http'));
  }, [logo]);

  const getLogo = () => {
    if (!useDefault)
      return <img src={`${logo}${logo.includes('?') ? '&' : '?'}t=${updatedAt}`} alt={name} width={size} height={size} onError={() => setUseDefault(true)} />;

    return <Icon size="sm" type="building-w" />;
  };

  return (
    <StyledCompanyLogo className={`${(className || '')}${(useDefault ? ` default color_${company.id % 6}` : '')}`} style={{ fontSize: size, width: size, height: size }} {...rest}>
      {getLogo()}
    </StyledCompanyLogo>
  );
};
