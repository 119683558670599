import styled from 'styled-components';

export const StyledKpiDataExplanation = styled.div`
  margin: 20px 0;

  .toolbar {
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    .action {
      display: flex;
      align-items: center;

      cursor: pointer;
      svg {
        margin-right: 5px;
      }
    }
  }

  .data-tables {
    overflow: auto;
    height: calc(100vh - 200px);
    table {
      table-layout: fixed;
    }
  }

  &.highlighted {
    th, td {
      opacity: 0.4;
    }
  }

  .no-highlight {
    opacity: 0.4;
    th, td {
      opacity: 0.4;
    }
  }

  .highlight {
    opacity: 1;
    th, td {
      opacity: 1;
    }
  }

  &:not(.compare-mode) {
    > div:not(:nth-child(2)) {
      table {
        thead {
          display: none;
        }
      }
    }
  }

  table {
    thead tr:first-child {
      font-weight: bold;
    }

    tr td {
      text-align: right;
      border-bottom: 1px solid transparent;
    }

    tr:not(:last-child):hover td {
      border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    }

    tr:hover td {
      background: #f9f9f9;
    }

    td {
      border-bottom: none;
      > div {
        font-size: 11px;
      }
    }

    th:first-child,
    td:first-child {
      text-align: left;
      padding-left: 5px !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 5px !important;
    }

    tr.summary {
      td {

        border-top: 1px solid ${props => props.theme.colors.lightGray};
        > div {
          padding-top:2px;
          font-weight: bold;
        }
      }
    }

    th {
      border-top: 1px solid ${props => props.theme.colors.lightGray};
      border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    }

    th, td {
      font-size: 10px !important;
      padding: 0 !important;
      text-align: right;
      &.highlight {
        opacity: 1;
      }
      > div {
        .compare-column {
          display: flex;
          align-items: center;
          i {
            display: block;
            margin-right: 5px;
            width: 12px;
            min-width: 12px;
            height: 12px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .title {
    margin: 5px 0 2px 5px;
    font-size: 15px;
    .compare-mode {
      display: flex;
      align-items: center;
      i {
        display: block;
        margin-right: 5px;
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 4px;
      }
    }
  }

  .comment {
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    margin: 0 0 25px 0;
    padding-top: 5px;
    font-size: 12px;
    color: ${props => props.theme.colors.legend};
    line-height: 1px;
  }
`;

