import styled from 'styled-components';

export const StyledLegendFormatter = styled.div`
  display: flex;
  align-items: center;
  .legend-formatter-legend {
     margin-right: 4px;
     background: ${props => props.color};
     width: 12px;
     min-width: 12px;
     height: 12px;
     border-radius: 50%;
  }
`
