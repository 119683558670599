import React, { useState } from "react";
import { StyledDataRoom } from "./DataRoom.style";
import Typography from "../../../components/Typography";
import routes from "../../routes";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import MsgBox from "../../../components/dialogs/MsgBox";
import FilesUpload from "../../../components/dialogs/FilesUpload";
import { StartupsAPI } from "../../../api";
import Error from "../../../components/Error";


export default function DataRoom() {
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState('');
  const { user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const helpLink = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;

  const openHelp = () => {
    window.open(helpLink);
  };

  const onSubmit = () => {
    setIsBusy(true);
    setError('');
    StartupsAPI.submitForReview().then(({success, error}) => {
      setIsBusy(false);
      if (success) {
        setShowSubmitDialog(true);
      } else if (error) {
        setError(error);
      }
    });
  };

  return (
    <StyledDataRoom>
      <div className="page-content">
        <div className="main">
          <Typography variant="h2">Data checklist</Typography>
          <Typography variant="h4">
            For best results, please refer to our <span className="link" onClick={openHelp}>templates and upload tips</span>.
          </Typography>
          <div className="data-files-list">
            Here is the list of the required data:
            <ol>
              <li>Pitch deck (ppt / pdf)</li>
              <li>MRR or Booking (xlsx)</li>
              <li>Income statement (xlsx)</li>
              <li>Cash flow statement (xlsx)</li>
              <li>Sales pipeline (xlsx)</li>
            </ol>
            In order to begin the funding process, click the 'Submit' button below once all the required files have been uploaded.
          </div>
          <div className="buttons">
            <Button disabled={isBusy} color="primary" onClick={onSubmit}>Submit</Button>
            {error ? <Error className="error-message" error={error} /> : null}
          </div>
        </div>
        <div className="upload-content">
          <FilesUpload cleanMode />
        </div>
      </div>
      {showSubmitDialog ?
        <MsgBox
          open
          modal={true}
          title="Congratulations!"
          footer={<Button onClick={() => setShowSubmitDialog(false)}>Done</Button>}
        >You just kicked off the funding process.<br />
          We will notify via email about the next step within 48 hours.
        </MsgBox> : null}
    </StyledDataRoom>
  );
};
