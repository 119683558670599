import React, {useState, useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import Button from '../../components/Button';
import { StyledCropDialog } from "./CropDialog.style";
import {Dialog} from "../dialogs";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function CropDialog({ open, file, onOk, onCancel, aspect, width, height, allowClearCrop }) {
  const imgRef = useRef(null);
  const [initialImage, setInitialImage] = useState();
  const [fileType, setFileType] = useState();
  const [fileName, setFileName] = useState();
  const [crop, setCrop] = useState({});
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (file) {
      setFileType(file.type);
      setFileName(file.name);
      const reader = new FileReader();
      reader.addEventListener('load', () => setInitialImage(reader.result));
      reader.readAsDataURL(file);
      setCrop({ unit: 'px', aspect, width, height });
    }
  }, [file, aspect, width, height]);

  const handleOnOk = (crop) => {
    if (onOk) {
      const image = imgRef.current;
      const canvas = document.createElement("canvas");
      if (crop && crop.width && crop.height) {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        canvas.toBlob(
          (blob) => {
            const croppedFile = new File([blob], fileName, {type: fileType});
            if (onOk)
              onOk(croppedFile, true);
          },
          fileType,
          1
        );
      } else {
        if (onOk)
          onOk(file, false);
      }
    }
  };

  const onClear = () => {
    setCrop({ unit: 'px', aspect, width: 0, height: 0 });
    setCompletedCrop(null);
  };

  const onSkipCrop = () => {
    onClear();
    handleOnOk(null);
  };

  return (
      <Dialog
        open={open} showCloseIcon={true} onClose={onCancel} header={<div>Crop image</div>}
      >
        <StyledCropDialog>
          <div className="crop-content">
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              minWidth={width}
              minHeight={height}
              keepSelection
            >
              <img src={initialImage} alt="Image to crop" ref={imgRef} />
            </ReactCrop>
          </div>
          <>
          {allowClearCrop ? <Buttons.Button variant='outlined' onClick={onClear}>Clear</Buttons.Button> : null}
          <div className="footer-buttons">
            {allowClearCrop && crop.width && crop.height ? <Button onClick={onSkipCrop}>Continue Without Cropping</Button> : null}
            <Button onClick={() => handleOnOk(completedCrop)}>{crop.width && crop.height ? 'Ok' : 'Continue Without Cropping'}</Button>
          </div>
          </>
        </StyledCropDialog>
      </Dialog>
  );
}

CropDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  aspect: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  allowClearCrop: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};

CropDialog.defaultProps = {
  allowClearCrop: false
};

export default CropDialog;

