import styled from 'styled-components';

export const StyledButton = styled.button`
  &.button-container {
    ${props => props.theme.colors[props.color] && props.color !== 'white' ? `color:${props.theme.colors['background-color']};` : props.theme.colors[props.borderColor] ? `color:${props.theme.colors[props.borderColor]};` : `color:${props.theme.colors["text-base"]}`}
    padding: ${props => props.size === 'small' ? '3px 12px' : props => props.size === 'large' ? '0 45px' :'0.2rem 1.8rem'};
    border: 1px solid transparent;

    ${props => props.borderColor ? `border-color:${props.theme.colors[props.borderColor]};` : ''}
    ${props => props.width ? `width:${props.width}px;` : ''}
    ${props => props.height ? `max-height:${props.height}px;` : ''}

    font-family: ${props => props.theme.fonts['primary-font']};
    font-weight: bold;
    font-size: ${props => props.size === 'small' ? '12px' : props => props.size === 'large' ? '1.7rem' : '1.5rem'};
    line-height: ${props => props.size === 'small' ? 'normal' : props => props.size === 'large' ? '4rem' : '2.6rem'};
    border-radius: ${props => props.size === 'small' ? '3px' : props => props.size === 'large' ? '5px' :  '0.4rem'};
    white-space: nowrap;
    transition: background-color 0.25s ease, box-shadow 0.25s ease;
    cursor: pointer;
    &:focus:not(:disabled) {
      box-shadow: 0 0 0 0.4rem rgba(0, 161, 222, 0.2);
      outline: 0;
      border-color: rgba(0, 161, 222, 0.8);
    }
    background-color: ${props => props.theme.colors[props.color]};
    &:hover:not(:disabled) {
      background-color: ${props => props.theme.colors[`${props.color}Hover`]};
      box-shadow: 0 3px 3px rgba(0,0,0,0.1);
    }
    &:disabled {
      opacity: 0.25;
      cursor: default;
    }
    &.loading {
      > div {
        display: inline;
      }
      i {
        margin-left: 10px;
        border-top-color: #777;
        margin-bottom: 2px;
      }
    }
    a, a:visited, a:hover, a:link {
      text-decoration: none;
      color: #fff;
    }
  }

`;
