import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { StyledPreferencesCard } from './PreferencesCard.style';
import Button from "../Button";
import Error from "../Error";
import Select2 from "../Select2";
import TextField from '@mui/material/TextField';
import {InvestorsAPI, StartupsAPI} from "../../api";
import {updateCurrentUser} from "../../actions/authActions";
import Loading from "../Loading/Loading";
import enums from "../../utils/enums";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import Preferences from "../../utils/preferences";

const SALES_CYCLE_UNIT_OPTIONS = [
  {label: 'Months', value: 'months'},
  {label: 'Quarters', value: 'quarters'},
  {label: 'Years', value: 'years'}
];

export default function PreferencesCard({ watchlistId, startupId, onSaveComplete }){
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const org = user.Organizations.find(o => o.id === user.organizationId);
  const isStartup = user?.isStartupMode;
  const overridesMode = !isStartup && !watchlistId;
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [preferences, setPreferences] = useState({});

  const setPreferencesFromOrganizationData = (orgData) => {
    const currentPreferences = orgData || Preferences.getOrganizationPreferences();
    setPreferences({
      PreferencesValid: currentPreferences.PreferencesValid,
      SalesCycleDisplayValue: currentPreferences.SalesCycleDisplayValue,
      SalesCycleDisplayUnit: currentPreferences.SalesCycleDisplayUnit,
      SalesCycleError: currentPreferences.SalesCycleError,
      FiscalYearEndingMonth: currentPreferences.FiscalYearEndingMonth,
      // MinimumBookingResolution: orgData.MinimumBookingResolution,
      // MinimumExpensesResolution: orgData.MinimumExpensesResolution,
      Currency: currentPreferences.Currency,
      BookingsLastActualDate: currentPreferences.BookingsLastActualDate,
      LastActualChurnAssumption: currentPreferences.LastActualChurnAssumption,
      FirstMonthCalculation: currentPreferences.FirstMonthCalculation,
      BookingsSourceFileType: currentPreferences.BookingsSourceFileType
    });
  };

  useEffect(() => {
    setLoading(true);
    if (isStartup) {
      const org = user.Organizations.find(o => o.id === user.organizationId);
      setPreferencesFromOrganizationData(org);
      setLoading(false);
    } else {
      if (watchlistId) {
        InvestorsAPI.getWatchlistCompany(watchlistId).then(({success, data, error}) => {
          if (success) {
            setPreferencesFromOrganizationData(data);
          } else {
            setError(error);
          }
          setLoading(false);
        });
      } else {
        setPreferencesFromOrganizationData();
        setLoading(false);
      }
    }
  }, [isStartup, watchlistId]);

  const updatePreferences = (preferenceName, value) => {
    const newPreferences = {
      ...preferences,
      [preferenceName]: value
    };
    setPreferences(newPreferences)
  };

  const onSaveClicked = () => {
    setBusy(true);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    let preferencesToSave = {
      LastActualChurnAssumption: preferences.LastActualChurnAssumption || enums.LAST_ACTUAL_CHURN_ASSUMPTION.find(o => o.label === 'Renewal').value,
      FirstMonthCalculation: preferences.FirstMonthCalculation || enums.FIRST_MONTH_CALCULATION_OPTIONS.find(o => o.label === 'Ending MRR').value,
    };

    if (!overridesMode) {
      preferencesToSave = {
        ...preferencesToSave,
        SalesCycleDisplayValue: preferences.SalesCycleDisplayValue,
        SalesCycleDisplayUnit: preferences.SalesCycleDisplayUnit,
        FiscalYearEndingMonth: preferences.FiscalYearEndingMonth || 12,
        Currency: preferences.Currency || 'USD'
      };
    }

    API.updatePreferences(preferencesToSave, watchlistId, startupId).then(({success, data, error}) => {
      if (success) {
        setPreferencesFromOrganizationData(data);
        if (isStartup) {
          const updatedUser = user;
          updatedUser.Organizations = updatedUser.Organizations.filter(o => o.id !== user.organizationId);
          updatedUser.Organizations.push(data);
          dispatch(updateCurrentUser(updatedUser));
        }

        // if (data.PreferencesValid && onSaveComplete)
        //   onSaveComplete();
        if (onSaveComplete)
          onSaveComplete();
      } else
        setError(error);

      setBusy(false);
    });
  };

  const renderSalesCycleSetting = () => {
    return <div className="setting-item-cnt">
      <h4>Sales cycle</h4>
      <div className="field-row">
        <div className="label">Set sales cycle to</div>
        <TextField
          disabled={overridesMode}
          type="number"
          label='Value'
          InputProps={{ inputProps: { maxLength: 3, min: 0, max: 100 } }}
          value={preferences.SalesCycleDisplayValue}
          onChange={(e) => {
            const {value} = e.target;
            if (value >= 0 && value <= 100)
              updatePreferences('SalesCycleDisplayValue', e.target.value);
          }}
        />
        <Select2
          disabled={overridesMode}
          className="sales-cycle-options"
          label='Period'
          options={SALES_CYCLE_UNIT_OPTIONS}
          value={preferences.SalesCycleDisplayUnit}
          onChange={(e) => updatePreferences('SalesCycleDisplayUnit', e.target.value)}
        />
        <Error className="pref-err" error={preferences.SalesCycleError || ' '} />
      </div>
      <div className="field-note">
        <strong>Note:</strong> Sales cycle sets S&M and impact all related calculations such as CAC, CAC payback etc.
      </div>
    </div>;
  };

  const renderFiscalYearMonthSetting = () => {
    const options = enums.FISCAL_YEAR_END_MONTH_OPTIONS.map(o => ({...o}));
    // const {MinimumBookingResolution, MinimumExpensesResolution, FiscalYearEndingMonth } = preferences;
    // const minimumResolution = [MinimumBookingResolution, MinimumExpensesResolution].includes(enums.Period.Quarterly) ? enums.Period.Quarterly : enums.Period.Monthly;
    // let note;
    // if (minimumResolution === enums.Period.Quarterly) {
    //   note = `Since the ${MinimumBookingResolution === enums.Period.Quarterly ? 'MRR' : 'P&L'} was loaded as calendar quarters, only the months specified above can be used as fiscal year ends.`;
    //   options.filter(o => ![3,6,9,12].includes(o.value)).forEach(o => o.disabled = true);
    // }

    return <div className="setting-item-cnt">
      <h4>Fiscal year</h4>
      <div className="field-row">
        <div className="label">Set fiscal year to ends on</div>
        <Select2
          disabled={overridesMode}
          className="fiscal-year-options"
          label='Month'
          options={options}
          value={preferences.FiscalYearEndingMonth || 12}
          onChange={(e) => updatePreferences('FiscalYearEndingMonth', e.target.value)}
        />
        <Error className="pref-err" error={' '} />
      </div>
      {/*{note &&*/}
      {/*  <div className="field-note">*/}
      {/*    <strong>Note:</strong> {note}*/}
      {/*  </div>*/}
      {/*}*/}
    </div>;
  };

  const renderCurrencySetting = () => {
    return <div className="setting-item-cnt">
      <h4>Currency</h4>
      <div className="field-row">
        <div className="label">Currency display symbol</div>
        <Select2
          disabled={overridesMode}
          className="fiscal-year-options"
          label='Symbol'
          options={enums.CURRENCIES}
          value={preferences.Currency || 'USD'}
          onChange={(e) => updatePreferences('Currency', e.target.value)}
        />
        <div></div>
      </div>
    </div>;
  };

  const renderLastActualChurnCalculation = () => {
    return <div className="setting-item-cnt">
      <Tooltip title={`What assumption would you like to take on contracts that are ending between ‘Last actual date’ (${moment(preferences.BookingsLastActualDate).format('MMMM DD, YYYY')}) and last day of the same year`}
               placement="bottom-start">
        <h4>Last actual churn calculation</h4>
      </Tooltip>
      <div className="field-row">
        <div className="label">Assume</div>
        <Select2
          className="fiscal-year-options"
          label='Assumption'
          options={enums.LAST_ACTUAL_CHURN_ASSUMPTION}
          value={preferences.LastActualChurnAssumption || enums.LAST_ACTUAL_CHURN_ASSUMPTION.find(o => o.label === 'Renewal').value}
          onChange={(e) => updatePreferences('LastActualChurnAssumption', e.target.value)}
        />
        <div></div>
      </div>
    </div>;
  };

  const renderFirstMonthCalculation = () => {
    if (![enums.SourceFileType.MRR].includes(preferences.BookingsSourceFileType))
      return null;
    return <div className="setting-item-cnt">
      <h4>First month calculation</h4>
      <div className="field-row">
        <div className="label">Count all MRR of the first month as</div>
        <Select2
          disabled={overridesMode}
          className="fiscal-year-options"
          label='Setting'
          options={enums.FIRST_MONTH_CALCULATION_OPTIONS}
          value={preferences.FirstMonthCalculation || enums.FIRST_MONTH_CALCULATION_OPTIONS.find(o => o.label === 'Ending MRR').value}
          onChange={(e) => updatePreferences('FirstMonthCalculation', e.target.value)}
        />
        <div></div>
      </div>
    </div>;
  };

  return (
    <StyledPreferencesCard>
      {loading ?
        <Loading size="medium">Loading settings...</Loading>
        :
        <div className="setting-items">
          {renderSalesCycleSetting()}
          {renderFiscalYearMonthSetting()}
          {renderCurrencySetting()}
          {renderLastActualChurnCalculation()}
          {renderFirstMonthCalculation()}
        </div>
      }

      <div className="footer">
        <Button className="primary" onClick={onSaveClicked} disabled={busy && !error} isLoading={busy}>Save</Button>
        <Error className="error-box" error={error} />
      </div>
    </StyledPreferencesCard>
  );
};

