import i18next from 'i18next';

const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

const extractLocale = (filename) => {
    const matched = filename.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) return matched[1];
    return null;
};

const resources = {};

locales.keys().forEach((key) => {
    const locale = extractLocale(key);
    if (locale) resources[locale] = { translation: locales(key) };
});

i18next.init({
    // eslint-disable-next-line no-undef
    lng: process.env.REACT_APP_DEFAULT_LANGUAGE || navigator.language.slice(0, 2) || 'en',
    debug: process.env.REACT_APP_CLUSTER_ENV === 'development',
    resources,
});

export const TranslationHelper = {
  get: (section, message) => {
    const cleanedErrorMessage = (message || '').replace(/:/g, '');
    const errorKey = `${section}.${cleanedErrorMessage}`;
    let translatedError = i18next.t(errorKey);
    if (translatedError === errorKey)
      return message;
    return translatedError;
  },
  getError: (message) => {
    return TranslationHelper.get('errors', message);
  }
};
