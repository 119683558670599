/* eslint-disable */
const QueryStringHelper = {
    parseQueryString(url = window.location.href, shouldDecode = true) {
        if (shouldDecode) url = decodeURIComponent(url);
        const parsedParams = {};
        const queryString = (url || '').split('?')[1];
        if (queryString) {
            const params = queryString.split('&');
            params.forEach((p) => {
                const paramParts = p.split('=');
                if (paramParts.length === 2) {
                    parsedParams[paramParts[0]] = paramParts[1];
                }
            })
        }
        return parsedParams;
    },

    getParameter(paramName) {
        return this.parseQueryString()[paramName];
    },

    stringifyQueryObject(obj) {
        let queryString = '';
        for (const key in obj) queryString += `${key}=${obj[key]}&`;
        if (queryString.endsWith('&')) queryString = queryString.slice(0, -1);
        return queryString;
    },
}

export default QueryStringHelper;
