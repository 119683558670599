import styled from 'styled-components';

export const StyledGiveFeedback = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.colors.link};
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.linkhover};
  }
  svg {
    height: 26px;
    width: 26px;
    padding: 4px;
    background: ${props => props.theme.colors.primary};
    fill: white;
    border-radius: 50%;
    margin-right: 5px;
  }
`
