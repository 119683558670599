import styled from 'styled-components';

export const StyledSearchAnalytics = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 6px 0 6px 5px;
  background: #f1f3f4;
  border-radius: 8px;
  width: 30%;
  min-width: 340px;
  border: 1px solid transparent;
  transition: background 100ms ease-in, width 100ms ease-out;

  &:active, &:focus, &:focus-within {
    box-shadow: 0 1px 1px 0 rgba(65, 69, 74, .3), 0 1px 3px 1px rgba(65, 69, 74, .3);
    background: #fff;
  }

  > div {
    width: calc(100% - 30px);
  }

  .search-icon {
    cursor: pointer;
    font-size: 24px;
    color: ${props => props.theme.colors['text-base']};
  }

  .search-input {
    padding: 4px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    font-size: 14px;
    &::placeholder {
      color: ${props => props.theme.colors.label};
    }
  }

  .search_results {
    position: absolute;
    background-color: #efefef;
    padding:0.2rem;
    border-radius: 0.4rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    max-height: 200px;
    width: 100%;
    overflow: auto;
    top: 40px;
    left: 0;
    z-index: 1;
    > div {
      font-size: 13px;
      padding: 5px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      &:hover, &.search-result-active {
        background-color: #fff;
        cursor: pointer;
        font-weight: bold;
      }
      &.search-result-active {
        border-top-color: #ccc;
        border-bottom-color: #ccc;
      }
    }
  }
`;

