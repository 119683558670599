import { createGlobalStyle } from 'styled-components';

import appDotsCtr from '../assets/icons/app-dots-ctr.svg';

const GlobalStyle = createGlobalStyle`

  *, *:after, *:before {
    box-sizing: inherit;
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    background-color: ${(props) => `${props.theme.colors['background-color']}`};
    scroll-behavior: smooth;
    scroll-padding-top: 100px; /* Because of the sticky header */
  }
  body {
    font-family: ${props => props.theme.fonts['primary-font']};
    font-size: 1.6em;
    line-height: 1.6;
    color: ${props => props.theme.colors['text-base']};
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
  }

  /* reset default margin and padding */
  h1, h2, h3, h4, h5, ul, ol, li {
    padding: 0;
    margin: 0;
  }

  a, a:link, a:visited {
    text-decoration: none;
    color: ${(props) => `${props.theme.colors.link}`};
  }
  a:hover {
    color: ${(props) => `${props.theme.colors.linkhover}`};
    text-decoration: underline;
  }

  // Mozilla hide alternate text while image is still loading
  img:-moz-loading {
    visibility: hidden;
  }

  /* Frame for app images */
  .img-frm {
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 4rem 2.4rem 0;
    border-radius: 2rem;
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 2.4rem;
      top: 1.8rem;
      width: 4rem;
      height: 1.2rem;
      background: url(${appDotsCtr}) no-repeat;
      background-size: contain;
    }
    > img {
      width: 100%;
      border-radius: 0.4rem 0.4rem 0 0;
      overflow: hidden;
      display: block; /* remove extra white space at the bottom */
    }
    @media only screen and (max-width : ${props => props.theme.general.mdBreakpoint}) {
      border-radius: 1.2rem;
      padding: 3rem 1.8rem 0;
      &::before {
        width: 2.4rem;
        top: 1.4rem;
        left: 1rem;
      }
    }
  }




  /* COLORS MODE */
  [mode=light] {
    .img-frm {
      background-color: #fdfbfb;
      box-shadow: 0 0 1.6rem rgba(0,0,0,0.16);
    }
  }
  [mode=dark] {
    /* logo */
    .logo-letter-color {fill: #fdfbfb}
    /* footer */
    .footer {
      border-color: rgba(255,255,255,0.1);
      background-color: #111;
    }
    .footer__social .icon:hover {
      color: #fff;
    }
    .footer__copyright {
      a:link,
      a:visited {color: #9B9B9B;}
      a:hover {color: #fff;}
      > span {
        color: #9B9B9B;
      }
    }
    .img-frm {
      background-color: #111;
      box-shadow: 0 0 1.6rem rgba(0,0,0,0.33);
    }
  }

  .MuiTooltip-popper {
    > div {
      font-size: 1.3rem;
      background-color: #3f3f3f;
    }
  }
`;

export default GlobalStyle;
