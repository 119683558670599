import React from 'react'
import PropTypes from 'prop-types';
import { StyledLegendFormatter } from './LegendFormatter.style'

const LegendFormatter = ({ label, color} ) => {
  return (
    <StyledLegendFormatter color={color} className={"legend-formatter-container"}>
      <div className={"legend-formatter-legend"}/>
      {label}
    </StyledLegendFormatter>)
}

LegendFormatter.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

LegendFormatter.defaultProps = {
  data: {}
};

export default LegendFormatter;
