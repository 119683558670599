import React, {useState} from 'react';
import FilesHelper from "../../utils/files-helper";
import Dropzone from 'react-dropzone';
import {PhotoCamera} from '../../assets/mui-icons';
import Loading from "../Loading/Loading";
import CropDialog from './CropDialog';
import { StyledImageEditor } from "./ImageEditor.style";

export default function ImageEditor({
                                      label, defaultPhotoUrl, maxWidth, maxHeight, onChange,
                                      className, hint,
                                      allowCrop = true, allowClearCrop, cropAspect, cropWidth, cropHeight
                                    }) {

  const [selectedFileData, setSelectedFileData] = useState({file: null, url: defaultPhotoUrl});
  const [busy, setBusy] = useState(false);
  const [showCropDialog, setShowCropDialog] = useState(false);
  const [cropFile, setCropFile] = useState(null);

  const onFileSelected = (file) => {
    if (file.type.match(/image.*/)) {
      if (allowCrop) {
        setShowCropDialog(true);
        setCropFile(file);
      } else {
        onFileReady(file);
      }
    }
  };

  const onImageCropped = (file, isCropped) => {
    setShowCropDialog(false);
    onFileReady(file, isCropped);
  };

  const onFileReady = (file, isCropped = false) => {
    setBusy(true);
    FilesHelper.ResizeImage(file, maxWidth, maxHeight).then(({src: url, file: resizedFile}) => {
      const newSelectedFile = {file: resizedFile, url};
      setSelectedFileData(newSelectedFile);
      setBusy(false);
      if (onChange)
        onChange({...newSelectedFile, isCropped});
    });
  };

  return <StyledImageEditor className={className}>
    <div className="container">
      {label ? <div>{label}</div> : null}
      <div className="photo" style={{"backgroundImage": `url(${selectedFileData.url})`}}>
        {busy ? <Loading /> : null}
        <Dropzone multiple={false} accept={{'image/*' :['.png','.jpg', '.gif', '.jpeg']}} onDrop={(files) => onFileSelected(files[0])}>
          {({getRootProps, getInputProps}) => (
            <section {...getRootProps()}>
              <input {...getInputProps()} />
              {!busy && !selectedFileData.url ? <button><PhotoCamera /></button> : null}
              {!busy && !selectedFileData.url ? <span>{hint || 'Add image'}</span> : null}
              {selectedFileData.url ? <span className="replace">Replace image</span> : null}
            </section>
          )}
        </Dropzone>
      </div>
    </div>
    {allowCrop && cropFile && <CropDialog
      open={showCropDialog}
      file={cropFile}
      aspect={cropAspect}
      width={cropWidth}
      height={cropHeight}
      allowClearCrop={allowClearCrop}
      onCancel={() => setShowCropDialog(false)}
      onOk={onImageCropped}/>
    }

  </StyledImageEditor>;
};

