import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Typography from '../Typography';
import {StyledKpiValue} from './KpiValue.style';
import Converters from "../../utils/converters";
import {useSelector} from "react-redux";
import {KpisAPI} from "../../api";
import Loading from "../Loading/Loading";
import General from "../../utils/general";

KpiValue.propTypes = {
  companyId: PropTypes.number,
  fetchValue: PropTypes.bool,
  kpiType: PropTypes.string,
  filters: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.string,
  valueDataType: PropTypes.string,
  currency: PropTypes.string
};

export default function KpiValue({companyId, watchlistId, fetchValue, kpiType, filters, title, value, valueDataType, currency = '$', ...rest}) {
  const {user} = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const [currentFilters, setCurrentFilters] = useState(null);
  const startupId = Number(isStartup ? user.organizationId :  companyId);
  const [data, setData] = useState({title, value, valueDataType, currency});
  const [isBusy, setIsBusy] = useState(fetchValue);

  useEffect(() => {
    let isMounted = true;  // note mutable flag
    if (fetchValue && currentFilters) {
      setIsBusy(true);
      KpisAPI.getKpi(startupId, watchlistId, kpiType, {}, currentFilters).then(({success, data}) => {
        if (isMounted) {
          if (success) {
            setData({ title: data.title, value: data.data[data.data.length - 1], valueDataType: data.valueDataType, currency: data.currency });
          }
          setIsBusy(false);
        }
      });
    }
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, [fetchValue, kpiType, startupId, watchlistId, currentFilters]);

  useEffect(() => {
    const alteredFilters = {...filters};
    delete alteredFilters.compareBySegment;
    delete alteredFilters.subType;
    if (!General.isEqualObject(alteredFilters, currentFilters)) {
      setCurrentFilters(alteredFilters);
    }
  }, [filters]);

  if (isBusy)
    return <StyledKpiValue loading="true" style={{...rest.style}}><Loading size="medium" /></StyledKpiValue>;

  const getFormattedValue = () => {
    try {
      let dataType = data.valueDataType;
      let value = data.value;
      if (typeof value === 'object') {
        if (value.hasOwnProperty('YoY')) // Growth YoY calculation
          value = value.YoY;
        else if (value.hasOwnProperty('value')) { // Single value calculation
          value = value.value;
        } else if (value.hasOwnProperty('endingBalance')) { // For ARR/Logos breakdown
          value = value.endingBalance;
        } else if (value.hasOwnProperty('_total')) { // For other breakdown charts
          value = value._total;
        } else if (value.hasOwnProperty('period')) { // For P&L revenue breakdown
          value = Object.keys(value).filter(k => !['period'].includes(k)).reduce((acc, key) => acc + value[key], 0);
        } else {
          value = 'N/A';
          dataType = 'text';
        }
      }
      switch (dataType) {
        case 'number': return Converters.thousandSeparator(value);
        case 'currency': return Converters.toCurrencyString(value);
        case 'percent': return Converters.toPercentage(value);
        default: return value;
      }
    } catch (e) {
      return 'N/A';
    }
  };

  return (
    <StyledKpiValue {...rest}>
      <Typography variant="h3">{data.title}{data.value?.YoY ? ' YoY' : ''}</Typography>
      <div className="value">
        {getFormattedValue()}
      </div>
    </StyledKpiValue>
  );
}

