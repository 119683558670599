import React, { useState } from "react";
import { StyledKpiDataExplanation } from './KpiDataExplanation.style';
import Table from "../Table";
import Converters from "../../utils/converters";
import enums from "../../utils/enums";
import cx from "classnames";
import ColorsHelper from "../../utils/colors-helper";
import { Download } from "../../assets/mui-icons";
import ErrorMsgBox from "../dialogs/ErrorMsgBox";
import moment from "moment";

export default function KpiDataExplanation({ kpiType, kpiResult = {}, segmentBy, selectedPeriod, selectedCohort, onDataDownload, isCompareMode = false, autoFormatValues = true}) {

  const [isDownloadingChartData, setIsDownloadingChartData] = useState(false);
  const [downloadChartDataError, setDownloadChartDataError] = useState();

  const { isFiscalYear, dataExplanation } = kpiResult;
  const highlightLastRow = (!segmentBy || isCompareMode) && !kpiType.includes(':growth') && !kpiType.includes('accumulative') && !kpiType.includes('ndr-wa-cohorts');

  const highlightedColumns = [];
  if (!selectedCohort && selectedPeriod && !selectedPeriod.includes(':') && !kpiType.includes('waterfall')) {
    highlightedColumns.push(formatPeriod(selectedPeriod, isFiscalYear));
    if (isCompareMode) {
      highlightedColumns.push((kpiResult?.dataExplanation && kpiResult.dataExplanation[0]?.columns || [])[0]?.Header);
    }
  }

  const getTitle = (title) => {
    if (isCompareMode && (segmentBy || kpiType.includes('breakdown') || kpiType.includes('waterfall') || kpiType.includes('cohort') || kpiType.includes('retention') || kpiType.includes('arpa') || kpiType.includes('accumulative') || kpiType.includes('ltm'))) {
      const color = ColorsHelper.getPrimaryColor(title);
      if (color) {
        return <span className="compare-mode"><i style={{backgroundColor: color}} />{title}</span>;
      }
      if (kpiType.includes('accumulative')) {
        const segmentName = title.split(' - ')[0];
        const color = ColorsHelper.getPrimaryColor(segmentName);
        if (color) {
          return <span className="compare-mode"><i style={{backgroundColor: color}} />{title}</span>;
        }
      }
    }
    return title;
  }

  const renderTable = (tableDefinition, index) => {
    let { columns = [], rows = [], comment, title } = tableDefinition || {};
    rows = resolveValuesByRowDataType(rows, columns.find(c => c.compareMode)?.accessor, autoFormatValues);

    if (kpiType.includes('cohort')) {
      columns.forEach(col => {
        col.allowEmptyValue = true;
      });
    }

    if (!columns.length)
      return null;

    if (autoFormatValues) {
      columns.forEach((c, index) => {
        if (index === 0) {
          c.width = `80px`;
        } else
          c.width = `${100 / (columns.length - 1)}%`;
      });
    }

    if (comment && !Array.isArray(comment))
      comment = [comment];

    return <div key={`data-exp-${index}`}>
      {title &&
        <h3 className="title">
          {getTitle(title)}
        </h3>
      }
      <Table className="data-table"
        data={rows}
        columns={columns}
        highlightedColumns={highlightedColumns}
        cbGetRowClassName={selectedCohort && !selectedPeriod && kpiType.includes('cohort') || kpiType.includes('retention') ? (row) => {
         if (selectedCohort && !selectedPeriod) {
           // alert(`'${row?.values?.cohort}' - '${Converters.normalizeCohortName(selectedCohort, isFiscalYear)}'`)
           return (row?.values?.cohort || row?.values?.label) === Converters.normalizeCohortName(selectedCohort, isFiscalYear) ? 'highlight' : 'no-highlight';
         }
         return '';
        } : undefined }
        cbGetCellClassName={selectedCohort && selectedPeriod && kpiType.includes('cohort') || kpiType.includes('retention') ? (row, cellIndex) => {
         if (selectedCohort && selectedPeriod) {
           const cohort = row?.values?.cohort || row?.values?.label;
           // alert(cohort)
           return cohort === Converters.normalizeCohortName(selectedCohort, isFiscalYear) && (cellIndex === 0 || convertCellIndexToPeriod(cohort, cellIndex - 2) === selectedPeriod) ? 'highlight' : 'no-highlight';
         }
         return '';
        } : undefined }
      />
      <div className="comment">
        {comment?.map((c,commentIndex) => <pre key={commentIndex}>{c}</pre>)}
      </div>
    </div>;
  };

  return (
      <StyledKpiDataExplanation className={cx({highlighted: !!highlightedColumns.length, highlightLastRow, ['compare-mode']: isCompareMode})}>
        <div className="toolbar">
          <a className="action" onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isDownloadingChartData && onDataDownload) {
              setIsDownloadingChartData(true);
              const {error} = await onDataDownload();
              if (error)
                setDownloadChartDataError(error || 'Failed to generate chart data.');
              setIsDownloadingChartData(false);
            }
          }}>
            <Download />
            <span> {isDownloadingChartData ? 'Downloading ...' : 'Download'}</span>
          </a>
          {downloadChartDataError ? <ErrorMsgBox title={"Error generating chart data"} open error={downloadChartDataError} onClose={() => {setDownloadChartDataError(null); setIsDownloadingChartData(false);} } /> : null}
        </div>
        <div className="data-tables">
        {dataExplanation.map(renderTable)}
        </div>
      </StyledKpiDataExplanation>
    );
};

function formatPeriod(period, isFiscalYear) {
  return isFiscalYear ? Converters.formatFiscalPeriodString(period) : Converters.formatPeriodString(period);
}

function resolveValuesByRowDataType(rows, comparePropName, autoFormatValues = true) {
  return rows?.map(row => {
    const rowClone = {...row};
    Object.keys(row).filter(propName => !['label', 'rowDataType'].includes(propName)).forEach(propName => {
      if (rowClone[propName] === null)
        rowClone[propName] = 'N/A';
      else if (propName === comparePropName)
        rowClone[propName] = <span className="compare-column"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(rowClone[propName])}} />{rowClone[propName]}</span>;
      else if (rowClone[propName] !== 'N/A' && row.rowDataType) {
        switch (row.rowDataType) {
          case enums.FieldDataType.CURRENCY:
            rowClone[propName] = Converters.toCurrencyString(rowClone[propName]);
            break;
          case enums.FieldDataType.POSITIVE_CURRENCY:
            rowClone[propName] = Converters.toFinancialNegativeString(rowClone[propName]);
            break;
          case enums.FieldDataType.DATE:
            rowClone[propName] = Converters.toDateString(rowClone[propName]);
            break;
          case enums.FieldDataType.NUMBER:
          case enums.FieldDataType.NUMERIC:
            rowClone[propName] = Converters.thousandSeparator(rowClone[propName]);
            break;
          case enums.FieldDataType.PERCENT:
          case enums.FieldDataType.PERCENTAGE: {
              if (!isNaN(rowClone[propName])) {
                const num = Number(rowClone[propName]);
                rowClone[propName] = `${num.toFixed(2).replace('.00', '')}%`;
                if (!autoFormatValues && num < 0)
                  rowClone[propName] = `(${rowClone[propName].replace('-', '')})`;
              }
           }
            break;
          default:
            break;
        }
      }
    });
    return rowClone;
  });
}


const convertCellIndexToPeriod = (cohort, cellIndex) => {
  let periodKind = enums.Period.Yearly;
  if (cohort.includes('Q'))
    periodKind = enums.Period.Quarterly;
  else if (cohort.includes('-'))
    periodKind = enums.Period.Monthly;

  switch (periodKind) {
    case enums.Period.Monthly: return moment(cohort, 'YYYY MM').add(cellIndex + 1, 'month').format('YYYY-MM');
    case enums.Period.Quarterly: return moment(cohort, 'YYYY [Q]Q').add(cellIndex + 1, 'quarter').format('YYYY-[Q]Q');
    default: return moment(cohort, 'YYYY').add(cellIndex + 1, 'year').format('YYYY');
  }
};

