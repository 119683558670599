import React from "react";

export default function Logo() {
  return (
  <svg xmlnsXlink='http://www.w3.org/2000/svg' width='80' height='28' viewBox='0 0 80 28'>
    <title>Novvo Ltd.</title>
    <path d='M32,13v7H28.7V13.7c0-1.1-.5-1.9-1.6-1.9s-1.8,1-1.8,2.3V20H22V9.3h3.2v1.5h.1A3.39,3.39,0,0,1,28.5,9C30.7,9,32.1,10.7,32,13Zm12.9,1.5c0,3.1-2.3,5.5-5.9,5.5s-6-2.4-6-5.5S35.4,9,39,9,44.9,11.4,44.9,14.5Zm-3.6,0c0-1.9-.9-3.2-2.4-3.2s-2.4,1.3-2.4,3.2.9,3.1,2.4,3.1S41.3,16.4,41.3,14.5ZM63.5,9,62,14l-.6,2.2L60.8,14,59.3,9H52.6l-1.5,5-.6,2.2L49.9,14,48.4,9H44.9l3.7,11h3.7L55.9,9.3,59.5,20h3.7L66.9,9Zm15.3,5.5c0,3.1-2.3,5.5-5.9,5.5s-6-2.4-6-5.5S69.3,9,72.9,9,78.8,11.4,78.8,14.5Zm-3.5,0c0-1.9-.9-3.2-2.4-3.2s-2.4,1.3-2.4,3.2.9,3.1,2.4,3.1S75.2,16.4,75.3,14.5Z' className='logo-letter-color'/>
    <path d='M16,4h4V0H16Z' fill='#00c4c3'/>
    <path d='M12,4H0V8H9.2L0,17.2,2.8,20,12,10.8V20h4V4Z' fill='#9ece62'/>
  </svg>
  );
}

Logo.propTypes = {
  // mode: PropTypes.string,
};
