import React, {useState} from 'react';
import Dialog from '../Dialog';
import PropTypes from "prop-types";
import DataFilesPanel from "../../DataFilesPanel";
import { StyledDataFilesDialog } from './DataFilesDialog.style';

DataFilesDialog.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default function DataFilesDialog({ open, watchlistId, onClose, autoSelectFileType, onSubmitSupportRequest, className }){
  return <Dialog
    open={open}
    fullWidth
    className={className}
    maxWidth="lgg"
  >
    <StyledDataFilesDialog>
      <DataFilesPanel
        className={className}
        inlineMode
        popupMode
        isDataReady={true}
        onCancel={onClose}
        autoSelectFileType={autoSelectFileType}
        watchlistId={watchlistId}
        onSubmitSupportRequest={onSubmitSupportRequest}
      />
    </StyledDataFilesDialog>
  </Dialog>;
};

