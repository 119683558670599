import React, { useEffect, useState } from "react";
import Icons from "../Icon";
import { StyledUserPhoto } from "./UserPhoto.style";
import PropTypes from "prop-types";

export default function UserPhoto({ className, user = {}, size = 'md', ...rest }) {
  const { imageUrl, name } = user;
  const [useDefault, setUseDefault] = useState(!imageUrl || !imageUrl.startsWith('http'));

  useEffect(() => {
    setUseDefault(!imageUrl || !imageUrl.startsWith('http'));
  }, [imageUrl]);

  const calculatePixelSize = () => {
    switch (size) {
      case 'sm':
        return 16;
      case 'md':
        return 24;
      case 'lg':
        return 32;
      case 'xl':
        return 64;
      case 'xxl':
        return 128;
    }
  };

  const pixelSize = calculatePixelSize();
  const getProfilePhoto = () => {
    if (!useDefault)
      return <img src={imageUrl} alt={name} width={pixelSize} height={pixelSize} onError={() => setUseDefault(true)} />;

    return <Icons size="md" type="user" />;
  };

  return (
    <StyledUserPhoto className={`${(className || '')}${(useDefault ? ` default color_${user.id % 6}` : '')}`} style={{ fontSize: pixelSize, width: pixelSize, height: pixelSize }} {...rest}>
      {getProfilePhoto()}
    </StyledUserPhoto>
  );
};

UserPhoto.propTypes = {
  size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm'])
};

UserPhoto.defaultProps = {
  type: 'default',
  size: 'md',
};
