import colors from "../styles/colors";

class ColorsHelper {

  static availableColorPalettes = Object.values(colors.chartsPalettes);

  static colorMap = Array.from(Array(this.availableColorPalettes.length).keys(), _ => null);

  static reset() {
    this.colorMap = Array.from(Array(this.availableColorPalettes.length).keys(), _ => null);
  }

  static getPaletteIndex(value) {
    return this.colorMap.indexOf(value);
  }

  static getColorPalette(value) {
    return this.availableColorPalettes[this.getPaletteIndex(value)];
  }

  static setColor(value) {
    if (this.getPaletteIndex(value) < 0) {
      const newIndex = this.getPaletteIndex(null);
      this.colorMap[newIndex] = value;
    }
  }

  static removeColor(value) {
    const newIndex = this.getPaletteIndex(value);
    this.colorMap[newIndex] = null;
  }

  static getPrimaryColor(value) {
    return this.getColorPalette(value)?.primary;
  }

  static getDarkColor(value) {
    return this.getColorPalette(value)?.dark;
  }

}

export default ColorsHelper;
