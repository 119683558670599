import React from 'react';
import {StyledDataGenerationNotification} from "./DataGenerationNotification.style";
import MsgBox from "../MsgBox";
import ChartLoading from '../../ChartLoading/ChartLoading';

export default function DataGenerationNotification({ open, showReloadPageText }){

  return <MsgBox
    open={open}
    modal={true}
  >
    <StyledDataGenerationNotification>
      <div className="loading-msg">Generating data</div>
      <ChartLoading animated={true} className="loading-indication" />
      {!!showReloadPageText && <div className="reload-note">Page will be reloaded when done!</div>}
    </StyledDataGenerationNotification>
  </MsgBox>;
};

