import React, {useEffect, useState} from 'react';
import Dialog from '../Dialog';
import Button from '../../Button';
import PropTypes from "prop-types";
import enums from '../../../utils/enums';
import { StyledPermissionsEditorsDialog, StyledPermissionsEditorsDialogFooter } from './PermissionsEditor.style';
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

PermissionsEditor.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func
};

PermissionsEditor.defaultProps = {
  title: 'Edit permissions'
};

export default function PermissionsEditor({ permissions: initialPermissions, open, title, onChange, onClose, className }){
  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    setPermissions(initialPermissions);
  }, [initialPermissions]);

  const onItemChanged = (key, checked) => {
    let newPermissionsValue;
    if (checked)
      newPermissionsValue = permissions | enums.Permissions[key];
    else
      newPermissionsValue = permissions & ~enums.Permissions[key];

    setPermissions(newPermissionsValue);
  };

  const onOk = () => {
    if (onChange)
      onChange(permissions);
    if (onClose)
      onClose();
  };

  const renderPermissionOptions = () => {
    return Object.keys(enums.Permissions).filter(key => key !== 'All').map(key => (
      <FormControlLabel
        key={enums.Permissions[key]}
        label={key}
        control={
          <Checkbox checked={!!(permissions & enums.Permissions[key])}
                    disabled={key === 'Profile'}
                    onChange={(e, checked) => onItemChanged(key, checked)} />
        } />
    ));
  };

  return <Dialog
      open={open}
      header={title}
      onClose={onClose}
      showCloseIcon
      className={className}
      modal={false}
      footer={
        <StyledPermissionsEditorsDialogFooter>
          <Button onClick={onOk}>OK</Button>
        </StyledPermissionsEditorsDialogFooter>
      }
    >
      <StyledPermissionsEditorsDialog>
      <FormGroup>
        {renderPermissionOptions()}
      </FormGroup>
      </StyledPermissionsEditorsDialog>
    </Dialog>;
};

