import styled from 'styled-components';

const headersColor = '#e9e9e9';
const borderColor = '#d8d8d8';

export const StyledExcelViewer = styled.div`
  height: calc(100% - 70px);
  align-items: center;
  display: flex;
  border: ${props => props.showBorder ? '1px solid #d8d8d8' : 'none'};

  .wizard-content {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  .sheet-selection-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .files-list {
    height: calc(100% - 100px);
    margin-bottom: 50px;
    overflow: auto;

    .radio__lbl {
      em {
        color: ${props => props.theme.colors.legend};
        font-size: 12px;
      }
    }
  }

  .excel-loading i {
    margin-right: 10px;
  }

  .table-content {
    font-size: 10px;
    user-select: none;

    table {
      width: 100%;
      background-color: #fff;
      font-size: 12px;
      color: #272727;

      tr {
        th, td {
          border-bottom: 1px solid ${borderColor};
          border-right: 1px solid ${borderColor};
        }
      }
      tr:last-child td {
        border-bottom: none;
      }

      tr td:last-child {
        border-right: none;
      }

      tr th:last-child {
      }

      tr:last-child td {
        border-bottom: 1px solid #d8d8d8;
      }

      tr td:last-child {
      }

      th {
        background-color: ${headersColor};
        padding: 5px;
        color: #7b7b7b;
        font-weight: normal;
      }
      tr {
        td {
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 5px;
          vertical-align: center;
        }
        &.dummy-row {
          td, td:first-child {
            background-color: transparent;
            border-right: none;
            border-left: none;
            text-align: center;
            vertical-align: middle;
            svg {
              font-size: 30px;
              margin: 10px 0;
              vertical-align: middle;
            }
          }
        }
        &.next-row-dummy {
          td, td:first-child {
            border-bottom: none;
          }
        }
      }

      thead tr {
        position: sticky;
        top: 0;
        th:first-child {
          position: sticky;
          left: 0;
          top: 0;
          z-index: 2;
        }
      }

      tr {
        td:first-child {
          background-color: ${headersColor};
          position: sticky;
          padding: 5px 6px;
          text-align: center;
          color: #7b7b7b;
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  .highlight {
    background-color: ${headersColor};
    color: #9b9b9b;

    &.current {
      background-color: rgba(0,161,222,0.25);
      color: #272727;
    }
  }
`;



