import React from 'react';
import PropTypes from "prop-types";
import Button from '../../../components/Button';
import {Dialog} from "../";
import {makeStyles} from "@mui/styles";
import 'react-image-crop/dist/ReactCrop.css';

FileReplaceConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  conflictingFiles: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func,
  onReplace: PropTypes.func,
  onCancel: PropTypes.func
};


const useStyles = makeStyles(theme => ({
  content: {
    fontSize: 13,
    padding: '20px 40px 20px 20px'
  },
  fileName: {
    fontWeight: 'bold'
  },
  question: {
    marginTop: 10
  },
  header: {
    fontSize: '1.6rem',
    fontWeight: 'bold'
  }
}));

function FileReplaceConfirmationDialog({ open, conflictingFiles, onAdd, onReplace, onCancel }) {
  const classes = useStyles();
  const isMultipleFiles = conflictingFiles.length > 1;

  return (
    <Dialog
      className='dlg'
      open={open}
      header={<div className={classes.header}>Files conflict</div>}
      footer={<>
        <div>
          <Button color="white" borderColor="primary" onClick={onCancel}>Cancel</Button>
          <Button onClick={onAdd}>Add new</Button>
          <Button onClick={onReplace}>Replace</Button>
        </div>
      </>
      }
    >
      <div className={classes.content}>
        {conflictingFiles.map((fileName, index) => <div key={`filename_${index}`} className={classes.fileName}>{fileName.replace(/.large$/g, '')}</div>)}
        <div className={classes.question}>File{isMultipleFiles ? 's' : ''} already exist, do you want to replace the existing file{isMultipleFiles ? 's' : ''} or add new?</div>
      </div>
    </Dialog>
  );
}


export default FileReplaceConfirmationDialog;

