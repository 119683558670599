import React, { useState } from "react";
import { StyledHelpMenu } from "./HelpMenu.style";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../../../../Typography";
import { Close } from "../../../../../assets/mui-icons";
import GetStartedTutorialDemoDialog from '../../../../dialogs/GetStartedTutorialDemoDialog';
import UsersAPI from "../../../../../api/users";
import routes from '../../../../../pages/routes';
import { useSelector } from "react-redux";

const HelpMenu = ({ mode }) => {
  const { user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const isExternalUser = user?.isExternalUser;
  const [isOpen, setIsOpen] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const helpLink = isStartup ? routes.Startups.FileUploadHelp : routes.Investors.FileUploadHelp;

  const openTutorial = () => {
    UsersAPI.markActivityDone('WatchedTutorialMovie').then();
    setShowTutorial(true);
  };

  const openHelp = () => {
    window.open(helpLink);
  };

  return (
    <StyledHelpMenu
      className={`menu ${isOpen ? "menu--open" : ""}`}
      mode={mode}
    >
      {showTutorial ? <GetStartedTutorialDemoDialog open onClose={() => setShowTutorial(false)} /> : null}
      <button
        className="menu__opener"
        data-testid={"accountIcon"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Tooltip title="Help" arrow placement="left-start"><div className="btn"><span>?</span></div></Tooltip>
      </button>
      <div className="menu__list">
        <div className="header">
          <Typography variant="h2">Help</Typography>
          <div className="close-btn" onClick={() => setIsOpen(false)}>
            <Close />
          </div>
        </div>
        <div className="menu-content">
          {!isExternalUser ? <div onClick={openTutorial}>
            <Typography variant="h3">Get started</Typography>
            This short tutorial provides an overview of how to generate analytics and how to use it to analyze a startup.
          </div> : null}
          <div onClick={openHelp}>
            <Typography variant="h3">Upload tips</Typography>
            Supported file formats, generating analytics, step by step data loading
          </div>
          <div onClick={openHelp}>
            <Typography variant="h3">Get templates</Typography>
            MRR template<br />
            Booking template<br />
            P&L template
          </div>
        </div>
      </div>
    </StyledHelpMenu>
  );
};

export default HelpMenu;
