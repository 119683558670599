import React, { useState } from "react";
import { StyledCustomersTable } from './CustomersTable.style';
import Table from "../Table";
import Converters from "../../utils/converters";
import ColorsHelper from "../../utils/colors-helper";
import { Download } from "../../assets/mui-icons";
import ErrorMsgBox from "../dialogs/ErrorMsgBox";

export default function CustomersTable({ kpiType, error, errorType, tableColumns, data = [], title, selectedSeriesKey, dataType, isFiscalYear, compareField, sortOrder, onDataDownload, ...rest }) {
  const [isDownloadingChartData, setIsDownloadingChartData] = useState(false);
  const [downloadChartDataError, setDownloadChartDataError] = useState();

  if (!selectedSeriesKey && kpiType.includes('-ndr-') && data.length)
    selectedSeriesKey = data[data.length-1].period;

  selectedSeriesKey = selectedSeriesKey?.split(':');

  const initialSort = [];
  const customers = getRelevantCustomers(data, selectedSeriesKey, kpiType, isFiscalYear, compareField);
  const valueAsText = !selectedSeriesKey && !kpiType.includes('breakdown') && !kpiType.includes('ltm') && !isCohortOrRetentionKpi(kpiType);
  const columns = (tableColumns || []).map((col) => {
    const def = {
      Header: col.label,
      accessor: col.name,
      dataType: valueAsText ? 'text' : col.dataType,
      sortable: true,
      width: col.width
    };

    if (col.dataType === 'currency') {
      def.sortType = (a, b) => {
        let value_a = a.values.hasOwnProperty(col.name) ? a.values[col.name] : a.values.value;
        let value_b = b.values.hasOwnProperty(col.name) ? b.values[col.name] : b.values.value;
        if (valueAsText) {
          value_a = typeof value_a === 'string' ? Converters.currencyStringToNumber(value_a) : 0;
          value_b = typeof value_b === 'string' ? Converters.currencyStringToNumber(value_b) : 0;
        }
        return value_a - value_b;
      };
    }

    if (col.name.endsWith(compareField)) {
      initialSort.push({id: col.name, desc: false});
      def.sortType = (a, b) => {
        const nameParts = col.name.split('.');
        const value_a = a.original[`${col.name}_original`] || a.original.customerFields && a.original.customerFields[`${nameParts[nameParts.length-1]}_original`] || '';
        const value_b = b.original[`${col.name}_original`] || b.original.customerFields && b.original.customerFields[`${nameParts[nameParts.length-1]}_original`] || '';
        const index_a = sortOrder.indexOf(value_a);
        const index_b = sortOrder.indexOf(value_b);
        return index_a - index_b;
      };
    }
    return def;
  });

    const startColumn = columns.find(c => c.accessor === 'value');
    const startPeriod = data.length > 0 ? data[0].period : null;
    const endPeriod = data.length > 0 ? data[data.length - 1].period : null;
    const endColumn = columns.find(c => c.accessor === 'lastValue');

    if (startColumn) {
      if (!selectedSeriesKey || selectedSeriesKey.length === 1) {
        if (endColumn) {
          startColumn.Header = startColumn.Header.replace('{PERIOD}', formatPeriod(selectedSeriesKey && selectedSeriesKey[0] || startPeriod, isFiscalYear));
        // } else if (startPeriod !== endPeriod) {
        //   startColumn.Header = startColumn.Header.replace('for {PERIOD}', '');
        } else {
          startColumn.Header = startColumn.Header.replace('for {PERIOD}', '');
        }
      } else {
        startColumn.Header = startColumn.Header.replace('for {PERIOD}', '');
      }
    }
    if (endColumn)
      endColumn.Header = endColumn.Header.replace('{PERIOD}', formatPeriod(selectedSeriesKey && selectedSeriesKey[0] || endPeriod, isFiscalYear));

  const theTitle = isCohortOrRetentionKpi(kpiType) ? title : getTitle(data, selectedSeriesKey || [startPeriod, endPeriod], title, isFiscalYear);

  return (
      <StyledCustomersTable {...rest}>
        <h3 className="title"><span>{theTitle}</span>
          {onDataDownload ? <span className="link" onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isDownloadingChartData && onDataDownload) {
              setIsDownloadingChartData(true);
              const {error} = await onDataDownload();
              if (error)
                setDownloadChartDataError(error || 'Failed to generate chart data.');
              setIsDownloadingChartData(false);
            }
          }}>
            <Download />
            <span> {isDownloadingChartData ? 'Downloading ...' : 'Download'}</span>
          </span> : null}
          {downloadChartDataError ? <ErrorMsgBox title={"Error generating chart data"} open error={downloadChartDataError} onClose={() => {setDownloadChartDataError(null); setIsDownloadingChartData(false);} } /> : null}
        </h3>
        <div>
          {error ? <div className="error-msg">{error}</div> : <Table data={customers} columns={columns} initialSort={initialSort} /> }
        </div>
      </StyledCustomersTable>
    );
};


function isCohortOrRetentionKpi(kpiType) {
  return kpiType.includes('cohort') || kpiType.includes('retention') && !kpiType.includes('ltm-retention');
}

function formatPeriod(period, isFiscalYear) {
  return isFiscalYear ? Converters.formatFiscalPeriodString(period) : Converters.formatPeriodString(period);
}

function getTitle(data, selectedSeriesKey, defaultTitle, isFiscalYear) {
  let title = defaultTitle;
  if (Array.isArray(selectedSeriesKey)) {
    if (selectedSeriesKey.length === 1 || selectedSeriesKey[0] === selectedSeriesKey[1]) {
      const periodData = data.find(r => r.period === selectedSeriesKey);
      if (periodData && periodData.title)
        title = periodData.title;
      else {
        title = `${defaultTitle}`;
      }
    } else if (!title.includes('(for ')) {
      title = `${defaultTitle} (for ${formatPeriod(selectedSeriesKey[0], isFiscalYear)} - ${formatPeriod(selectedSeriesKey[1], isFiscalYear)})`;
    }
  }
  if (!title.includes('(for ') && data.length === 1) {
    title = `${defaultTitle} (for ${formatPeriod(data[0].period, isFiscalYear)})`;
  }

  return generateTitleElement(title);
}

function generateTitleElement(title) {
  if (title && title.includes('(') && title.includes(')')) {
    const segments = title.match(/(.*)\((.*)\)(.*)/);
    return <>{segments[1]} <span style={{padding: "0 3px", fontWeight: "normal", fontSize: "12px", whiteSpace: "nowrap"}}>({segments[2]})</span> {segments[3]}</>;
  }
  return title;
}

function getRelevantCustomers(data, selectedSeriesKey, kpiType, isFiscalYear, compareField) {
  if (isCohortOrRetentionKpi(kpiType))
    return getRelevantCustomersForCohorts(data, selectedSeriesKey, kpiType, isFiscalYear, compareField);

  if (selectedSeriesKey) {
    const isMultiPeriod = selectedSeriesKey.length > 1 && selectedSeriesKey[0] !== selectedSeriesKey[1];
    const customers = [];
    let lastPeriodData;
    if (isMultiPeriod) {
      const relevantData = data.filter(r => r.period >= selectedSeriesKey[0] && r.period <= selectedSeriesKey[1]);
      lastPeriodData = relevantData[relevantData.length-1];
      relevantData.forEach(d => {
        const periodCustomers = d.customers;
        if (periodCustomers.length) {
          periodCustomers.forEach(c => {
            if (compareField) {
              const compareValue = c[compareField];
              if (compareValue && !c[`${compareField}_original`]) {
                c[`${compareField}_original`] = compareValue;
                c[compareField] = <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
              }
            }
            c.value = <>{Converters.toCurrencyString(c.value, {positiveOnly: false})} <em>({formatPeriod(d.period, isFiscalYear)})</em></>;
          });
          customers.push(...periodCustomers);
        }
      });
    }
    else {
      lastPeriodData = data.find(r => r.period === selectedSeriesKey[0]);
      customers.push(...(lastPeriodData || {customers: []}).customers);
      if (compareField) {
        customers.forEach(c => {
          if (c.customerFields) {
            const compareValue = c.customerFields[compareField];
            if (compareValue && !c.customerFields[`${compareField}_original`]) {
              c.customerFields[`${compareField}_original`] = compareValue;
              c.customerFields[compareField] = <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
            }
          }
          const compareValue = c[compareField];
          if (compareValue && !c[`${compareField}_original`]) {
            c[`${compareField}_original`] = compareValue;
            c[compareField] = <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
          }
        });
      }
    }

    if (lastPeriodData?.churnedCustomers?.length) {
      if (!isMultiPeriod) {
        lastPeriodData.churnedCustomers = lastPeriodData.churnedCustomers.filter(c => c.churnedOn === selectedSeriesKey[0]);
      }

      lastPeriodData.churnedCustomers.forEach(customer => {
        const {churnedOn, showRealChurnedValue, value} = customer;
        if (compareField) {
          const compareValue = customer[compareField];
          if (compareValue && !customer[`${compareField}_original`]) {
            customer[`${compareField}_original`] = compareValue;
            customer[compareField] = <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
          }
        }
        if (churnedOn && churnedOn !== '0000-00') {
          if (value) {
            customers.push({
              ...customer,
              value: <>{showRealChurnedValue ? Converters.toCurrencyString(value, {positiveOnly: false}) : 0} {isMultiPeriod && <em>Churned
                on {formatPeriod(churnedOn, isFiscalYear)}</em>}</>
            });
          }
        }
      });
    }
    return customers;
  }

  const customersDict = {};
  if (kpiType.includes('breakdown')) {
    const customers = [].concat(...(data || []).map(r => r.customers));
    customers.forEach(c => {
      const {customer_id} = c.customerFields;

      if (compareField) {
        const compareValue = c.customerFields[compareField];
        if (compareValue && !c.customerFields[`${compareField}_original`]) {
          c.customerFields[`${compareField}_original`] = compareValue;
          c.customerFields[compareField] = <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
        }
      }

      if (!customersDict[customer_id])
        customersDict[customer_id] = {...c};
      else {
        customersDict[customer_id]['new'] += c['new'];
        customersDict[customer_id]['expansion'] += c['expansion'];
        customersDict[customer_id]['churn'] += c['churn'];
        customersDict[customer_id]['contraction'] += c['contraction'];
        customersDict[customer_id]['reactivation'] += c['reactivation'];
        customersDict[customer_id]['endingBalance'] = c['endingBalance'];
      }
    });
    return Object.values(customersDict);
  }

  const customersData = [].concat(...(data || []));
  customersData.forEach((periodResult, index) => {
    if (periodResult.customers) {
      periodResult.customers.forEach(customer => {
        const {customerRefKey, value} = customer;
        if (compareField) {
          const compareValue = customer[compareField];
          if (compareValue && !customer[`${compareField}_original`]) {
            customer[`${compareField}_original`] = compareValue;
            customer[compareField] = getCompareFieldIcon(compareValue);
          }
        }
        if (!customersDict[customerRefKey]) {
          customersDict[customerRefKey] = {...customer};
        } else {
          customersDict[customerRefKey].value = value;
        }
      });

      if (periodResult.churnedCustomers?.length) {
        periodResult.churnedCustomers.forEach(customer => {
          const {customerRefKey, churnedOn} = customer;
          if (customersDict[customerRefKey])
            customersDict[customerRefKey].churnedOn = churnedOn;
        });
      }
    }
  });

  Object.keys(customersDict).forEach(customerId => {
    const {churnedOn, showRealChurnedValue, value} = customersDict[customerId];
    customersDict[customerId].value = Converters.toCurrencyString(value, {positiveOnly: true});
    if (churnedOn && churnedOn !== '0000-00') {
      customersDict[customerId].value = <>{showRealChurnedValue ? customersDict[customerId].value : 0} <em>Churned
        on {formatPeriod(churnedOn, isFiscalYear)}</em></>;
    }
  });

  return Object.values(customersDict);
}

function getCompareFieldIcon(compareValue) {
  return <span className="compare-value"><i style={{backgroundColor: ColorsHelper.getPrimaryColor(compareValue)}} />{compareValue}</span>;
}

function getRelevantCustomersForCohorts(data, selectedSeriesKey, kpiType, isFiscalYear, compareField) {
  data.forEach(customer => {
    if (compareField)
      customer[compareField] = getCompareFieldIcon(customer[compareField]);
    if (customer.churnedOn)
      customer.endingBalance = <>{Converters.toCurrencyString(customer.endingBalance)} <em>Churned on {formatPeriod(customer.churnedOn, isFiscalYear)}</em></>;
  });
  return data;
}
