import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { StyledCheckboxFilter } from './CheckboxFilters.style';
import Typography from '../Typography';
import converters from '../../utils/converters'
import { ReactComponent as ChevronDown } from '../../assets/images/i-chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/i-chevron-up.svg';
import Checkbox from '../Checkbox';
import cx from 'classnames';
import TextInput from "../TextInput";
import Tooltip from "@mui/material/Tooltip";
import ColorsHelper from "../../utils/colors-helper";

const renderValueText = (value, isInverted) => {
  const formattedValue = converters.toCurrencyString(value);
  if(isInverted) { return `${formattedValue} and higher`;}
  else { return `Up to ${formattedValue}`;}
}

function CheckboxFilters({ title, isDefaultOpen, values, options, onChange, className, allTextSearch, maxVisibleItems, allowCollapse, isCompareModeSet }) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen || !allowCollapse);
  const [showAll, setShowAll] = useState(false);
  const [textFilter, setTextFilter] = useState(null);
  const [isCompareMode, setIsCompareMode] = useState(isCompareModeSet);
  const MAX_COMPARE_ITEMS = 4;
  const isRange = options.some(op => op.min && op.max);

  useEffect(() => {
    if (isCompareMode !== isCompareModeSet)
      setIsCompareMode(isCompareModeSet);
  }, [isCompareModeSet]);

  const clearSelectedColor = (value) => {
    ColorsHelper.removeColor(value);
  };

  const clearColorSelection = () => {
    ColorsHelper.reset();
  };

  useEffect(() => {
    if (isCompareMode && !isCompareModeSet)
      toggleCompare();
  }, [isCompareModeSet]);

  useEffect(() => {
    if (isCompareMode) {
      values.forEach(v => ColorsHelper.setColor(v));
    }
  }, [isCompareModeSet]);

  const onChangeInternal = (checked, selectedOptions, isCompareMode) => {
    if (isCompareMode) {
      if (checked)
        selectedOptions.forEach(op => saveColorSelection(op));
      else
        selectedOptions.forEach(op => clearSelectedColor(op));
    }
    if (onChange)
      onChange(checked, selectedOptions, isCompareMode);
  };

  const selectAll = () => { onChangeInternal(true, options.map(op => op.name)); }
  const clearAll = () => {
    setTextFilter(null);
    onChangeInternal(false, options.map(op => op.name));
  }

  const saveColorSelection = (value) => {
    ColorsHelper.setColor(value);
  };

  const toggleCompare = () => {
    const isEnabled = !isCompareMode;
    setIsCompareMode(isEnabled);
    if (!isEnabled)
      onChangeInternal(false, options.map(op => op.name), isEnabled);
    else {
      clearColorSelection();
      if (!values.length) {
        onChangeInternal(true, options.filter((o, i) => i<MAX_COMPARE_ITEMS).map(op => op.name), isEnabled);
      }
      else {
        onChangeInternal(true, options.filter((o) => values.includes(o.value)).map(op => op.name), isEnabled);
      }
    }
  };

  const onSearchText = (e) => {
    setTextFilter(e.target.value);
  };

  let filteredOptions = options;
  if (filteredOptions) {
    if (textFilter) {
      filteredOptions = filteredOptions.filter(option => option.name.toLowerCase().includes(textFilter.toLowerCase()));
    }

    if (!showAll) {
      filteredOptions = filteredOptions.slice(0, maxVisibleItems);
    }
  }

  const renderShowMoreElement = () => {
    if (maxVisibleItems !== -1 && maxVisibleItems < options.length) {
      return <div className="text-btn" onClick={() => setShowAll(!showAll)}>{showAll ? 'Show less' : `Show all ${options.length}`}</div>;
    }
  };

  const getSelectedColor = (value) => {
    return ColorsHelper.getPrimaryColor(value);
  };

  const reachedToggleLimit = values.length >= MAX_COMPARE_ITEMS;

  return (
        <StyledCheckboxFilter className={cx("checkbox-filter-container", className)} allowCollapse={allowCollapse}>
            <div className={"title-container"} onClick={allowCollapse ? () => setIsOpen(!isOpen) : null}>
              <Typography variant="h3">{title}</Typography>
              {allowCollapse ? (isOpen ? <ChevronUp/> : <ChevronDown/>) : null}
            </div>
            {isOpen && (
              <div className={"checkboxes-container"}>
                {allTextSearch && <div className='search-box'>
                  <TextInput type="text" placeholder={options && options.length ? `e.g. ${options[0].name}` : 'Search ...'} onChange={onSearchText}  />
                </div>
                }
                <div className={"select-clear-section"}>
                  <Typography onClick={(values.length <= MAX_COMPARE_ITEMS || isCompareMode) && !isRange ? toggleCompare : null} className={"compare" + (isCompareMode ? ' selected' : '') + (values.length > MAX_COMPARE_ITEMS || isRange ? ' disabled' : '')} size={"12px"} color={"legend"}>
                    {(values.length > MAX_COMPARE_ITEMS && !isCompareMode || isRange) ? <Tooltip title={isRange ? "To compare ranges, please regenerate the saas-metrics" : "Up to two segments can be compared. Please deselect " + (values.length - MAX_COMPARE_ITEMS) + " and click Compare."}><span>Compare</span></Tooltip> : "Compare"}
                  </Typography>
                  <Typography size={"12px"} color={"legend"}>·</Typography>
                  {isCompareMode ? <Typography className="label" size={"12px"} color={"legend"}>Select up to {MAX_COMPARE_ITEMS}</Typography> : <>
                  <Typography onClick={selectAll} className={"select-all"} size={"12px"} color={"legend"}>Select all</Typography>
                  <Typography size={"12px"} color={"legend"}>·</Typography>
                    <Typography onClick={clearAll} className={"clear-all"} size={"12px"} color={"legend"}>Clear all</Typography>
                  </>}
                </div>
                <div className={"checkboxes-list"}>
                  {filteredOptions.map(({name, label}, index) => (
                    <div key={`${name}_${index}`} className={"checkbox-container"}>
                      <Checkbox style={{backgroundColor: isCompareMode ? getSelectedColor(name) : null}} disabled={isCompareMode && !values.includes(name) && reachedToggleLimit} checked={values.includes(name)} onChange={(e) => {
                        if (!isCompareMode || !reachedToggleLimit || !e.target.checked) {
                          const disableCompare = !e.target.checked && values.length === 1;
                          onChangeInternal(e.target.checked, [name], isCompareMode && !disableCompare);
                          if (isCompareMode && disableCompare)
                            setIsCompareMode(false);
                        }
                      }}>
                      <Typography className={name === '[No value]' ? 'no-value': undefined}>{label}</Typography>
                      </Checkbox>
                    </div>
                  ))}
                </div>
                {renderShowMoreElement()}
              </div>
            )}
        </StyledCheckboxFilter>
    );
}

CheckboxFilters.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  isDefaultOpen: PropTypes.bool,
  allTextSearch: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  maxVisibleItems: PropTypes.number
};

CheckboxFilters.defaultProps = {
  inverted: false,
  values: [],
  allTextSearch: false,
  maxVisibleItems: -1
};

export default CheckboxFilters;
