import styled from 'styled-components';

export const StyledRecommendations = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: normal;
  h3 {
    font-size: 14px;
  }
  .company {
    display: flex;
    flex-direction: column;
    .company-info {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        margin-right: 8px;
      }
      .company-details {
        display: flex;
        flex-direction: column;
        strong {
          font-weight: 500;
        }
        span {
          font-size: 11px;
          color: ${props => props.theme.colors.label};
        }
      }
    }
    .company-actions {
      margin: 5px 0 0 40px;
    }
  }
`;
