/* eslint-disable no-param-reassign */
import axios from 'axios';
import { TranslationHelper } from './i18n';
import store from '../store';
import { userLogout } from '../actions/authActions';
import { getCSRFToken } from '../api/auth';
import { refreshToken, verifyTokenAsync } from "../asyncActions/authAsyncActions";

class RetryHelper {
  static async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  static async retry(config) {
    if (!config.retries)
      config.retries = 0;
    config.retries++;
    if (config.retries <= 3) {
      console.log(`Retrying request... retry #${config.retries + 1}. waiting ${config.retries * 1000}ms`);
      await RetryHelper.sleep(config.retries * 1000);
      return axios.request(config);
    }
    return { success: false, statusCode: 500, error: 'Could not reach the server. Make sure you have an internet connection and try again.'};
  }
}

class AppGuardErrorHandler {

  static get IsEnabled() {
    return !!window.__AppGuard__;
  }

  static isSupportMode(error) {
    const { response } = error;
    return !!(response && response.data && response.data.errorId);
  }

  static validateError(error) {
    const { response, message } = error;
    if (response) {
      const { data, status } = response;
      if (status === 500 && data.error === 'INTERNAL_SERVER_ERROR' && data.errorId)
        return true;
      if (status === 403 && !data.error && data !== 'Forbidden' && message === 'Request failed with status code 403')
        return true;
    } else if (window.navigator.onLine) {
      // There is no response, means that it was blocked before reaching the server
      return true;
    }
  }

  static notifyIfNeeded(error) {
    if (!AppGuardErrorHandler.IsEnabled)
      return;

    window.__AppGuard__.handleAxiosError(error, AppGuardErrorHandler.validateError, AppGuardErrorHandler.isSupportMode);
  }
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';

const getRefererHeader = () => {
  const referer = window.location.href;
  if (referer.includes('#') && referer.length > 10000)
    return referer.split('#')[0];
  return referer;
};

class CSRFToken {
  static token = '';
  static get() {
    return CSRFToken.token || ''
  }
  static set(token) {
    CSRFToken.token = token || ''
  }
}

axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        config.headers['X-Strategy'] = 'cookie';
        config.headers['X-Referer'] = getRefererHeader();
        if (!['get', 'head', 'options'].includes(config.method)) {
          const csrfToken = CSRFToken.get();
          if (csrfToken)
            config.headers['X-CSRF-TOKEN'] = csrfToken;
        }
        return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.headers['x-refresh-token']) {
      const { silentAuth, isAuthenticated } = store.getState()['auth'];
      if (isAuthenticated && !silentAuth) {
        console.log('Refreshing the token');
        store.dispatch(refreshToken());
      }
    }
    return response.data;
  },
  (error) => {
    if (axios.isCancel(error))
      return error;
    const { config, response, message } = error;
    AppGuardErrorHandler.notifyIfNeeded(error);

    // Do something with response error
    const { data, status, headers } = response || {};
    let statusCode = status || 500;
    if (data) {
      statusCode = data.statusCode || statusCode
      if([401, 403].includes(statusCode) && data === "Unauthorized") {
        store.dispatch(userLogout());
      }
      if (statusCode === 403 && data.error === 'INVALID_CSRF_TOKEN') {
        console.log('CSRF token is invalid. Re-fetching CSRF token');
        return getCSRFToken().then((res) => {
          if (res?.csrfToken) {
            CSRFToken.set(res.csrfToken);
          }
          return axios.request(config);
        });
      }
      if (data.error) {
        data.error = TranslationHelper.getError(data.error);
        return data;
      }
    } else {
      return RetryHelper.retry(config);
    }
    // There is no response (Network error)
    // We got a network error and there is no internet connection

    const errorMessage = statusCode === 429 ? `Too many requests. | Please wait ${headers['x-ratelimit-retry-after'] ? Math.ceil(headers['x-ratelimit-retry-after']) : 'a few'} seconds before trying again.` : !window.navigator.onLine ? 'No internet connection' : message;
    return { success: false, statusCode, error: errorMessage };
  }
);
