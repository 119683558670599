import React from "react";
import { StyledProfile } from './profile.style';
import PageTitle from "../../../components/PageTitle";
import { CompanyProfileDetailsPanel } from "../../../components/CompanyProfile";
import Recommendations from "../../../components/Recommendations";
import { useLocation } from "react-router-dom";

export default function Profile() {
  const location = useLocation();
  const from_path = location.state?.from_path || `/`;

  return (
    <StyledProfile>
      <PageTitle icon="close" variant="h1" lineheight="1" path={from_path}>Company profile</PageTitle>
      <div className="page-content">
        <div className="groups">
        </div>
        <div className="main">
          <CompanyProfileDetailsPanel />
        </div>
        <div className="suggestions">
          <Recommendations type="investors" />
        </div>
      </div>
    </StyledProfile>
  );
};
