import React from 'react';
import PropTypes from "prop-types";
import { StyledIcon } from './IconStyle';

const Icon = ({ type, size, onClick, className = '' }) => {
    return (
        <StyledIcon className={`icon ${type} ${size} ${className}`} onClick={onClick}/>
    );
};

Icon.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm'])
};

Icon.defaultProps = {
  type: 'default',
  size: 'md',
};

export default Icon;
