import React from 'react';
import Typography from '../../components/Typography';
import { StyledErrorBoundary } from "./ErrorBoundary.style";

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    try {
      if (window.__AppGuard__)
        window.__AppGuard__.log(error, { trace: errorInfo });
    } catch (e) {
      // Do nothing
    }
  }

  showErrorMessage() {
    return this.state.hasError && (!window.__AppGuard__ || !window.__AppGuard__.config.showDialog);
  }

  reloadPage() {
    window.location.reload();
  }

  render() {
    if (this.showErrorMessage()) {
      return (<>
        <StyledErrorBoundary id="ErrorBoundary">
          <div>
            <Typography variant="h1">We've encountered an unknown issue</Typography>
            <Typography variant="h2">The error will be logged and examined. Please try to <span onClick={this.reloadPage}>refresh</span> the page and try again.</Typography>
          </div>
        </StyledErrorBoundary>
        {this.props.children}
      </>);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
