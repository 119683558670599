import {useEffect, useState} from "react";

export default function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const reset = () => {
    setValue(initialValue);
  };

  const handleChange = (e) => {
    const inputElement = e.target || e;
    if (inputElement.type === 'checkbox')
      setValue(inputElement.checked);
    else {
      const newValue = typeof initialValue === 'number' ? Number(inputElement.value) : inputElement.value;
      setValue(newValue);
    }
  };

  return {
    value,
    reset,
    checked: !!value,
    onChange: handleChange,
  };
}

