import styled from 'styled-components';

export const Styled404 = styled.div`
  &.notfound-page {
   display: flex;
   justify-content: center;
   align-items: center;
   height: calc(100vh - 100px);
  }
`


