import styled from 'styled-components';

export const StyledHelpMenu = styled.div`
  &.menu {
  }
  .menu__opener {
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    .btn {
      font-weight: bold;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      border-radius: 50%;
      color: white;
      background-color: ${props => props.theme.colors.primary};
      width: 28px;
      height: 28px;
      margin-top: 2px;
      ${(props) => props.mode === "dark" && `fill:#efefef;`}
    }
  }
  .menu__list {
    width: 40rem;
    position: fixed;
    z-index: 12;
    right: 0;
    top: 8rem;
    bottom: 0;
    background-color: #efefef;
    padding:0.2rem;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.26, 0.20, 0.020, 1.6);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    .header {
      display: flex;
      justify-content: center;
      position: relative;
      h2 {
        font-size: 20px;
        margin: 20px 0;
      }
      .close-btn {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
        &:hover {
          background: #d2d2d2;
          border-radius: 50%;
        }
      }
    }
    .menu-content {
      padding: 20px;
      > div {
        cursor: pointer;
        padding: 10px;
        font-size: 14px;
        border-radius: 5px;
        margin: 14px 0;
        h3 {
          font-weight: bold;
          margin: 0;
        }
        &:hover {
          background: white;
        }
      }
    }
  }

  &.menu--open .menu__list {
    opacity: 0.95;
    visibility: visible;
    transform: scale(1);
  }
`
