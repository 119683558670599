import React, {useState, useEffect} from 'react';
import {StartupsAPI, InvestorsAPI} from "../../../api";
import {useSelector} from "react-redux";
import Loading from '../../Loading/Loading';
import RadioButton from "../../RadioButton";
import {StyledFileSelector} from "./FileSelector.style";
import Typography from "../../Typography";


export default function FileSelector({ watchlistId, selectedFile: initialFile, onFileSelected }){
  const {user} = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchDataFilesList(initialFile);
  }, []);

  const fetchDataFilesList = (fileToSelect) => {
    setIsLoading(true);
    const API = isStartup ? StartupsAPI : InvestorsAPI;
    API.getUploadedDataFiles(watchlistId).then(({success, data}) => {
      if (success) {
        setFiles(data.files);
        if (fileToSelect)
          selectFile(fileToSelect, data.files);
        else if (!selectedFile && data.files?.length === 1)
          selectFile(data.files[0].name, data.files);
      }
      setIsLoading(false);
    });
  };

  const selectFile = (fileName, fileCollection = files) => {
    setSelectedFile(fileName);
    if (onFileSelected) {
      const file = fileCollection.find(f => f.name === fileName);
      onFileSelected(fileName, file ? new Date(Number(file.lastModified)) : null);
    }
  };

  const renderFilesList = () => {
    const options = (files || []).map((file, index) => {
      const isLargeFile = file.name.endsWith('.large');
      const isZeroSize = file.size <= 0;
      return {
        value: file.name,
        disabled: isLargeFile || isZeroSize,
        label: <div className="radio-option-lbl" key={`file-${index}-${file.name}`}>
          <div>
            <div>{file.name.replace(/.large$/gm,'')}</div>
            {isLargeFile && <div className="comment">(Large file - not yet uploaded)</div>}
            {isZeroSize && <div className="comment">(Empty file)</div>}
          </div>
        </div>
      };
    });

    return <div className="files-list">
      {isLoading ?
        <Loading size="small" />
      :
        <RadioButton layout="vertical" onChange={(e) => {
          selectFile(e.target.value);
        }} options={options} selectedValue={selectedFile} />
      }
    </div>;
  };

  return <StyledFileSelector>
    <Typography variant="h3">Available files</Typography>
    {renderFilesList()}
  </StyledFileSelector>;
};


