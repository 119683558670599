import BaseApi from './base-api';

class PublicAPI extends BaseApi {
  constructor() {
    super('/public');
  }

  preSignup(email, type) {
    const preSignupBody = {email};
    if (type)
      preSignupBody.type = type;
    return this.post('pre-signup', preSignupBody);
  }

  validateSignedUrl(url) {
    return this.post('signed/validate', {url});
  }
}

export default new PublicAPI();
