import React, {useEffect, useState} from 'react';
import { StyledNavigationPanel } from './NavigationPanel.style'
import cx from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavigationPanel({ options, selectedValue: initialValue, onSelect, className }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isHashCodeValid = options.some(o => o.value === initialValue);
  const [selectedValue, setSelectedValue] = useState(isHashCodeValid ? initialValue : options[0]?.value);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const selectValueIfValid = (item) => {
    if (selectedValue === item.value)
      return;

    if (item.redirectTo) {
      navigate(item.redirectTo);
    }

    const isHashCodeValid = options.some(o => o.value === item.value);
    if (isHashCodeValid && selectedValue !== item.value) {
      setSelectedValue(item.value);
      if (onSelect)
        onSelect(item.value);
    }
  };

  useEffect(() => {
    const hashCode = (location.hash || '').replace('#', '');
    selectValueIfValid({ value: hashCode });
  }, [location]);

  const onInternalClick = (evt, item) => {
     if (item.onClick) {
       evt.stopPropagation();
       evt.preventDefault();
       item.onClick();
     } else {
       selectValueIfValid(item);
     }
  };

  const renderItem = (item, index) => {
    if (item.type === 'section')
      return <div key={index} className="section">{item.label}</div>;

    if (item.disabled)
      return <div key={index} className="disabled">{item.icon} <span>{item.label}</span>{item.labelPostfix}</div>;

    if (item.type === 'separator')
      return <hr key={index} />;

    let href;
    if (!item.redirectItem && !item.redirectTo)
      href = `#${item.value}`;

    return <a key={index} href={href} onClick={(evt) => onInternalClick(evt, item)} className={cx({selected: selectedValue === item.value})}>{item.icon} <span>{item.label}</span>{item.labelPostfix}</a>;
  };

  return (
      <StyledNavigationPanel className={cx('nav-panel', className)} >
        {options.map(renderItem)}
      </StyledNavigationPanel>
  );
};

