const mapPalette = (palette) => {
  return {
    lightest: colors[`palette${palette}-lightest`],
    lighter: colors[`palette${palette}-lighter`],
    light: colors[`palette${palette}-light`],
    primary: colors[`palette${palette}`],
    dark: colors[`palette${palette}-dark`],
    darker: colors[`palette${palette}-darker`],
    darkest: colors[`palette${palette}-darkest`]
  }
}

const colors = {
    'background-color': '#fdfbfb',
    'background-color-dark-mode': '#2d2d2d',
    'body-color': '#fff',
    primary: '#00A1DE',
    primaryHover: '#028dc1',
    secondary: '#02C4C3',
    secondaryLighten: '#00EDEC',
    secondaryDarken: '#019897',
    secondaryHover: '#01adac',
    brand: '#02C4C3', // branding
    accent: '#e90085', // use for emphasize action and highlight information
    neutral: '#272e4a', // text and container
    'success ': '#6bb121', // user success
    error: '#D53C21', // user error
    shadow: '#9E9E9E', // box shadows
    gray: "#BBBBBB", // Gray tags
    lightGray: "#D8D8D8",
    lighterGray: "#f6f6f6",
    pink: "#E27EA6",

    /* Color overrides with readable names */
    'lightBlue': '#12999E', // Used for highlighted tags
    green: '#9ECE62',
    darkGreen: '#73AF55',
    cyan: '#02C4C3',

    /* Identify */
    'identity-cyan': '#02C4C3',
    'identity-green': '#9ECE62',
    'identity-yellow': '#E7CE63',
    'identity-orange': '#ED7E5D',

    /* Extended Palette */
    /* Core - 1 */
    'ex1': '#C060A1',
    'ex2': '#3D72B0',
    'ex3': '#00A1DE',
    'ex4': '#12999E',
    'ex5': '#02C4C3',
    'ex6': '#73AF55',
    'ex7': '#9ECE62',
    'ex8': '#E7CE63',
    'ex9': '#ED7E5D',
    'ex10': '#D53C21',
    /* Darker */
    'ex11': '#A41180',
    'ex12': '#28467E',
    'ex13': '#0083BD',
    'ex14': '#10625E',
    'ex15': '#00A8A3',
    'ex16': '#508741',
    'ex17': '#7DB53E',
    'ex18': '#D2A200',
    'ex19': '#E75C18',
    'ex20': '#BB3019',
    /* Core  */
    'ex21': '#E5BAD6',
    'ex22': '#4E91CF',
    'ex23': '#50BEE5',
    'ex24': '#10B3C9',
    'ex25': '#ADE5E6',
    'ex26': '#93C974',
    'ex27': '#C6E2A4',
    'ex28': '#F1E2A2',
    'ex29': '#F2A28A',
    'ex30': '#F1492A',

    /* Text colors */
    'text-base': '#272727',
    'text-lighter': '#222',
    label: '#7b7b7b',
    legend: '#9b9b9b',
    tag: '#202122',
    white: '#FFFFFF',
    black: '#000',
    disabled: "#BBBBBB",

    /* Link color */
    link: "#00A1DE",
    linkhover: '#0081BE',
    navigation: "#02C4C3",

    /* Signals */
    bad: '#ED6D5D',
    warning: '#E6C432',
    good: '#55AF7B',

    /* Color Palettes for Charts */
    'palette1-lightest': '#CDF0ED',
    'palette1-lighter': '#B1E3E6',
    'palette1-light': '#96CCDB',
    palette1: '#7CB0CE',
    'palette1-dark': '#659EAE',
    'palette1-darker': '#50888C',
    'palette1-darkest': '#3B6A66',

    'palette2-lightest': '#CBCFEE',
    'palette2-lighter': '#B3B0E2',
    'palette2-light': '#A294D6',
    palette2: '#967AC8',
    'palette2-dark': '#7364A7',
    'palette2-darker': '#524F88',
    'palette2-darkest': '#3A3E67',

    'palette3-lightest': '#F2CFD9',
    'palette3-lighter': '#E9B4CB',
    'palette3-light': '#DF9BC3',
    palette3: '#D382BE',
    'palette3-dark': '#B26B95',
    'palette3-darker': '#90546E',
    'palette3-darkest': '#6E3E4B',

    'palette4-lightest': '#F8EBCB',
    'palette4-lighter': '#F3D4AC',
    'palette4-light': '#EBB690',
    palette4: '#E29276',
    'palette4-dark': '#BF8760',
    'palette4-darker': '#9B774B',
    'palette4-darkest': '#756337',

    'palette5-lightest': '#E7E2B3',
    'palette5-lighter': '#D6DA92',
    'palette5-light': '#B9CB72',
    palette5: '#A6AA5C',
    'palette5-dark': '#888147',
    'palette5-darker': '#655933',
    'palette5-darkest': '#504120',
    /* End Color Palettes for Charts */
};

colors.chartPallette = [
  colors.ex1, colors.ex2, colors.ex3, colors.ex4, colors.ex5, colors.ex6, colors.ex7, colors.ex8, colors.ex9, colors.ex10,
  colors.ex11, colors.ex12, colors.ex13, colors.ex14, colors.ex15, colors.ex16, colors.ex17, colors.ex18, colors.ex19, colors.ex20,
  colors.ex21, colors.ex22, colors.ex23, colors.ex24, colors.ex25, colors.ex26, colors.ex27, colors.ex28, colors.ex29, colors.ex30
];

colors.chartsPalettes = {
  revenue: mapPalette(1),
  logos: mapPalette(2),
  custom: mapPalette(3),
  efficiency: mapPalette(4),
  pnl: mapPalette(5)
}

export default colors;

