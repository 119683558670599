import styled from 'styled-components';

export const StyledDataFilesDialog = styled.div`
  height: calc(100vh - 4rem);
  .datafiles-dialog {
    height: calc(100% - 50px);
    box-shadow: none;
    padding: 0 10px 10px 10px;
    .dialog-title {
      font-size: 50px;
    }
  }
`;

