import React from "react";
import PropTypes from 'prop-types';
import { StyledChartLegend } from './ChartLegend.style';

export default function ChartLegend({ series=[], growthVisibility, mainColor }) {
  const getSegmentName = (key) => {
    return ((key || '').split(`['`)[1] || '').split(`']`)[0];
  };

  const currentSeries = series.filter(s => !s.key.includes('transparent') && !s.key.endsWith(`insight['value']`)).sort((i1, i2) => i1.order - i2.order).reduce((acc, s) => {
    const segment = getSegmentName(s.key);
    if (segment) {
      if (!acc.find(i => i.label === segment))
        acc.push({label: segment, color: s.color, type: s.type});
    } else {
      acc.push(s);
    }
    return acc;
  }, []);

  return (
        <StyledChartLegend>
          {currentSeries.map((s, index) => (s.label ? <div key={`legend_` + index}>
                <i className={s.type} style={{backgroundColor: s.color}} />
                <span>{s.label}</span>
              </div> : null))
          }
          {growthVisibility ?
            <div className="growth-item" >
              <span style={{color: mainColor}}>{growthVisibility} growth</span>
            </div>
          : null}
        </StyledChartLegend>
    );
}

ChartLegend.propTypes = {
  series: PropTypes.array
};

ChartLegend.defaultProps = {
  series: []
};
