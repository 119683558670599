import React, {useEffect, useState} from 'react';
import {StyledFieldsDefinitionCard} from './FieldsDefinitionCard.style';
import {useSelector} from "react-redux";
import enums from "../../../utils/enums";
import Select2 from "../../Select2";
import DatePicker from '../../DatePicker';
import moment from "moment";

const RECURRING_VALUE = 'recurring';
const NON_RECURRING_VALUE = 'non-recurring';

function filterFields(fields, sourceFileType) {
  return fields.filter(f => f.sourceFileType === sourceFileType).sort((a, b) => a.id - b.id);
}

function getFileLastModifiedDate(fileLastModified, format= 'YYYY-MM-DD') {
  const momentDate = moment(fileLastModified);
  if (momentDate.isValid())
    return momentDate.format(format);
}

const setInitialValues = (company, fieldsMapping, sourceFileType, initialValidationDate) => {
  const fields = filterFields(fieldsMapping, sourceFileType);
  if ([enums.SourceFileType.MRR, enums.SourceFileType.Bookings].includes(sourceFileType)) {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    if (attributionDateField && !attributionDateField.dirty) {
      attributionDateField.additionalInfo = attributionDateField.additionalInfo || {};
      attributionDateField.additionalInfo.firstMonthCalculation = company.FirstMonthCalculation;
      if (!attributionDateField.additionalInfo.validationDate)
        attributionDateField.additionalInfo.validationDate = initialValidationDate;
    }
  }
  return fields;
};

export default function FieldsDefinitionCard({ sourceFileType, fieldsMapping = [], onChange, fileLastModified, company }){
  const {user} = useSelector((state) => state.auth);
  const isEditable = user?.isAdmin;
  const initialValidationDate = getFileLastModifiedDate(fileLastModified) || getFileLastModifiedDate(new Date());
  const [fields, setFields] = useState(setInitialValues(company, fieldsMapping, sourceFileType, initialValidationDate));
  const [validationDateOption, setValidationDateOption] = useState(initialValidationDate);

  const updatePropertyValue = (field, propName, value, propertiesToReset = []) => {
    const newState = [...fields];
    const fieldState = newState.find(f => f.id === field.id);
    fieldState[propName] = value;

    if (propName === 'format') {
      const endDateFieldState = newState.find(f => f.name === 'endDate');
      if (endDateFieldState) {
        endDateFieldState[propName] = value;
        endDateFieldState.dirty = true;
      }
    }

    propertiesToReset.forEach(p => {
      fieldState[p] = ['customerKpi'].includes(p) ? false : '';
    });

    if (propName === 'dataType') {
      fieldState.format = '';
    }

    if (propName === 'dataType' && ['currency', 'revenue', 'number', NON_RECURRING_VALUE].includes(value)) {
      fieldState.format = enums.NUMBER_VALUE_FORMATS[0].value;
      fieldState.currencyType = enums.CURRENCY_SYMBOLS[0];
    }

    fieldState.dirty = true;
    setFields(newState);
    if (onChange)
      onChange(newState);
  };

  const getMrrActualQuarters = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const { format, sourceFields } = attributionDateField;
    const minimumResolution = determineMinimumResolution(format);
    if (sourceFileType === enums.SourceFileType.MRR && minimumResolution === enums.Period.Quarterly) {
      const quarters = sourceFields.replace(/c_/g, '').replace(/_/g, ' ').toUpperCase().split(',').map(quarter => ({
        label: quarter,
        value: moment(quarter, format.replace('-', ' ')).endOf('quarter').format('YYYY-MM-DD')
      }));

      return quarters;
      // const today = moment().format('YYYY-MM-DD');
      // return quarters.filter(q => q.value <= today);
    }
    return [];
  };

  const updateValidationDate = (date) => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    if (attributionDateField) {
      let newValidationDate = date;
      if (!isQuarterlyMrr()) {
        newValidationDate = date === 'MAX_BOOKING_DATE' ? date : getFileLastModifiedDate(date || fileLastModified) || getFileLastModifiedDate(new Date());
        if (attributionDateField.additionalInfo?.validationDate === newValidationDate)
          return;
      }
      updateAttributionDateAdditionalInfo({validationDate: newValidationDate});
    }
  };

  const updateAttributionDateAdditionalInfo = (data = {}) => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    if (attributionDateField) {
      attributionDateField.additionalInfo = {
        ...attributionDateField.additionalInfo,
        ...data
      };
      attributionDateField.dirty = true;
      const newState = [...fields];
      setFields(newState);
      if (onChange)
        onChange(newState);
    }
  };

  // const updateSalesCycle = (unit, value) => {
  //   const attributionDateField = fields.find(f => f.name === 'attributionDate');
  //   if (attributionDateField) {
  //     const {salesCycleUnit, salesCycleValue} = attributionDateField.additionalInfo || {};
  //     const info = {salesCycleUnit, salesCycleValue};
  //     if (unit)
  //       info.salesCycleUnit = unit;
  //     if (!isNaN(value))
  //       info.salesCycleValue = value;
  //
  //     attributionDateField.additionalInfo = {
  //       ...attributionDateField.additionalInfo,
  //       ...info
  //     };
  //     attributionDateField.dirty = true;
  //     const newState = [...fields];
  //     setFields(newState);
  //     if (onChange)
  //       onChange(newState);
  //   }
  // };

  const isQuarterlyMrr = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const { format } = attributionDateField;
    const minimumResolution = determineMinimumResolution(format);
    return sourceFileType === enums.SourceFileType.MRR && minimumResolution === enums.Period.Quarterly;
  };

  const setDefaultValues = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const { validationDate } = attributionDateField?.additionalInfo || {};
    if (validationDate) {
      const lastModified = getFileLastModifiedDate(fileLastModified);
      if (lastModified !== validationDate && validationDateOption !== 'MAX_BOOKING_DATE')
        setValidationDateOption('CUSTOM');
      const date = (validationDate !== 'MAX_BOOKING_DATE') ? moment(validationDate, 'YYYY-MM-DD').toDate() : validationDate;
      updateValidationDate(date);
    } else
      updateValidationDate();
  };

  // const setDefaultValues = () => {
  //   const attributionDateField = fields.find(f => f.name === 'attributionDate');
  //   const quarterlyMrr = isQuarterlyMrr();
  //   const { validationDate } = attributionDateField?.additionalInfo || {};
  //   if (validationDate) {
  //     if (!quarterlyMrr) {
  //       const lastModified = getFileLastModifiedDate(fileLastModified);
  //       if (lastModified !== validationDate && validationDateOption !== 'MAX_BOOKING_DATE')
  //         setValidationDateOption('CUSTOM');
  //       const date = (validationDate !== 'MAX_BOOKING_DATE') ? moment(validationDate, 'YYYY-MM-DD').toDate() : validationDate;
  //       updateValidationDate(date);
  //     }
  //   } else {
  //     if (quarterlyMrr) {
  //       const quarters = getMrrActualQuarters();
  //       if (quarters.length)
  //         updateValidationDate(quarters[quarters.length-1].value);
  //     } else
  //       updateValidationDate();
  //   }
  // };

  const renderDataTypeSelect = (field) => {
    const value = field.dataType === 'currency' && field.revenueType === NON_RECURRING_VALUE ? NON_RECURRING_VALUE : field.dataType;
    return <Select2
      className="datatype-input"
      label='Data type'
      options={enums.DATA_TYPES_OPTIONS}
      value={value || ''}
      disabled={!isEditable}
      onChange={(e) => updatePropertyValue(field, 'dataType', e.target.value, ['format', 'currencyType'])}
    />;
  };

  const renderRevenueTypeSelect = (field, options = enums.REVENUE_TYPES_OPTIONS) => {
    const value = field.revenueType;
    return <Select2
      className="revenuetype-input"
      label='Revenue type'
      options={options}
      value={value || ''}
      disabled={!isEditable}
      onChange={(e) => updatePropertyValue(field, 'revenueType', e.target.value, [])}
    />;
  };

  const renderFormatSelect = (field) => {
    if (field.dataType && ['date', 'currency', 'revenue', NON_RECURRING_VALUE].includes(field.dataType)) {
      let formatOptions = enums.NUMBER_VALUE_FORMATS;
      if (field.dataType === 'date') {
        formatOptions = field.validFormats.map(o => {
          let label = o;
          label = label.replace('[FQ]', '');
          label = label.replace('[Q]', '');
          label = label.replace('[FY]', '');
          if (!label.includes('YY') && label.includes('Y'))
            label = label.replace('Y', 'YYYY');
          if (!label.includes('MMMM') && !label.includes('MMM') && !label.includes('MM') && label.includes('M'))
            label = label.replace('M', 'MM');
          if (!label.includes('DD') && label.includes('D'))
            label = label.replace('D', 'DD');
          return {value: o, label};
        });
      }
      const defaultValue = field.dataType === 'currency' ? '-' : undefined;
        return <Select2
          className="format-input"
          label='Format'
          options={formatOptions}
          value={field.format || defaultValue}
          disabled={!isEditable}
          onChange={(e) => {
            updatePropertyValue(field, 'format', e.target.value);
          }}
        />;
    }
    if (field.dataType === 'text') {
      return <label><input type="checkbox" checked={field.format === 'case-insensitive'} onChange={(e) => updatePropertyValue(field, 'format', e.target.checked ? 'case-insensitive' : null)} /> case insensitive</label>
    }
  };

  const renderLabelInput = (field) => {
    return <div className="label"><span>Attribute as appears in the file</span>{field.label || <em>- Blank cells -</em>}</div>;
  };

  const renderDateFormatting = (dateField) => {
    if (dateField.format && dateField.validFormats.length <= 1)
      return null;
    return <div className="field-row" >
        {renderLabelInput(dateField)}
        {shouldShowTypeColumn() && <div></div>}
        {renderFormatSelect(dateField)}
    </div>;
  };

  const renderRevenueField = (field, {index, arrMrrOnly, hideTypeSelection} ) => {
    const typeOptions = ['ARR', 'MRR'];
    if (sourceFileType === enums.SourceFileType.Bookings)
      typeOptions.push('TOTAL');

    if (!arrMrrOnly)
      typeOptions.push(NON_RECURRING_VALUE);

    let typeSelection;
    if (shouldShowTypeColumn())
        typeSelection = !hideTypeSelection && typeOptions.length ? renderRevenueTypeSelect(field, enums.REVENUE_TYPES_OPTIONS.filter(o => typeOptions.includes(o.value))) : <div></div>;

    return <div className="field-row" key={field.id} >
      {renderLabelInput(field)}
      {typeSelection}
      {renderFormatSelect(field)}
    </div>;
  };

  const renderRevenueCategory = (revenueField, index=0) => {
    return <div className="field-row" key={revenueField.id} >
      {renderLabelInput(revenueField)}
      {renderRevenueTypeSelect(revenueField, enums.REVENUE_TYPES_OPTIONS.filter(o => [RECURRING_VALUE, NON_RECURRING_VALUE].includes(o.value)))}
    </div>;
  };

  const renderMRRMandatoryFields = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const revenueField = fields.find(f => f.name === 'revenue');
    const revenueCategories = fields.filter(f => f.name === 'revenueCategory');
    attributionDateField.label = 'Date format';
    // revenueField.label = 'Revenue format';
    return <>
      {renderRevenueField(revenueField, {arrMrrOnly: true})}
      {revenueCategories.map((revenueTypeField, index) => renderRevenueCategory(revenueTypeField, index))}
      {renderDateFormatting(attributionDateField)}
    </>;
  };

  const renderBookingsMandatoryFields = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const revenueFields = fields.filter(f => f.name === 'revenue');
    const revenueCategories = fields.filter(f => f.name === 'revenueCategory');
    attributionDateField.label = 'Date format';
    return <>
      {revenueFields.map((revenueField, index) => renderRevenueField(revenueField, {index, arrMrrOnly: !!revenueCategories.length}))}
      {revenueCategories.map((revenueTypeField, index) => renderRevenueCategory(revenueTypeField, index))}
      {renderDateFormatting(attributionDateField)}
    </>;
  };

  const renderExpensesMandatoryFields = () => {
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const revenueField = fields.find(f => f.name === 'amount');
    const attributionDateFieldExpected = fields.find(f => f.name === 'attributionDateExpected');
    const revenueFieldExpected = fields.find(f => f.name === 'amountExpected');
    const attributionDateFieldForecast = fields.find(f => f.name === 'attributionDateForecast');
    const revenueFieldForecast = fields.find(f => f.name === 'amountForecast');
    return <>
      {renderDateFormatting(attributionDateField)}
      {renderRevenueField(revenueField, {hideTypeSelection: true})}
      {attributionDateFieldExpected && renderDateFormatting(attributionDateFieldExpected)}
      {revenueFieldExpected && renderRevenueField(revenueFieldExpected, {hideTypeSelection: true})}
      {attributionDateFieldForecast && renderDateFormatting(attributionDateFieldForecast)}
      {revenueFieldForecast && renderRevenueField(revenueFieldForecast, {hideTypeSelection: true})}
    </>;
  };

  const renderMandatoryFields = () => {
    let mandatoryFields;
    switch (sourceFileType) {
      case enums.SourceFileType.Bookings:
        mandatoryFields = renderBookingsMandatoryFields();
        break;
      case enums.SourceFileType.MRR:
        mandatoryFields = renderMRRMandatoryFields();
        break;
      case enums.SourceFileType.Customers:
        mandatoryFields = null;
        break;
      case enums.SourceFileType.PnL:
      case enums.SourceFileType.BalanceSheet:
      case enums.SourceFileType.CashFlow:
        mandatoryFields = renderExpensesMandatoryFields();
        break;
    }
    return <div>
      <h4>Revenue formatting</h4>
      {mandatoryFields}
    </div>;
  };

  const renderAdditionalFields = () => {
    const additionalFields = fields.filter(f => f.isExternal && !f.name);
    if (additionalFields.length) {
      return <div>
        <h4>Attributes formatting</h4>
        {additionalFields.map(f => <div className="field-row" key={`additional-${f.id}`}>
          {renderLabelInput(f)}
          {renderDataTypeSelect(f)}
          {renderFormatSelect(f)}
        </div>)
        }
      </div>
    }
  };

  const determineMinimumResolution = (periodFormat) => {
    if (periodFormat) {
      if (periodFormat.includes('Q'))
        return enums.Period.Quarterly;
      if (['YYYY', 'YY'].includes(periodFormat))
        return enums.Period.Yearly;
    }
    return enums.Period.Monthly;
  }

  const renderAdditionalSettings = () => {
    if (![enums.SourceFileType.MRR, enums.SourceFileType.PnL].includes(sourceFileType))
      return null;

    const {FiscalYearEndingMonth} = company;
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const { isFiscalYearInput, fiscalYearEndingMonth } = attributionDateField?.additionalInfo || {};
    const { format } = attributionDateField;
    let minimumResolution = determineMinimumResolution(format);

    let showCalendarFiscalSelect = minimumResolution !== enums.Period.Monthly;
    let showFiscalYearEndingMonthSelect = isFiscalYearInput && minimumResolution === enums.Period.Quarterly && FiscalYearEndingMonth === 12;
    if (!showCalendarFiscalSelect && !showFiscalYearEndingMonthSelect)
      return null;

    const fiscalYearEndMonthOptions = enums.FISCAL_YEAR_END_MONTH_OPTIONS.map(o => ({...o}));

    return <div>
      <h4>Period settings</h4>
      {showCalendarFiscalSelect &&
        <div className="field-row" >
          <div className="label">Calendar / Fiscal</div>
            <Select2
              className="datatype-input"
              label='Value'
              options={enums.FISCAL_CALENDAR_OPTIONS}
              value={!!isFiscalYearInput ? 'fiscal' : 'calendar'}
              onChange={(e) => updateAttributionDateAdditionalInfo({isFiscalYearInput: e.target.value === 'fiscal'})}
            />
        </div>
      }
      {showFiscalYearEndingMonthSelect &&
        <div className="field-row" >
          <div className="label">Fiscal year ends on</div>
            <Select2
              className="fiscal-year-options"
              label='Month'
              options={fiscalYearEndMonthOptions}
              value={fiscalYearEndingMonth || FiscalYearEndingMonth || 12}
              onChange={(e) => updateAttributionDateAdditionalInfo({fiscalYearEndingMonth: e.target.value})}
            />
        </div>
      }
      {/*{isFiscalYearInput && isReducedOptions &&*/}
      {/*  <div className="field-note">*/}
      {/*    <strong>Note:</strong> Since the {enums.SourceFileType.MRR === sourceFileType ? 'P&L' : 'MRR'} was loaded as calendar quarters, only the months specified above can be used as fiscal year ends.*/}
      {/*  </div>*/}
      {/*}*/}
    </div>;
  };

  // const renderAdditionalSettings = () => {
  //   if (![enums.SourceFileType.MRR, enums.SourceFileType.PnL].includes(sourceFileType))
  //     return null;
  //
  //   const {ExpensesDataReady, BookingsDataReady, BookingsFiscalInput, ExpensesFiscalInput, FiscalYearEndingMonth} = company;
  //   const attributionDateField = fields.find(f => f.name === 'attributionDate');
  //   const { isFiscalYearInput, fiscalYearEndingMonth } = attributionDateField?.additionalInfo || {};
  //   const { format } = attributionDateField;
  //   let minimumResolution = determineMinimumResolution(format);
  //
  //   let showCalendarFiscalSelect = minimumResolution !== enums.Period.Monthly;
  //
  //   const otherFiscalYearInput = sourceFileType === enums.SourceFileType.MRR ? ExpensesFiscalInput : BookingsFiscalInput;
  //   const isOtherFileGenerated = sourceFileType === enums.SourceFileType.MRR ? ExpensesDataReady : BookingsDataReady;
  //   let showFiscalYearEndingMonthSelect = false;
  //   const fiscalYearEndMonthOptions = enums.FISCAL_YEAR_END_MONTH_OPTIONS.map(o => ({...o}));
  //
  //   if (minimumResolution === enums.Period.Quarterly) {
  //     if (isFiscalYearInput) {
  //       if (!otherFiscalYearInput && isOtherFileGenerated) {
  //         showFiscalYearEndingMonthSelect = true;
  //         fiscalYearEndMonthOptions.filter(o => ![3,6,9,12].includes(o.value)).forEach(o => o.disabled = true);
  //       }
  //     } else if (otherFiscalYearInput) {
  //       showFiscalYearEndingMonthSelect = true;
  //       fiscalYearEndMonthOptions.filter(o => ![3,6,9,12].includes(o.value)).forEach(o => o.disabled = true);
  //     }
  //   } else {
  //     if (otherFiscalYearInput) {
  //       showFiscalYearEndingMonthSelect = true;
  //     }
  //   }
  //
  //   if (!showCalendarFiscalSelect && !showFiscalYearEndingMonthSelect)
  //     return null;
  //
  //   return <div>
  //     <h4>Period settings</h4>
  //     {showCalendarFiscalSelect &&
  //       <div className="field-row" >
  //         <div className="label">Calendar / Fiscal</div>
  //         <Select2
  //           className="datatype-input"
  //           label='Value'
  //           options={enums.FISCAL_CALENDAR_OPTIONS}
  //           value={!!isFiscalYearInput ? 'fiscal' : 'calendar'}
  //           onChange={(e) => updateAttributionDateAdditionalInfo({isFiscalYearInput: e.target.value === 'fiscal'})}
  //         />
  //       </div>
  //     }
  //     {showFiscalYearEndingMonthSelect &&
  //       <div className="field-row" >
  //         <div className="label">Fiscal year ends on</div>
  //         <Select2
  //           className="fiscal-year-options"
  //           label='Month'
  //           options={fiscalYearEndMonthOptions}
  //           value={fiscalYearEndingMonth || FiscalYearEndingMonth || 12}
  //           onChange={(e) => updateAttributionDateAdditionalInfo({fiscalYearEndingMonth: e.target.value})}
  //         />
  //       </div>
  //     }
  //     {/*{isFiscalYearInput && isReducedOptions &&*/}
  //     {/*  <div className="field-note">*/}
  //     {/*    <strong>Note:</strong> Since the {enums.SourceFileType.MRR === sourceFileType ? 'P&L' : 'MRR'} was loaded as calendar quarters, only the months specified above can be used as fiscal year ends.*/}
  //     {/*  </div>*/}
  //     {/*}*/}
  //   </div>;
  // };

  const renderFileDate = () => {
    if (![enums.SourceFileType.Bookings, enums.SourceFileType.MRR].includes(sourceFileType))
      return null;

    const isMRR = sourceFileType === enums.SourceFileType.MRR;
    const displayFormat = isMRR ? 'MMMM YYYY' : 'MMMM, DD YYYY';
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    // const { additionalInfo = {} } = attributionDateField;
    // const { validationDate } = additionalInfo;
    // if (isQuarterlyMrr()) {
    //   const options = getMrrActualQuarters();
    //   // let optionValue = validationDate;
    //   // if (!optionValue && options.length)
    //   //   optionValue = options[options.length-1].value;
    //
    //   return <div className="field-row file-date">
    //     <div className="label">Last actual quarter</div>
    //     <Select2
    //       className="file-date-options-input"
    //       label='Options'
    //       options={options}
    //       value={validationDate || options[options.length-1].value}
    //       onChange={(e) => {
    //         updateValidationDate(e.target.value);
    //       }}
    //     />
    //   </div>
    // }

    const fileModificationDate = !fileLastModified || fileLastModified.getTime() > Date.now() ? new Date() : fileLastModified;
    const lastModifiedDate = getFileLastModifiedDate(fileModificationDate);
    const lastModifiedDateString = getFileLastModifiedDate(fileModificationDate, displayFormat);
    const options = [];
    if (lastModifiedDateString)
      options.push({label: `File creation date (${lastModifiedDateString})`, value: lastModifiedDate});
    options.push({label: `Last calculated booking ${isMRR ? 'month' : 'date'}`, value: 'MAX_BOOKING_DATE'});
    options.push({label: 'Custom', value: 'CUSTOM'});
    const currentValidationDate = attributionDateField?.additionalInfo?.validationDate ? moment(attributionDateField.additionalInfo.validationDate, 'YYYY-MM-DD').toDate() : new Date();
    const optionValue = attributionDateField?.additionalInfo?.validationDate === 'MAX_BOOKING_DATE' ? 'MAX_BOOKING_DATE' : validationDateOption;
    return <div>
      <h4>Attributes formatting</h4>
      <div className="field-row file-date">
        <div className="label">Set last actual {isMRR ? 'month' : 'date'} to</div>
        <Select2
          className="file-date-options-input"
          label='Options'
          options={options}
          value={optionValue}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setValidationDateOption(selectedValue);
            updateValidationDate(selectedValue === 'CUSTOM' ? lastModifiedDate : selectedValue);
          }}
        />
        {optionValue === 'CUSTOM' &&
          <div className="format-input">
            <DatePicker date={currentValidationDate} dateFormat={displayFormat} onChange={(dt) => updateValidationDate(dt)} maxDate={new Date()} />
          </div>
        }
      </div>
    </div>;
  };

  const renderFirstMonthCalculation = () => {
    if (![enums.SourceFileType.MRR].includes(sourceFileType))
      return null;
    const attributionDateField = fields.find(f => f.name === 'attributionDate');
    const firstMonthCalculation = attributionDateField?.additionalInfo?.firstMonthCalculation;
    const allowedValues = enums.FIRST_MONTH_CALCULATION_OPTIONS.map(o => o.value);
    const defaultValue = sourceFileType === enums.SourceFileType.MRR ? 'existing' : 'new';
    return <div>
      <h4>First month calculation</h4>
      <div className="field-row file-date">
        <div className="label">Count all MRR of the first month as</div>
        <Select2
            className="datatype-input"
            label='Setting'
            options={enums.FIRST_MONTH_CALCULATION_OPTIONS}
            value={firstMonthCalculation || defaultValue}
            onChange={(e) => updateAttributionDateAdditionalInfo({firstMonthCalculation: allowedValues.includes(e.target.value) ? e.target.value : defaultValue})}
          />
      </div>
    </div>;
  }

  const shouldShowTypeColumn = () => {
    return [enums.SourceFileType.Bookings, enums.SourceFileType.MRR].includes(sourceFileType);
  };

  const renderFieldsDefinition = () => {
    if (!fields.length)
      return null;

    return <div className="fields-list">
      <div>Please identify the data formats used in the file</div>
      <div className="fields">
        {renderMandatoryFields()}
        {renderAdditionalFields()}
        {renderFileDate()}
        {renderFirstMonthCalculation()}
        {renderAdditionalSettings()}
      </div>
    </div>;
  };

  return <StyledFieldsDefinitionCard>
    {renderFieldsDefinition()}
   </StyledFieldsDefinitionCard>;
};

