import styled from "styled-components";
import iMenu from "../../assets/icons/menu.svg";
import iClose from "../../assets/icons/close.svg";
import iBack from "../../assets/icons/arrow-left.svg";
import iMerge from "../../assets/icons/merge.svg";
import iLinechart from "../../assets/icons/linechart.svg";
import iMegaphone from "../../assets/icons/megaphone.svg";
import iZart from "../../assets/icons/zart.svg";
import iSearch from "../../assets/icons/search.svg";
import iChatSec from "../../assets/icons/chat-sec.svg";
import iChat from "../../assets/icons/chat.svg";
import iScatter from "../../assets/icons/scatter.svg";
import iTune from "../../assets/icons/tune.svg";
import iBuilding from "../../assets/images/i-building.svg";
import iBuildingWhite from "../../assets/images/i-building-white.svg";
import iBuildingGray from "../../assets/images/i-building-gray.svg";
import iUser from "../../assets/images/i-user-white.svg";
import iEdit from "../../assets/images/i-edit.svg";

export const StyledIcon = styled.i`
  &.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    /* icon size */
    &.xxl {
      width: 128px;
      height: 128px;
      min-width: 128px;
      background-size: contain;
    }
    &.xl {
      width: 64px;
      height: 64px;
      min-width: 64px;
      background-size: contain;
    }
    &.lg {
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-size: contain;
    }
    &.md {
      width: 24px;
      height: 24px;
      min-width: 24px;
      background-size: contain;
    }
    &.sm {
      width: 16px;
      height: 16px;
      min-width: 16px;
      background-size: contain;
    }
    /* icon type */
    &.default {
      background-color: #eee;
      border: 1px solid #ccc;
      position: relative;
      &:before {
        content: "Icon prop 'type' is missing";
        padding: 2px 4px;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        font-size: 11px;
        line-height: normal;
        font-style: normal;
        white-space: nowrap;
        color: white;
        background-color: #D53C21;
        visibility: hidden;
      }
      &:hover:before {
        visibility: visible;
      }
    }
    &.menu {
      background-image: url(${iMenu});
    }
    &.close {
      background-image: url(${iClose});
    }
    &.back {
      background-image: url(${iBack});
    }
    &.merge {
      background-image: url(${iMerge});
    }
    &.linechart {
      background-image: url(${iLinechart});
    }
    &.megaphone {
      background-image: url(${iMegaphone});
    }
    &.zart {
      background-image: url(${iZart});
    }
    &.search {
      background-image: url(${iSearch});
    }
    &.chat-sec {
      background-image: url(${iChatSec});
    }
    &.chat {
      background-image: url(${iChat});
    }
    &.scatter {
      background-image: url(${iScatter});
    }
    &.tune {
      background-image: url(${iTune});
    }
    &.building {
      background-image: url(${iBuilding});
    }
    &.user {
      background-image: url(${iUser});
    }
    &.building-w {
      background-image: url(${iBuildingWhite});
    }
    &.building-g {
      background-image: url(${iBuildingGray});
    }
    &.edit {
      background-image: url(${iEdit});
    }
  }
`;
