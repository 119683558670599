import React, {useState} from "react";
import PropTypes from "prop-types";
import { StyledCompanySupportCard } from "./CompanySupportCard.style";
import CompanyLogo from '../../../../components/CompanyLogo';
import enums from '../../../../utils/enums';
import Converters from "../../../../utils/converters";
import {useDispatch} from "react-redux";
import MoreMenu from "../../../../components/MoreMenu";
import {switchOrganizationAsync} from "../../../../asyncActions/authAsyncActions";
import routes from "../../../../pages/routes";
import {useNavigate} from "react-router-dom";
import {Confirm} from "../../../../components/dialogs";

CompanySupportCard.propTypes = {
  companyRequests: PropTypes.object.isRequired,
};

export default function CompanySupportCard({ companyRequests, onRequestChanged, selectedGroup = 'ACTIVE' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {organizationId, organizationName, organizationLogo, organizationType} = companyRequests;
  const isStartup = companyRequests.organizationType === enums.OrganizationType.Startup;
  const Organization = {id: organizationId, name: organizationName, logo: organizationLogo};
  const [changeStatusConfig, setChangeStatusConfig] = useState({open: false});
  const activeStatuses = [enums.SupportStatus.Pending, enums.SupportStatus.InProgress];
  const inactiveStatuses = [enums.SupportStatus.Aborted, enums.SupportStatus.Done];
  const allowedStatuses = selectedGroup === 'ACTIVE' ? activeStatuses : inactiveStatuses;

  const switchToOrganization = (watchlistId) => {
    dispatch(switchOrganizationAsync(organizationId)).then(() => {
      let companyLink;
      if (watchlistId)
        companyLink = routes.Investors.getWatchlistAnalyticsUrl(watchlistId);
      else
        companyLink = routes.Startups.getStartupAnalyticsUrl();
      navigate(companyLink);
    });
  };

  const markStatus = (requestId, newStatus, confirm = false) => {
    if (confirm) {
      setChangeStatusConfig({open: true, requestId, newStatus});
    } else {
      if (onRequestChanged)
        onRequestChanged(requestId, newStatus);
    }
  };

  const getMenuOptions = (request) => {
    const options = [];
    const { status, id } = request;
    if (status !== enums.SupportStatus.InProgress)
      options.push({label: 'Set in progress', onClick: () => markStatus(id, enums.SupportStatus.InProgress)});
    if (status !== enums.SupportStatus.Done)
      options.push({label: 'Set completed', disabled: !request.dataReady, onClick: () => markStatus(id, enums.SupportStatus.Done, true)});
    if (status !== enums.SupportStatus.Pending)
      options.push({label: 'Set pending', onClick: () => markStatus(id, enums.SupportStatus.Pending)});
    if (status !== enums.SupportStatus.Aborted)
      options.push({label: 'Set cancelled', onClick: () => markStatus(id, enums.SupportStatus.Aborted, true)});
    return options;
  };

  const renderStatus = (status) => {
    return <strong className={`status-${status}`}>{getStatusText(status)}</strong>
  };

  const getOrganizationName = (request) => {
    const {companyName, status, watchlistId} = request;
    if (activeStatuses.includes(status))
      return <strong className="organization link" onClick={() => switchToOrganization(watchlistId)}>{companyName}</strong>;
    return <strong className="organization">{companyName}</strong>
  };

  const renderRequestItem = (request) => {
    const {companyLogo, status} = request;
    if (!allowedStatuses.includes(status))
      return null;

    const company = {id: organizationId, name: organizationName, logo: companyLogo};
    return <div key={request.id} className="request">
      <CompanyLogo company={company} size={32}/>
      <div className="name-cnt">
        {getOrganizationName(request)}
        {isStartup ? <span>{organizationType}</span> : null}
      </div>
      {renderRequestDetails(request)}
    </div>;
  };

  const renderRequestDetails = (request) => {
    const {userName, userEmail, kind, createdAt, updatedAt, status} = request;
    return <>
      <div>{userName || userEmail}</div>
      <div>{getKindText(kind)}</div>
      <div className="created-date">
        <div className="date">{Converters.toShortDateTime(createdAt)}</div>
        <div>{Converters.toElapsedTime(createdAt)} ago</div>
      </div>
      <div>{renderStatus(status)}</div>
      <div className="date">{Converters.toShortDateTime(updatedAt)}</div>
      <div className="actions">
        {activeStatuses.includes(status) ? <MoreMenu options={getMenuOptions(request)}/> : null}
      </div>
    </>;
  };

  const renderOrganizationNameAndLogo = (request) => {
    return <div className="org-name-logo">
      <CompanyLogo company={Organization} size={32}/>
      <div className="name-cnt">
        <strong className={"organization" + (isStartup ? ' link' : '')} onClick={isStartup ? () => switchToOrganization() : null}>{organizationName}</strong>
        <span>{organizationType}</span>
      </div>
      {request ? renderRequestItem(request) : null}
    </div>;
  };

  const renderInvestorRequests = () => {
    return <div className="company-card">
      {renderOrganizationNameAndLogo()}
      {companyRequests.items.map(renderRequestItem)}
    </div>;
  };

  const getMostRelevantRequestItem = () => {
    if (selectedGroup === 'ACTIVE')
      return companyRequests.items.find(r => activeStatuses.includes(r.status));
    return companyRequests.items.find(r => inactiveStatuses.includes(r.status));
  };

  if (!companyRequests?.items?.length)
    return null;

  return (
    <StyledCompanySupportCard>
      {isStartup ? renderRequestItem(getMostRelevantRequestItem()) : renderInvestorRequests()}
      <Confirm title={<strong>Support request status</strong>}
               yesText="Confirm"
               open={changeStatusConfig.open}
               onYes={() => {
                 setChangeStatusConfig({open: false});
                 markStatus(changeStatusConfig.requestId, changeStatusConfig.newStatus);
               }}
               onClose={() => setChangeStatusConfig({open: false})}>
              {getStatusConfirmationPopup(changeStatusConfig.newStatus)}
      </Confirm>
    </StyledCompanySupportCard>
  );
}



function getKindText(kind) {
  switch (kind) {
    case 'DATA_GENERATION': return 'Data generation';
    default: return kind;
  }
}

function getStatusText(status) {
  switch (status) {
    case enums.SupportStatus.Aborted: return 'Cancelled';
    case enums.SupportStatus.Done: return 'Completed';
    case enums.SupportStatus.InProgress: return 'In progress';
    case enums.SupportStatus.Pending: return 'Pending';
    default: return status;
  }
}

function getStatusConfirmationPopup(status) {
  switch (status) {
    case enums.SupportStatus.Aborted: return 'By setting status to cancelled, the item will be moved to inactive requests';
    case enums.SupportStatus.Done: return 'By setting status to completed, a notification will be sent to the requester and the ticket will be resolved';
    case enums.SupportStatus.InProgress: return 'Changing the status to in progress will notify the requester that work has started';
    case enums.SupportStatus.Pending: return 'Changing the status to in progress will notify the requester that work is pending';
    default: return status;
  }
}
