import React, {useEffect, useState} from 'react';
import { StyledSelect2 } from './Select2.style';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


Select2.propTypes = {

};

function prepareOptions(options) {
  if (options && options.length) {
    if (options[0].constructor.name === 'Object')
      return options;
    return options.map(o => ({ label: o, value: o }));
  }
  return [];
}

export default function Select2({ options: initialOptions, label, value, onChange, className, variant='outlined', ...rest }) {
  const [options, setOptions] = useState(prepareOptions(initialOptions));

  useEffect(() => {
    setOptions(prepareOptions(initialOptions));
  }, [initialOptions]);

  return (
        <StyledSelect2 className={className}>
          <FormControl fullWidth>
            {label && <InputLabel id="simple-select-label">{label}</InputLabel>}
            <Select
              labelId="simple-select-label"
              value={value}
              label={label}
              onChange={onChange}
              variant={variant}
              defaultValue={value}
              {...rest}
            >
              {options.map((option, index) => (
                <MenuItem key={`${option.value}-${index}`} value={option.value} disabled={option.disabled} style={{fontWeight: option.value === value ? 'bold': 'normal'}}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledSelect2>
    );
}


