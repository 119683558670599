import React, { useEffect, useState } from "react";
import { StyledInsights } from './Insights.style';
import General from '../../utils/general';
import Typography from "../Typography";
import { ArrowDropUp, Insight } from "../../assets/mui-icons";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { InvestorsAPI, StartupsAPI } from "../../api";

export default function Insights ({ startupId, watchlistId, onCollapseExpand, onInsightClicked, selectedInsight }) {
  if (!General.validateFeatureFlag('insights', watchlistId))
    return null;

  const { user } = useSelector((state) => state.auth);
  const [insights, setInsights] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const API = user.isStartupMode ? StartupsAPI : InvestorsAPI;

  const toggleIsOpen = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onCollapseExpand)
      onCollapseExpand(newState);
  };

  useEffect(() => {
    if (!insights.length) {
      API.getInsights(startupId, watchlistId).then(({success, error, data}) => {
        if (success)
          setInsights(data);
      });
    }
  }, [startupId, watchlistId, API]);

  useEffect(() => {
    if (selectedInsight >= 0 && !isOpen) {
      toggleIsOpen();
    }
  }, [selectedInsight]);

  if (!insights.length)
    return null;

  const onInsightClickedInternal = (insight) => {
    if (onInsightClicked) {
      onInsightClicked(insight);
    }
  };

  return <StyledInsights>
    <Typography className={isOpen ? 'open' : undefined} variant="h3" onClick={toggleIsOpen}><span><Insight />Insights <em>({insights.length})</em></span> {isOpen ? <ArrowDropUp  />:null}</Typography>
    {isOpen ?
      <div className="insights-container">
        {insights.map((insight, index) => <Tooltip arrow placement="left-start" title={insight.tooltip} key={`single_insight_${index}`}><div className={"single-insight" + (insight.id === selectedInsight ? ' selected' : '')} onClick={() => onInsightClickedInternal(insight)}>
          <Typography className="h4"><span className={insight.status} />{insight.name}</Typography>
          <div>
            {insight.description}
          </div>
        </div></Tooltip>)}
      </div>
    : null}
  </StyledInsights>
}

