import styled from 'styled-components';

export const StyledSelect = styled.div`
  .form__select {
    width: 100%;
    margin: 0;
    padding: 0.6rem 1.2rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-family: ${props => props.theme.fonts['primary-font']};
    line-height: 2.6rem;
    height: 4rem;
    color: rgba(0,0,0,0.9);
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='M7.41,9,12,13.58,16.59,9,18,10.41l-6,6-6-6Z'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 0.8rem center;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      box-shadow: 0 0 0 0.4rem rgba(0, 161, 222, 0.2);
      outline: 0;
      border-color: rgba(0, 161, 222, 0.8);
    }
    ${props => props.error ?  `{
      border-color: rgba(213, 60, 63, 0.8);
      &:focus {
        box-shadow: 0 0 0 0.4rem rgba(213, 60, 63, 0.2);
        outline: 0;
        border-color: rgba(213, 60, 63, 0.8);
      }
    }` : ''}
  }
`

