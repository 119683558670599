import styled from 'styled-components';

export const StyledDialog = styled.div`
  padding: 15px;
  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 150px;
    min-width: 450px;
  }
  .dialog-title {
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    >div:first-child {
      flex: 1;
    }

    .icon-close {
      button {
        color: black;
      }
    }
  }

  .dialog-content {
    font-size: 12px;
  }

  .dialog-footer {
    margin-top: 20px;
    padding: 0;
  }

  .button-container:not(:first-child) {
    margin-left: 20px;
  }
`;

