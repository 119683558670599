import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ChartCard from '../ChartCard';
import KPIsAPI from '../../api/kpis';
import CreateChartData, { getHeatmapColorScheme } from "./CreateChartData";
import Converters from "../../utils/converters";
import colors from "../../styles/colors";
import CohortChart from "../CohortChart";
import CustomersTable from "../CustomersTable";
import { TranslationHelper } from "../../utils/i18n";
import ColorsHelper from "../../utils/colors-helper";
import ChartLegend from "../ChartLegend";
import General from "../../utils/general";

LazyChartCard.propTypes = {
  kpiType: PropTypes.string.isRequired,
  startupId: PropTypes.number.isRequired,
  watchlistId: PropTypes.string,
  dataType: PropTypes.oneOf([null, 'percent', 'currency']),
  segmentBy: PropTypes.string,
  filters: PropTypes.object,
  color: PropTypes.string,
  hideTootips: PropTypes.bool,
  hideAxisLabels: PropTypes.bool,
  onChartClick: PropTypes.func,
  cbPrepareChartData: PropTypes.func
};

export default function LazyChartCard({ startupId, watchlistId, kpiType, segmentBy, filters, dataType, color,
                                        hideTootips = false, onChartClick,  displayLegend = true,
                                        onDataFetched, hideAxisLabels = false, layout, highlightOptions,
                                        filterOnHighlightOptions, isMainChart,selectedBenchmark, onDataDownload, ...rest  }) {
  const [chartType, setChartType] = useState();
  const [chartData, setChartData] = useState(null);
  const [chartLayout, setChartLayout] = useState(layout);
  const [currentFilters, setCurrentFilters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorType, setErrorType] = useState(null);
  const isCompareMode = !!filters?.compareBySegment;

  const fetchChartData = (startupId, watchlistId, kpiType, segmentBy, theFilters, mountedState) => {
    setLoading(true);
    setError();
    const options = {segmentBy};
    let criteria = theFilters?.criteria;
    if (isMainChart) {
      options.includeDataExplanation = true;
      if (!isCompareMode && !kpiType.includes('waterfall'))
        options.includeGrowth = true;

      if (General.validateFeatureFlag('benchmark', watchlistId)) {
        if (selectedBenchmark)
          options.includeBenchmark = selectedBenchmark;
      }
      if (General.validateFeatureFlag('insights', watchlistId)) {
        options.includeInsights = true;
      }
    } else if (theFilters?.compareBySegment && highlightOptions?.selectedSegment && filterOnHighlightOptions && !segmentBy && !kpiType.includes('waterfall')) {
      criteria = JSON.parse(JSON.stringify(criteria));
      const filter = Object.values(criteria.must || criteria.should || {}).find(item => item.leftOperand === theFilters.compareBySegment);
      if (filter) {
        filter.rightOperand = [highlightOptions.selectedSegment];
      }
    }

    KPIsAPI.getKpi(startupId, watchlistId, kpiType, options, {...theFilters, criteria}).then(({success, data, error}) => {
      if (mountedState.mounted) {
        if (success) {
          dataType = data.valueDataType || dataType;
          setChartType(data.chartType);
          setChartLayout(data.chartLayout);
          if (data.error) {
            setError(TranslationHelper.getError(data.error));
            setErrorType(data.errorType);
          }
          const chartData = prepareChartData(data, color, kpiType, dataType, hideAxisLabels, hideTootips, rest.isFiscalYear, theFilters, highlightOptions, isMainChart, watchlistId);
          setChartData(chartData);
          if (onDataFetched)
            onDataFetched(data);
        } else {
          setError(error);
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const alteredFilters = { ...filters };

    delete alteredFilters.subType;

    if (filterOnHighlightOptions)
      Object.assign(alteredFilters, highlightOptions);

    if ((kpiType.includes('-retention') || kpiType.includes('-cohort')) && !kpiType.includes(':customers'))
      delete alteredFilters.selectedCohort;

    if (kpiType.includes('-waterfall') || kpiType.includes('-accumulative') || segmentBy)
      alteredFilters.selectedPeriod = highlightOptions?.selectedPeriod;
    else if (!kpiType.includes(':customers') && !kpiType.includes('-accumulative') && !segmentBy) {
      delete alteredFilters.selectedPeriod;
    }

    if (!General.isEqualObject(alteredFilters, currentFilters)) {
      setCurrentFilters(alteredFilters);
    }
  }, [filters, kpiType, segmentBy, highlightOptions, filterOnHighlightOptions]);

  useEffect(() => {
      const mountedState = { mounted: true };  // note mutable flag
      if (currentFilters)
        fetchChartData(startupId, watchlistId, kpiType, segmentBy, currentFilters, mountedState);
      return () => {
        mountedState.mounted = false
      }; // cleanup toggles value, if unmounted
  }, [startupId, watchlistId, kpiType, segmentBy, currentFilters, selectedBenchmark]);

  if (loading || !chartData)
    return <ChartCard loading={true} color={color} hideTootips {...rest} error={error} isMainChart={isMainChart} isCompareMode={isCompareMode}/>;

  const {showLegend} = chartData;
  let legendEnabled = displayLegend && showLegend;

  let selectedSeriesKey = segmentBy ? null : highlightOptions?.selectedPeriod;
  let selectedSeriesSubType = kpiType.includes('-waterfall') ? highlightOptions?.subType : null;
  if (chartType === 'cohort') {
    selectedSeriesKey = highlightOptions?.selectedCohort;
    if (selectedSeriesKey)
      selectedSeriesSubType = highlightOptions?.selectedPeriod;
    else if (highlightOptions?.selectedPeriod)
      selectedSeriesKey = `c ${highlightOptions?.selectedPeriod}`;
  }

  if (chartType === 'heatmap') {
    selectedSeriesKey = highlightOptions?.selectedCohort;
    selectedSeriesSubType = highlightOptions?.selectedPeriod;
  }

  if (chartType === 'cohort') {
    const isCompare = chartData.length > 1;
    return <>
      {isCompare && isMainChart ? <ChartLegend series={chartData[0].series} /> : null}
      <div className={`${isCompare ? 'multi-charts' : ''}`}>
      {chartData.map((resultSeries, index) => {
        return <CohortChart key={`cohort_${index}`} data={resultSeries.data}
                          metadata={resultSeries.metadata}
                          hideTootips={hideTootips || isCompare}
                          onChartClick={onChartClick}
                          title={resultSeries.title}
                          selectedSeriesKey={selectedSeriesKey}
                          error={error}
                          isMainChart={isMainChart}
                          {...rest}
        />;
    })}</div></>;
  }

  if (chartType === 'customers-table') {
    return <CustomersTable
              kpiType={kpiType}
              tableColumns={chartData.columns}
              data={chartData.data}
              title={chartData.title}
              dataType={chartData.dataType}
              selectedSeriesKey={selectedSeriesKey}
              error={error}
              errorType={errorType}
              compareField={filters.compareBySegment}
              sortOrder={chartData.sortOrder}
              onDataDownload={onDataDownload}
              {...rest}
    />;
  }

  let chartDataCopy = chartData;
  if (!Array.isArray(chartData)) {
    chartDataCopy = [{...chartData}];
    if (highlightOptions?.displayKeyFilter && chartDataCopy[0]?.metadata?.series?.length > 1) {
      if (!chartData.hasGrowthValues && chartData.metadata.series.some(s => s.key.endsWith('QoQ') || s.key.endsWith('YoY'))) {
        chartDataCopy[0].metadata = {...chartDataCopy[0].metadata};
        const displayBenchmark = !!selectedBenchmark && ['YoY', 'QoQ'].includes(highlightOptions?.displayKeyFilter);
        chartDataCopy[0].metadata.series = chartData.metadata.series.filter(s => s.key.endsWith(highlightOptions.displayKeyFilter) || (displayBenchmark && s.key.startsWith('benchmark_')));
        legendEnabled = legendEnabled && chartDataCopy[0].metadata.series.length > 1;
      }
    }
  }

  const allowGrowthVisibility = () => {
    return isMainChart && !kpiType.includes(':growth-rate');
  };

  const renderChartCards = (chartsData) => {
    return chartsData.map((singleChartData, index) => {
      return <ChartCard key={`single_chart_${index}`}
                 chartType={chartType}
                 color={color}
                 hideTootips={hideTootips}
                 onChartClick={onChartClick}
                 {...singleChartData}
                 showLegend={legendEnabled}
                 layout={chartLayout}
                 selectedSeriesKey={selectedSeriesKey}
                 selectedSeriesSubType={selectedSeriesSubType}
                 error={error}
                 errorType={errorType}
                 isMainChart={isMainChart}
                 isCompareMode={isCompareMode}
                 growthVisibility={allowGrowthVisibility() ? highlightOptions?.displayKeyFilter : null}
                 {...rest}
              />;
    });
  };

  if (chartDataCopy.length > 1)
    return <>{isMainChart && chartType === 'heatmap' ? <ChartLegend series={chartDataCopy[0].series} /> : null}
      <div className="multi-charts">
      {renderChartCards(chartDataCopy)}
  </div></>

  return renderChartCards(chartDataCopy);
}


function prepareChartData(data, color, kpiType, dataType, hideAxisLabels, hideTootips, isFiscalYear, filters, highlightOptions, isMainChart, watchlistId) {
  const isCompareMode = !!filters?.compareBySegment;
  if (data.chartType === 'heatmap')
    return prepareHeatMapData(data, color, kpiType, data.chartType, filters, isFiscalYear);
  if (data.chartType === 'customers-table')
    return prepareCustomersTableData(data, filters);
  else if (data.chartType === 'cohort')
    return prepareMrrCohortData(data, filters);
  else if ([KPIsAPI.Types.ARR_Waterfall].includes(kpiType)) {
    const chartData = CreateChartData(prepareWaterfallData(data, isFiscalYear), color, kpiType, data.chartType, dataType, hideAxisLabels, data.kpiCategory, filters, highlightOptions?.displayKeyFilter, isMainChart, watchlistId, isFiscalYear);
    chartData.showLegend = isCompareMode;
    return chartData;
  }
  else {
    const chartData = CreateChartData(data, color, kpiType, data.chartType, dataType, hideAxisLabels, data.kpiCategory, filters, highlightOptions?.displayKeyFilter, isMainChart, watchlistId, isFiscalYear);
    if (filters.compareBySegment)
      chartData.showLegend = true;
    if (data.hasBenchmark)
      chartData.hasBenchmark = true;
    if (data.hasInsights)
      chartData.hasInsights = true;
    if (data.hasGrowthValues)
      chartData.hasGrowthValues = true;
    return chartData;
  }
}


function prepareHeatMapData(serverData, color, kpiType, chartType, filters, isFiscalYear) {
  const weightedAvgLabel = 'Weighted Avg';
  const isCompare = !!filters?.compareBySegment;
  const returnedData = [];
  const isARR = kpiType !== 'logos-retention';

  if (isCompare) {
    const series = [];
    const segments = serverData.dataKeyPoints;
    segments.forEach((segmentName, index) => {
      const color = ColorsHelper.getColorPalette(segmentName).primary;
      const colorScheme = getHeatmapColorScheme(kpiType, segmentName);
      series.push({key:segmentName, label:segmentName, color});
      let yLabels = Object.keys(serverData.data).filter(key => key !== weightedAvgLabel).sort();
      const data = [];
      let maxValuesCount = 0;
      const emptyRowsToRemove = [];
      yLabels.forEach(lbl => {
        const itemValues = Object.values(serverData.data[lbl].values).map(v => v.hasOwnProperty(segmentName) ? v[segmentName] * 100 : 0);
        itemValues.shift();
        maxValuesCount = Math.max(itemValues.length, maxValuesCount);
        if (itemValues.length)
          data.push(itemValues);
        else
          emptyRowsToRemove.push(lbl);
      });

      const itemValues = Object.values(serverData.data[weightedAvgLabel].values[segmentName]).map(v => v * 100);
      itemValues.shift();
      maxValuesCount = Math.max(itemValues.length, maxValuesCount);
      if (itemValues.length) {
        data.push(itemValues);
        yLabels.push(weightedAvgLabel);
      }
      if (emptyRowsToRemove.length)
        yLabels = yLabels.filter(lbl => !emptyRowsToRemove.includes(lbl));

      let xLabelPrefix;
      if (yLabels[0].includes('-Q'))
        xLabelPrefix = 'Q';
      else if (yLabels[0].includes('-'))
        xLabelPrefix = 'M';
      else
        xLabelPrefix = 'Year';

      const xLabels = [];
      for (let i=1; i<=maxValuesCount; i++)
        xLabels.push(`${xLabelPrefix}${i}`);

      const fixedYLabels = yLabels.map(lbl => lbl.replace('c ', '').replace('-', ' '));

      const resultData = {
        series,
        yLabels: fixedYLabels,
        xLabels,
        data,
        chartType,
        valueFormatter: Converters.toPercentage,
        colorScheme,
        color: colors.brand,
        weightedAvgLabel,
        cohortsReferences: prepareHeatmapCohortsReferences(serverData.data, segmentName, weightedAvgLabel, isARR, isCompare),
        isARR
      };

      if (index === 0)
        resultData.title = serverData.title;
      else if (index === 1)
        resultData.title = <span style={{visibility: 'hidden'}}>&nbsp;</span>;

      returnedData.push(resultData);
    });
  } else {
    let yLabels = Object.keys(serverData.data).filter(key => key !== weightedAvgLabel).sort();
    yLabels.push(weightedAvgLabel);
    const data = [];
    let maxValuesCount = 0;
    const emptyRowsToRemove = [];
    yLabels.forEach(lbl => {
      const itemValues = Object.values(serverData.data[lbl].values).map(v => v * 100);
      itemValues.shift();
      maxValuesCount = Math.max(itemValues.length, maxValuesCount);
      if (itemValues.length)
        data.push(itemValues);
      else
        emptyRowsToRemove.push(lbl);
    });

    if (emptyRowsToRemove.length)
      yLabels = yLabels.filter(lbl => !emptyRowsToRemove.includes(lbl));

    let xLabelPrefix;
    if (yLabels[0].includes('-Q'))
      xLabelPrefix = 'Q';
    else if (yLabels[0].includes('-'))
      xLabelPrefix = 'M';
    else
      xLabelPrefix = 'Year';

    const xLabels = [];
    for (let i=1; i<=maxValuesCount; i++)
      xLabels.push(`${xLabelPrefix}${i}`);

    const fixedYLabels = yLabels.map(lbl => lbl.replace('c ', '').replace('-', ' '));

    returnedData.push({
      title: serverData.title,
      yLabels: fixedYLabels,
      xLabels,
      data,
      chartType,
      valueFormatter: Converters.toPercentage,
      colorScheme: getHeatmapColorScheme(kpiType),
      color: colors.brand,
      weightedAvgLabel,
      cohortsReferences: prepareHeatmapCohortsReferences(serverData.data, null, weightedAvgLabel, isARR, isCompare),
      isARR
    });
  }

  return returnedData;
}

function prepareHeatmapCohortsReferences(cohortsData, segmentName, weightedAvgLabel, isCurrency = false, isCompare = false) {
  const cohorts = Object.keys(cohortsData).filter(key => key !== weightedAvgLabel);
  const getReferenceValue = (cohort) => {
    return segmentName ? cohortsData[cohort].referenceValues[segmentName] : cohortsData[cohort].referenceValue;
  };
  const maxValue = Math.max(...cohorts.map(cohort => getReferenceValue(cohort)));
  const converterOptions = {maximumFractionDigits: isCompare ? 0 : 2};
  return cohorts.reduce((acc, cohort) => {
    const referenceValue = getReferenceValue(cohort);
    acc[cohort.replace('c ', '').replace('-', ' ')] = {
      referenceValue: isCurrency ? Converters.toCurrencyString(referenceValue, converterOptions) : Converters.toDecimalString(referenceValue),
      weight: referenceValue / maxValue * 100
    };
    return acc;
  }, {});
}

function formatPeriod(period, isFiscalYear) {
  return isFiscalYear ? Converters.formatFiscalPeriodString(period) : Converters.formatPeriodString(period);
}

function prepareMrrCohortData(serverData, filters) {
  const isCompareMode = !!filters?.compareBySegment
  const { data, title, dataKeyPoints = [] } = serverData;
  const chartSeries = [];
  const result = [];
  const cohortsData = [];
  let cohortNames = [];
  Object.keys(data).sort().forEach(key => {
    const item = data[key];
    cohortsData.push({
      name: key,
      ...data[key]
    });
    cohortNames = [...cohortNames, ...Object.keys(item)];
  });
  if (isCompareMode) {
    dataKeyPoints.forEach((segmentName, index) => {
      const resultData = { data: cohortsData, metadata: [], series: chartSeries};
      if (index === 0)
        resultData.title = title;
      else if (index === 1)
        resultData.title = <span style={{visibility: 'hidden'}}>&nbsp;</span>;
      const colorPalette = ColorsHelper.getColorPalette(segmentName);
      const availableColors = Object.values(colorPalette);
      const reversedColors = [...availableColors];
      reversedColors.reverse().splice(0, 1);
      const colorScheme = [...availableColors, ...reversedColors];
      resultData.metadata = {
        palette: colorPalette,
        cohorts: Array.from(new Set(cohortNames)).sort().map((cohortName, index) => {
          return {
            key: `${cohortName}['${segmentName}']`,
            label: cohortName,
            color: colorScheme[index % (colorScheme.length-1)]
          };
        })
      };
      chartSeries.push({key: segmentName, label: segmentName, color: colorPalette.primary });
      result.push(resultData);
    })
  } else {
    const resultData = { data: cohortsData, metadata: [], title};
    const availableColors = Object.values(colors.chartsPalettes.revenue);
    const reversedColors = [...availableColors];
    reversedColors.reverse().splice(0, 1);
    const colorScheme = [...availableColors, ...reversedColors];
    resultData.metadata = {
      palette: colors.chartsPalettes.revenue,
      cohorts: Array.from(new Set(cohortNames)).sort().map((cohortName, index) => {
        return {
          key: cohortName,
          color: colorScheme[index % (colorScheme.length-1)]
        };
      })
    };
    result.push(resultData);
  }
  return result;
}

const prepareWaterfallData = (serverData, isFiscalYear) => {
  const current = serverData.data[0];
  const subTypeMap = {new: 'New', reactivation: 'Reactivation', expansion: 'Expansion', contraction: 'Contraction', churn: 'Churn'};
  let tooltipTitle = formatPeriod(current.startingPeriod, isFiscalYear);
    if (current.startingPeriod !== current.endingPeriod)
      tooltipTitle += ' - ' + formatPeriod(current.endingPeriod, isFiscalYear);
  if (current.segments) {
    let mrrSubTypes = ['new', 'reactivation', 'expansion', 'contraction', 'churn'];
    Object.values(current.segments).forEach(segmentValues => {
      mrrSubTypes = mrrSubTypes.filter(st => Object.keys(segmentValues).includes(st));
    });
    const maxValues = mrrSubTypes.reduce((acc, subType) => {
      acc[subType] = Object.values(current.segments).reduce((acc, item) => {
        const subTypeValue = Math.abs(item[subType]);
        if (subTypeValue > 0 && subTypeValue > acc)
          acc = subTypeValue;
        return acc;
      }, 0);
      return acc;
    }, {});
    maxValues.startingBalance = 0;
    maxValues.endingBalance = 0;


    const segmentNames = Object.keys(current.segments);
    const data = [{
      period: 'Starting ARR',
      segments: segmentNames.reduce((acc, segment) => {
        if (maxValues.startingBalance < current.segments[segment].startingBalance)
          maxValues.startingBalance = current.segments[segment].startingBalance;
        acc[segment] = {
          transparent: 0,
          startingBalance: current.segments[segment].startingBalance,
          total: current.segments[segment].endingBalance
        }
        return acc;
      }, {})
    }];


    const mrrSubTypesMap = {new: 'startingBalance', reactivation: 'new', expansion: 'reactivation', contraction: 'expansion', churn: 'contraction'};
    const currentValues = {};
    const getAccumulativeValues = (subType) => {
      const newSubtype = mrrSubTypesMap[subType];
      if (newSubtype) {
        let value = maxValues[newSubtype] || 0;
        if (['contraction', 'churn'].includes(newSubtype))
          value = -value;
        return value + getAccumulativeValues(newSubtype);
      }
      return 0;
    };

    mrrSubTypes.forEach(subType => {
      data.push({
        period: subTypeMap[subType],
        segments: segmentNames.reduce((acc, segment) => {
          const keyValue = current.segments[segment][subType];
          if (!keyValue)
            return acc;
          const value = Math.abs(keyValue);
          if (!currentValues[segment] || currentValues[segment] < value)
            currentValues[segment] = value;
          acc[segment] = {
            [subType]: value,
            transparent: getAccumulativeValues(subType) - (['contraction', 'churn'].includes(subType) ? value : 0),
            total: data[0].segments[segment].endingBalance
          }
          return acc;
        }, {})
      })
    });

    data.push({
      period: 'Ending ARR',
      segments: segmentNames.reduce((acc, segment) => {
        acc[segment] = {
          transparent: 0,
          endingBalance: current.segments[segment].endingBalance,
          total: current.segments[segment].endingBalance,
        }
        return acc;
      }, {}),
    });
    return {
      ...serverData,
      data,
      disableTooltipActiveMode: true,
      tooltipTitle
    };
  } else {
    const data = [{
      period: 'Starting ARR',
      transparent: 0,
      startingBalance: current.startingBalance,
      total: current.endingBalance
    }];
    const keys = ['new', 'reactivation', 'expansion', 'contraction', 'churn'];
    let currentValue = data[0].startingBalance;
    keys.forEach(key => {
      const keyValue = current[key];
      if (!keyValue)
        return;
      if (keyValue < 0)
        currentValue += keyValue;
      data.push({
        period: subTypeMap[key],
        [key]: Math.abs(keyValue),
        transparent: currentValue,
        total: data[0].total
      })
      if (keyValue > 0)
        currentValue += keyValue;
    });

    data.push({
      period: 'Ending ARR',
      transparent: 0,
      endingBalance: current.endingBalance,
      total: data[0].total,
    });

    return {
      ...serverData,
      data,
      disableTooltipActiveMode: true,
      tooltipTitle
    };
  }
};

const prepareCustomersTableData = (serverData, filters) => {
  const { tableColumns, data, title } = serverData;
  const sortOrder = [];
  if (filters?.compareBySegment && filters?.criteria) {
    const criteria = Object.values(filters.criteria.must || filters.criteria.should || {}).find(item => item.leftOperand === filters.compareBySegment);
    if (criteria && criteria.rightOperand) {
      sortOrder.push(...criteria.rightOperand);
    }
  }
  return {
    title,
    data,
    columns: tableColumns,
    sortOrder
  };
};
/*
function shouldFetchGrowthRate(kpiType, chartType, isMainChart) {
  return isMainChart &&
    !kpiType.includes(':growth-rate') &&
    !['heatmap', 'customers-table', 'cohort'].includes(chartType) &&
    ![KPIsAPI.Types.ARR_Waterfall].includes(kpiType);
}

const appendGrowthData = (chartData, growthData) => {
  debugger;
  return chartData;
};
*/
