import styled from 'styled-components';
// show badge of current environment mode
// mode [ development > qa > staging ]
export const StyledEnvironmentTag = styled.i`
        &.environment--mode-development {
            background-color: #ff8f00;
        }
        &.environment--mode-demo {
            background-color: #619e4d;
        }
        &.environment--mode-staging {
            background-color: #d480e8;
        }
        &.environment--mode-production {
            background-color: #d480e8;
        }

        &__wrapper {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 14px;
        }

        font: normal bold 9px/1.2 Arial, sans-serif;
        color: #fff;
        background-color: #ff8f00;
        letter-spacing: 0.5em;
        text-align: center;
        position: fixed;
        z-index: 10;
        top:0; /* -25px; */
        left: 50%;
        display: block;
        float: left;
        padding: 0.3rem 1rem 0.3rem 1.7rem;
        border-radius: 0 0 4px 4px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: top 500ms linear;
        transition: top 500ms linear;

        &::before {
            content: '';
            position: inherit;
            top: 0;
            left: 0;
            right: 100%;
            height: 3px;
            background-color: inherit;
            -webkit-transition: left 1s ease 500ms;
            transition: left 1s ease 500ms;
        }
        &::after {
            content: '';
            position: inherit;
            top: 0;
            left: 100%;
            right: 0;
            height: 3px;
            background-color: inherit;
            -webkit-transition: right 1s ease 500ms;
            transition: right 1s ease 500ms;
        }
        ${props => props.animate && `
            top: 0;
            &::before {
                left: -50vw;
            }
            &::after {
                right: -50vw;
            }
        `}
`;

export default {
    StyledEnvironmentTag,
};
