import React from "react";
import { StyledUsersManagement } from './UsersManagement.style';
import PageTitle from "../../../components/PageTitle";
import Recommendations from "../../../components/Recommendations";
import TeamListPanel from '../../common/TeamListPanel';
import { useLocation } from "react-router-dom";

export default function UsersManagement() {
  const location = useLocation();
  const from_path = location.state?.from_path || `/`;

  return (
    <StyledUsersManagement>
      <PageTitle icon="close" variant="h1" lineheight="1" path={from_path}>Team management</PageTitle>
      <div className="page-content">
        <div className="groups">
        </div>
        <div className="main">
          <TeamListPanel />
        </div>
        <div className="suggestions">
          <Recommendations type="investors" />
        </div>
      </div>
    </StyledUsersManagement>
  );
};
