import axios from 'axios';

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
    return axios.get(`/token`);
};

export const getCSRFToken = async () => {
    return axios.get('/csrf-token');
};

// verify refresh token to generate new access token if refresh token is present
export const userReset = async (email, password, verification) => {
    return axios.post(`/reset`, { email, password, verification });
};

// user login API to validate the credential
export const userRegisterService = async (signedUrlGuid, email, name, password, organizationName, websiteUrl, industry, type, timezone) => {
    return axios.post(`/register`, { signedUrlGuid, email, name, password, organizationName, websiteUrl, industry, type, timezone });
};

// user login API to validate the credential
export const userLoginService = async (email, password, mfaCode) => {
  return axios.post(`/login`, { email, password, mfaCode});
};

// user login API to validate the credential
export const userForgotPasswordService = async (email) => {
    return axios.post(`/forgot`, { email });
};

export const validateSignature = async (verification) => {
    return axios.get(`/validate?verification=${verification}`);
};

// verify email address
export const verifyEmail = async (verification) => {
    return axios.post(`/verify`, { verification });
};

// manage user logout
export const userLogoutService = async () => {
    return axios.post(`/logout`);
};

// manage team join validation
export const validateJoinService = async (signedUrl) => {
  return axios.post(`/validateJoin`, {signedUrl});
};

// manage team join
export const joinTeamService = async (signedUrl, name, password, timeZone) => {
  return axios.post(`/join`, {signedUrl, name, password, timeZone});
};

// get reset link
export const getResetLink = async (email) => {
    return axios.post(`/resetlink`, { email });
};

export const switchOrganization = async (organizationId) => {
  return axios.get(`/organization/${organizationId}/switch`);
};
