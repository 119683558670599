import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { PieChart as PieChartComponent, Pie, Cell, Tooltip, Legend } from 'recharts';

const PieChart = ({data, metadata, width, height, outerRadius, theme, hideLegend, ...rest}) => {

  const tooltipFormatter = (value) => {
    if (metadata.valueFormatter)
      return metadata.valueFormatter(value);
    return value;
  };

  return (
      <PieChartComponent width={width} height={height} className={"pie-chart"}>
        <Pie
          data={data}
          cx="50%"
          cy={height/2.4}
          labelLine={false}
          legendType="circle"
          outerRadius={outerRadius}
          dataKey={metadata.key || 'value'}
          nameKey={metadata.nameKey || 'name'}
          stroke={"transparent"}
          {...rest}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={theme.colors[entry.color] || entry.color} />
          ))}
        </Pie>
        {!hideLegend && <Legend  />}

        <Tooltip formatter={tooltipFormatter} labelFormatter={(index) => data[index].name}  separator=": "/>
      </PieChartComponent>
    );

};

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  metadata: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  outerRadius: PropTypes.number,
  hideLegend: PropTypes.bool,
};

PieChart.defaultProps = {
  data: [],
  metadata: {},
  width: 160,
  height: 160,
  outerRadius: 80,
  hideLegend: false
};

export default withTheme(PieChart);
