import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '../FormLabel';
import { StyledTextInput } from './TextInput.style';

function TextInput({ label, type, focus, mandatory, error, ...rest }) {
    const searchInput = useRef(null);
    useEffect(() => {
        // current property is referred to input element
        if (focus && searchInput && searchInput.current) {
          searchInput.current.select();
          searchInput.current.focus();
        }
    }, [focus]);

    let input = <input className="form__input" type={type} ref={searchInput} {...rest} />;
    if (type === 'textarea')
      input = <textarea className="form__input" ref={searchInput} {...rest} />;

    const labelObject = mandatory ? <span>{label} <em>* mandatory</em></span> : label;

    return (
        <StyledTextInput error={error}>
          <FormLabel label={labelObject} error={error} />
          {input}
          {error && <span className="error">{error}</span>}
        </StyledTextInput>
    );
}

TextInput.propTypes = {
    label: PropTypes.string,
    focus: PropTypes.bool,
    error: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'textarea', 'password', 'number', 'email']),
};

TextInput.defaultProps = {
    type: 'text',
    focus: false,
    label: '',
    error: false
};

export default TextInput;
