import React, { useEffect, useState } from "react";
import Button from '../Button';
import { StyledInvitations, StyledInvitationConfirmation } from './Invitations.style';
import { UsersAPI } from "../../api";
import CompanyLogo from "../CompanyLogo";
import enums from "../../utils/enums";
import {Confirm} from "../../components/dialogs";
import { useSelector } from "react-redux";


function Invitations({onAccept}) {

  const [invitations, setInvitations] = useState([]);
  const [invitationId, setInvitationId] = useState(null);
  const [mode, setMode] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const isStartup = user?.isStartupMode;

  useEffect(() => {
    UsersAPI.getMyInvitationsReceived().then(({ success, data }) => {
      if (success) {
        setInvitations(data);
      }
    });
  }, []);

  const showConfirmation = (mode, invitationId) => {
    setInvitationId(invitationId);
    setMode(mode);
  };

  const rejectInvitation = async (invitationId) => {
    const {success, data} = await UsersAPI.rejectInvitation(invitationId);
    if (success && data?.invitationStatus === enums.InvitationStatus.Rejected) {
      showConfirmation();
      setInvitations([...invitations.filter(inv => inv.id !== invitationId)]);
    }
  };

  const acceptInvitation = async (invitationId) => {
    const {success, data} = await UsersAPI.acceptInvitation(invitationId);
    if (success && data?.invitationStatus === enums.InvitationStatus.Accepted) {
      showConfirmation();
      setInvitations([...invitations.filter(inv => inv.id !== invitationId)]);
      if (onAccept)
        onAccept();
    }
  };

  const proceedAction = async () => {
    if (mode && invitationId) {
      if (mode === 'accept')
        await acceptInvitation(invitationId);
      else
        await rejectInvitation(invitationId);
    }
  };

  const getConfirmationText = (invitation) => {
    const {InvitingOrganization: company, connectionType} = invitation;
    if (mode === 'accept') {
      if (isStartup)
        return `The ${company.name} will be added to the ${connectionType === enums.ConnectionType.Prospect ? 'Prospect' : 'Current'} investors list and you will have access to the investor profile once you click the 'Confirm' button.`;
      return `The ${company.name} will be added to the ${connectionType === enums.ConnectionType.Prospect ? 'Prospect' : 'Portfolio'} companies list and you will have access to the company data once you click the 'Confirm' button.`;
    } else {
      return `The invitation message will be taken off your 'invitation received' section. The inviter doesn't get a notification about that operation.`;
    }
  };


  const renderConfirmationDialog = () => {
    if (!mode || !invitationId)
      return null;
    const invitation = invitations.find(i => i.id === invitationId);
    if (!invitation)
      return null;



    return <Confirm open onClose={() => showConfirmation()} title={`${mode === 'accept' ? 'Accept' : 'Reject'} invitation`} noText="Cancel" yesText="Confirm" onYes={proceedAction}>
      <StyledInvitationConfirmation>
        {getConfirmationText(invitation)}
      </StyledInvitationConfirmation>
    </Confirm>;
  };

  const renderItem = (invitation, index) => {
    const {InvitingOrganization: company, InvitingUser: contact} = invitation;
    return <div key={`recommendation_${index}`}>
      <div className="company">
        <div className="company-info">
          <CompanyLogo company={company} size={32}/>
          <div className="company-details">
            <strong>{company.name}</strong>
            <span>{contact.name}</span>
          </div>
        </div>
        <div className="company-actions">
          <Button color="primary" size="small" onClick={() => showConfirmation('accept', invitation.id)}>Accept</Button>
          <Button color="white" borderColor="primary" size="small" onClick={() => showConfirmation('reject', invitation.id)}>Ignore</Button>
        </div>
      </div>
    </div>;
  };

  return <StyledInvitations>
    {renderConfirmationDialog()}
    <h3>Received invitations</h3>
    {Array.isArray(invitations) && invitations.length ? invitations.map((item, index) => renderItem(item, index)) : <div className="empty-message">There are no pending invitations</div>}
  </StyledInvitations>;
}

export default Invitations;
