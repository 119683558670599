import styled from "styled-components";

export const StyledImageEditor = styled.div`
  width: 100%;
  height: 100%;
  font-size: 11px;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  cursor: pointer;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .photo {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    button {
      background: transparent;
      border: 0;
      width: 100%;
      vertical-align: middle;
      font-size: 11px;
      cursor: pointer;
      svg {
        font-size: 24px;
        color: ${props => props.theme.colors.primary};
      }
    }
    span.replace {
      position: absolute;
      bottom: -20px;
    }
  }
  input {
    cursor: pointer;
    background: transparent;
    border: none;
  }
`;
